/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Growth from "../images/icons/icon-growth.png";
import LMGrowth from "../images/icons/icon-growth-lm.png";
import Reinforce from "../images/icons/icon-reinforce.png";
import LMReinforce from "../images/icons/icon-reinforce-lm.png";
import Infrastructure from "../images/icons/icon-infrastructure.png";
import LMInfrastructure from "../images/icons/icon-infrastructure-lm.png";
import Decentralisation from "../images/icons/icon-decentralisation.png";
import LMDecentralisation from "../images/icons/icon-decentralisation-lm.png";
import Web3systems from "../images/web3-systems.png";
import Web3systemsmobile from "../images/web3-systems-mobile.png";
import KevinCoutinho from "../images/leaders-partners/Kevin-Coutinho.jpg";
import JoshuaHunt from "../images/leaders-partners/Joshua-Hunt.jpg";
import NormanLip from "../images/leaders-partners/Norman-Lip.jpg";
import AnandSubramanian from "../images/leaders-partners/Anand-Subramanian.jpg";
import PonnieClark from "../images/leaders-partners/Ponnie-Clark.jpg";
import AchintaDas from "../images/leaders-partners/Achinta-Das.jpg";
import MikeStewart from "../images/leaders-partners/mike-stewart.jpg";
import NitishKasar from "../images/leaders-partners/Nitish-Kasar.jpg";
import NeerajKhairwal from "../images/leaders-partners/Neeraj-Khairwal.jpg";
import MatiuRudolph from "../images/leaders-partners/Matiu-Rudolph.jpg";

import PartnerUWA from "../images/partner-uwa.png";
import PartnerMme from "../images/partner-mme.svg";
import PartnerLx from "../images/partner-l1x-labs.svg";
import PartnerPrysm from "../images/partner-prysm-group.svg";
import LMPartnerUWA from "../images/partner-uwa-lm.png";
import LMPartnerMme from "../images/partner-mme-lm.svg";
import LMPartnerLx from "../images/partner-l1x-labs-lm.svg";
import LMPartnerPrysm from "../images/partner-prysm-group-lm.svg";

import PropTypes from "prop-types";
// import { Partner } from "./partner";
// import { LMPartner } from "./partner-lm";
import { Link } from "react-router-dom";
import Config from "../config";

export class About extends Component {
  render() {


    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>About Us</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Who We Are
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>Who We Are</h4>
                  <h1>
                    Meet the leaders at the <br />
                    forefront of blockchain   <br />
                    interoperability.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section web3-usecases our-goals leaders-partners">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-left">Foundation Council</h3>
              </div>
              <div className="col-md-6">
                <p className="pb-80 text-left">
                  Layer One X has been designed in collaboration with industry and
                  academic partners to address critical gaps in current
                  blockchain constructs.
                </p>
              </div>
            </div>
            <div className="cards-section mb-30">
              <div className="row">
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={KevinCoutinho}
                        alt="Kevin Coutinho"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Kevin Coutinho</h4>
                    <p>Founder</p>
                    <a className="social" href="https://www.linkedin.com/in/thekevincoutinho">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={JoshuaHunt}
                        alt="Joshua Hunt"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Joshua Hunt</h4>
                    <p>Cofounder and Legal</p>
                    <a className="social" href="https://www.linkedin.com/in/josh-hunt-640a408/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={NormanLip}
                        alt="Norman Lip"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Norman Lip</h4>
                    <p>Cofounder</p>
                    <a className="social" href="https://www.linkedin.com/in/norman-lip-250b41/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="mb-30 text-mgmt-center">Management</h3>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={MatiuRudolph}
                        alt="Matiu Rudolph"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Matiu Rudolph</h4>
                    <p>Chief Operating Officer</p>
                    <a className="social" href="https://www.linkedin.com/in/matiurudolph/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={MikeStewart}
                        alt="Mike Stewart"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Mike Stewart</h4>
                    <p>Chief Strategy Officer</p>
                    <a className="social" href="https://www.linkedin.com/in/mike-s-8463b4/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={PonnieClark}
                        alt="Ponnie Clark"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Ponnie Clark</h4>
                    <p>Research</p>
                    <a className="social" href="https://www.linkedin.com/in/ponnie-clark-12b0152b/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>

                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={AnandSubramanian}
                        alt="Anand Subramanian"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Anand Subramanian</h4>
                    <p>Advisory</p>
                    <a className="social" href="https://www.linkedin.com/in/asubra75/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={AchintaDas}
                        alt="Achinta Das"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Achinta Das</h4>
                    <p>Blockchain Researcher</p>
                    <a className="social" href="https://www.linkedin.com/in/achinta-das/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={NitishKasar}
                        alt="Nitish Kasar"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Nitish Kasar</h4>
                    <p>Blockchain Researcher</p>
                    <a className="social" href="https://www.linkedin.com/in/nitishkasar/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <figure>
                      <img
                        src={NeerajKhairwal}
                        alt="Neeraj Khairwal"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Neeraj Khairwal</h4>
                    <p>Blockchain Researcher</p>
                    <a className="social" href="https://www.linkedin.com/in/neeraj-khairwal-343b8619/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        {/* <Partner /> */}

        <section className="section bg-blue partner-section">
          <div className="partner">
            <div className="container">
              <p>Partnering with world leading organisations to enable Web3</p>
              <div className="row">
                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerMMEModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerMme
                          : PartnerMme
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerPrysmModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerPrysm
                          : PartnerPrysm
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerL1XLabsModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerLx
                          : PartnerLx
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>

                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerUWAModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerUWA
                          : PartnerUWA
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>

              <div
                className="modal fade partner-modal"
                id="PartnerPrysmModal"
                tabIndex="-1"
                aria-labelledby="PartnerPrysmModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerPrysm
                                      : PartnerPrysm
                                  }
                                  alt="Prysm"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              Prysm Group is the Tokenomics Partner for I1X, delivering value to platforms by leveraging established economic research.
                            </h4>
                            <p>
                              Prysm Group is an economic consulting and corporate learning firm focused on emerging technologies. Founded by Harvard PhD economists, the firm assists corporates, governments and startups in the adoption and implementation of blockchain, digital asset and the metaverse through its advisory and educational services. Prysm Group areas of expertise include incentive design, monetization and governance for both open and closed blockchain and metaverse platforms.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade partner-modal"
                id="PartnerMMEModal"
                tabIndex="-1"
                aria-labelledby="PartnerMMEModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerMme
                                      : PartnerMme
                                  }
                                  alt="MME"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              MME is the Legal Partner for Layer One X, providing comprehensive and interdisciplinary advice in the areas of legal, tax and compliance.

                            </h4>
                            <p>
                              MME is an innovative business law and tax firm with offices in the two Swiss economic centres, Zurich and Zug.

                            </p>
                            <p>Legal | Tax | Compliance: In these three areas we offer comprehensive, interdisciplinary solutions with speed and efficiency. Our specialists tailor their advice to your needs in order to provide practical, cost-effective solutions that sustainably improve your business — whether in a national or an international environment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade partner-modal"
                id="PartnerUWAModal"
                tabIndex="-1"
                aria-labelledby="PartnerUWAModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerUWA
                                      : PartnerUWA
                                  }
                                  alt="UWA"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              The University of Western Australia (UWA) is the academic partner for  Layer One X.
                            </h4>
                            <p>
                              UWA is a leading Australian research university and has an international reputation for excellence, innovation and enterprise. A member of the Australian 'Group of Eight' research universities. Sitting on the banks of the Swan River, the UWA Perth campus is the oldest in Western Australia. UWA offers higher education in many areas, including medicine, architecture and law, in addition to a full Masters program for blockchain.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade partner-modal"
                id="PartnerL1XLabsModal"
                tabIndex="-1"
                aria-labelledby="PartnerL1XLabsModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerLx
                                      : PartnerLx
                                  }
                                  alt="L1X_Labs"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              L1X_LABS is the Development Partner for Layer One X. Working with businesses to build and innovate on the Layer One X blockchain protocol.

                            </h4>
                            <p>
                              At L1.X LABS, we empower businesses to innovate on our proprietary Layer One X blockchain protocol. With our help, clients can build blockchain solutions for business use cases where performance, scalability, security and sustainable growth are key requirements. Every business is unique. We work with you to provide a tailored solution that meets your specific needs.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="section web3-usecases our-goals text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-left">
                  Our goals & priorities at <br />
                  Layer One X
                </h3>
              </div>
              <div className="col-md-6">
                <p className="pb-80 text-left">
                  Layer One X is being built by the Layer One X Foundation Switzerland to
                  facilitate the goals and objectives for an interoperable,
                  decentralised internet.
                </p>
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <figure>
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMGrowth
                          : Growth
                      }
                        alt="Growth" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Growth Opportunities</h4>
                      <p>
                        Unrivalled interoperability between blockchain networks to
                        connect more services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMReinforce
                            : Reinforce
                        }
                        alt="Reinforced"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>Reinforced Reliability</h4>
                      <p>
                        An always-up protocol central to the web3 era that
                        strengthens user trust and sovereignty.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMInfrastructure
                            : Infrastructure
                        }
                        alt="Infrastructure"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>Strong Infrastructure</h4>
                      <p>
                        Deliver superior web3 infrastructure, allowing developers
                        to build rapidly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMDecentralisation
                            : Decentralisation
                        }
                        alt="Decentralisation"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>Decentralisation</h4>
                      <p>
                        Superior decentralisation in governance, commerce and
                        capital markets, improving access for all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section worldsweb3 web3-systems text-left">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 d-flex">
                <div className="content align-self-center">
                  <h3>
                    We’re building layer-one infrastructure to connect the
                    world’s growing web3 systems.
                  </h3>
                  <p>
                    We believe that the current chaotic battle royale of
                    competing trustless chains and protocols must find an
                    equitable accord.{" "}
                  </p>
                  <p>
                    We’re connecting the worlds of digital and physical, DeFi
                    and loyalty; of buying, selling, staking and earning.
                    Forging a new era of user trust and sovereignty that
                    ultimately delivers more equitable outcomes for all.
                  </p>
                  <p>
                    Our foundational, fast and secure interoperability is made
                    to connect, with utility to unite, Layer One X allows
                    developers and builders in Web3 and DeFi to run
                    high-performing projects on enterprise-grade infrastructure.{" "}
                  </p>
                  <p>
                    Welcome to a new dawn of seamless transactions and
                    transportable data throughout and beyond online spaces.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 p-0">
                <figure>
                  <img
                    src={Web3systems}
                    alt="web3 systems"
                    className="img-fluid desktop"
                  />
                  <img
                    src={Web3systemsmobile}
                    alt="Interoperability"
                    className="img-fluid mobile"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-10 m-auto">
                <h2>
                  Built to unite a <br />
                  decentralised future.

                </h2>
                <p>
                  Create the next leap forward with interoperable, decentralised
                  and scalable Web3 infrastructure made for next-gen dApp
                  development.
                </p>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <Link className="glow-button gradient-border-mask" to="/public-sale">
                      {/* <span>Buy Layer One X</span> */}
                      <span>{Config.publicSaleButtonLabel}</span>
                    </Link>
                  </div>

                  <div className="btn-style  btn-style-two">
                    <Link className="glow-button gradient-border-mask" to="../build/build-on-layeronex">
                      <span>Build on Layer One X</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
About.propTypes = {
  theme: PropTypes.string,
};
