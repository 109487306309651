import React, { Component } from "react";

export class NftTerms extends Component {
  render() {
    return (
      <>
        <section class="banner__section Terms__details__section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="banner__content__box">
                  <div class="banner__header how-it-works__banner terms__head">
                    <h2>NFT Giveaway Terms</h2>
                    <h1>
                      The <span>Details</span>{" "}
                    </h1>
                  </div>
                  <p>
                    A limited series of 5000 Layer One X official NFTs will be released, commemorating this first breakthrough in blockchain technology.
                  </p>
                  <p>
                    Each “Premium Wallet Holder” will receive a commemorative NFT. If you do not have a “Premium Wallet”, you have the option to contribute $1000 USDC to the Layer One X private sale. After your contribution has been received, your wallet will be upgraded to a “Premium Wallet”. The wallet holder will receive a limited edition commemorative NFT after the $1000 is contributed to the Layer One X private sale, likewise, the contributor will also receive 10,000 Layer One X tokens  (.10 presale evaluation coin) vested over 15 months from launch. Limit one (1) commemorative NFT per “Premium Wallet”, while supplies last, giveaway terms are subject to change or stop without pre-written notice.
                  </p>
                  <h3>
                   <span> Raffle Giveaway</span>
                  </h3>
                  <p>
                    Each “Premium Wallet” holding a commemorative NFT will also be eligible to participate and will   receive one (1) entry into a draw to win the “Milestone X Moment NFT.” There will only be one mint of this video. The randomly selected wallet address will win ownership over the video of the first-ever recorded decentralized transfer of assets between an EVM and Non-EVM chain. The winner will also receive 100,000 Layer One X tokens given to them at the mainnet launch. The eligible “Premium Wallets” cut-off for the raffle drawing will be 11:59pm WST. The official drawing and winner announcement will be held on or before April 5th, 2023. 
                  </p>
                  <h3>
                   <span> Community Celebration</span>
                  </h3>
                  <p>
                    Five (5) of the 5000 NFTs will be removed from the available NFTs to be given away. These NFTs will be rewarded to the five (5) best eligible content creators who create content related to this historical blockchain moment and post it on their socials.
                  </p>
                  <h3>
                   <span> Social Media Content Eligibility </span>
                  </h3>
                  <h4>
                   <span> Creators </span>
                  </h4>
                  <p>
                    The creator competition will start immediately and finish on the 30th of March. To be considered eligible, a creator has posted their content before 3/31/2023 at 11:59pm WST time. Likewise, they must post the link to their social post(s) in the official Layer One X Discord (https://discord.gg/layeronex) #Giveaway channel explicitly designated for this creator competition. Once the competition closes on the 31th of March at 11:59pm  WST time, within 48 hours ten (10) of the best social media content created will be selected by the Layer One X team. Then the Layer One X Community will vote on the discord channel for their favorite. Voting will end on April 3th, 2023 at 11:59 pm WST time. Winners will be notified through Discord by a Moderator. 
                  </p>
                  <p>
                    The Top five (5) winners will be where each winner will receive two of the highly coveted commemorative NFTs. Plus, they will be entered into the drawing to win the major prize of the Milestone X Moment NFT and the 100,000 Layer One X tokens at launch. 
                  </p>
                  <h4>
                   <span> Premium Wallets </span>
                  </h4>
                  <p>
                    Premium wallet holders are eligible to participate in the social media content contest. Likewise, a premium wallet holder may receive an additional NFT, if they are voted a winner by the Layer One X Discord community.
                  </p>
                  <p>
                    All Layer One X x_Team members are excluded from winning the drawing, including the social media content creator NFT. In the event, one of their wallets is chosen for the drawing, a new premium wallet will randomly be selected. 
                  </p>
                  {/* <div class="details__txt__btn__box">
                    <h2>Don’t Miss Out!</h2>
                    <div className="btn__box text-center">
                      <a href="/invest/buy-l1x" className="btn btn--white btn--animated">
                        Get a Premium Wallet
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NftTerms;
