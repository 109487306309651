import React from "react";
import { Modal } from "react-bootstrap";

const ThankYouMessageModal = (props) => {
  return (
    <>
     
        <Modal
          show={props.show}
          cancel={props.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
             {props.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{props.text}</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={props.close}
              type="button"
              className="btn btn-primary"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default ThankYouMessageModal;
