import React, { useEffect, useState } from "react"
import validator from "validator";

import Logo from "../../images/logol1x-icon.svg";
import { servicePostTermsheetInvestor } from "../../services/apiService";
import Notify from "../../utils/notify";

import CircularProgress from "../CircularProgress/index";
import Config from "../../config";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";

import StorageService from "../../services/storageService";

const TermsComponents = (props) => {
    return (<div className="mb-1 form-check text-left">
        <input
            type="checkbox"
            name="acknowledge"
            value={props.value}
            onChange={props.handleClick}
            className="form-check-input whiteList_terms_checkbox"
            style={{ width: "1.5em", height: "1.5em" }}
        />
        {
            props.hyperLinkText ?
                <Link className="termandcondition__btn" target="_blank" to={props.link}>
                    <label
                        className="form-check-label form-label cursor__pointer"
                        style={{ "verticalAlign": "sub", marginLeft: 10, fontWeight: 600 }}
                    >
                        {props.text} <u>{props.hyperLinkText}</u>
                    </label>
                </Link> :
                <div className="termandcondition__btn" >
                    <label
                        className="form-check-label form-label cursor__pointer"
                        style={{ "verticalAlign": "sub", marginLeft: 10, fontWeight: 600 }}
                    >
                        {props.text}
                    </label>
                </div>
        }

    </div>
    )
}


const TermsheetInvestor = (props) => {

    const [selectedCurrency, setSelectCurrency] = useState('');
    const [amount, setAmount] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitStatus, setsubmitStatus] = useState(false);
    const currencyList = ['AUD', 'USD']
    const [acknowledge, setAcknowledge] = React.useState(false);
    const [mainnetTerms, setMainnetTerms] = React.useState(false);
    const [terms, setTerms] = React.useState(false);
    const [disableApproveButton, setDisableApproveButton] = React.useState(true);
    const [emailAddress, setEmailAddress] = useState('');


    useEffect(() => {

        let parseContactData = StorageService.getData('PREFILLED_USER_DETAILS');

        if (parseContactData) {
            setEmailAddress(parseContactData.email)
        }


        if (window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                window.location.reload();
            });
        }

    }, [])

    const processDirectInvestTx = async () => {
        let errors = {};

        if (validator.isEmpty(selectedCurrency)) {
            errors.network = "Please select network";
        }

        if (validator.isEmpty(amount)) {
            errors.amount = "Please enter  amount";
        }
        else if (parseFloat(amount) <  Config.publicSaleMinInvestmentAmount) {
            errors.amount = `Please enter amount greater than or equal to ${Config.publicSaleMinInvestmentAmount}`;
        }

        setErrors(errors)
        // return
        if (Object.keys(errors).length === 0) {

            setsubmitStatus(true)
            let data = {
                email: emailAddress,
                currency: selectedCurrency,
                amount: amount
            }

            console.log("data", data)
            let _txSubmitResponse = await servicePostTermsheetInvestor(data);

            if (_txSubmitResponse.status === "success") {
                setsubmitStatus(false)
                Notify(_txSubmitResponse.message, "success");
                props.handleUserSteps('termsheetInvestmentThankYouPage')
                return
            }
            else {
                Notify(_txSubmitResponse.message, "error");
                setsubmitStatus(false);
                return
            }
        }
        return errors;
    }

    return (
        <div className="banner__fixed__card__box">
            <div className="banner__fixed__card__head">
                <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
            </div>

            <div className="fixed__card__main__content">
                <div className="direct__tab__btn__section">
                    <div className="tab__btn__box">
                        <h4 className="public_sale_card_heading">Termsheet Contribution</h4>
                        <p className="public_sale_card_p"><small>Details entered once cannot be changed</small></p>
                        <text className="direct__section__subheading">Minimum Contribution ${Config.publicSaleDirectMinInvestmentAmount}</text>
                    </div>
                </div>
                <div>

                    <div className="mb-3 direct_address_margin">
                        <label className="form-label buy_section_form_label">Select Currency <span className="red-astric">*</span></label>
                        <select
                            className="form-select public_sale_input"
                            aria-label="Default select example"
                            name="network"
                            value={selectedCurrency}
                            onChange={(event) => {
                                setSelectCurrency(event.target.value);
                            }}
                        >
                            <option key={"default-network"} value="">
                                Please Select currency
                            </option>

                            {currencyList.map((_currency) => {
                                return (
                                    <option key={_currency} value={_currency}>
                                        {_currency}
                                    </option>
                                );
                            })}
                        </select>
                        {errors && errors.network && (
                            <span className="error">{errors.network}</span>
                        )}
                    </div>


                    <div className="buy_section_form_input_margin">
                        <label className="form-label buy_section_form_label">
                            Enter Amount (
                            {"Min : $" +
                                (props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount.toLocaleString() : Config.publicSalePremiumWalletMinInvestmentAmount.toLocaleString()) +
                                ", Max : $" +
                                Config.publicSaleMaxInvestmentAmount.toLocaleString()}
                            )
                        </label>
                        <input
                            type="hidden"
                            name="amount"
                            value={amount}
                            className="form-control"
                            id=""
                        />

                        <NumericFormat
                            onValueChange={(values) => {
                                setAmount(values.value);
                            }}
                            thousandSeparator={true}
                            className="form-control"
                            placeholder=""
                            inputMode="numeric"
                            prefix="$ "
                            value={amount}
                        />

                        {errors && errors.amount && (

                            <span className="error">
                                <div className="expected_amoun_error">
                                    <p className="expected_amoun_error_txt">Please enter amount  </p>
                                    <p className="expected_amoun_error_txt">*Min contribution amount is  ${Config.publicSaleMinInvestmentAmount.toLocaleString()} USD</p>
                                    <p className="expected_amoun_error_txt">*Max contribution amount is {`<=`} ${Config.publicSaleMaxInvestmentAmount.toLocaleString()} USD</p>
                                </div>
                            </span>
                        )}
                    </div>

                    <>
                        <TermsComponents handleClick={() => {
                            setAcknowledge(!acknowledge)
                            if (!acknowledge && terms && mainnetTerms) {
                                setDisableApproveButton(false)
                            }
                            else {
                                setDisableApproveButton(true)
                            }
                        }}
                            text="Acknowledge that the tokens will be released upon the Genesis block"
                            hyperLinkText={null}
                        />

                        <TermsComponents handleClick={() => {
                            setMainnetTerms(!mainnetTerms)
                            if (terms && acknowledge && !mainnetTerms) {
                                setDisableApproveButton(false)
                            }
                            else {
                                setDisableApproveButton(true)
                            }
                        }}
                            text="I have read and understand the details of the"
                            hyperLinkText="Mainnet Beta Documentation"
                            link="https://blog.l1x.foundation/what-is-layer-one-x-mainnet-beta/"
                        />

                        <TermsComponents handleClick={() => {
                            setTerms(!terms)
                            if (!terms && acknowledge && mainnetTerms) {
                                setDisableApproveButton(false)
                            }
                            else {
                                setDisableApproveButton(true)
                            }
                        }}
                            text="I accept the "
                            hyperLinkText="Terms & Conditions"
                            link="https://blog.l1x.foundation/terms-conditions/"
                        />
                    </>
                    {

                        submitStatus ?
                            <button
                                disabled
                                className="btn btn-primary buy-btn-loader"
                            >
                                <span className="loader-span">
                                    <CircularProgress /> Submiting ...
                                </span>
                            </button>
                            :
                            <div className="direct_investment_confirm_btn_box">
                                <button
                                    type="button"
                                    onClick={processDirectInvestTx}
                                    disabled={disableApproveButton}
                                    className="btn btn-primary buy-btn-loader"
                                >
                                    Proceed Tx
                                </button>
                            </div>
                    }

                    <button disabled={submitStatus} onClick={() => {
                        props.handleUpdateCurrentStep(2)
                        props.handleUserSteps('publicSaleStep')
                    }} className="reciept__details__goBack__btn">
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TermsheetInvestor