import React, { useEffect, useState, useRef } from "react";
import { redirect, useSearchParams } from "react-router-dom";

import uploadIcon from "../../images/upload.svg";
// import * as qs from 'query-string';
// import validator from "validator";
// import { useLocation } from "react-router-dom";
import {
  servicePublicSaleBankTransferConfirmation,
  serviceGetPublicSaleProspectExits,
  servicePublicSaleValidateBankRefID
} from "../../services/apiService";
import Notify from "../../utils/notify";
import closeIcon from "../../images/close-menu-lm.svg";

const BankTransferImageUpload = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [transactionReceiptNumber, setTransactionReceiptNumber] = useState("");
  const inputFile = useRef(null);
  const [errors, setErrors] = useState(null);
  const [submitStatus, setsubmitStatus] = useState(false);
  //const history = useHistory();

  useEffect(() => {
    validateBankRef();

  }, [])

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      var parts = filename.split(".");
      //const fileType = parts[parts.length - 1];
      //console.log("fileType", files); //ex: zip, rar, jpg, svg etc.
      setImageName(filename);
      setImage(files[0]);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onCancelFileUpload = () => {
    setImage("");
    setImageName("");
  };

  const validateBankRef = async () => {
    const _internal_ref = searchParams.get("ref");

    //validate ref id
    let _responseValidateBankRefID = await servicePublicSaleValidateBankRefID(_internal_ref);

    if (_responseValidateBankRefID.status == 'failure') {
      setsubmitStatus(false);
      //Notify(_responseValidateBankRefID.message, "error")

      //await new Promise((r) => setTimeout(r, 2000));
      //window.location.href = '/';
      //history.push("/");
      return props.handleUserSteps('bankTransferRefValidationMessage');
    }
  }

  const handleSubmitImage = async () => {
    let errors = {};
    if (image === "") {
      errors["image"] = "Please upload image";
    }

    if (transactionReceiptNumber == "") {
      errors["transaction_receipt_number"] =
        "Please enter your unique identity number";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setsubmitStatus(true);

      try {
        const formData = new FormData();
        const _internal_ref = searchParams.get("ref");

        if (_internal_ref === null) {
          Notify("Please check the url", "error");
          return;
        }

        formData.append("internal_ref", _internal_ref);
        formData.append("document", image);
        formData.append("transaction_receipt_number", transactionReceiptNumber);



        let _transferConfirmation =
          await servicePublicSaleBankTransferConfirmation(formData);

        if (_transferConfirmation.status === "success") {
          props.handleUserSteps("bankTransferThankYou");
        } else {
          Notify(_transferConfirmation.message, "error");
        }

        setsubmitStatus(false);
      } catch (error) {
        setsubmitStatus(false);
        Notify("Error while submittting", "error");
      }
    }
  };

  const backToRegister = () => {
    window.location.href = "/public-sale";
  };

  return (
    <div className="banner__fixed__card__box thank_you_card_height">
      <div className="public_sale_early_access_header">
        <img
          alt="Transfer $USD from bank account"
          onClick={backToRegister}
          className="public_sale_early_acces_close_icon"
          src={closeIcon}
        />
      </div>

      <div className="p-30">
        <div className="head__thankyou__box">
          <h4 className="thankyou__head">
            {" "}
            Step 5. Transfer $USD from bank account.
          </h4>
        </div>
        <div className="direct_thank_you_txt">
          <p> Upload your bank transfer confirmation. </p>
        </div>

        <div className="upload__image__box">
          <input
            style={{ display: "none" }}
            accept=".jpeg,.png,.pdf,.heic"
            ref={inputFile}
            onChange={handleFileUpload}
            type="file"
          />
          <div
            onClick={() => {
              onButtonClick();
            }}
            className="upload__img__circle"
          >
            {/* <input hidden type="file" name='fileInput' accept ="image/*" onChange ={handleChange} className="upload__img__circle"/> */}
            {imageName !== "" ? (
              <img src={uploadIcon} />
            ) : (
              <img src={uploadIcon} />
            )}
          </div>
          <p className="upload__img__txt">Upload limit 2MB</p>
          <p className="upload__img__txt">
            Allowed file types JPEG, PNG, PDF, HEIC
          </p>
        </div>
        {errors && errors.image && (
          <span className="error">{errors.image}</span>
        )}

        <div className="browse__file__btn__box">
          <button
            onClick={() => {
              onButtonClick();
            }}
            type="button"
            className=" w-100 browse__file__btn"
          >
            Browse File
          </button>
        </div>

        {imageName ? (
          <div className="upload__file__img__deatils">
            <p> {imageName}</p>
            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onCancelFileUpload();
                }}
              >
                Delete
              </span>{" "}
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Added transaction receipt */}
        <div className="direct_thank_you_txt">
          <p> Enter your unique identity number </p>
          <input
            type="text"
            name="transaction_receipt_number"
            className="form-control public_sale_input"
            value={transactionReceiptNumber}
            onChange={(event) =>
              setTransactionReceiptNumber(event.target.value)
            }
            placeholder="Unique identity number"
          />
          {errors && errors.transaction_receipt_number && (
            <span className="error">{errors.transaction_receipt_number}</span>
          )}
        </div>
      </div>

      <div className="public_sale_check_eligibility_box p-30">
        <button
          disabled={submitStatus}
          onClick={() => {
            handleSubmitImage();
          }}
          type="button"
          className="btn btn-primary w-100 close__thankyou__btn"
        >
          {submitStatus ? "Submiting ..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default BankTransferImageUpload;
