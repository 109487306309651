
import * as Sentry from "@sentry/react";
import { NotificationManager } from "react-notifications";
import Config from "../config/index"

let notification = "";

export default function Notify(notificationText, type) {
    Sentry.init({
        // dsn: "https://f967f14631c246688faed62cf474c00c@o4504461740015616.ingest.sentry.io/4504604050980864%22",
        dsn: Config.sentry.dsn
    });

    // Return false if recieved same error message multiple times
    if (notificationText === notification) {
        return false
    }

    notification = notificationText;


    if (type === 'winner') {
        return Sentry.captureMessage("NFT Winner Trigger: " + notificationText, "info");
    }

    setTimeout(() => {
        notification = ''
    }, 2000);

    if (type === 'success') {
        return NotificationManager.success(
            notificationText,
            "Success!",
            2000
        );
    }

    if (type === 'error' || type === 'failure') {
        // Sentry.captureMessage(notificationText);
        return NotificationManager.error(notificationText, "", 2000);
    }


}

