/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
/* 
  import PartnerUWA from "../images/partner-uwa.png";
  import PartnerMme from "../images/partner-mme.svg";
  import PartnerLx from "../images/partner-l1x-labs.svg";
  import PartnerPrysm from "../images/partner-prysm-group.svg";
  import LMPartnerUWA from "../images/partner-uwa-lm.png";
  import LMPartnerMme from "../images/partner-mme-lm.svg";
  import LMPartnerLx from "../images/partner-l1x-labs-lm.svg";
  import LMPartnerPrysm from "../images/partner-prysm-group-lm.svg";
  import LogoPattern from  "../images/icons/logo-pattern.png";
  import LMLogoPattern from  "../images/icons/logo-pattern.png";
  import { Partner } from "./partner"; 
  import { Link } from "react-router-dom";
*/
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

export class BuyL1XProico extends Component {
  render() {

    return (
      <Navigate to="/public-sale"/> 
    )
    
//     return (
//       <>
//         <section className="banners-section sub-banner">
//           <nav className="breadcrumbs">
//             <div className="container">
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to="/">
//                     <i className="fa-solid fa-home"></i>
//                   </Link>
//                 </li>
//                 <li className="breadcrumb-item">
//                   <a>Invest</a>
//                 </li>
//                 <li className="breadcrumb-item active" aria-current="page">
//                   Buy L1X
//                 </li>
//               </ol>
//             </div>
//           </nav>
//           <div className="banner-section text-center d-flex align-self-center pb-64">
//             <div className="container">
//               <div className="row">
//                 <div className="col-sm-12 m-auto">
//                   <h1 className="mb-16">Buy L1X</h1>
//                   <p className="pb-0 mb-0 fontsize-26">
//                   Invest in the interoperable, scalable blockchain network that will shape 
//                     <br /> the future of Web3.
//                   </p>
//                   {/* <div className="buttons">
//                     <a
//                       className="btn btn-style-three"
//                       href="https://docsend.com/view/yxxumg97x5mzv77v"
//                       target={"_blank"}
//                     >
//                       <i className="fa-solid fa-file"></i> View Whitepaper
//                       <i className="fa-solid fa-arrow-up-right-from-square"></i>
//                     </a>
//                   </div> */}
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="pro-ico-live text-center d-flex align-self-center p-118">
//             <div className="container">
//             <div className="row">
//               <div className="col-12 d-flex justify-content-center">
//                   <div className="pro-ico">
//                     <h3 className="pb-18 mb-0">Private Sale is live</h3>
//                     <h6 className="d-flex justify-content-center pb-0"><img
//                       src={
//                         this.props.themeEnabled === "light"
//                           ? LMLogoPattern
//                           : LogoPattern
//                       }
//                       alt="Layer One X"
//                       className="img-fluid"
//                     />
//                      1 L1X = 0.05 USDT</h6>
//                     <h5 className="pb-0 mb-0">USDT Raised: </h5>
//                     <h4 className="pb-18 mb-0">$6,743,319.54 / $10,382,992</h4>
//                     <div className="progress">
//   <div className="progress-bar" style={{width: "65%"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
// </div>

                    // <p>Get an early investor discount. Secure Layer One X <br/>
                    // tokens before the official projected public sale.</p>

//                     <div className="buttons d-flex justify-content-center mb-24">
//                     <div className="btn-style btn-style-one">
//                       <a
//                         className="glow-button gradient-border-mask"                        
//                       >
//                         <span>Connect Wallet</span>
//                       </a>
//                     </div>

//                     <div className="btn-style  btn-style-two">
//                       <a
//                         className="glow-button gradient-border-mask"                        
//                       >
//                         <span>New to crypto?</span>
//                       </a>
//                     </div>
//                   </div>

                   
          //           <h6 className="howtobuy d-flex justify-content-center mb-0 pb-0"><i className="fa-solid fa-question"></i> How to buy Layer One X</h6>
          //         </div>
          //     </div>
          //   </div>
          //   </div>
          // </div>
          
//         <section className="partner-section no-bg">
//           <div className="partner">
//             <div className="container">
//               <p>Partnering with world leading organisations to enable Web3</p>
//               <div className="row">
//                 <div className="col-3">
//                   <figure
//                     data-bs-toggle="modal"
//                     data-bs-target="#PartnerMMEModal"
//                   >
//                     <img
//                       src={
//                         this.props.themeEnabled === "light"
//                           ? LMPartnerMme
//                           : PartnerMme
//                       }
//                       alt="Layer One X"
//                       className="img-fluid"
//                     />
//                   </figure>
//                 </div>
//                 <div className="col-3">
//                   <figure
//                     data-bs-toggle="modal"
//                     data-bs-target="#PartnerPrysmModal"
//                   >
//                     <img
//                       src={
//                         this.props.themeEnabled === "light"
//                           ? LMPartnerPrysm
//                           : PartnerPrysm
//                       }
//                       alt="Layer One X"
//                       className="img-fluid"
//                     />
//                   </figure>
//                 </div>
//                 <div className="col-3">
//                   <figure
//                     data-bs-toggle="modal"
//                     data-bs-target="#PartnerL1XLabsModal"
//                   >
//                     <img
//                       src={
//                         this.props.themeEnabled === "light"
//                           ? LMPartnerLx
//                           : PartnerLx
//                       }
//                       alt="Layer One X"
//                       className="img-fluid"
//                     />
//                   </figure>
//                 </div>

//                 <div className="col-3">
//                   <figure
//                     data-bs-toggle="modal"
//                     data-bs-target="#PartnerUWAModal"
//                   >
//                     <img
//                       src={
//                         this.props.themeEnabled === "light"
//                           ? LMPartnerUWA
//                           : PartnerUWA
//                       }
//                       alt="Layer One X"
//                       className="img-fluid"
//                     />
//                   </figure>
//                 </div>
//               </div>

//               <div
//                 className="modal fade partner-modal"
//                 id="PartnerPrysmModal"
//                 tabIndex="-1"
//                 aria-labelledby="PartnerPrysmModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-xl modal-dialog-centered">
//                   <div className="modal-content">
//                     <div className="modal-body">
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       >
//                         Close
//                       </button>
//                       <div className="row">
//                         <div className="col-lg-5">
//                           <div className="bg-card-bg">
//                             <div className="bg-card d-flex justify-content-center align-items-center">
//                               <figure>
//                                 <img
//                                   src={
//                                     this.props.themeEnabled === "light"
//                                       ? LMPartnerPrysm
//                                       : PartnerPrysm
//                                   }
//                                   alt="Prysm"
//                                   className="img-fluid"
//                                 />
//                               </figure>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-lg-7">
//                           <div className="content">
//                             <h4>
//                             Prysm Group is the Tokenomics Partner for I1X, delivering value to platforms by leveraging established economic research.
//                             </h4>
//                             <p>
//                             Prysm Group is an economic consulting and corporate learning firm focused on emerging technologies. Founded by Harvard PhD economists, the firm assists corporates, governments and startups in the adoption and implementation of blockchain, digital asset and the metaverse through its advisory and educational services. Prysm Group areas of expertise include incentive design, monetization and governance for both open and closed blockchain and metaverse platforms.
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

              
//               <div
//                 className="modal fade partner-modal"
//                 id="PartnerMMEModal"
//                 tabIndex="-1"
//                 aria-labelledby="PartnerMMEModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-xl modal-dialog-centered">
//                   <div className="modal-content">
//                     <div className="modal-body">
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       >
//                         Close
//                       </button>
//                       <div className="row">
//                         <div className="col-lg-5">
//                           <div className="bg-card-bg">
//                             <div className="bg-card d-flex justify-content-center align-items-center">
//                               <figure>
//                                 <img
//                                   src={
//                                     this.props.themeEnabled === "light"
//                                       ? LMPartnerMme
//                                       : PartnerMme
//                                   }
//                                   alt="MME"
//                                   className="img-fluid"
//                                 />
//                               </figure>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-lg-7">
//                           <div className="content">
//                             <h4>
//                             MME is the Legal Partner for Layer One X, providing comprehensive and interdisciplinary advice in the areas of legal, tax and compliance.  

// //                             </h4>
// //                             <p>
// //                             MME is an innovative business law and tax firm with offices in the two Swiss economic centres, Zurich and Zug.  

// //                             </p>
// //                             <p>Legal | Tax | Compliance: In these three areas we offer comprehensive, interdisciplinary solutions with speed and efficiency. Our specialists tailor their advice to your needs in order to provide practical, cost-effective solutions that sustainably improve your business — whether in a national or an international environment.
// // </p>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>

              
              // <div
              //   className="modal fade partner-modal"
              //   id="PartnerUWAModal"
              //   tabIndex="-1"
              //   aria-labelledby="PartnerUWAModalLabel"
              //   aria-hidden="true"
              // >
              //   <div className="modal-dialog modal-xl modal-dialog-centered">
              //     <div className="modal-content">
              //       <div className="modal-body">
              //         <button
              //           type="button"
              //           className="btn-close"
              //           data-bs-dismiss="modal"
              //           aria-label="Close"
              //         >
              //           Close
              //         </button>
              //         <div className="row">
              //           <div className="col-lg-5">
              //             <div className="bg-card-bg">
              //               <div className="bg-card d-flex justify-content-center align-items-center">
              //                 <figure>
              //                   <img
              //                     src={
              //                       this.props.themeEnabled === "light"
              //                         ? LMPartnerUWA
              //                         : PartnerUWA
              //                     }
              //                     alt="UWA"
              //                     className="img-fluid"
              //                   />
              //                 </figure>
              //               </div>
              //             </div>
              //           </div>
              //           <div className="col-lg-7">
              //             <div className="content">
              //               <h4>
              //               The University of Western Australia (UWA) is the academic partner for  Layer One X.
              //               </h4>
              //               <p>
              //               UWA is a leading Australian research university and has an international reputation for excellence, innovation and enterprise. A member of the Australian 'Group of Eight' research universities. Sitting on the banks of the Swan River, the UWA Perth campus is the oldest in Western Australia. UWA offers higher education in many areas, including medicine, architecture and law, in addition to a full Masters program for blockchain. 
              //               </p>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>

              
              // <div
              //   className="modal fade partner-modal"
              //   id="PartnerL1XLabsModal"
              //   tabIndex="-1"
              //   aria-labelledby="PartnerL1XLabsModalLabel"
              //   aria-hidden="true"
              // >
              //   <div className="modal-dialog modal-xl modal-dialog-centered">
              //     <div className="modal-content">
              //       <div className="modal-body">
              //         <button
              //           type="button"
              //           className="btn-close"
              //           data-bs-dismiss="modal"
              //           aria-label="Close"
              //         >
              //           Close
              //         </button>
              //         <div className="row">
              //           <div className="col-lg-5">
              //             <div className="bg-card-bg">
              //               <div className="bg-card d-flex justify-content-center align-items-center">
              //                 <figure>
              //                   <img
              //                     src={
              //                       this.props.themeEnabled === "light"
              //                         ? LMPartnerLx
              //                         : PartnerLx
              //                     }
              //                     alt="L1X_Labs"
              //                     className="img-fluid"
              //                   />
              //                 </figure>
              //               </div>
              //             </div>
              //           </div>
              //           <div className="col-lg-7">
              //             <div className="content">
              //               <h4>
              //               L1X_LABS is the Development Partner for Layer One X. Working with businesses to build and innovate on the Layer One X blockchain protocol. 

//                             </h4>
//                             <p>
//                             At L1.X LABS, we empower businesses to innovate on our proprietary Layer One X blockchain protocol. With our help, clients can build blockchain solutions for business use cases where performance, scalability, security and sustainable growth are key requirements. Every business is unique. We work with you to provide a tailored solution that meets your specific needs.

//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//         </section>




//       </>
//     );
  }
}
BuyL1XProico.propTypes = {
  theme: PropTypes.string,
};
