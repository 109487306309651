import React from "react"
import Logo from "../../images/logol1x-icon.svg";

import ActiveLogo from "../../images/orange-icon-logo.svg";
import SaleCardIcon2 from "../../images/card-icon-2.png";
import SaleCardIcon3 from "../../images/card-icon-3.png";
import SaleCardIcon4 from "../../images/card-icon-4.png";
import SaleCardIcon6 from "../../images/card-icon-6.png";
import SaleCardIcon7 from "../../images/card-icon-7.svg";
import SaleCardIcon8 from "../../images/card-icon-8.svg";
import RegistrationForm from "./registrationForm";
import Config from "../../config/index";
import CurrencyFormatter from "../../services/currencyService";
import WalletIcon from "../../images/wallet/wallet_icon.svg";
import TickCircle from "../../images/tick-circle.svg";
import publicSaleCloseBanner from "../../images/public-sale-close-banner.png";
import storageService from "../../services/storageService";



const PublicSaleRegistrationDetails = (props) => {

    const PublicSaleDetails = (props) => {
        return (
            <>
                <div className={"fixed__card__sale__content"}>
                    <div className="foxed__card__sale">
                        <img src={props.image} alt="logo" className="fixed__card__icon" />
                        <text className="sale_card_icon_label">{props.label}</text>
                    </div>
                    <div className="fixed__card__content__value">
                        <text className="sale_card_icon_label_value">{props.value}</text>
                    </div>
                </div>

                {props.smallValue ?
                    <div className="small__card__sale__content">
                        <div className="small__card__sale">
                            <text className="small_card_icon_label"></text>
                        </div>
                        <div className="small__card__content__value">
                            <text className="small_card_icon_label_value">{props.smallValue}</text>
                        </div>
                    </div>
                    : ""
                }
            </>
        )
    }

    function handleAlreadyRegistered(_name) {
        storageService.removeData("email");
        props.updateEarlyAccessLearnMoreButton(true)
        if (window.hj) {
            window.hj('vpv', '/public-sale');
        }
        props.handleUserSteps('publicSaleHowToContribute')
        //props.handleUserSteps('publicSaleEarlyAccess')
    }

    function handleUserForm(_name) {
        props.handleUserSteps(_name)
    }

    return (
        <div className="banner__fixed__card__box disabled-form" >
        
            <div className="early_access_container form__Buy__now__main__container">
                <div className="early_access_box ">
                    <div className="early_access_wallet_box ">
                        <img src={TickCircle} className="early_access_wallet" />
                        <label className="early_access_label">Already Registered?</label>
                    </div>
                    <label className="early_access_learn_more">Click here</label>
                    {/* <label  onClick={() => handleAlreadyRegistered()} className="early_access_learn_more">Click here</label> */}
                </div>
            </div>
            <div className="fixed__card__main__content" >
                <RegistrationForm
                    premiumWalletAccess={props.premiumWalletAccess}
                    handleUserStep={(_name) => { handleUserForm(_name) }}
                    handleLearnMore={() => { props.handleLearnMore() }}
                    updateEarlyAccessLearnMoreButton={(_value) => { props.updateEarlyAccessLearnMoreButton(_value) }}
                    updateUserFromRegistationForm={(_value) => { props.updateUserFromRegistationForm(_value) }}
                />
            </div>
            <div className="abs-wrapper"></div>
            <img src={publicSaleCloseBanner} className="abs-img"/>
        </div>
    )
}

export default PublicSaleRegistrationDetails