/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "./style.css"
//import Banner from "../../images/public-sale-banner.jpg";
import Banner from "../../images/Hero_table-1.jpg";
import CardImg1 from "../../images/card-img-1.svg";
import CardImg2 from "../../images/card-img-2.svg";
import CardImg3 from "../../images/card-img-3.svg";
import CardImg4 from "../../images/card-img-4.svg";
import CardImg5 from "../../images/card-img-5.svg";
import CardImg6 from "../../images/card-img-6.svg";
import CardImg7 from "../../images/card-img-7.svg";
import CardImg8 from "../../images/card-img-8.svg";
import RoadmapFlow from "../../images/roadmapFlow.svg";
import AccordianImage from "../../images/accordian-image.jpg";
import Logo from "../../images/logol1x-icon.svg";
import PropTypes from "prop-types";
import PublicBuySection from "./publicBuySection";
//import l1xTalkLogo from "../../images/x_talk_vide.gif"
//import l1xTalkLogo from "../../images/X-Talk_Transparent_3.webm"
import PublicSaleKycForm from "./publicSaleKycForm";
import PublicSaleDirectInvestment from "./publicSaleDirectInvestment";
import PublicSaleRegistrationDetails from "./newPublicSaleRegistrationDetails";
import PublicSaleDetails from "./publicSaleDetails";
import PublicSaleEarlyAccess from "./publicSaleEarlyAccess";
import PublicSaleHowToContribute from "./publicSaleHowToContribute";
import PublicSaleNotEligible from "./publicSaleNotEligible";
import DirectInvestmentThankYouPage from "./directInvestmentThankYouPage";
import CountryNotListed from "./countryNotListCard";
import TermsheetInvestor from "./termsheetInvestor";
import TermsheetInvestmentThankYouPage from "./termsheetInvestmentThankYouPage";
import PublicSaleValidateCustomer from "./publicSaleValidateCustomer";
import PublicSaleMoonPaymentGateway from "./publicSaleMoonPaymentGateway";
import PublicSaleMoonPaymentThankYou from "./publicSaleMoonPaymentThankYou";
import PublicSaleBankTransfer from "./publicSaleBankTransfer";
import PublicSaleDetailsDirectTransfer from "./publicSaleDetailsDirectTransfer";
import BankTransferThankYou from "./bankTransferThankYou";
import BankTransferEmailDetails from "./bankTransferEmailDetails";
import BankTransferImageUpload from "./bankTransferImageUpload";
import BankTransferRefValidationMessage from "./bankTransferRefValidationMessage"

const FeaturesCard = (props) => {
  return (
    <div className="col-md-6 mb-4">
      <div className={"make__layeronex__container " + props.divCss}>
        <img src={props.image} alt="layer One X Make" className="make__layeronex__icon" />
        <p className="layeronex__box__o-txt">{props.title}</p>
        <p className="layeronex__card__txt">{props.description}</p>
      </div>
    </div>
  )
}

var _logoVideo = 'https://l1x-foundation.s3.ap-southeast-1.amazonaws.com/X-Talk_Transparent_3.webm';

export class PublicSale extends Component {
  state = {
    showPublicSale: false,
    email: '',
    errors: null,
    name: '',
    country: '',
    registrationCompleted: false,
    learnMoreStatus: false,
    referralCode: null,
    // steps: 'publicSaleStep',
    steps: 'publicSaleRegistrationForm',
    //steps: 'bankTransferImageUpload',
    // steps: "publicSaleBuy",
    // steps:"publicSaleDirectInvestment",
    // steps:"publicSaleEarlyAccess",
    // steps: "publicSaleNotEligible",
    // steps: "directInvestmentThankYouPage",
    // steps: "countryNotListed",
    // steps: "bankTransferThankYou",
    // steps: "bankTransferEmailDetails",
    // steps: "bankTransferImageUpload",
    walletAddress: null,
    currentStep: 1,
    premiumWalletAccess: false,
    earlyAccessLearnMoreButton: false,
    isUserFromRegistationForm: false,
    userPaymentType: '',
    paymentTransactionType: 0
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  handleUserSteps = (_value) => {
    console.log("handleUserSteps called", _value)
    if (window.hj) {
      window.hj('vpv', _value);
    }
    this.setState({ steps: _value })
    // this.myRef.current.scrollIntoView()
  }

  handleUpdateCurrentStep = (_value) => {
    console.log("handleUpdateCurrentStep called")
    this.setState({ currentStep: _value })
    // this.myRef.current.scrollIntoView()
  }

  handleComponentChange = () => {
    console.log("handleComponentChange called")
    this.setState({ registrationCompleted: true })
    // this.myRef.current.scrollIntoView()
  }

  handleLearnMore = () => {
    console.log("handleLearnMore called")
    this.setState({ learnMoreStatus: true })
    this.myRef.current.scrollIntoView()
  }

  handleGoBack = () => {
    this.setState({ registrationCompleted: false, email: '', name: '', country: '' })
  }

  handleConnectedWallet = (_address) => {
    this.setState({ walletAddress: _address })
  }


  updatePremiumWalletAccess = (_value) => {
    this.setState({ premiumWalletAccess: _value })
  }

  updateEarlyAccessLearnMoreButton = (_value) => {
    this.setState({ earlyAccessLearnMoreButton: _value })
  }

  updateUserFromRegistationForm = (_value) => {
    this.setState({ isUserFromRegistationForm: _value })
  }

  updateUserPaymentType = (_value) => {
    this.setState({ userPaymentType: _value })
  }

  updatePaymentTransactionType = (_value) => {
    this.setState({ paymentTransactionType: _value })
  }

  render() {
    return (
      <>
        <div className="main__container__sale">
          <div className="button__scroll__box sticky-enquiry">
            <a href="#">Buy Now</a>
          </div>

          <div className="header__form__sale mobileTop-padding">
            <div className="container">
              <div className="row flex__firection__mobile">
                <div className="col-md-8">
                  <div className="main__header__box">
                    <h1>Why Contribute to Layer One X?</h1>
                  </div>
                  <img src={Banner} className="w-100 banner__img__sale" alt="Layer One X – Public Sale" />
                  <p className="banner__img__content">Unleash Blockchain’s True Potential with Layer One X</p>
                  <h3 className="join__decentralized">See why intelligent Contributors are going all-in with Layer One X</h3>

                  <div className="l1x__revolutionize">
                    <p>Discover the 9 killer features that will make us the most utilized blockchain in crypto history.</p>
                  </div>
                  <div className="l1x__talk__container">
                    <div className="l1x__talk__logo">
                      {/* <img src={l1xTalkLogo} alt="L1X in action" /> */}
                      <video muted autoPlay={true} loop>
                        <source src={_logoVideo} type="video/webm"></source>
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="l1x__talk__content__container">
                      <h3>Native Interoperability with Xtalk:</h3>
                      <p className="l1x__talk__sub__head">Say goodbye to cumbersome and risky bridges and hello to seamless connectivity.</p>
                      <p>Layer One X’s unicorn solution, Xtalk, enables instant transfer of tokens, NFTs, and smart contract logic across a network of chains, including Ethereum, Binance Smart Chain, Polygon, Solana, Avalanche, Arbitrum, Fantom, and Optimism, with many more chains to come. These 8 chains alone hold more than 80% of TVL across all blockchains!</p>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <FeaturesCard divCss="" image={CardImg1} title="Expanded User Base and Liquidity:" description="By leveraging Xtalk, applications on Layer One X gain access to significantly more users and liquidity compared to the Ethereum chain alone. Imagine the insane possibilities and reach for dApps!" />
                    <FeaturesCard divCss="make__layeronex__container_2" image={CardImg2} title="Universal Gas Token:" description="Bid farewell to the need for multiple tokens to cover gas fees on different chains. The Layer One X token becomes the universal gas token on any chain within the Layer One X network of chains. Simplify your operations and streamline your expenses." />
                    <FeaturesCard divCss="make__layeronex__container_2" image={CardImg3} title="Cost-Efficient Transactions:" description="Rejoice as high gas fees become a thing of the past! Layer One X offers transactions at a fraction of the cost compared to Ethereum, with gas fees no more than 1 cent. Experience financial freedom and efficiency like never before." />
                    <FeaturesCard divCss="" image={CardImg4} title="Blazing Speeds:" description="Layer One X boasts impressive speeds, supporting up to 100,000 transactions per second (tps). And the best part? We’re not stopping there. Future stages focus on achieving speeds surpassing 200,000 tps, propelling your applications to new heights." />
                    <FeaturesCard divCss="" image={CardImg6} title="Lightning-Fast Transaction Finality:" description="Time is of the essence in the blockchain world, and Layer One X understands that. Enjoy transaction finality in less than 2 seconds, empowering you to make real-time decisions that provide exceptional user experiences." />
                    <FeaturesCard divCss="make__layeronex__container_2" image={CardImg7} title="Mobile Device Validation & Rewards:" description="Layer One X takes inclusivity to a new level by allowing mobile devices to validate transactions. As a user, you can contribute to the network’s security and be rewarded for participating, creating a perpetual airdrop-like experience." />
                    <FeaturesCard divCss="make__layeronex__container_2" image={CardImg5} title="AI and Encryption Technology to Secure Your Data:" description="Layer One X is built using the latest security technologies to enable safety for the user. The network uses innovative AI technology to help detect bad nodes in the consensus mechanism, and State-of-the-art encryption to secure data from exploitation." />
                    <FeaturesCard divCss="" image={CardImg8} title="Next-Gen Wallet Experience:" description="In the future, using a wallet will be as easy and safe as sending a text. Our vision for crypto wallets is more than how to send money and hold art. It becomes an extension of us and how we interact in crypto." />

                    <div className="advantage__content__box">
                      <div className="advantage__content">
                        <p>Take advantage of the most significant blockchain opportunity this decade! The Layer One X public sale is launching in July, marking the beginning of the most exciting blockchain journey of the decade.</p>
                        <p className="advantage__txt">Time is slipping away for you to take part. Join the Layer One X blockchain revolution now and click on Register-Whitelist Now.</p>
                      </div>
                    </div>

                    <div className="second__chance__box__container">
                      <div className="second__chance__box">
                        <text className="second__chance__txt">Hurry, your second chance opportunity waits for no one!</text>

                        <p>Still have questions? Join the conversations on:</p>
                        <div className="second__chance__social__icon">
                          <ul className="d-flex justify-content-center justify-content-center">
                            <li><a href="https://discord.gg/LayerOneX" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-discord"></i></a></li>
                            <li><a href="https://telegram.me/Layer1X" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-telegram"></i></a></li>
                            <li><a href="https://twitter.com/LayerOneX" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/layer-one-x" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a></li>
                            <li><a href="https://medium.com/@Layeronex" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-medium"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="layeronex__roadmap__box mb-4">
                      <h3 className="layeronex__roadmap__title">Layer One X Roadmap</h3>
                      <img src={RoadmapFlow} alt="Roadmap" className="roadmap__img w-100" />
                      <div className="layeronex__roadmap__link_box">
                        <a className="layeronex__roadmap__link" target="_blank" rel="noopener noreferrer" href="https://blog.l1x.foundation/layeronex-roadmap/">View the L1X Roadmap</a>
                      </div>
                    </div>

                    <div className="layeronex__roadmap__box mt-4">
                      {/* <h3 >How is Layer One X different to other blockchains?</h3> */}
                      <div className="layer0nex__accordion__container mt-4">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button accordion-button__first" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is X-Talk?
                              </button>
                            </h2>

                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <img src={AccordianImage} alt="How does X-Talk work?" className="w-100" />
                                <p className="accordian__content__p">
                                  <b>X-Talk</b> - Cross Chain Connection, Communication, Data Transfer, and Verification is only Possible when we attach a Virtual Machine to X-Talk
                                </p>
                                <p className="accordian__content__p">
                                  <b>Decentralized</b> - Layer One X provides a decentralized consensus mechanism to transfer logic securely. Significant checks are done that enable the transfer of wants by knowing what you are going to retrieve.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Logic Transfer</b> - Ability to communicate with other chains based on what condition you want to set, for example, file transfer vs. API call. You were removing dependency and enabling interconnectivity to web3.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Improved Reliability</b> - Reduce the risk of downtime. Dapps can switch to another chain, ensuring that users can continue to access Dapp’s services without interruption.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Cross Chain</b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility.
                                </p>
                                <p className="accordian__content__p">
                                  <b>X-Talk Architecture</b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allows for the secure and efficient exchange of assets, messages, and logic.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Capital Efficiency</b> - Smart contracts collaboration and communication enable more efficient and timely deployment of capital.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Improved user experience</b> - Providing more comprehensive services to users, making Dapps more attractive and increasing adoption. </p>
                                <p className="accordian__content__p">
                                  <b>Aggregation Development</b> - protocols that can aggregate defi services across multiple chains. Interact with various liquidity pools to provide users with the best possible rates for their transactions.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What Is Tokenization of Securities (Assets)</button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p">
                                  <b>Speed</b> - Transaction performance at the Speed of NASDAQ or other traditional markets, settlement can be achieved in. T+5mins (compared with T+2Days)</p>
                                <p className="accordian__content__p">
                                  <b>Tokenize Once & Use on Multiple Chains</b> - Ability to Tokenize multiple forms of data such as ownership records, health data, intellectual web3 creation and multiple others. Use the tokenized data without minting it again on the other chain allowing for a single source of truth for ownership, reducing transaction fees and ease of use for the tokenized data.</p>
                                <p className="accordian__content__p">
                                  <b>Control Access of tokenized data from Layer One X Wallet</b> - Layer One X Wallet is designed and developed to hold multiple forms of data types, and it also provides access to non-L1X Addresses on the Layer One X Chain to get into the ecosystem and fractionally or rent use the tokenized data on the Layer One X Platform, for example, Airbnb.
                                </p>
                                <p className="accordian__content__p"><b>Fractionalized Investment </b> - Provides fractional ownership, which enables investors to own a small portion of an asset, and it reduces the barriers to entry for investors who may not have the necessary capital to invest in large assets.</p>
                                <p className="accordian__content__p"><b>Maintain data Integrity </b> - Prove the authenticity of the tokenized data for multiple purposes and use cases without revealing the actual information, for example, providing credit cards at a hotel.</p>
                                <p className="accordian__content__p"><b>Reduced Cost </b> - Providing faster settlement times, reduced transaction costs, and increased accessibility to a wider range of investors.</p>
                                <p className="accordian__content__p"><b>Market Disruption </b> - Disrupting traditional financial systems to enable new types of asset ownership, investment, and trading on a global scale.</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What Is Universal Decentralized Identity?
                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>L1X.OneIdentity </b> - UDI provides access to one account for Web3. Funnel multiple usernames, emails, and other types of information into one identifier that automatically understands where and what information is to be tapped into</p>
                                <p className="accordian__content__p"><b>One-Decentralized Drive </b> - Store Fungible, Non-Fungible, Other Contract information on the chain linked to one identifier.</p>
                                <p className="accordian__content__p"><b>Access Control your Assets </b> - Provide and implement strategies around using your asset from one place. For example, the amount is limited on the credit amount.</p>
                                <p className="accordian__content__p"><b>Logic Transfer </b> - Logic transfer in blockchain involves transferring application logic using smart contracts, enabling customization of business logic using native transaction Cross-Chain using X-Talk.</p>
                                <p className="accordian__content__p"><b>Cross Chain </b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility.</p>
                                <p className="accordian__content__p"><b>X-Talk Architecture </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of assets, messages, and logic.</p>
                                <p className="accordian__content__p"><b>Smart Contracts </b> - Providing multi-signature access control and sharing using Smart Contracts. By creating a self-sovereign digital identity system where individuals own and control their own personal data rather than relying on third-party identity providers.</p>
                                <p className="accordian__content__p"><b>Fraud Security & Safety </b> - Using Layer One X Blockchain technology to provide security, using Homomorphic Encryption to transact data while it remains encrypted.</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> -  Blockchains' immutability, transparency, and decentralized nature make them extraordinarily secure. There is no single point of failure with blockchain storage because blockchains are decentralized, and the data stored in each block is unalterable, meaning criminals can't access the information.</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                Introducing Web3 as a Service (WaaS)
                              </button>
                            </h2>
                            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Decentralized Websites </b> - Layer One X VM is the custom-built virtual machine that executes eBPF bytecode. The ability for eBPF to access system resources. For example, GPUs can be directly accessed to speed graphic intensive games. Making eBPF 10-20x more efficient and faster than WebAssembly.</p>
                                <p className="accordian__content__p"><b>Distributed Content </b> - Websites utilizing decentralized domains using peer-to-peer networks to distribute content, with no single point of failure or control, resistant to censorship and downtime.</p>
                                <p className="accordian__content__p"><b>DNS </b> - blockchain-based DNS system, domain names are stored on a blockchain and can be accessed and updated by anyone with access to the network. This technology provides a more democratic and decentralized approach to domain name registration and management and can help to prevent domain name hijacking and other types of attacks.</p>
                                <p className="accordian__content__p"><b>Email Security </b> - Securing emails using blockchain verification of addresses and data encryption.</p>
                                <p className="accordian__content__p"><b>Layer One X As Your Passport </b> - Use Layer One X Wallet to sign in to the website so the website can recognize you for access control and data integrity.</p>
                                <p className="accordian__content__p"><b>Components </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of assets, messages, and logic.</p>
                                <p className="accordian__content__p"><b>IPFS </b> - InterPlanetary File System is a distributed system for storing and accessing files, web applications, and other data on a decentralized network. Unlike traditional web hosting, which relies on a centralized server to store and serve data, IPFS uses a peer-to-peer network to distribute data across the network, making it more secure, reliable, and censorship-resistant.</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> - Blockchains' immutability, transparency, and decentralized nature make them extraordinarily secure. There is no single point of failure with blockchain websites. Because blockchains are decentralized, and the data stored in each block is unalterable, criminals can't access the information.</p>

                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                Scalable Enterprise Applications
                              </button>
                            </h2>
                            <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Speed </b> - With a transaction speed of 500ms, the fastest blockchain</p>
                                <p className="accordian__content__p"><b>SaaS Applications </b> - Layer One X VM is the custom-built virtual machine that executes eBPF bytecode. The ability for eBPF to access system resources. For example, GPUs can be directly accessed to speed graphic intensive games. Making eBPF 10-20x more efficient and faster than WebAssembly.</p>
                                <p className="accordian__content__p"><b>Hybrid Consensus Mechanism </b> - Consensus algorithms are used together in a blockchain network to achieve specific goals. Such as scalability, security, or decentralization. Hybrid consensus mechanisms are designed to overcome the limitations and tradeoffs of individual consensus mechanisms and to provide a more efficient and effective way of securing a blockchain network</p>
                                <p className="accordian__content__p"><b>Logic Transfer </b> - Logic transfer in blockchain Applications involves transferring business logic using smart contracts, enabling customization of transaction information and Native Cross-Chain transactions.</p>
                                <p className="accordian__content__p"><b>Cross Chain </b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility</p>
                                <p className="accordian__content__p"><b>X-Talk Architecture </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of assets, messages, and logic</p>
                                <p className="accordian__content__p"><b>Smart Contracts </b> - Deployed on Layer One X, making the application logic transparent and tamper-proof. This process allows users to access and verify the data, ensuring accuracy and transparency. Integrated with smart contracts to allow transactions to operate using business logic. This can include executing business rules using logic.</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> - Blockchains' immutability, transparency, and decentralized nature make them extraordinarily secure. There is no single point of failure with blockchain storage. Because blockchains are decentralized, and the data stored in each block is unalterable, criminals can't access the information. "Using Layer One X Blockchain technology to provide security against online fraud</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                Government Services
                              </button>
                            </h2>
                            <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Taxation </b> - Using the Layer One X decentralized ledger, Governments can foster better transparency in solving tax collection issues</p>
                                <p className="accordian__content__p"><b>GST, VAT, Sales Tax </b> - Automate the collection of tax on invoice payments using smart contracts to automatically send taxes to Tax Office.</p>
                                <p className="accordian__content__p"><b>Bills and Payments </b> - Governments can implement Layer One X Blockchain to improve traditional billing and payments systems</p>
                                <p className="accordian__content__p"><b>Welfare Distribution </b> - Layer One X Blockchain can provide capabilities to improve welfare distribution</p>
                                <p className="accordian__content__p"><b>Digital IDs </b> - Using a decentralized ledger, implementation of true digital identities for all</p>
                                <p className="accordian__content__p"><b>Healthcare Solutions </b> - Improve Healthcare using distributed ledger technology</p>
                                <p className="accordian__content__p"><b>Legal Enforcement </b> - Government can use Layer One X Public Blockchain data to monitor transactions to facilitate legal transactions and stop money laundering</p>
                                <p className="accordian__content__p"><b>Fraud Security & Safety </b> - Using Layer One X Blockchain technology to provide security for Social Security online fraud</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> - Governments can use the Layer One X Blockchain to protect vital infrastructure against cyber-attacks and hacks</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed accordion-button__last" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                Gaming
                              </button>
                            </h2>
                            <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Speed </b> - With a transaction speed of 500ms, the fastest blockchain</p>
                                <p className="accordian__content__p"><b>Web3 Games </b> - Layer One X VM is the custom-built virtual machine that executes eBPF bytecode. The ability for eBPF to access system resources. For example, GPUs can be directly accessed to increase speed for more graphic-intensive games. Making eBPF 10-20x more efficient and faster than WebAssembly.</p>
                                <p className="accordian__content__p"><b>Logic Transfer </b> - Logic transfer in blockchain gaming involves transferring game logic or rules using smart contracts, enabling customization of game mechanics and Native Cross-Chain transactions.</p>
                                <p className="accordian__content__p"><b>Cross Chain </b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility.</p>
                                <p className="accordian__content__p"><b>X-Talk Architecture </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of NFT assets, messages, and logic.</p>
                                <p className="accordian__content__p"><b>Smart Contracts </b> - Deployed on Layer One X, making the game logic transparent and tamper-proof. This tech allows all players to access and verify the game rules, ensuring fairness and transparency. Integrated with the smart contracts to allow players to interact with the game logic. This process can include executing game mechanics, checking scores, and receiving rewards. Customized to allow for flexibility in in-game rules. For example, Adding new game mechanics or adjusting existing ones to fit the needs of the players.</p>
                                <p className="accordian__content__p"><b>Game Asset Transfers </b> - Game assets include 3D object creations, in-game items, resources, and more. Non-fungible tokens (NFTs) are unique digital assets that can represent anything from in-game items to artwork and music.</p>
                              </div>
                            </div>
                          </div>
                          <div ref={this.myRef} className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed accordion-button__last" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                Didn't Find Your Country
                              </button>
                            </h2>
                            <div id="collapsenine" className={this.state.learnMoreStatus ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body learn_more_accordian">
                                <p className="accordian__content__p learn_more_accordian_txt">
                                  Due to the significant regulatory uncertainty in a number of global jurisdictions, with respect to digital assets and cryptographic tokens, Layer One X has decided to exclude those contributors living in the following countries from participating in our public sale. In the event circumstances change before or during our sale then we will update this FAQ in line with those changes.
                                  <p>
                                    <ol style={{ paddingLeft: 0, marginTop: '1.3rem' }}>
                                      <li>1. Afghanistan</li>
                                      <li>2. Belarus</li>
                                      <li>3. Democratic People's Republic of Korea (DPRK)</li>
                                      <li>4. Iran</li>
                                      <li>5. Myanmar</li>
                                      <li>6. Syria</li>
                                      <li>7. Venezuela</li>
                                      <li>8. China</li>
                                      <li>9. United States of America</li>
                                      <li>10. Sudan</li>
                                      <li>11. Central African Republic</li>
                                      <li>12. Crimea, Donetsk, Luhansk and Sevastopol</li>
                                      <li>13. Democratic Republic of the Congo</li>
                                      <li>14. Eritrea</li>
                                      <li>15. Ethiopia</li>
                                      <li>16. Iraq</li>
                                      <li>17. Libya</li>
                                      <li>18. Mali</li>
                                      <li>19. North Korea</li>
                                      <li>20. Regions of Ukraine</li>
                                      <li>21. Russia</li>
                                      <li>22. Somalia</li>
                                      <li>23. Yemen</li>
                                      <li>24. Zimbabwe</li>
                                    </ol>
                                  </p>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <div className="col-md-4">

                  {
                    this.state.steps === "publicSaleRegistrationForm" ?
                      <PublicSaleRegistrationDetails
                        handleLearnMore={() => { this.handleLearnMore() }}
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        updateEarlyAccessLearnMoreButton={(_value) => this.updateEarlyAccessLearnMoreButton(_value)}
                        premiumWalletAccess={this.state.premiumWalletAccess}
                        updateUserFromRegistationForm={(_value) => this.updateUserFromRegistationForm(_value)}

                      /> : ""
                  }

                  {/*Public sale Card with Prospect Steps */}
                  {
                    this.state.steps === "publicSaleStep" ?
                      <PublicSaleDetails
                        currentStep={this.state.currentStep}
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        walletAddress={this.state.walletAddress}
                        handleConnectedWallet={(_address) => this.handleConnectedWallet(_address)}
                      /> : ""
                  }

                  {/*Public sale Card with Prospect Steps */}
                  {
                    this.state.steps === "publicSaleStepDirectTransfer" ?
                      <PublicSaleDetailsDirectTransfer
                        currentStep={this.state.currentStep}
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        walletAddress={this.state.walletAddress}
                        handleConnectedWallet={(_address) => this.handleConnectedWallet(_address)}
                      /> : ""
                  }

                  {/* Prospect KYC Section Component */}

                  {
                    this.state.steps === "publicSaleKyc" ?
                      <PublicSaleKycForm
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        handleConnectedWallet={(_address) => this.handleConnectedWallet(_address)}
                        walletAddress={this.state.walletAddress}
                        paymentType={this.state.userPaymentType}
                      /> : ""
                  }

                  {/* Buy Section Component */}
                  {
                    this.state.steps === "publicSaleBuy" ?
                      <div className="banner__fixed__card__box">
                        <div className="banner__fixed__card__head">
                          <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
                        </div>
                        <div className="fixed__card__main__content">
                          <PublicBuySection
                            handleUserSteps={(_value) => this.handleUserSteps(_value)}
                            handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                            premiumWalletAccess={this.state.premiumWalletAccess}
                          />
                        </div>
                      </div>
                      : ""
                  }

                  {/* Direct Investment Section Component */}
                  {
                    this.state.steps === "publicSaleDirectInvestment" ?
                      <PublicSaleDirectInvestment
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        handleConnectedWallet={(_address) => this.handleConnectedWallet(_address)}
                        paymentTransactionType={this.state.paymentTransactionType}
                        walletAddress={this.state.walletAddress}
                        paymentType={this.state.userPaymentType}
                      /> : ""
                  }

                  {/* Termsheet Investor Section Component */}
                  {
                    this.state.steps === "termsheetInvestor" ?
                      <TermsheetInvestor
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        handleConnectedWallet={(_address) => this.handleConnectedWallet(_address)}
                        walletAddress={this.state.walletAddress}
                      /> : ""
                  }

                  {/* Early Access Component */}
                  {
                    this.state.steps === "publicSaleEarlyAccess" ?
                      <PublicSaleEarlyAccess
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        updatePremiumWalletAccess={(_value) => this.updatePremiumWalletAccess(_value)}
                        isUserFromRegistationForm={this.state.isUserFromRegistationForm}
                      /> : ""
                  }

                  {/* How to contribute Component */}
                  {
                    this.state.steps === "publicSaleHowToContribute" ?
                      <PublicSaleHowToContribute
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        updateUserPaymentType={(_value) => this.updateUserPaymentType(_value)}
                        isUserFromRegistationForm={this.state.isUserFromRegistationForm}
                      /> : ""
                  }

                  {/* How to contribute Component */}
                  {
                    this.state.steps === "publicSaleValidateCustomer" ?
                      <PublicSaleValidateCustomer
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        paymentType={this.state.userPaymentType}
                      /> : ""
                  }
                  {/* Moon Payment Gateway */}
                  {
                    this.state.steps === "publicSaleMoonPaymentGateway" ?
                      <PublicSaleMoonPaymentGateway
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        paymentType={this.state.userPaymentType}
                        updatePaymentTransactionType={(_value) => this.updatePaymentTransactionType(_value)}
                      /> : ""
                  }
                  {/* Bank Transfer */}
                  {
                    this.state.steps === "publicSaleBankTransfer" ?
                      <PublicSaleBankTransfer
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        paymentType={this.state.userPaymentType}
                      /> : ""
                  }
                  {/* Early Access Not Eligiblet Component */}
                  {
                    this.state.steps === "publicSaleNotEligible" ?
                      <PublicSaleNotEligible
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        updatePremiumWalletAccess={(_value) => this.updatePremiumWalletAccess(_value)}
                      /> : ""
                  }

                  {/* Thank You page for Direct Investment */}
                  {
                    this.state.steps === "directInvestmentThankYouPage" ?
                      <DirectInvestmentThankYouPage
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                        updatePremiumWalletAccess={(_value) => this.updatePremiumWalletAccess(_value)}
                      /> : ""
                  }

                  {/* Thank You page for Moon payment gateway */}
                  {
                    this.state.steps === "publicSaleMoonPaymentThankYou" ?
                      <PublicSaleMoonPaymentThankYou
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      /> : ""
                  }

                  {/* Thank You page for Termsheet Investment */}
                  {
                    this.state.steps === "termsheetInvestmentThankYouPage" ?
                      <TermsheetInvestmentThankYouPage
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                        handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                      // updatePremiumWalletAccess={(_value) => this.updatePremiumWalletAccess(_value)}
                      /> : ""
                  }

                  {/* Thank You page for Direct Investment */}
                  {
                    this.state.steps === "countryNotListed" ?
                      <CountryNotListed
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      // handleUpdateCurrentStep={(_value) => this.handleUpdateCurrentStep(_value)}
                      /> : ""
                  }

                  {/* Bank Transfer Thank you component */}
                  {
                    this.state.steps === "bankTransferThankYou" ?
                      <BankTransferThankYou
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      /> : ""
                  }

                  {/* Bank Transfer Email details component */}
                  {
                    this.state.steps === "bankTransferEmailDetails" ?
                      <BankTransferEmailDetails
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      /> : ""
                  }

                  {/* Bank Transfer Image upload component */}
                  {
                    this.state.steps === "bankTransferImageUpload" ?
                      <BankTransferImageUpload
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      /> : ""
                  }
                  {/* Bank Transfer Image upload component */}
                  {
                    this.state.steps === "bankTransferRefValidationMessage" ?
                      <BankTransferRefValidationMessage
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      /> : ""
                  }
                  {/* Referral Component */}
                  {/* {
                    this.state.steps === "referralReward" ?
                      <PublicSaleReferralReward
                        handleUserSteps={(_value) => this.handleUserSteps(_value)}
                      /> : ""
                  } */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
PublicSale.propTypes = {
  theme: PropTypes.string,
};
