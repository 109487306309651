import React, { useState, useEffect } from "react";
import { serviceAdminLogin } from "../../services/adminApiService";
import CircularProgress from "../CircularProgress/index";
import validator from "validator";
import logger from "../../utils/logger";
import { useNavigate } from "react-router-dom"
import AuthService from "../../services/authService";
import Notify from "../../utils/notify";
import Config from "../../config";


const AdminLogin = () => {
  const intialValues = { authCode: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate()


  function validateEmail(values) {
    logger("validateEmail", values);
    let errors = {};
    if (validator.isEmpty(values.authCode)) {
      errors.authCode = "Please enter an authentication token";
    }
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateEmail(formValues));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const submitLoginForm = async () => {
    try {
      let form_data = {
        auth_code: formValues.authCode,
      };
      logger("form_data", form_data);
      let _res = await serviceAdminLogin(form_data);

      if (_res.status === "success") {
        setIsSubmitting(false);

        AuthService.storeAdminAuthToken(_res.data.token);

        //action after successful login
        navigate("/"+Config.adminUrlHash+`/admin/dashboard`)

          return Notify(_res.message,"success");

          } else {
        setIsSubmitting(false);
        return Notify(_res.message,"error")
      }
    } catch (e) {
      setIsSubmitting(false);
      Notify("Error while login,please try agian","error")
      logger("error", e.message);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitLoginForm();
    }
  }, [formErrors]);

  return (
    <>
      <section className="admin-banners-section sub-banner">
        <div className="pro-ico-live text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <h3 className="pb-18 mb-0">Login</h3>

                  {/* login form with auth code*/}
                  <form
                    id="registrationForm"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="mb-3">
                      <label for="authCode" className="form-label">
                        Authentication Token
                      </label>
                      <div className="d-flex position__relative">
                        <input
                          type="text"
                          value={formValues.authCode}
                          name="authCode"
                          onChange={handleChange}
                          className="form-control email__generate__otp__txt__box"
                          id="authCode"
                          aria-describedby="authCode"
                          placeholder="Please provide authentication token"
                        />
                      </div>
                      {formErrors.authCode && (
                        <span className="error">{formErrors.authCode}</span>
                      )}
                    </div>

                    {isSubmitting === true ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
