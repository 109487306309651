import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CustomModal = ({ show, stakeData, stakeHeaderList, onHide }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Stake Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <table className="table-responsive table-striped">
                        <thead>
                            <tr>
                                {stakeHeaderList.map((item, index) => (
                                    <th>{item}</th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {stakeData ? (
                                <>
                                    {stakeData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.amount}</td>
                                            <td>{item.duration}</td>
                                            <td>{item.expected_beta_reward}</td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <p>No Data Found</p>
                            )}
                        </tbody>

                    </table>
                }
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
};

export default CustomModal;