/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Validators from "../images/icons/partial-integrity.png";
import LMValidators from "../images/icons/partial-integrity-lm.png";
import Initiators from "../images/icons/icon-supply-chain.png";
import LMInitiators from "../images/icons/icon-supply-chain-lm.png";
import Constructors from "../images/icons/icon-decentralisation.png";
import LMConstructors from "../images/icons/icon-decentralisation-lm.png";
import L1XConsensus from "../images/l1x-consensus.png";
import LML1XConsensus from "../images/l1x-consensus.png";

import ConsensusMechanism from "../images/icons/icon-consensus-mechanism.png";
import LMConsensusMechanism from "../images/icons/icon-consensus-mechanism-lm.png";
import Interoperate from "../images/icons/icon-interoperate-without-oracles.png";
import LMInteroperate from "../images/icons/icon-interoperate-without-oracles-lm.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class NativeTransactionLifecycle extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Build</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Native Transaction Lifecycle
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>Native Transaction Lifecycle</h4>
                  <h1 className="mb-32">
                    Blazingly fast transactions
                    <br />
                    on the Layer One X blockchain.
                  </h1>
                  <div className="buttons d-flex justify-content-center">
                    <div className="btn-style btn-style-one">
                      <Link to={"../start-building"} className="glow-button gradient-border-mask">
                        <span>Apply Now</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="main-sticky">
          <nav className="sticky-top section-sticky-top w-auto">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <ul>
                    <span>Jump to</span>
                    <li>
                      <a href="build/native-transaction-lifecycle/#introduction">
                        Introduction
                      </a>
                    </li>
                    <li>
                      <a href="build/native-transaction-lifecycle/#our-transaction-lifecycle-simplified">
                        Our transaction lifecycle, simplified.
                      </a>
                    </li>
                    <li>
                      <a href="build/native-transaction-lifecycle/#ways-to-participate">
                        Ways to participate
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          <section
            className="section web3-usecases our-goals state-of-the-art text-center bg-blue"
            id="introduction"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-left">
                    Build and transact with native <br />
                    tokens on the Layer One X platform.
                  </h3>
                </div>
                <div className="col-md-6">
                  <p className="pb-32 text-left">
                    Transactions in Layer One X are categorised as either ‘normal’ or
                    ‘interoperable’. This initial parameter informs the VM which
                    consensus-construction mechanism should be used for purpose
                    of achieving fast confirmation and instant finality in all
                    scenarios —ideal for speed, utility, security and to reduce
                    the possibility of human error.
                  </p>
                  <p className="pb-80 text-left">
                    Below is an illustration of a normal (aka native or
                    on-chain) transaction.
                  </p>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                            ? LMInitiators
                            : Initiators
                          }
                          alt="Initiators"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Initiators</h4>
                        <p>
                          These nodes introduce the transactions into the Layer One X
                          network. They perform the basic validation for
                          mandatory details before submitting the transaction
                          for validation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                            ? LMValidators
                            : Validators
                          }
                          alt="Validators"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Validators</h4>
                        <p>
                          Validators are the nodes that have the capacity to
                          validate the transaction based on account state
                          history. In Layer One X, active mobile devices with limited
                          resources are wisely utilised to hasten the process of
                          transaction validation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMConstructors
                              : Constructors
                          }
                          alt="Constructors"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Constructors</h4>
                        <p>
                          Block creation and synchronisation of global state is
                          the responsibility of the constructor nodes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-blue">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  
              <div className="row">
                <div className="col-md-12">
                  <figure className="transaction-validation-img">
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LML1XConsensus
                          : L1XConsensus
                      }
                      alt="L1X Consensus"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="section web3-usecases text-left lifecycle"
            id="our-transaction-lifecycle-simplified"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="mb-80">
                    Our native transaction
                    <br />
                    lifecycle, simplified.
                  </h2>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>1.</span> Transaction initiated
                        </h3>
                        <ul>
                          <li>
                            A user submits the transaction to transfer Layer One X
                            tokens to another user using the Layer One X portal.
                          </li>
                          <li>
                            Initiator in the Layer One X network verifies the
                            authenticity of the transaction and submits it to
                            the randomly selected Leader of the dynamically
                            formed group.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>2.</span> Leader node verification
                        </h3>
                        <ul>
                          <li>
                            Leader fetches the account state history of the
                            sender from any peer full node.
                          </li>
                          <li>
                            It gets the latest transaction hash of the sender
                            along with the balance from the full node.
                          </li>
                          <li>
                            Leader verifies the sufficient balance, creates the
                            proof containing the latest transaction hash of the
                            sender and its signature, and partially validates
                            the transaction and sends it to the validators of
                            the group for validation.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>3.</span> Validators verify
                        </h3>
                        <ul>
                          <li>
                            Validators get the latest transaction hash from the
                            proof and validate for its existence and
                            authenticity from any full node in the Layer One X network.
                          </li>
                          <li>
                            On successful verification, the validator sends the
                            response back to the leader.
                          </li>
                          <li>
                            The Leader aggregates the response from all the
                            validators and sends the validates transaction with
                            the final integrity to the constructor.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>4.</span> New block is constructed
                        </h3>
                        <ul>
                          <li>
                            Constructor verifies the final integrity and mints
                            the validates transactions in a new block.
                          </li>
                          <li>
                            The new block is sent to all the connected full
                            nodes.
                          </li>
                          <li>
                            Full nodes perform block validation and sent it to
                            peers.
                          </li>
                          <li>
                            The user is intimated about successful transfer of
                            Layer One X tokens.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section powering-apps power-unity text-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="title">
                    <h2>
                      Explore the power of unity <br />
                      with Layer One X
                    </h2>
                    <p className="pb-80">
                      Get fast confirmation and instant finality with native
                      transactions, and additional validations for secure
                      interoperability at the base layer of blockchain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde consensus-mechanism d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMConsensusMechanism
                              : ConsensusMechanism
                          }
                          alt="Consensus Mechanism"
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Consensus Mechanism</p>
                      <h3 className="pb-16">
                        A truly decentralised <br />
                        consensus mechanism
                        
                      </h3>
                      <p className="pb-24">
                        Mobile-enabled transaction validation means more
                         than <br/>10,000 nodes can serve as validators in the Layer One X network.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/consensus-mechanism">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card h-full">
                        <div className="bg wyde interoperate d-flex justify-content-center flex-column">
                          <figure className="d-flex">
                            <img
                              src={
                                this.props.themeEnabled === "light"
                                  ? LMInteroperate
                                  : Interoperate
                              }
                              alt="Interoperate without oracles"
                              className="img-fluid"
                            />
                          </figure>
                          <p className="pb-8">Interoperate without oracles </p>
                          <h3 className="pb-16">
                          Interoperable <br />transaction lifecycle
                          
                          </h3>
                          <p className="pb-24">
                          Fast, secure transactions in the fragmented <br/>blockchain and cryptocurrency ecosystem.
                          </p>
                          <div className="buttons d-flex justify-content-center">
                            <div className="btn-style btn-style-one btn-style-white">
                              <Link className="glow-button gradient-border-mask" to="../build/interoperable-transaction-lifecycle">
                                <span>Read More</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </section>
        </div>
        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Read more in the <br />
                    whitepaper.
                  </h2>
                  <p>
                    Read the technical documentation and architectural overview
                    of the Layer <br /> One X blockchain network.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <a className="glow-button gradient-border-mask" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                      <span>View Whitepaper </span> 
                      <i className="fa-solid fa-arrow-up-right-from-square pl-8"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
NativeTransactionLifecycle.propTypes = {
  theme: PropTypes.string,
};
