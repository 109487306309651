import React, { useState } from "react";
import { Link } from "react-router-dom";
import Config from "../../config";

const SideBar = () => {
  const [seletectedPage, setSelectedPage] = useState("Dashboard");

  return (
    <>
      <div className="l-navbar sidebar__show" id="nav-bar">
        <nav className="nav">
          <div>
            {/* <a href="#" className="nav_logo"></a> */}
            <div className="nav_list pt-5">
              <Link onClick={() => setSelectedPage("Dashboard")} className={seletectedPage === "Dashboard" ? "nav_link active_dashboard_link" : "nav_link"} to={"/" + Config.adminUrlHash + "/admin/dashboard"}>
                <i class="bx bx-grid-alt nav_icon"></i>
                <span className="nav_name " >Dashboard</span>{" "}
              </Link>
              <Link onClick={() => setSelectedPage("Prospects")} className={seletectedPage === "Prospects" ? "nav_link active_dashboard_link" : "nav_link"} to={"/" + Config.adminUrlHash + "/admin/prospects"}>
                <i class="bx bx-list-ul nav_icon"></i>
                <span className="nav_name">Prospects</span>
              </Link>
              <Link onClick={() => setSelectedPage("Transactions")} className={seletectedPage === "Transactions" ? "nav_link active_dashboard_link" : "nav_link"} to={"/" + Config.adminUrlHash + "/admin/prospect-transactions"}>
                <i class="bx bx-list-ul nav_icon"></i>
                <span className="nav_name">Transactions</span>
              </Link>
              <Link onClick={() => setSelectedPage("Configurations")} className={seletectedPage === "Configurations" ? "nav_link active_dashboard_link" : "nav_link"} to={"/" + Config.adminUrlHash + "/admin/onchain-config"}>
                <i class="bx bxs-cog nav_icon"></i>
                <span className="nav_name">Configurations</span>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default SideBar;
