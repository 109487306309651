import React from "react";
const NavBar = () => {
  return (
    <>
      <header className="premium_wallet_nav">
      </header>
    </>
  );
};

export default NavBar;
