import React, { useState } from "react";
import CircularProgress from "../CircularProgress/index";
import {  serviceGetReferralCode} from "../../services/apiService";
import validator from "validator";
import Notify from "../../utils/notify";
import copy from "copy-to-clipboard";

const ForgetReferral = () => {
  const [referralCode, setReferralCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');


  const handleSubmit = async () => {

    let error = true
    if (validator.isEmpty(email)) {
      setEmailError("Please enter a email address");
      error=false
    } else if (!validator.isEmail(email)) {
      setEmailError("Invalid email format");
      error=false
    }

    if (error) {
      setEmailError('')
      setIsSubmitting(true);

      let response = await serviceGetReferralCode(email);

      if (response.status === "success") {
        setIsSubmitting(false);
        setReferralCode(response.data.referralCode)

        return Notify(response.message, 'success');
      } else {
        setIsSubmitting(false);
        return Notify(response.message, 'error');
      }
    }
  };

  const copyToClipboard = (data) => {
    copy(data);
    return Notify("Copied!", "success")
  };


  return (
    <>
      <section className="banners-section sub-banner">
        <div className="pro-ico-live text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <h3 className="pb-18 mb-0">Forget Referral Code</h3>

                    {referralCode === '' ? <div className="mb-3">
                      <label className="form-label">
                        Email Address<span className="asteric">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        name="email"
                        placeholder="Please enter your email address"
                      />
                      {emailError && (
                        <span className="error">{emailError}</span>
                      )}
                    </div> : <div className="mb-3">
                      <label className="form-label referralCode_forget_label">
                        Referral Code : <span className="referralCode_forget_span">{referralCode} </span><span>  <img
                          onClick={() => copyToClipboard(referralCode)}
                          className="deposite_address_icon"
                          src={require("../../images/copy.png")}
                          alt="copy"
                        /></span>
                      </label>
                    </div>}

                    {isSubmitting === true ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      referralCode === '' ? <button  onClick={()=>handleSubmit()} className="btn btn-primary">
                        SUBMIT
                      </button> :

                        <button
                          onClick={() => {
                            setEmail('')
                            setReferralCode('')
                          }}
                          className="btn btn-primary">
                          Go Back
                        </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetReferral;
