import React, { useState , useEffect } from "react"

import BasicDetails from ".//basicDetails"
import BuySection from "./buySection"
import PauseCampaign from "./pauseCampaign"
import Registration from "./registration"
import KycForm from "./kycForm"
import KYCFlagged from "./kycFlagged"
import Config from "../../config/index"
import logger from "../../utils/logger"
import WhitelistingDone from "./whitelistingDone"

import { serviceGetVerifyToken } from "../../services/apiService";


const PrivateSale = () => {
    const [showForm, setShowForm] = useState(Config.sectionID.basicDetailsForm)
    const [formValue, setFormValue] = useState(0)
    const [email, setEmail] = useState('')

    function handleFormData(_formValue, registerForm) {
        logger("_formValue", _formValue)
        logger("registerForm", registerForm)
        setShowForm(_formValue)

        if (registerForm) {
            setFormValue(registerForm)
        }
    }

    function handleEmailData(_email) {
        logger("_formValue", _email)
        setEmail(_email)
    }

    useEffect(() => {
        (async() => {

            // If JWT Token exists and is valid then take user to relevant screen
            let _tokenResponse = await serviceGetVerifyToken();
            if(_tokenResponse.status === "success" && _tokenResponse.data.completion_stage !== null)
            {
            
                /* if (parseInt(_tokenResponse.data.completion_stage) === 1) {

                    if(Config.holdBuyingFlow === true)
                    {
                        setShowForm(Config.sectionID.whitelistingDone);
                    }
                    else
                    {
                        setShowForm(Config.sectionID.KYCForm)
                    }
                }

                if (parseInt(_tokenResponse.data.completion_stage) === 2) {
                    setShowForm(Config.sectionID.buySectionForm)
                }
                if (parseInt(_tokenResponse.data.completion_stage) === 3) {
                    setShowForm(Config.sectionID.KYCFlagged)
                } */

                setShowForm(Config.sectionID.buySectionForm);
            }
        })();
        

    },[]);

    return <>
        
        {showForm === Config.sectionID.registrationForm ? <Registration handleForm={(_formValue,registerForm) => handleFormData(_formValue,registerForm)} formValue={formValue} email={email} /> : ""}
        {showForm === Config.sectionID.basicDetailsForm ? <BasicDetails handleForm={(_formValue, registerForm) => handleFormData(_formValue, registerForm)} handleEmail={(_email) => handleEmailData(_email)} email={formValue}/> : ""}
        {showForm === Config.sectionID.KYCForm ? <KycForm handleForm={(_formValue) => handleFormData(_formValue)} /> : ""}
        {showForm === Config.sectionID.buySectionForm ? <BuySection handleForm={(_formValue) => handleFormData(_formValue)} /> : ""}
        {showForm === Config.sectionID.pauseCampaign ? <PauseCampaign /> : ""}
        {showForm === Config.sectionID.KYCFlagged ? <KYCFlagged /> : ""}
        {showForm === Config.sectionID.whitelistingDone ? <WhitelistingDone /> : ""}

    </>
}

export default PrivateSale