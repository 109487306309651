/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PartialIntegrity from "../images/icons/partial-integrity.png";
import LMPartialIntegrity from "../images/icons/partial-integrity-lm.png";
import FinalIntegrity from "../images/icons/final-integrity.png";
import LMFinalIntegrity from "../images/icons/final-integrity-lm.png";
import HowL1XWorksValidation from "../images/how-l1x-works-validation.png";
import HowL1XWorksValidationmobile from "../images/how-l1x-works-validation-mobile.png";

import Device from "../images/icons/icon-device.png";
import LMDevice from "../images/icons/icon-device-lm.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Config from "../config";

export class HowItWorks extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Invest</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                How It Works
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>How It Works</h4>
                  <h1 className="mb-16">
                  The foundational platform for <br />
                  decentralised Web3 systems.
                  </h1>
                  <p className="pb-32 mb-0">It’s our goal to create a more secure and accessible digital world by <br/>connecting Web3 systems. Join the mission.</p>
                  <div className="buttons d-flex justify-content-center">
                    <div className="btn-style btn-style-one">
                      <Link className="glow-button gradient-border-mask" to="/public-sale">
                        {/* <span>Buy L1X</span> */}
                        <span>{Config.publicSaleButtonLabel}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
<div className="main-sticky">
        <nav className="section-sticky-top w-auto fixed-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 m-auto">
                <ul>
                  <span>Jump to</span>
                  <li>
                    <a href="/how-it-works/#interoperability">
                    Interoperability
                    </a>
                  </li>
                  <li>
                    <a href="/how-it-works/#performance">
                    Performance
                    </a>
                  </li>
                  <li>
                    <a href="/how-it-works/#security">
                    Security
                    </a>
                  </li> 
                  <li>
                    <a href="/how-it-works/#validation">
                    Validation
                    </a>
                  </li> 
                  <li>
                    <a href="/how-it-works/#buy-l1x">
                    Buy L1X
                    </a>
                  </li>                  
                </ul>
              </div>
            </div>
          </div>
        </nav>


        <section className="section web3-usecases our-goals state-of-the-art text-center bg-blue" id="interoperability">
          <div className="container">
            <div className="row pb-80">
              <div className="col-md-6">
                <h3 className="text-left">
                Empowering innovators to build the interoperable internet of the future
                </h3>
              </div>
              <div className="col-md-6">
                <p className="pb-32 text-left">
                Private and public blockchain networks continue to suffer from scalability issues in reaching finality securely, at speed and for a low cost. This trilemma has stymied the wide adoption of blockchain its potential for many industrial uses. </p>
                <p className="pb-32 text-left">Fragmented blockchain architecture and disparate systems have led to less-than-perfect cross-chain methods such as exchanges, oracles, side chains and bridges, all of which have their own drawbacks. </p>
                <p className="pb-32 text-left">Layer One X is a foundational protocol that overcomes interoperability challenges though a novel approach.</p>
                <p className="pb-0 text-left">
It’s important to note that Layer One X isn’t a cryptocurrency, exchange or NFT marketplace. Nor is it a federated database. It’s the foundational underlying (layer one) blockchain technology that allows businesses of any kind build a decentralised network, execute smart contracts and build interoperable dApps (decentralised apps).
                </p>
              </div>
            </div>
            </div>
        </section>



        <section className="section worldsweb3 fastest-low-cost" id="validation">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
               

                <div className="web3 mb-32">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center cont-tb-48">
                        <h4>How Layer One X Works: Validation </h4>
                        <h3>
                        Understanding Proof-of-Consensus and Participation
                        </h3>
                        <p>
                        PoC and PoP are a way to achieve agreement, trust, and security across a decentralised computer network. It differs from Proof-of-Work (as in Bitcoin), Proof-of-Stake (Ethereum), or Proof-of-History (Solana) in a few ways. The Layer One X proof mechanism involves a randomised selection from a pool of smart devices (like the one you’re on now) and leader nodes to validate transactions and then construct new blocks on the chain. PoC and PoP are the foundation of security as the network processing scales.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>     
                        <img
                          src={HowL1XWorksValidation}
                          alt="How L1X Works Validation"
                          className="img-fluid desktop"
                        />
                        <img
                          src={HowL1XWorksValidationmobile}
                          alt="How L1X Works Validation"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center cont-tb-56">
                      <h4>Interoperability</h4>
                      <h3>Trade across chains with a single identity, wallet and portfolio </h3>
                      <p className="pb-32">
                      Blockchain programmers and users have long been burdened with unacceptable ‘switching costs’. In cases where assets can be transferred between different chains, high gas fees, excruciating processing times, security issues and the capacity for human error (wrong wallet address!) all must be overcome. </p>
<p className="pb-0">Now developers can run EVM (Ethereum Virtual Machine)-compatible code and coordinate consensus with Layer One X VM, which allows users to transact with multiple blockchains with a single identity or wallet/portfolio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center cont-tb-56">
                      <h4>Compatibility</h4>
                      <h3>Built for building</h3>
                      <p>
                      Layer One X Chain powers execution, consensus mechanism and storage. Interoperable adapters are integrated with consensus for maximum security while allowing scalability in interoperable transactions making dApps development fast and efficient.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
    
   



    

        <section className="section fastest-low-cost text-center" id="performance">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                  <div className="title">
                <h2>
                A new wave of speed and   <br />
                performance
                </h2>
                <p className="pb-80">
                Layer One X is a highly scalable with fast finality and interoperability at rapid speeds.
                </p>
                </div>
              </div>
            </div>

            <div className="row fact-section justify-content-center">
              <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3> &#60;$0.00001 </h3>
                  <p>Enjoy the lowest fees on chain</p>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3>100K</h3>
                  <p>Transactions per second (theoretical)</p>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3> &#60;500ms </h3>
                  <p>Network block creation time</p>
                </div>
              </div>
              {/* <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3>3333x </h3>
                  <p>Faster than Ethereum</p>
                </div>
              </div> */}
            </div>

            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center cont-tb-56">
                      <h4>Low Cost</h4>
                      <h3>Decentralised transactions and authentication at the lowest possible cost</h3>
                      <p className="pb-0">
                      Transactions are fast enough and low-cost enough to power traditional retail PoS systems at scale, as well as being suitable for IP royalty payments in the digital economy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center cont-tb-56">
                      <h4>Speed</h4>
                      <h3>As the pool of validators expand we become faster, together.</h3>
                      <p className="pb-0">
                      Layer One X’s capacity and speed will expand as the pool of validators grows. Our network already has <span className="pink-color">XX,XXX</span> micro-validating devices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>     


        <section className="section web3-usecases our-goals state-of-the-art mobile-node-validation text-center" id="security">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-left">
                Anyone can become a validator 
                </h3>
              </div>
              <div className="col-md-6">
                <p className="pb-80 text-left">
                You can add your phone, tablet or smart watch to the validator pool today and start earning rewards. 
                </p>                  
              </div>
            </div>
            <div className="cards-section mb-80">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMDevice
                            : Device
                        }
                        alt="Device"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>
                      Smart-device-agnostic validation.
                      </h4>
                      <p>
                      iPhone, Android or tablet. If it has a screen and internet connection it can validate
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMPartialIntegrity
                            : PartialIntegrity
                        }
                        alt="Partial Integrity"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>
                      Potential for other IoT validators
                      </h4>
                      <p>
                      In a world of 44 billion IoT devices, we’e developing ways for even your smart fridge to become a validation device 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMFinalIntegrity
                            : FinalIntegrity
                        }
                        alt="Final Integrity"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>
                      Leader nodes and random validation
                      </h4>
                      <p>
                      Auctor amet porta platea ut pellentesque amet venenatis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Portability</h4>
                      <h3>Securely connecting Web3 technologies at blazing speeds</h3>
                      <p className="pb-40">
                      The digital economy is open for business. Take your digital identity and assets with you across the worlds of Web3 with confidence they’ll stay where you left them. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Reward Validation</h4>
                      <h3>Unite with other validators on the network and earn Layer One X tokens.</h3>
                      <p className="pb-40 pink-color">
                      Mobile-based transaction validation mechanism provide validators with rewards for participation on the network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="decentralised-section text-center d-flex align-self-center" id="buy-l1x">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                  Built to unite a   <br/> 
                  decentralised future.
                  </h2>
                  <p>
                  Fund the next leap forward with interoperable, decentralised and scalable <br/>Web3 infrastructure made for next-gen dApp development.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <Link className="glow-button gradient-border-mask" to="/public-sale">
                      {/* <span>Buy L1X </span>  */}
                      <span>{Config.publicSaleButtonLabel}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </>
    );
  }
}
HowItWorks.propTypes = {
  theme: PropTypes.string,
};
