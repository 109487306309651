/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from "react";
import PartialIntegrity from "../images/icons/partial-integrity.png";
import LMPartialIntegrity from "../images/icons/partial-integrity-lm.png";
import FinalIntegrity from "../images/icons/final-integrity.png";
import LMFinalIntegrity from "../images/icons/final-integrity-lm.png";
import HowL1XWorksValidation from "../images/how-l1x-works-validation.png";
import HowL1XWorksValidationmobile from "../images/how-l1x-works-validation-mobile.png";

import Device from "../images/icons/icon-device.png";
import LMDevice from "../images/icons/icon-device-lm.png";
import dummyImgExpe from "../images/blockchain-as-a-service.png";
import checkSquare from "../images/check_icon_sq.png";
import mannetImg from "../images/MAINNET-1.png";
import L1XApp1 from "../images/L1X-App-1.png";
import L1XApp2 from "../images/L1X-App-2.png";
import L1XApp3 from "../images/L1X-App-Screen-shot-3.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Config from "../config";

function L1xAppLandingPage() {

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    return (
        <>
            <section className="banners-section sub-banner applanding__page__container">
                {/* <nav className="breadcrumbs">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="fa-solid fa-home"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <a>Invest</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    How It Works
                                </li>
                            </ol>
                        </div>
                    </nav> */}
                <div className="banner-section text-center d-flex align-self-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 m-auto">
                                <h1 className="mb-5">Experience Web3 Like Never Before with L1X App Launching on 15th October 2023 </h1>

                                {/* <div className="buttons d-flex justify-content-center">
                                    <div className="btn-style btn-style-one">
                                        <a className="glow-button gradient-border-mask" href="https://staging.dlndp61jz4t1k.amplifyapp.com/" target="_blank" >
                                            <span>L1XApp</span>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="main-sticky">
                {/* <nav className="section-sticky-top w-auto fixed-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 m-auto">
                                    <ul>
                                        <span>Jump to</span>
                                        <li>
                                            <a href="/how-it-works/#interoperability">
                                                Interoperability
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/how-it-works/#performance">
                                                Performance
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/how-it-works/#security">
                                                Security
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/how-it-works/#validation">
                                                Validation
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/how-it-works/#buy-l1x">
                                                Buy L1X
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav> */}


                <section className="banners-section sub-banner ">
                    <div className="banner-section text-center d-flex align-self-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 m-auto">

                                    <div className="row mb-3">
                                        <div className="col-md-7 center-center__app__l">
                                            <div className="check__content__expe__l1x mb-3">
                                                <div className="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                                <p>L1X App is the first multi-chain ecosystem experience that allows you to access multiple projects with multiple accounts from a single place. </p>
                                            </div>
                                            <div class="check__content__expe__l1x mb-3">
                                                <div class="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                                <p>L1X App removes the complexities of Web3 space and drives you through a user-friendly experience to perform complex actions into easy to understand steps. </p>
                                            </div>
                                            <div class="check__content__expe__l1x">
                                                <div class="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                                <p>L1X App allows you to customise your web3 experience with Widgets that you can customise and use as an interface to Web3 projects from one Dashboard.  </p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <img className="image__experience" src={L1XApp2} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section web3-usecases our-goals state-of-the-art text-center bg-blue" id="interoperability">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 m-auto">
                                <div className="row mb-3">
                                    <div className="col-md-5">
                                        <img className="image__experience" src={L1XApp1} />
                                    </div>
                                    <div className="col-md-7 center-center__app__l">
                                        <div className="check__content__expe__l1x mb-3">
                                            <div className="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                            <p>With L1X Dashboard you can quickly perform DeFi activities from a single widget from your home screen. </p>
                                        </div>
                                        <div class="check__content__expe__l1x mb-3">
                                            <div class="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                            <p>Quickly navigate to multiple sessions and web pages such as explorer, staking, swap and account from a single space called Tray. </p>
                                        </div>
                                        <div class="check__content__expe__l1x">
                                            <div class="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                            <p>Quickly search and customise your explorer experience with account structures that you can use to follow and attach folders to transactions to quickly navigate to them.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="banners-section sub-banner ">
                    <div className="banner-section text-center d-flex align-self-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 m-auto">

                                    <div className="row mb-3">
                                        <div className="col-md-7 center-center__app__l">
                                            <div className="check__content__expe__l1x mb-3">
                                                <div className="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                                <p>Explore the all new Explorer that allows you to explore transactions, easily follow wallet and separate transactions into folders on the explorer. </p>
                                            </div>
                                            <div class="check__content__expe__l1x mb-3">
                                                <div class="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                                <p>The Junk folder has been configured to send all the non-utility tokens to it automatically providing a more seamless way to check transactions and making it more secure. </p>
                                            </div>
                                            <div class="check__content__expe__l1x">
                                                <div class="check__icon__exp__app"><i class="fa-regular fa-square-check"></i></div>
                                                <p>The new Flow Filter allows you to filter transactions while keeping the same view you are at. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <img className="image__experience" src={L1XApp3} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section web3-usecases our-goals state-of-the-art text-center bg-blue" id="interoperability">

                    <div className="container">
                        <div className="tab__container__bx">
                            <h3>RoadMap</h3>
                            <ul className="nav__tabs__ul">
                                <li className="nav__tabs__li">
                                    <button
                                        className={`btn nav__tabs__btn ${activeTab === 1 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(1)}
                                    >L1X App v1</button>
                                </li>
                                <li className="nav__tabs__li">
                                    <button
                                        className={`btn nav__tabs__btn ${activeTab === 2 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(2)}
                                    >L1X App v2</button>
                                </li>
                                <li className="nav__tabs__li">
                                    <button
                                        className={`btn nav__tabs__btn ${activeTab === 3 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(3)}
                                    >L1X App v3</button>
                                </li>
                                <li className="nav__tabs__li">
                                    <button
                                        className={`btn nav__tabs__btn ${activeTab === 4 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(4)}
                                    // className="btn nav__tabs__btn"
                                    >L1X App v4</button>
                                </li>
                            </ul>
                            <div className="tab__content__container__bx">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            //className="tab__content__bx__txt tab__active__roadmap"
                                            className={`tab__content__bx__txt ${activeTab === 1 ? 'tab__active__roadmap' : 'tab__inactive__roadmap'}`}
                                        >
                                            <p>15th October 2023</p>
                                            <ul>
                                                <li>Create your own web3 Space, personalizing your Home Screen as per your needs.</li>
                                                <li>Perform Cross Chain Staking effortlessly.</li>
                                                <li>Swap assets across different chains with ease.</li>
                                                <li>Combine Cross Chain Swap and Stake in a single transaction.</li>
                                                <li>Enjoy a personalized Blockchain Explorer experience.</li>
                                                <li>Track and manage your assets and portfolio seamlessly.</li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div
                                            className={`tab__content__bx__txt ${activeTab === 2 ? 'tab__active__roadmap' : 'tab__inactive__roadmap'}`}
                                        >
                                            <p>30th October 2023</p>
                                            <ul>
                                                <li>Claim exclusive rewards by joining the X_Perks Program.</li>
                                                <li>Elevate your Web3 expertise and become an X_Pert.</li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div
                                            className={`tab__content__bx__txt ${activeTab === 3 ? 'tab__active__roadmap' : 'tab__inactive__roadmap'}`}
                                        >
                                            <p>	30th November 2023</p>
                                            <ul>
                                                <li>Discover the L1X dApp store, where projects host their creations in user spaces, providing you with multi-chain application access.</li>
                                                <li>Install and monitor all your project permissions and actions from a single, convenient location.</li>
                                                <li>Experience enhanced capabilities with a KYC-Based Smart Contract for your User Profile.</li>
                                                <li>Seamlessly convert between fiat and cryptocurrencies with our onramp and offramp.</li>
                                                <li>Use the Wallet Widget to swap and send assets in a single transaction.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div
                                            className={`tab__content__bx__txt ${activeTab === 4 ? 'tab__active__roadmap' : 'tab__inactive__roadmap'}`}
                                        >
                                            <p>30th December 2023</p>
                                            <ul>
                                                <li>Access a full developer's experience right from L1Xapp.com.</li>
                                                <li>Empower projects to hire developers directly. Create Clips and Widgets for users to try applications before fully committing.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="container tabular__form__container"> */}
                    {/* <table class="table table-hover">
                                <thead>
                                    <tr className="tabular__road__map__head">
                                        <th>L1X App</th>
                                        <th>L1X App</th>
                                        <th>L1X App</th>
                                        <th>L1X App</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>15th October 2023</td>
                                        <td>30th October 2023</td>
                                        <td>30th November 2023</td>
                                        <td>30th December 2023</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li>Create your own web3 Space, personalizing your Home Screen as per your needs.</li>
                                                <li>Perform Cross Chain Staking effortlessly.</li>
                                                <li>Swap assets across different chains with ease.</li>
                                                <li>Combine Cross Chain Swap and Stake in a single transaction.</li>
                                                <li>Enjoy a personalized Blockchain Explorer experience.</li>
                                                <li> Track and manage your assets and portfolio seamlessly.</li>
                                            </ul>
                                           
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Claim exclusive rewards by joining the X_Perks Program.</li>
                                                <li> Elevate your Web3 expertise and become an X_Pert.</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Discover the L1X dApp store, where projects host their creations in user spaces, providing you with multi-chain application access.</li>
                                                <li>Install and monitor all your project permissions and actions from a single, convenient location.</li>
                                                <li>Experience enhanced capabilities with a KYC-Based Smart Contract for your User Profile.</li>
                                                <li>Seamlessly convert between fiat and cryptocurrencies with our onramp and offramp.</li>
                                                <li>Use the Wallet Widget to swap and send assets in a single transaction.</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Access a full developer's experience right from L1Xapp.com.</li>
                                                <li>Empower projects to hire developers directly. Create Clips and Widgets for users to try applications before fully committing.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}
                    {/* <div className="row pb-80">
                                <div className="col-md-6">
                                    <h3 className="text-left">
                                        Empowering innovators to build the interoperable internet of the future
                                    </h3>
                                </div>
                                <div className="col-md-6">
                                    <p className="pb-32 text-left">
                                        Private and public blockchain networks continue to suffer from scalability issues in reaching finality securely, at speed and for a low cost. This trilemma has stymied the wide adoption of blockchain its potential for many industrial uses. </p>
                                    <p className="pb-32 text-left">Fragmented blockchain architecture and disparate systems have led to less-than-perfect cross-chain methods such as exchanges, oracles, side chains and bridges, all of which have their own drawbacks. </p>
                                    <p className="pb-32 text-left">Layer One X is a foundational protocol that overcomes interoperability challenges though a novel approach.</p>
                                    <p className="pb-0 text-left">
                                        It’s important to note that Layer One X isn’t a cryptocurrency, exchange or NFT marketplace. Nor is it a federated database. It’s the foundational underlying (layer one) blockchain technology that allows businesses of any kind build a decentralised network, execute smart contracts and build interoperable dApps (decentralised apps).
                                    </p>
                                </div>
                            </div> */}
                    {/* </div> */}
                </section>

                <section className="decentralised-section text-center d-flex align-self-center" id="buy-l1x">
                    <div className="container">
                        <div className="row h-full align-items-center">
                            <div className="col-sm-12">
                                <div className="title sub__head__elevent">
                                    <h3> Elevate your Web3 Mastery with X_Pert</h3>
                                    <h4>
                                        The X_Pert program opens doors to a world of possibilities, Leverage your influence and expertise to introduce individuals to L1XApp, while earning lifetime recurring revenue.

                                        Get access to specialized resources, rewards, and be part of a dynamic community of like-minded professionals.
                                    </h4>
                                </div>
                                <div className="buttons d-flex justify-content-center mt-5 mb-5">
                                    <div className="btn-style btn-style-one">
                                        <Link
                                            className="glow-button gradient-border-mask"
                                            to="https://l1xapp.com/xpert/about"
                                        >
                                            <span>X_Pert Registration</span>
                                        </Link>
                                    </div>
                                </div>
                                <p>Unlock endless earning and rewards. Dive into the X_Pert program!</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}
L1xAppLandingPage.propTypes = {
    theme: PropTypes.string,
};

export default L1xAppLandingPage;