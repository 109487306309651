import React, { useState, useEffect } from "react";
import { servicePostRegisterAsInvestor, serviceGetWhiteListingStatus } from "../../services/apiService";
import CircularProgress from "../CircularProgress/index";
// import { useParams } from 'react-router-dom';
import validator from "validator";
import Config from "../../config/index";
import logger from "../../utils/logger";
// import { NumericFormat } from "react-number-format";
import MessageModal from "../private-sale/messageModal";
import RegistrationMessage from "../private-sale/registrationMessage";
import { useNavigate, Link, useParams } from "react-router-dom"
import Notify from "../../utils/notify";
import CountryCode from "../../config/countryCode";
import AuthService from "../../services/authService";

const BasicDetails = (props) => {
  const { referrerCode } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const intialValues = {
    email: props.email ? props.email : "",
    firstName: "",
    lastName: "",
    middleName:"",
    country:"",
    birthYear:"",
    referrerCode: referrerCode,
    terms: false,
  };


  const [formValues, setFormValues] = useState(intialValues);
  const [showLogin, setShowLogin] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  const [showRegistrationMessage, setShowRegistrationMessage] = useState(false);
  const navigate = useNavigate()


  const handleReferral = (e) => {
    navigate(`/referral`, { state: { email: formValues.email, firstName: formValues.firstName, lastName: formValues.lastName } })
  };


  const submitForm = async () => {
    try {
      logger("formValues", formValues);

      let formData = {
        email: formValues.email,
        first_name: formValues.firstName,
        middle_name: formValues.middleName,
        last_name: formValues.lastName,
        country: formValues.country,
        birth_year: formValues.birthYear,
        referrer_code: formValues.referrerCode,
        is_tc_agreed: formValues.terms,
      };

      let _investorRes = await servicePostRegisterAsInvestor(formData);
      if (_investorRes.status === "success") {

        AuthService.storeAuthToken(_investorRes.data.token);

        setIsSubmitting(false);

        let _whiteListRes = await getWhiteSaleStatus();

        if (_whiteListRes) {
          return false
        }

        if (Config.holdBuyingFlow === true) {
          setShowLogin(true)
        }
        else {
          // props.handleEmail(formValues.email)
          // props.handleForm(Config.sectionID.registrationForm, Config.sectionID.verifyOTP);

          // Navigate User to Buy section 
          props.handleForm(Config.sectionID.buySectionForm)

        }



        return false;
      }

      setIsSubmitting(false);
      return Notify(_investorRes.message, "error")

    } catch (e) {
      setIsSubmitting(false);
      return Notify("Error while submitting form", "error");
    }
  };

  const getWhiteSaleStatus = async () => {
    try {

      let _res = await serviceGetWhiteListingStatus();

      if (_res.status === "success") {
        setShowRegistrationMessage(_res.data === "true" ? true : false);
        return _res.data === "true" ? true : false
      }

      return Notify(`${_res.message}`, "error")
    } catch (e) {
      return Notify('Error while getting whitelisting status', 'error')
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormValues({ ...formValues, terms: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateEmail(formValues));
  };

  function validateEmail(values) {
    let errors = {};

    if (validator.isEmpty(values.email)) {
      errors.email = "Please enter a email address";
    } 
    else if (!validator.isEmail(values.email)) {
      errors.email = "Invalid email format";
    } 

    if (validator.isEmpty(values.firstName)) {
      errors.firstName = "Please enter first name";
    } 

    /* else if (validator.isEmpty(values.lastName)) {
      errors.lastName = "Please enter last name";
    } 
    else if (validator.isEmpty(values.amount)) {
      errors.amount = "Please enter amount";
    } 
    else if (!validator.isFloat(values.amount, { min: Config.minInvestmentAmount })) {
      errors.amount = `Please enter amount greater than equal to ${Config.minInvestmentAmount}`;
    } 
    else if (!validator.isFloat(values.amount, { max: Config.maxInvestmentAmount })) {
      errors.amount = "Please valid amount";
    } */

    if (!formValues.terms) {
      errors.terms = "Please check the terms and conditions";
    }

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    }

    return errors;
  }


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formErrors]);

  return (
    <>
      {/* <section className="banners-section sub-banner"> */}
      {showRegistrationMessage ?
        <RegistrationMessage /> :
        <section className="banner-transparent">
          <div className="text-center d-flex align-self-center p-118">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="pro-ico form__box__container">
                    <h3 className="pb-18 mb-0">Investment Details</h3>
                    <p style={{ textAlign: 'justify' }}>
                      <small>
                        Register here for the rare opportunity to invest at the early stages of this revolutionary layer one blockchain protocol.
                      </small>
                    </p>

                    <form onSubmit={handleSubmit} noValidate>
                      <div className="mb-3">
                        <label  className="form-label">
                          Email <span className="asteric">*</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="mb-3">
                        <label  className="form-label">
                          First Name <span className="asteric">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          value={formValues.firstName}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                        {formErrors.firstName && (
                          <span className="error">{formErrors.firstName}</span>
                        )}
                      </div>
                      
                      <div className="mb-3">
                        <label  className="form-label">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          name="middleName"
                          value={formValues.middleName}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                        {formErrors.middleName && (
                          <span className="error">{formErrors.middleName}</span>
                        )}
                      </div>

                      <div className="mb-3">
                        <label  className="form-label">
                          Last Name <span className="asteric">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          value={formValues.lastName}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                        {formErrors.lastName && (
                          <span className="error">{formErrors.lastName}</span>
                        )}
                      </div>
                      
                      <div className="mb-3">
                        <label  className="form-label">
                          Birth Year (Example: 1990) <span className="asteric">*</span>
                        </label>
                        <input
                          type="text"
                          name="birthYear"
                          value={formValues.birthYear}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                          placeholder=""
                        />
                        {formErrors.birthYear && (
                          <span className="error">{formErrors.birthYear}</span>
                        )}
                      </div>

                      <div className="mb-3">
                        <label  className="form-label">
                          Country Name  <span className="asteric">*</span>
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="country"
                          value={formValues.country}
                          onChange={handleChange}
                        >
                          <option key={"default-country"} value="">
                            Select Country
                          </option>
                          {
                            CountryCode.COUNTRIES.map((_country) => {
                              return <option key={_country.name + _country.code} value={_country.code}>{_country.name}</option>
                            })
                          }
                        </select>
                        {formErrors.country && (
                          <span className="error">{formErrors.country}</span>
                        )}
                      </div>

                    

                      {/* <div className="mb-3">
                        <label  className="form-label">
                          Investment Amount (USDT/USDC){" "}
                          <span className="asteric">*</span>
                        </label>
                        <input
                          type="hidden"
                          name="amount"
                          value={formValues.amount}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                        />
                        <NumericFormat
                          onValueChange={(values) => {
                            setFormValues({ ...formValues, amount: values.value });
                          }}

                          thousandSeparator={true}
                          className="form-control"
                          inputmode="numeric"
                        />
                        {formErrors.amount && (
                          <span className="error">{formErrors.amount}</span>
                        )}
                      </div> */}


                      <div className="mb-3">
                        <label  className="form-label">
                          Referrer Code (Code of who referred you)
                        </label>
                        <input
                          type="text"
                          name="referrerCode"
                          value={formValues.referrerCode}
                          onChange={handleChange}
                          className="form-control"
                          id=""
                          readOnly={referrerCode ? true : false}
                        />
                        {formErrors.referrerCode && (
                          <span className="error">{formErrors.referrerCode}</span>
                        )}
                      </div>


                      <div className="mb-3 form-check text-left">
                        <input
                          type="checkbox"
                          name="terms"
                          value={formValues.terms}
                          onChange={handleCheckboxChange}
                          className="form-check-input whiteList_terms_checkbox"
                          id="exampleCheck1"
                          style={{ width: "1.5em", height: "1.5em" }}
                        />
                        {/* <button
                        type="button"
                        className="termandcondition__btn"
                        data-bs-toggle="modal"
                        data-bs-target="#termsAndConditionModal"
                      >
                        <label
                          className="form-check-label form-label cursor__pointer"
                          for="exampleCheck1"
                        >
                          I agree to Terms & Conditions
                        </label>
                      </button> */}

                        <Link className="termandcondition__btn" target="_blank" to="/whitelist-terms-conditions">
                          <label
                            className="form-check-label form-label cursor__pointer"
                            style={{ "verticalAlign": "sub",marginLeft: 10 }}
                          >
                            I have read and agree to the <u>Layer One X Whitelist Terms & Conditions</u>
                          </label>
                        </Link>

                        {formErrors.terms && (
                          <span className="error">{formErrors.terms}</span>
                        )}
                      </div>

                      {isSubmitting === true ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {" "}
                          <CircularProgress size={30} />
                        </div>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      <div
        className="modal fade"
        id="termsAndConditionModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title term__head" id="exampleModalLabel">
                Terms & Conditions
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="term__and__conditions__content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
          </div>
        </div>
      </div>

      <MessageModal title="login" show={showLogin} close={() => { setShowLogin(false); setShowReferral(true) }} />
      <MessageModal title="referral" show={showReferral} close={() => navigate('/')} yes={() => handleReferral(false)} />
    </>
  );
};

export default BasicDetails;
