import Config from "../config";
import * as ethers from "ethers";

function getNetworks(){
    if(Config.networkMode === "testnet"){
        return Config.testnet.enabledNetworks
    }
    else
    {
        return Config.mainnet.enabledNetworks
    }
}

function getTokens(){
    if(Config.networkMode === "testnet"){
        return Config.testnet.enabledTokens
    }
    else
    {
        return Config.mainnet.enabledTokens
    }
}

function getNetworkById(_chainId){
    
    if(ethers.utils.isHexString(_chainId) === false)
    {
        _chainId = (ethers.BigNumber.from(_chainId)).toHexString();
        _chainId = ethers.utils.hexStripZeros(_chainId);
    }

    if(Config.networkMode === "testnet"){
        return Config.testnet.chainIdMap[_chainId];
    }
    else
    {
        return Config.mainnet.chainIdMap[_chainId];
    }
}

function getFormalNetworkName(_abbr){
    if(Config.networkMode === "testnet"){
        return Config.testnet.formalNetworkNameMap[_abbr];
    }
    else
    {
        return Config.mainnet.formalNetworkNameMap[_abbr];
    }
}

function getNetworkConfig(_abbr){
    if(Config.networkMode === "testnet"){
        return Config.testnet.networkConfig[_abbr];
    }
    else
    {
        return Config.mainnet.networkConfig[_abbr];
    }
}

function getTokenAddress(_network,_token){
    if(Config.networkMode === "testnet"){
        return Config.testnet.tokenAddress[_network][_token];
    }
    else
    {
        return Config.mainnet.tokenAddress[_network][_token];
    }
}

function getPrivateSaleAddress(_network){
    if(Config.networkMode === "testnet"){
        return Config.testnet.privateSaleContract[_network];
    }
    else
    {
        return Config.mainnet.privateSaleContract[_network];
    }
}

function getPublicSaleAddress(_network){
    if(Config.networkMode === "testnet"){
        return Config.testnet.publicSaleContract[_network];
    }
    else
    {
        return Config.mainnet.publicSaleContract[_network];
    }
}

export default {
    getNetworks,
    getTokens,
    getNetworkById,
    getFormalNetworkName,
    getNetworkConfig,
    getTokenAddress,
    getPrivateSaleAddress,
    getPublicSaleAddress,
}