import React, { useEffect, useState } from "react"
import validator from "validator";
import Config from "../../config";
import Logo from "../../images/logol1x-icon.svg";
import { serviceGetPublicSaleProspectExits, servicePostProspectKYC, serviceGetCheckProspectKyc } from "../../services/apiService";
import Notify from "../../utils/notify";
import CircularProgress from "../CircularProgress/index";
import CurrencyFormatter from "../../services/currencyService";
import closeIcon from "../../images/close-menu-lm.svg";
import storageService from "../../services/storageService";
let tempAmount = 0;

const PublicSaleValidateCustomer = (props) => {
    const [emailAddress, setEmailAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitStatus, setsubmitStatus] = useState(false);
    const [totalUSD, setTotalUSD] = useState('');
    const [totalL1X, setTotalL1x] = useState('');
    //useEffect(() => {

    // let parseContactData = StorageService.getData('PREFILLED_USER_DETAILS');

    // if (parseContactData) {
    //     setEmailAddress(parseContactData.email)
    // }



    //, [])
    // useEffect(() => {
    // let _email = storageService.getData('email');
    // if (_email !== null && typeof _email !== 'undefined') {
    //     let postEmail = _email.split('"').join('');
    //     setEmailAddress(postEmail)
    // }
    // }, [])


    // Only Allow 4 digits number
    const handleNumberValidation = (evt) => {

        let theEvent = evt || window.event;
        // const currentValue = theEvent.target.value;
        const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Tab", "."];

        //const regex = /^\d$/;
        const regex = /^\d+(\.\d+)?$/;

        if (
            !regex.test(theEvent.key) &&
            !allowedKeys.includes(theEvent.key)
        ) {
            theEvent.preventDefault();
        }
    }

    const calculateL1X = (_value) => {
        setAmount(_value)
        if (_value) {
            let _calculateL1x = _value / Config.publicSaleConversionRate + " L1X";
            setTotalL1x(_calculateL1x);
        } else {
            setTotalL1x("");
        }
    }

    const processValidateProspect = async () => {
        let errors = {};

        let minInvestment = Config.publicSaleMinInvestmentAmount;
        let maxInvestment = Config.publicSaleMaxInvestmentAmount;

        if (validator.isEmpty(emailAddress)) {
            errors.emailAddress = "Please enter email address";
        }
        else if (!validator.isEmail(emailAddress)) {
            errors.emailAddress = "Please enter valid email address";
        }

        if (validator.isEmpty(amount)) {
            errors.amount = "Please enter expected amount";
        }

        // if (validator.isEmpty(expectedAmount.toString())) {
        //     errors.expectedAmount = "Please enter expected amount";
        // }
        // else 
        if (parseFloat(amount) < parseFloat(minInvestment)) {
            errors.amount = `Please enter amount greater than $${minInvestment}`;
        }
        else if (parseFloat(amount) > parseFloat(maxInvestment)) {
            errors.amount = `Please enter amount lesser than $${maxInvestment}`;
        }

        setErrors(errors)

        if (Object.keys(errors).length === 0) {

            setsubmitStatus(true)
            let data = {
                email: emailAddress.toLocaleLowerCase(),
                amount: amount
            }

            //localStorage.setItem("email", data.email);
            storageService.setData("email", data.email);
            storageService.setData("amount", amount);
            // Check is email exist 
            let _checkIsExistEmail = await serviceGetPublicSaleProspectExits(data.email);

            if (_checkIsExistEmail.status == 'failure') {
                setsubmitStatus(false)
                Notify(_checkIsExistEmail.message, "error")
                props.handleUserSteps('publicSaleRegistrationForm')
                return
            }

            if (_checkIsExistEmail.status == 'success') {

                let user_details = {
                    firstName: _checkIsExistEmail.data.first_name,
                    lastName: _checkIsExistEmail.data.last_name,
                    email: _checkIsExistEmail.data.email,
                    referralCode: _checkIsExistEmail.data.referral_code,
                    expectedAmount: _checkIsExistEmail.data.expected_amount,
                    country: _checkIsExistEmail.data.country
                }

                storageService.setData('PREFILLED_USER_DETAILS', user_details);

                if (props.paymentType == 'DebitCard') {
                    setsubmitStatus(false)
                    props.handleUserSteps('publicSaleMoonPaymentGateway')
                    return
                }

                if (props.paymentType == 'BankTransfer') {
                    setsubmitStatus(false)
                    props.handleUserSteps('publicSaleBankTransfer')
                    return
                }

                if (props.paymentType == 'DirectPayment') {
                    setsubmitStatus(false)
                    //props.handleUpdateCurrentStep(2)
                    props.handleUserSteps('publicSaleStepDirectTransfer')
                    return
                }

                if (props.paymentType == 'ProccedByMetamask') {
                    setsubmitStatus(false)
                    //props.handleUpdateCurrentStep(2)
                    props.handleUserSteps('publicSaleStep')
                    return
                }

                // Validate wallet address
                // let _walletAddress = _checkIsExistEmail.data.wallet_address;
                // let _txSubmitResponse = await serviceGetCheckProspectKyc(_walletAddress);

                // if (_txSubmitResponse.status === "success") {

                //     if (props.paymentType == 'DirectPayment') {
                //         setsubmitStatus(false)
                //         //props.handleUpdateCurrentStep(2)
                //         props.handleUserSteps('publicSaleStepDirectTransfer')
                //         return
                //     }

                // } else if (_txSubmitResponse.status === "failure") {
                //     Notify("We couldn't find record.", "error");
                //     setsubmitStatus(false)
                //     props.handleUserSteps('publicSaleRegistrationForm')
                //     return
                // }
                // else {
                //     Notify("Error while submitting prospect data", "error");
                //     setsubmitStatus(false)
                //     props.handleUserSteps('publicSaleRegistrationForm')
                //     return
                // }

            }

        }
        return errors;
    }






    return (
        <div className="banner__fixed__card__box public_sale_kyc_form">
            {/* <div className="banner__fixed__card__head">
                <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
            </div> */}
            <div className="public_sale_early_access_header">
                <img
                    alt="Early Access available"
                    onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                    className="public_sale_early_acces_close_icon" src={closeIcon} />
            </div>

            <div className="fixed__card__main__content">


                <div>
                    <h4 className="returning__contributor__head">Returning Contributor</h4>
                    <div className="mb-3">
                        <input
                            name="emailAddress"
                            value={emailAddress}
                            onChange={(event) => setEmailAddress(event.target.value.toLocaleLowerCase())}
                            className="form-control public_sale_input"
                            placeholder="Email"
                        />
                        {errors && errors.emailAddress && (
                            <span className="error">{errors.emailAddress}</span>
                        )}
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">Contribution</label>
                            <input
                                type="text"
                                name="amount"
                                value={amount}
                                onChange={(event) => {
                                    tempAmount = event.target.value
                                    setAmount(event.target.value)
                                    calculateL1X(event.target.value)
                                }}
                                //onChange={(event) => calculateL1X(event.target.value)}
                                className="form-control public_sale_input"
                                placeholder="Total $USD"
                                onKeyDown={handleNumberValidation}
                            />
                        </div>

                        <div className="col-6 pl-0">
                            <label className="form-label">L1X Coins</label>
                            <input
                                readOnly="true"
                                type="text"
                                name="totalL1x"
                                value={totalL1X}
                                className="form-control public_sale_input"
                                placeholder="Amount (L1X)"
                            />
                        </div>
                    </div>
                    {errors && errors.amount && (
                        <span className="error">
                            <div className="expected_amoun_error">
                                <p className="expected_amoun_error_txt">Please enter expected amount  </p>
                                <p className="expected_amoun_error_txt">* Min contribution amount is {CurrencyFormatter.format(Config.publicSalePremiumWalletMinInvestmentAmount)}</p>
                                <p className="expected_amoun_error_txt">* Max contribution amount is {`<=`} {CurrencyFormatter.format(Config.publicSaleMaxInvestmentAmount)}</p>
                            </div>
                        </span>
                    )}

                    {/* <div className="mb-3">
                        <input
                            type="text"
                            name="amount"
                            value={amount}
                            onChange={(event) => {
                                tempAmount = event.target.value
                                setAmount(event.target.value)
                            }}
                            className="form-control public_sale_input"
                            id=""
                            placeholder="$ Amount"
                            onKeyDown={handleNumberValidation}
                        />
                        {errors && errors.amount && (
                            <span className="error">
                                <div className="expected_amoun_error">
                                    <p className="expected_amoun_error_txt">Please enter expected amount  </p>
                                    <p className="expected_amoun_error_txt">* Min contribution amount is {CurrencyFormatter.format(Config.publicSalePremiumWalletMinInvestmentAmount)}</p>
                                    <p className="expected_amoun_error_txt">* Max contribution amount is {`<=`} {CurrencyFormatter.format(Config.publicSaleMaxInvestmentAmount)}</p>
                                </div>
                            </span>
                        )}
                    </div> */}

                    {/* Submit Button */}
                    {submitStatus ? (
                        <button
                            disabled
                            className="btn btn-primary buy-btn-loader"

                        >
                            <span className="loader-span">
                                <CircularProgress /> Submiting ...
                            </span>
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={processValidateProspect}
                            className="btn btn-primary buy-btn-loader validate-customer-extended"

                        >
                            Submit
                        </button>
                    )}

                    {/* <button disabled={submitStatus} onClick={() => {
                        props.handleUserSteps('publicSaleHowToContribute')
                    }} className="reciept__details__goBack__btn">Go Back</button> */}

                </div>
            </div>
        </div>
    )
}

export default PublicSaleValidateCustomer