import React, { useEffect, useState } from "react"
import validator from "validator";
import CountryCode from "../../config/countryCode";

import {
    serviceCheckReferralCode,
    serviceGetContactData,
    servicePublicSaleProspect,
} from "../../services/apiService";

import Notify from "../../utils/notify";
import { NumericFormat } from "react-number-format";
import Config from "../../config";
import CurrencyFormatter from "../../services/currencyService";
import StorageService from "../../services/storageService";
import L1XIcon from "../../images/logo.svg";
import storageService from "../../services/storageService";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const RegistrationForm = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [referrerCode, setReferrerCode] = useState('');
    const [referrerCodeData, setReferrerCodeData] = useState(null);
    const [expectedAmount, setExpectedAmount] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitStatus, setsubmitStatus] = useState(false);
    const [isReferralCodeValid, setIsReferralCodeValid] = useState(false);
    const [referralCodeText, setReferralCodeText] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [totalUSD, setTotalUSD] = useState('');
    const [totalL1X, setTotalL1x] = useState('');

    useState(() => {

        // If Via Link
        const queryParameters = new URLSearchParams(window.location.search)
        const contactId = queryParameters.get("contactID")

        async function getUsercontactData() {
            // serviceGetContactData
            let _contactData = await serviceGetContactData(contactId)


            if (_contactData.status === 'success') {

                setFirstName(_contactData.data.firstName)
                setLastName(_contactData.data.lastName)
                setEmail(_contactData.data.email)
                setReferrerCode(_contactData.data.referrerCode)
                setExpectedAmount(_contactData.data.expectedAmount)
                setCountry(_contactData.data.country)

                let user_details = {
                    firstName: _contactData.data.firstName,
                    lastName: _contactData.data.lastName,
                    email: _contactData.data.email,
                    referralCode: _contactData.data.referrerCode,
                    expectedAmount: _contactData.data.expectedAmount,
                    country: _contactData.data.country
                }

                StorageService.setData('PREFILLED_USER_DETAILS', user_details);
            }
        }

        if (contactId) {
            getUsercontactData()
        }

    }, [])

    const handleCharValidation = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;

        key = String.fromCharCode(key);
        if (key.length === 0) return;
        var regex = /^[A-Z a-z.\b\t]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
        // }
    }

    // Only Allow 4 digits number
    const handleNumberValidation = (evt) => {
        let theEvent = evt || window.event;
        // const currentValue = theEvent.target.value;
        const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Tab"];

        const regex = /^\d$/;

        if (
            !regex.test(theEvent.key) &&
            !allowedKeys.includes(theEvent.key)
        ) {
            theEvent.preventDefault();
        }
    }

    const calculateL1X = (_value) => {
        setTotalUSD(_value)
        if (_value) {
            let _calculateL1x = _value / Config.publicSaleConversionRate + " L1X";
            setTotalL1x(_calculateL1x);
        } else {
            setTotalL1x("");
        }
    }

    const handleButtonClick = async () => {
        let errors = {};

        let minInvestment = Config.publicSaleMinInvestmentAmount;
        let maxInvestment = Config.publicSaleMaxInvestmentAmount;

        console.log("capitalizeFirstLetter",)
        console.log("eventevent", email)

        if (validator.isEmpty(firstName)) {
            errors.firstName = "Please enter first name";
        }

        if (validator.isEmpty(lastName)) {
            errors.lastName = "Please enter last name";
        }

        if (validator.isEmpty(email)) {
            errors.email = "Please enter a email address";
        }
        else if (!validator.isEmail(email)) {
            errors.email = "Please enter a valid email address";
        }

        if (validator.isEmpty(birthYear)) {
            errors.birthYear = "Please enter a birth year";
        }

        if (validator.isEmpty(expectedAmount)) {
            errors.expectedAmount = "Please enter expected amount";
        }
        else if (parseFloat(expectedAmount) < minInvestment) {
            errors.expectedAmount = `Please enter amount greater than $${minInvestment}`;
        }
        else if (parseFloat(expectedAmount) > maxInvestment) {
            errors.expectedAmount = `Please enter amount lesser than $${maxInvestment}`;
        }

        if (validator.isEmpty(country)) {
            errors.country = "Please select country";
        }

        setErrors(errors)

        if (Object.keys(errors).length === 0) {
            setsubmitStatus(true)

            let data = {
                firstName: capitalizeFirstLetter(firstName),
                lastName: capitalizeFirstLetter(lastName),
                email: email.toLocaleLowerCase(),
                birthYear: birthYear,
                country: country,
                referralCode: referrerCode,
                expectedAmount: expectedAmount
            }
            let _txSubmitResponse = await servicePublicSaleProspect(data);

            if (_txSubmitResponse.status === "success") {
                StorageService.setData('PREFILLED_USER_DETAILS', data);
                StorageService.setData('email', data.email);
                StorageService.setData('amount', data.expectedAmount);

                setsubmitStatus(false)

                if (country === 'NOTL') {
                    return props.handleUserStep("countryNotListed")
                }
                //return props.handleUserStep("publicSaleStep")
                props.updateUserFromRegistationForm(true)

                return props.handleUserStep("publicSaleHowToContribute")
            }
            else {
                Notify(_txSubmitResponse.message, "error");
                setsubmitStatus(false)
                return
            }
        }
        return errors;
    }

    useEffect(() => {
        const referralCode = window.location.pathname.split("/").pop()
        console.log("🚀 ~ file: registrationForm.js:188 ~ useEffect ~ referralCode:", referralCode)

        if (referralCode === 'bank_transfer_confirmation') {
            return props.handleUserStep("bankTransferImageUpload")
        }

        if (referralCode !== 'public-sale') {
            setReferrerCodeData(referralCode)
            setReferrerCode(referralCode)
        }
    }, []);


    const handleBlur = async () => {

        let _checkReferralResponse = await serviceCheckReferralCode(referrerCode);

        if (_checkReferralResponse.status === "success") {
            setIsReferralCodeValid(true)
            setReferralCodeText("Valid referral code")
        }
        else {
            setIsReferralCodeValid(false)
            setReferralCodeText("Invalid referral code")
        }
    };


    return (
        <>
            <div className="l1x__head__form__icon">
                <h5><img src={L1XIcon} /> Buy L1X Coin</h5>
            </div>
            <div className="mb-2">
                <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(event) => setFirstName(capitalizeFirstLetter(event.target.value))}
                    className="form-control public_sale_input"
                    id=""
                    placeholder="Name "
                    onKeyDown={handleCharValidation}
                />
                {errors && errors.firstName && (
                    <span className="error">{errors.firstName}</span>
                )}
            </div>

            <div className="mb-2">
                <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(event) => setLastName(capitalizeFirstLetter(event.target.value))}
                    className="form-control public_sale_input"
                    id=""
                    placeholder="Last Name"
                    onKeyDown={handleCharValidation}
                />
                {errors && errors.lastName && (
                    <span className="error">{errors.lastName}</span>
                )}
            </div>

            <div className="mb-2">
                <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value.toLocaleLowerCase())}
                    className="form-control public_sale_input"
                    id=""
                    placeholder="Email "
                />
                {errors && errors.email && (
                    <span className="error">{errors.email}</span>
                )}
            </div>

            <div className="mb-2">

                <input
                    type="text"
                    name="birthYear"
                    value={birthYear}
                    onChange={(event) => {
                        setBirthYear(event.target.value)
                    }}
                    className="form-control public_sale_input"
                    id=""
                    placeholder=" Birth Year (Example: 1990)"
                    onKeyDown={handleNumberValidation}
                />
                {errors && errors.birthYear && (
                    <span className="error">{errors.birthYear}</span>
                )}
            </div>

            <div className="mb-2">
                <input
                    disabled={referrerCodeData ? true : false}
                    type="text"
                    name="referrerCode"
                    value={referrerCode}
                    onChange={(event) => setReferrerCode(event.target.value)}
                    className="form-control public_sale_input"
                    id=""
                    // placeholder="Referral Code"
                    //onBlur={handleBlur}
                    placeholder="Referred by (code)"
                />
                {referralCodeText === '' ? "" :
                    <div className={isReferralCodeValid ? "registration_eligible_validation" : "registration_eligible_not_validation"}> {referralCodeText}</div>
                }

                {errors && errors.referrerCode && (
                    <span className="error">{errors.referrerCode}</span>
                )}
            </div>

            <div className="row mb-2">
                <div className="col-6">
                    <label className="form-label">Contribution</label>
                    <input
                        type="hidden"
                        name="expectedAmount"
                        value={expectedAmount}
                        className="form-control public_sale_input"
                        id=""
                    />

                    <NumericFormat
                        onValueChange={(values) => {
                            setExpectedAmount(values.value);
                            calculateL1X(values.value)
                        }}
                        //thousandSeparator={true}
                        className="form-control public_sale_input"
                        placeholder="Total $USD"
                        inputMode="numeric"
                        //prefix="$ "
                        value={expectedAmount}
                    />


                    {/* <input
                        type="text"
                        name="calculateCoins"
                        value={totalUSD}
                        onChange={(event) => calculateL1X(event.target.value)}
                        className="form-control public_sale_input"
                        placeholder="Total $USD"
                        onKeyDown={handleNumberValidation}
                    /> */}
                </div>

                <div className="col-6 pl-0">
                    <label className="form-label">L1X Coins</label>
                    <input
                        readOnly="true"
                        type="text"
                        name="totalL1x"
                        value={totalL1X}
                        className="form-control public_sale_input"
                        placeholder="Amount (L1X)"
                    />
                </div>
                {props.premiumWalletAccess ?
                    <div className="registration_eligible_validation"> Your are eligible for minimum ${Config.publicSaleMinInvestmentAmount.toLocaleString()} USD</div>
                    : ""}

                {errors && errors.expectedAmount && (
                    <span className="error">
                        <div className="expected_amoun_error">
                            <p className="expected_amoun_error_txt">Please enter expected amount  </p>
                            <p className="expected_amoun_error_txt">* Min contribution amount is {props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount : CurrencyFormatter.format(Config.publicSalePremiumWalletMinInvestmentAmount)}</p>
                            <p className="expected_amoun_error_txt">* Max contribution amount is {`<=`} {CurrencyFormatter.format(Config.publicSaleMaxInvestmentAmount)}</p>
                        </div>
                    </span>
                )}
            </div>

            {/* <div className="mb-2">
                <input
                    type="hidden"
                    name="expectedAmount"
                    value={expectedAmount}
                    className="form-control public_sale_input"
                    id=""
                />

                <NumericFormat
                    onValueChange={(values) => {
                        setExpectedAmount(values.value);
                    }}
                    thousandSeparator={true}
                    className="form-control public_sale_input"
                    placeholder="Investment "
                    inputMode="numeric"
                    prefix="$ "
                    value={expectedAmount}
                />

                {props.premiumWalletAccess ?
                    <div className="registration_eligible_validation"> Your are eligible for minimum ${Config.publicSaleMinInvestmentAmount.toLocaleString()} USD</div>
                    : ""}

                {errors && errors.expectedAmount && (
                    <span className="error">
                        <div className="expected_amoun_error">
                            <p className="expected_amoun_error_txt">Please enter expected amount  </p>
                            <p className="expected_amoun_error_txt">* Min contribution amount is {props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount : CurrencyFormatter.format(Config.publicSalePremiumWalletMinInvestmentAmount)}</p>
                            <p className="expected_amoun_error_txt">* Max contribution amount is {`<=`} {CurrencyFormatter.format(Config.publicSaleMaxInvestmentAmount)}</p>
                        </div>
                    </span>
                )}
            </div> */}

            <div className="mb-2">
                <select
                    className="form-select public_sale_input"
                    aria-label="Default select example"
                    name="country"
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}
                >
                    <option key={"default-country"} value="">
                        Select Country
                    </option>
                    {
                        CountryCode.COUNTRIES.map((_country) => {
                            return <option key={_country.name + _country.code} value={_country.code}>{_country.name}</option>
                        })
                    }
                </select>
                {errors && errors.country && (
                    <span className="error">{errors.country}</span>
                )}
            </div>

            <div className="public_sale_learn_more_container">
                <text className="sale_card_find_country_txt">Didn't find your country ?
                    {/* <text onClick={() => { props.handleLearnMore() }} className="cursor__pointer public_sale_learn_more_txt" > Learn more</text></text> */}
                    <text className="cursor__pointer public_sale_learn_more_txt" > Learn more</text></text>
            </div>

            {/* <div onClick={() => { handleButtonClick() }} className="fixed__card__btn__box mt-4"> */}
            <div className="fixed__card__btn__box mt-4">
                {/* <button disabled={submitStatus} className="fixed__card__btn public_sale_register_btn">{submitStatus ? "Submiting ..." : "Register - Whitelist Now "}</button> */}
                {/* <button disabled={submitStatus} className="fixed__card__btn public_sale_register_btn">{submitStatus ? "Submiting ..." : "Buy"}</button> */}
                <button disabled className="fixed__card__btn public_sale_register_btn">{"Buy"}</button>
            </div>
        </>
    )
}

export default RegistrationForm