import React, { useState } from "react";
import Dashboard from "./dashboard";
import NavBar from "./navBar";
import SideBar from "./sideBar";
import Prospects from "./prospects";
import ProspectTransactions from "./prospectTransactions";
import OnChainConfig from "./onChainConfig";
import PrivateSaleInvestors from "./privateSaleInvestors";
import "./style.css";

const Admin = (props) => {

  const [saleType, setSaleType] = useState("");


  const handleValueChange = () => {
    setSaleType(localStorage.getItem("saleType"));
  };
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css"
      />
      <body id="body-pd">
        <NavBar onValueChange={handleValueChange}/>
        <SideBar />
        {props.path === "dashboard" ? <Dashboard saleType={saleType}/> : ""}
        {props.path === "prospects" ? <Prospects saleType={saleType} /> : ""}
        {props.path === "prospect-transactions" ? <ProspectTransactions saleType={saleType}/> : ""}
        {props.path === "onchain-config" ? <OnChainConfig saleType={saleType}/> : ""}
        {props.path === "private-sale-investors" ? <PrivateSaleInvestors/> : ""}
        
      </body>
    </>
  );
};

export default Admin;
