import React from "react"
import closeIcon from "../../images/close-menu-lm.svg";
import tickCircleIcon from "../../images/tick-circle.svg";

const PublicSaleMoonPaymentThankYou = (props) => {
    return (
        <div className="banner__fixed__card__box thank_you_card_height">
            <div className="p-30 mt-5">
                {/* <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div> */}

                <div className="head__thankyou__box">
                    <img src={tickCircleIcon} />
                    <h4 className="thankyou__head">  Thank you for your contribution.</h4>
                </div>
                <div className="direct_thank_you_txt">
                  <p>  We’ve confirmed the transaction hash submitted deposited to our account.
                    </p>
                    <p>Please verify your transaction ownership by sending the Moonpay transaction email
                    to <b>invest@l1x.foundation</b></p>
                </div>
            </div>

            <div className="public_sale_check_eligibility_box p-30">
                <button
                    onClick={() => {
                        props.handleUserSteps('publicSaleRegistrationForm')
                    }}
                    type="button"
                    className="btn btn-primary w-100 close__thankyou__btn"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default PublicSaleMoonPaymentThankYou