import Config from "../config/index";
import logger from "../utils/logger";
import AuthService from "./authService";

const base_url = Config.api.url;
const blockchain_url =  Config.api.blockchain_url;

function getData(url) {
  return fetch(`${base_url + url}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Authorization": AuthService.getAuthToken()
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

function adminGetData(url) {
  return fetch(`${base_url + url}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      Authorization: AuthService.getAdminAuthToken(),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

function postRawJsonData(url, data) {
  logger("postRawJsonData", data);

  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] === null) {
      delete data[key];
    }
  });

  return fetch(`${base_url + url}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },

    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

function postTokenJsonData(url, data) {
  logger("postRawJsonData", data);

  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] === null) {
      delete data[key];
    }
  });

  return fetch(`${base_url + url}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: AuthService.getAuthToken(),
    },

    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}


function getBlockchainData(url) {
  return fetch(`${blockchain_url + url}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function postImage(url, data) {

   return fetch(`${base_url + url}`, {
       method: "POST", // *GET, POST, PUT, DELETE, etc.        
       body: data
   
   })
       .then((response) => response.json())
       .then((data) => {
           return data;
       })
       .catch((error) => {
           logger("error",error)
           return error;
       });
}


export default {
  getData,
  postRawJsonData,
  postTokenJsonData,
  adminGetData,
  getBlockchainData,
  postImage
};
