/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import EasyLearn from "../images/icons/icon-easy-learn.png";
import LMEasyLearn from "../images/icons/icon-easy-learn-lm.png";
import Synchronous from "../images/icons/icon-synchronous.png";
import LMSynchronous from "../images/icons/icon-synchronous-lm.png";
import OpenSource from "../images/icons/icon-opensource.png";
import LMOpenSource from "../images/icons/icon-opensource-lm.png";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class HackathonRegistation extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                {/* <li className="breadcrumb-item">
                  <a>Build</a>
                </li> */}
                <li className="breadcrumb-item active" aria-current="page">
                  27th May 2023 at Ritz Carlton, Pune, INDIA
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>27th May 2023 at Ritz Carlton, Pune, INDIA </h4>
                  <h3 className="mb-32">
                    Unleash The Potential Of World's First Layer One <s>Interoperable</s> X-Talk Virtual Machine.
                  </h3>
                  <h3><strong>Hurry, Limited Seats!</strong></h3>
                  <div className="buttons d-flex justify-content-center">
                    {/* <div className="btn-style btn-style-one">
                      <Link className="glow-button gradient-border-mask" to="../start-building">
                        <span>Start Building</span>
                      </Link>
                    </div> */}

                    <div className="btn-style  btn-style-two">
                      {/* <Link className="glow-button gradient-border-mask" to="/join-hackathon-waitlist">
                        <span>JOIN THE WAITLIST</span>
                      </Link> */}
                      <a class="glow-button gradient-border-mask" href="http://portal.l1x.foundation/" target="_blank" rel="noopener noreferrer">
                        <span>JOIN THE WAITLIST</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="main-sticky">
          {/* <nav className="section-sticky-top fixed-bottom">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <ul>
                    <span>Jump to</span>
                    <li>
                      <a href="build/build-on-layeronex#interoperability">
                        Interoperability
                      </a>
                    </li>
                    <li>
                      <a href="build/build-on-layeronex#scalability">
                        Scalability
                      </a>
                    </li>
                    <li>
                      <a href="build/build-on-layeronex#evm-compatibility">
                        EVM Compatibility
                      </a>
                    </li>
                    <li>
                      <a href="build/build-on-layeronex#smart-contracts">
                        Smart Contracts
                      </a>
                    </li>
                    <li>
                      <a href="build/build-on-layeronex#consensus-mechanism">
                        Consensus Mechanism
                      </a>
                    </li>
                    <li className="active">
                      <Link to="../start-building">
                        Start Building
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav> */}

          {/* <section
          className="section fastest-low-cost grant-pack text-center"
          id="grants"
        >
          <div className="container">
            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center text-center">
                      <h4>Grant Applications</h4>
                      <h3>Get funding and build</h3>
                      <p className="pb-32">
                        Our grant program is open to developers big ideas and
                        those who want to support our initiatives.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/grant-applications">
                            <span>Apply For Grant</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center text-center">
                      <h4>Developer Resources</h4>
                      <h3>Download developer pack</h3>
                      <p className="pb-32">
                        Get started on your interoperable dApp with resources to
                        start building and the Layer One X SDK.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <a className="glow-button gradient-border-mask">
                            <span>Download</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

          <section className="section web3-usecases our-goals state-of-the-art text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-left">
                    Layer One X is bringing projects directly{" "}
                    <br />to you that make a difference{" "}
                    <br /> in Web3.
                  </h3>
                </div>
                <div className="col-md-6">
                  <p className="text-left pb-32">
                    Join us in the one day Hackathon to follow along the World's first X-Talk Virtual Machine and a brand new Virtual Machine.{" "}
                  </p>
                  <p className="pb-80 text-left">
                    The Hackathon will focus on Layer One X protocol, projects building on Layer One X, allowing you to build your first X-Talk Smart Contract and learn about all new Layer One X Contract Standard Store. Follow along the journey where we innovate and integrate web3 into the innovator lifestyle.

                  </p>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMEasyLearn
                              : EasyLearn
                          }
                          alt="Easy Learn"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>
                          GROW
                        </h4>
                        <p className="d-none d-md-block">
                          Learn about all new Layer One Layer One X Protocol and start building smart contracts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMSynchronous
                              : Synchronous
                          }
                          alt="Synchronous"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>
                          FINANCE
                        </h4>
                        <p className="d-none d-md-block">
                          Participate and bid in the DAO based Developer portal & Apply for the <strong> $5 Million Layer One X Developer Grant Program</strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMOpenSource
                              : OpenSource
                          }
                          alt="OpenSource"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>
                          SOCIALISE
                        </h4>
                        <p className="d-none d-md-block">
                          Mentor and learn from the best of the Web3 developer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttons d-flex justify-content-center mt-5">
              <div className="btn-style btn-style-one">
                {/* <Link className="glow-button gradient-border-mask" to="/join-hackathon-waitlist">
                  <span>JOIN THE WAITLIST</span>
                </Link> */}

                <a class="glow-button gradient-border-mask" href="http://portal.l1x.foundation/" target="_blank" rel="noopener noreferrer">
                  <span>JOIN THE WAITLIST</span>
                </a>

              </div>
            </div>
            {/* </div> */}
          </section>


          {/* <section className="section worldsweb3 interoperable text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-80">
                    The blockchain for interoperable and <br />
                    scalable application development
                  </h2>
                </div>

                <div className="web3" id="interoperability">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Interoperability</h4>
                        <h3>
                          A consensus mechanism to leverage cross-chain
                          communication
                        </h3>
                        <p className="pb-32">
                          Build dApps that scale on Layer One X while leveraging
                          cross-chain communication to exchange tokens, data and
                          ID rapidly and securely.
                        </p>
                        <div className="buttons">
                          <a className="btn btn-style-three">
                            <i className="fa-solid fa-file"></i>{" "}
                            Interoperability
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={Interoperability1}
                          alt="Interoperability"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Interoperability1mobile}
                          alt="Interoperability"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3" id="scalability">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Scalability</h4>
                        <h3>Web3 infrastructure at scale with low latency</h3>
                        <p className="pb-32">
                          Achieve high throughput on Layer One X Virtual Machine with
                          pre-built and custom smart contracts. Layer One X VM can
                          theoretically process up to 100,000 transaction per
                          second with sub-second finalisation.
                        </p>
                        <div className="buttons">
                          <a className="btn btn-style-three">
                            <i className="fa-solid fa-file"></i> Scalability
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 order-last order-md-first order-lg-first">
                      <figure>
                        <img
                          src={Scalability}
                          alt="Scalability"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Scalabilitymobile}
                          alt="Scalability"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3" id="evm-compatibility">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>EVM Compatibility</h4>
                        <h3>Easy and seamless migration</h3>
                        <p className="pb-32">
                          Layer One X Virtual Machine (Layer One X VM) is shipped with EVM
                          compatibility for singular consensus. Change your RPC
                          endpoints to seamlessly migrate data to and from the
                          Layer One X system.
                        </p>
                        <div className="buttons evm-button">
                          <a className="btn btn-style-three">
                            <i className="fa-solid fa-file"></i> EVM Compatible
                            Blockchains
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={EVMCompatibility}
                          alt="EVM Compatibility"
                          className="img-fluid desktop"
                        />
                        <img
                          src={EVMCompatibilitymobile}
                          alt="EVM Compatibility"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3" id="utility">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Utility</h4>
                        <h3>Deliver competitive market differentiation</h3>
                        <p>
                          Independent software vendors can embed Layer One X
                          capabilities seamlessly into products, such as
                          delivering faster cross-border payments, without
                          exposing how they achieved this.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 order-last order-md-first order-lg-first">
                      <figure>
                        <img
                          src={Utility}
                          alt="Utility"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Utilitymobile}
                          alt="Utility"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

          {/* <section
          className="section fastest-low-cost rapidly-build text-center"
          id="smart-contracts"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-80">
                    Rapidly build dynamic and
                    <br /> interoperable smart contracts
                  </h2>
                </div>
              </div>
            </div>
            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Build Smart Contracts</h4>
                      <h3>Complex contract upgrades are a thing of the past</h3>
                      <p>
                        Build deployable smart contracts in Rust and dynamically
                        update without redeploying the smart contract.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Interoperable Contracts</h4>
                      <h3>Speed up delivery time of deployment</h3>
                      <p>
                        Call out-of-the-box deployed smart contracts to leverage
                        interoperability and consensus from a single contract.
                        Dynamically set parameters for rapid dApp development.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

          {/* <section className="section fastest-low-cost rapidly-build innovative-consensus-mechanism text-center" id="consensus-mechanism">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-80">
                    An innovative consensus mechanism
                    <br /> to improve transactional finality
                  </h2>
                </div>
              </div>
            </div>
            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Consensus Mechanism</h4>
                      <h3>
                        A Proof-of-Participation-<br />based consensus 
                         mechanism
                      </h3>
                      <p className="pb-32">
                        Layer One X uses a proprietary technology framework to 'farm'
                        thousands of randomly assigned mobile devices for its
                        computational pool; a true decentralisation of the layer
                        one blockchain network.
                      </p>
                      <div className="buttons">
                        <Link className="btn btn-style-three" to="../build/consensus-mechanism">
                          <i className="fa-solid fa-file"></i> Consensus
                          Mechanism
                          <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Data Sharding</h4>
                      <h3>
                        Data can be sharded and stored securely on Layer One X
                        blockchain nodes
                      </h3>
                      <p className="pb-32">
                        Leverage inbuilt sharding and concurrency while
                        executing pre-deployed or custom smart contracts.
                        Sharding reduces duplication of work to offer
                        scalability while preserving decentralisation and
                        security.
                      </p>
                      <div className="buttons">
                        <a className="btn btn-style-three">
                          <i className="fa-solid fa-file"></i> EVM Compatible
                          Blockchains
                          <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

          {/* <section className="section powering-apps power-unity text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Explore the power of unity <br />
                    with Layer One X
                  </h2>
                  <p className="pb-80">
                    Forget developing on X and bridging with Y. Now you can
                    exchange value across blockchains using a single protocol.
                  </p>
                </div>                
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-32">
                    <div className="bg wyde consensus-mechanism d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMConsensusMechanism
                              : ConsensusMechanism
                          }
                          alt="Consensus Mechanism"
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Consensus Mechanism</p>
                      <h3 className="pb-16">
                        A truly decentralised <br /> consensus mechanism
                      </h3>
                      <p className="pb-24">
                        Mobile-enabled transaction validation means more<br/>
                         than 10,000 nodes can serve as validators in the Layer One X <br/>
                         network.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/consensus-mechanism">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde nativechain d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMNativechain
                              : Nativechain
                          }
                          alt="Native chain development "
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Native chain development </p>
                      <h3 className="pb-16">
                      Native Transaction <br/>Lifecycle
                      </h3>
                      <p className="pb-40">
                      Use Layer One X to accommodate transactions using a range <br/>of assets without the need for smart contracts.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/native-transaction-lifecycle">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde interoperate d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMInteroperate
                              : Interoperate
                          }
                          alt="Interoperate without oracles"
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Interoperate without oracles</p>
                      <h3 className="pb-16">
                      Interoperable <br/>Transaction Lifecycle
                      </h3>
                      <p className="pb-40">
                      Fast, secure transactions in the fragmented <br/>blockchain and cryptocurrency ecosystem.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/interoperable-transaction-lifecycle">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        </div>
        {/* <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Start building with <br /> Layer One X.
                    
                  </h2>
                  <p>
                    Create the interoperable future with Layer One X, using the right
                    system <br/> architecture, operations, principles, and best
                    practices.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <Link className="glow-button gradient-border-mask" to="../start-building">
                      <span>Start Building</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}
HackathonRegistation.propTypes = {
  theme: PropTypes.string,
};
