import React, { useState, useEffect, useRef } from "react";
import { serviceGetEarlyAccessExplicitList } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";


const Prospects = ({ saleType }) => {
  const [walletList, setWalletList] = useState([]);
  const headerList = [
    "Email",
    "Created At"
  ];
  const [offset, setOffset] = useState(0);
  const [limit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [authCodeBtnTxt, setAuthCodeBtnTxt] = useState('Reset');
  const [autoCodeFieldDisable, setAutoCodeFieldDisable] = useState(true);
  const [data, setGetListData] = useState([]);

  useEffect(() => {
    let _authCode = localStorage.getItem('premium_wallet_auth_code')
    if (_authCode !== null) {
      setAuthCode(_authCode)
    }

  }, []);


  const handlePageChange = (activePage) => setOffset(activePage * limit);

  useEffect(() => {

    let _authCode = localStorage.getItem('premium_wallet_auth_code')

    if (_authCode !== null) {
      setAuthCode(_authCode)
    }

  }, [authCode]);

  const getWalletList = async (_data) => {
    try {
      setLoader(true);

      _data.authCode = localStorage.getItem('premium_wallet_auth_code');
      _data.email = email ? email : "";

      let _res = await serviceGetEarlyAccessExplicitList(_data);

      if (_res.status === "success") {
        setWalletList(_res.data.explicitList);
        setTotalPages(_res.data.totalPages);
        // logger("prospects Data", _res.data.prospectList);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };

  useEffect(() => {
    let data = {
      limit: limit,
      offset: offset,
      authCode: authCode ? authCode : '',
      email: email ? email : ""
    }
    setGetListData(data)
    getWalletList(data);

  }, [offset]);



  function resetAuthCode() {

    if (authCodeBtnTxt === 'Save') {
      setAutoCodeFieldDisable(true)
      setAuthCode(authCode)
      setAuthCodeBtnTxt('Reset')
      window.localStorage.setItem("premium_wallet_auth_code", authCode)
    }
    else {
      setAutoCodeFieldDisable(false)
      setAuthCode('')
      setAuthCodeBtnTxt('Save')
    }

  }

  return (
    <div className="row premium_wallet_list_card">
      <div className="col-md-12">
        <label className="premium_wallet_title">Early Access Explicit List</label>

        <div className="add_premium_wallet_container">
          <div className="col-md-5">
            <div className="add_premium_wallet_form">
              <div className="premium_wallet_form_auth_box">
                <label className="premium_wallet_add_form_label">Authorization code</label>
                <input
                  disabled={autoCodeFieldDisable}
                  name="authCode"
                  value={authCode}
                  onChange={(event) => setAuthCode(event.target.value)}
                  className="form-control"
                  placeholder="Enter authorization code *"
                />
              </div>

              <span className="add_icon_span">
                <button onClick={() => resetAuthCode()} className="add_premimun_wallet_btn"> {authCodeBtnTxt}</button>
              </span>
            </div>
          </div>
        </div>

        <div className="number__box__head">
          <div
            className="searchContainer"
            style={{ display: "flex", color: '#000', justifyContent: "space-between" }}
          >
            <div className="premium_wallet_card_title">
              Early Access Explicit List
            </div>
            <div>
              <input
                type="text"
                onChange={async (e) => {
                  setEmail(e.target.value)
                }
                }
                style={{
                  fontSize: 17,
                  padding: 6,
                }}
                placeholder="Search by email"
              />
              <button onClick={() => getWalletList(data)} className="add_premimun_wallet_btn" style={{ margin: "5px" }}>
                Search
              </button>
            </div>

          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  {headerList.map((item, index) => (
                    <th>{item}</th>
                  ))}
                </tr>
              </thead>
              {loader === false ? (
                <tbody>
                  {walletList.length > 0 ?
                    walletList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.email}</td>
                        <td>
                          {item.created_at}
                        </td>

                      </tr>
                    ))
                    : <tr><td colSpan={2}>Record not found.</td></tr>}
                </tbody>
              ) : (
                ""
              )}
            </table>
          </div>
          <Pagination
            offset={offset}
            onPageChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Prospects;
