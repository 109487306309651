import React, { useState, useEffect } from "react";
import { servicePostKycData } from "../../services/apiService";
import CircularProgress from "../CircularProgress/index";
// import { useParams } from 'react-router-dom';
import CountryCode from "../../config/countryCode";
import validator from "validator";
import Config from "../../config/index";
import logger from "../../utils/logger";
import Notify from "../../utils/notify";
import KycMessage from "./kycMessage";

const KycForm = (props) => {
  const intialValues = { firstName: "", lastName: "", middleName: "", country: "", birthYear: "", terms: false };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isKycFail,setIsKycFail] = useState(false)

  const submitForm = async () => {
    try {
      logger("formValues", formValues);
      let form_data = {
        "first_name": formValues.firstName,
        "middle_name": formValues.middleName,
        "last_name": formValues.lastName,
        "country_code": formValues.country,
        "birth_year": formValues.birthYear
      }
      logger("_investorRes", formValues)
      let _investorRes = await servicePostKycData(form_data);

      if (_investorRes.status === 'success') {
        setIsSubmitting(false);
        props.handleForm(Config.sectionID.buySectionForm)
        Notify(`${_investorRes.message}`,"success");
        return
      }
      setIsKycFail(true)
      setIsSubmitting(false);
      return Notify(`${_investorRes.message}`,"error");

    } catch (e) {
      setIsSubmitting(false);
      setIsKycFail(true)
      return Notify('Error while submitting form','error')
    }
  };


  const handleCheckboxChange = (e) => {
    setFormValues({ ...formValues, terms: e.target.checked });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formValues));
  };

  function validateForm(values) {
    let errors = {};

    if (validator.isEmpty(values.firstName)) {
      errors.firstName = "Please enter a first name";
    } 
    // else if (validator.isEmpty(values.middleName)) {
    //   errors.middleName = "Please enter a middle name";
    // } 
    else if (validator.isEmpty(values.lastName)) {
      errors.lastName = "Please enter a last name";
    } 
    else if (validator.isEmpty(values.country)) {
      errors.country = "Please select country";
    }
    else if (validator.isEmpty(values.birthYear)) {
      errors.birthYear = "Please enter a birth year";
    } 
    else if (values.terms === false) {
      errors.terms = "Please select the check box";
    }
    // else if (validator.isLength(values.birthYear, { max: 0, min: 5 })) {
    //   errors.birthYear = "Please valid birth year";
    // }
    // else if (validator.isDate(values.birthYear, [{ format: "YYYY" }])) {
    //   errors.birthYear = "Please valid  birth year";
    // }

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    }

    return errors;
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formErrors,isKycFail]);

  return (
    <>
      {/* <section className="banners-section sub-banner"> */}
      {isKycFail === true ? (
        <KycMessage />
      ) : (
      <section className="banner-transparent">
        <div className="text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <h3 className="pb-18 mb-0">KYC Verification</h3>
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="mb-3">
                      <label for="" className="form-label">
                        First Name  <span className="asteric">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                        className="form-control"
                        id=""
                        placeholder=""
                      />
                      {formErrors.firstName && (
                        <span className="error">{formErrors.firstName}</span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label for="" className="form-label">
                        Middle Name  
                      </label>
                      <input
                        type="text"
                        name="middleName"
                        value={formValues.middleName}
                        onChange={handleChange}
                        className="form-control"
                        id=""
                        placeholder=""
                      />
                      {formErrors.middleName && (
                        <span className="error">{formErrors.middleName}</span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label for="" className="form-label">
                        Last Name  <span className="asteric">*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        value={formValues.lastName}
                        onChange={handleChange}
                        className="form-control"
                        id=""
                        placeholder=""
                      />
                      {formErrors.lastName && (
                        <span className="error">{formErrors.lastName}</span>
                      )}
                    </div>


                    <div className="mb-3">
                      <label for="" className="form-label">
                        Country Name  <span className="asteric">*</span>
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="country"
                        value={formValues.country}
                        onChange={handleChange}
                      >
                        <option value="" selected>
                          Select Country
                        </option>
                        {
                          CountryCode.COUNTRIES.map((_country) => {
                            return <option value={_country.code}>{_country.name}</option>
                          })
                        }
                      </select>
                      {formErrors.country && (
                        <span className="error">{formErrors.country}</span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label for="" className="form-label">
                        Birth Year (Example: 1990) <span className="asteric">*</span>
                      </label>
                      <input
                        type="text"
                        name="birthYear"
                        value={formValues.birthYear}
                        onChange={handleChange}
                        className="form-control"
                        id=""
                        placeholder=""
                      />
                      {formErrors.birthYear && (
                        <span className="error">{formErrors.birthYear}</span>
                      )}
                    </div>

                    <div class="mb-3 form-check text-left">

                      <input
                        type="checkbox"
                        name="terms"
                        value={formValues.terms}
                        onChange={handleCheckboxChange}
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <button
                        type="button"
                        class="termandcondition__btn"
                        data-bs-toggle="modal"
                        data-bs-target="#termsAndConditionModal"
                      >
                        <label
                          class="form-check-label form-label cursor__pointer"
                          for="exampleCheck1"
                        >
                          I verify the above details.<br />
                          <span style={{ fontSize: 14 }}>(Please note once information submitted cannot be changed)</span>
                        </label>
                      </button>

                      {formErrors.terms && (
                        <span className="error">{formErrors.terms}</span>
                      )}

                    </div>

                    {isSubmitting === true ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        SUBMIT
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
    </>
  );
};

export default KycForm;
