import React from "react";

const KycMessage = () => {
  return (
    <>
      {/* <section className="banners-section sub-banner"> */}
      <section className="banner-transparent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="campaign__paused__head">Please raise a ticket in Discord and the team will get in touch with you.</h3>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};


export default KycMessage;

