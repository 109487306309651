import React, { Component } from "react";
import componentOne from "../../../src/images/Component-1.png";
import L1xNftCoin from "../../../src/images/L1X-NFT-coin-EVM-Non-EVM-Final.png";
import bannerOne from "../../../src/images/banner-image.png";

export class NftInvest extends Component {
  render() {
    return (
      <>
        <section className="banner__section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner__content__box">
                  <div className="banner__header">
                    <h1>
                      Grab a Part of Crypto <span> HISTORY. </span>
                    </h1>
                  </div>
                  <div className="responsive__image__banner__home">
                    <img
                      src={componentOne}
                      alt="Grab a Part of Crypto HISTORY. "
                    />
                  </div>
                  <h3>
                   <span> Hold on to your hats because Layer One X shattered a
                    never-before-seen blockchain barrier. A feat so miraculous
                    it will definitely make your jaw drop.
                    </span>
                  </h3>
                  <h3>
                  <span style={{whitespace:'nowrap'}}role="img" aria-label="bubbly">So grab a glass of bubbly 🍾🥂, and let us raise a toast to the first EVM to Non-EVM transfer in crypto history, done by a layer one blockchain without the use of a bridge.</span>
                  </h3>
                  <h3>
                  <span> To celebrate, we’re giving all premium wallets a
                    commemorative NFT coin.
                    </span> 
                  </h3>
                  <div className="responsive__image__banner__home">
                    <img
                      src={L1xNftCoin}
                      alt="Grab a Part of Crypto HISTORY. "
                    />
                  </div>
                  {/* <h2 className="get__nft__head">
                    {" "}
                    Get your Limited Edition NFT Today! — Only 5,000 minted!
                  </h2>
                  <div className="btn__box">
                    <a href="/invest/buy-l1x" className="btn btn--white btn--animated">
                      Get a Premium Wallet
                    </a>
                  </div>
                  <br/>
                  <h3>
                  <span> <i> *Contribute $1000 USDC to get an Layer One X premium wallet. </i>
                    </span> 
                  </h3> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner__image__box for__desktop">
                  <img src={bannerOne} alt="Grab a Part of Crypto HISTORY. " />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NftInvest;
