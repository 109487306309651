import React, { useState, useEffect, useRef } from "react";
import { servicegetEarlyAccessList, serviceUploadEarlyAccessCsv, serviceCheckEarlyAccess } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";
import deleteIcon from "../../images/delete.png"
import uploadIcon from "../../images/upload.svg";
const { ethers } = require('ethers');


const Prospects = ({ saleType }) => {
  const [walletList, setWalletList] = useState([]);
  const headerList = [
    "Access Key",
    "Created At"
  ];
  const [offset, setOffset] = useState(0);
  const [limit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [accessKey, setEarlyAccess] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [authCodeBtnTxt, setAuthCodeBtnTxt] = useState('Reset');
  const [autoCodeFieldDisable, setAutoCodeFieldDisable] = useState(true);
  const [_data, setSearchData] = useState([]);

  const [csv, setCsv] = useState("");
  const [csvName, setCsvName] = useState("");
  const inputFile = useRef(null);
  const [errors, setErrors] = useState(null);
  const [submitStatus, setsubmitStatus] = useState(false);


  useEffect(() => {
    let _authCode = localStorage.getItem('premium_wallet_auth_code')
    if (_authCode !== null) {
      setAuthCode(_authCode)
    }

  }, []);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      var parts = filename.split(".");
      //const fileType = parts[parts.length - 1];
      //console.log("fileType", files); //ex: zip, rar, jpg, svg etc.
      setCsvName(filename);
      setCsv(files[0]);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onCancelFileUpload = () => {
    setCsv("");
    setCsvName("");
  };

  const handleUploadCsv = async () => {
    let errors = {};
    if (csv === "") {
      errors["csv"] = "Please upload csv";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setsubmitStatus(true);

      try {
        const formData = new FormData();
        formData.append("file", csv);
        formData.append("auth_code", authCode);

        let _response = await serviceUploadEarlyAccessCsv(formData);
        console.log(_response);
        if (_response.status === "success") {
          Notify(_response.message, "success");

          let data = {
            limit: limit,
            offset: offset,
            authCode: authCode ? authCode : '',
            accessKey: ''
          }
          setSearchData(data)
          getPremiumWalletList(_data);

        } else {
          Notify(_response.message, "error");
        }

        setsubmitStatus(false);
      } catch (error) {
        setsubmitStatus(false);
        Notify("Error while submittting", "error");
      }
    }
  };


  const handlePageChange = (activePage) => setOffset(activePage * limit);

  // const checkAccessKey = async (accessKey) => {
  //   try {
  //     setLoader(true);

  //     let _res = await serviceCheckEarlyAccess(accessKey);

  //     if (_res.status === "success") {
  //       setLoader(false);
  //       Notify(_res.message, "success");
  //     } else {
  //       setLoader(false);
  //       return Notify(_res.message, "error");
  //     }
  //   } catch (e) {
  //     setLoader(false);
  //     logger("error", e.message);
  //     return Notify(
  //       "Error while checking access key ,please try agian",
  //       "error"
  //     );
  //   }
  // };

  const getPremiumWalletList = async (_data) => {
    try {
      setLoader(true);

      let data = {
        limit: limit,
        offset: offset,
        authCode: localStorage.getItem('premium_wallet_auth_code'),
        accessKey: accessKey ? accessKey : ""
      }
      // data.authCode = "uxkheetiirkmvcu"
      let _res = await servicegetEarlyAccessList(data);

      if (_res.status === "success") {
        setWalletList(_res.data.walletList);
        setTotalPages(_res.data.totalPages);
        // logger("prospects Data", _res.data.prospectList);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };

  useEffect(() => {
    let data = {
      limit: limit,
      offset: offset,
      authCode: authCode ? authCode : '',
      accessKey: accessKey ? accessKey : ""
    }

    getPremiumWalletList(_data);

  }, [offset]);



  function resetAuthCode() {

    if (authCodeBtnTxt === 'Save') {
      setAutoCodeFieldDisable(true)
      setAuthCode(authCode)
      setAuthCodeBtnTxt('Reset')
      window.localStorage.setItem("premium_wallet_auth_code", authCode)
    }
    else {
      setAutoCodeFieldDisable(false)
      setAuthCode('')
      setAuthCodeBtnTxt('Save')
    }

  }

  return (
    <div className="row premium_wallet_list_card">
      <div className="col-md-12">
        <label className="premium_wallet_title">Early Access</label>

        <div className="add_premium_wallet_container">

          <div className="col-md-5">
            <div className="direct_thank_you_txt">
              <p> Upload csv </p>
            </div>

            <div className="upload__image__box">
              <input
                style={{ display: "none" }}
                accept=".csv"
                ref={inputFile}
                onChange={handleFileUpload}
                type="file"
              />
              <div
                onClick={() => {
                  onButtonClick();
                }}
                className="upload__img__circle"
              >
                {csvName !== "" ? (
                  <img src={uploadIcon} />
                ) : (
                  <img src={uploadIcon} />
                )}
              </div>
              <p className="upload__img__txt">Upload limit 2MB</p>
              <p className="upload__img__txt">
                Allowed csv
              </p>
            </div>
            {errors && errors.csv && (
              <span className="error">{errors.csv}</span>
            )}

            <div className="browse__file__btn__box">
              <button
                onClick={() => {
                  onButtonClick();
                }}
                type="button"
                className=" w-100 browse__file__btn"
              >
                Browse File
              </button>
            </div>

            {csvName ? (
              <div className="upload__file__img__deatils">
                <p> {csvName}</p>
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onCancelFileUpload();
                    }}
                  >
                    Delete
                  </span>{" "}
                </div>
              </div>
            ) : (
              ""
            )}
            <button
              disabled={submitStatus}
              onClick={() => {
                handleUploadCsv();
              }}
              type="button"
              className="btn btn-primary w-100 close__thankyou__btn"
            >
              {submitStatus ? "Uploading ..." : "Upload"}
            </button>


          </div>
          <div className="col-md-2" />
          <div className="col-md-5">
            <div className="add_premium_wallet_form">
              <div className="premium_wallet_form_auth_box">
                <label className="premium_wallet_add_form_label">Authorization code</label>
                <input
                  disabled={autoCodeFieldDisable}
                  name="authCode"
                  value={authCode}
                  onChange={(event) => setAuthCode(event.target.value)}
                  className="form-control"
                  placeholder="Enter authorization code *"
                />
              </div>

              <span className="add_icon_span">
                <button onClick={() => resetAuthCode()} className="add_premimun_wallet_btn"> {authCodeBtnTxt}</button>
              </span>
            </div>
          </div>
        </div>

        <div className="number__box__head">
          <div
            className="searchContainer"
            style={{ display: "flex", color: '#000', justifyContent: "space-between" }}
          >
            <div className="premium_wallet_card_title">
              Early Access List
            </div>
            <div>
              <input
                type="text"
                onChange={async (e) => {
                  setEarlyAccess(e.target.value)
                }
                }
                style={{
                  fontSize: 17,
                  padding: 6,
                }}
                placeholder="Search by access key"
              />
              <button onClick={() => getPremiumWalletList(_data)} className="add_premimun_wallet_btn" style={{ margin: "5px" }}>
                Search
              </button>
            </div>

          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  {headerList.map((item, index) => (
                    <th>{item}</th>
                  ))}
                </tr>
              </thead>
              {loader === false ? (
                <tbody>
                  {walletList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.access_key}</td>
                      <td>
                        {item.created_at}
                      </td>

                    </tr>
                  ))}
                </tbody>
              ) : (
                ""
              )}
            </table>
          </div>
          <Pagination
            offset={offset}
            onPageChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Prospects;
