/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import L1XDeX from "../images/l1x-dex.png";
// import L1XDeXmobile from "../images/l1x-dex-mobile.png";
// import ConnectAWallet from "../images/connect-a-wallet.png";
// import ConnectAWalletmobile from "../images/connect-a-wallet-mobile.png";
// import Step3 from "../images/step3.png";
// import Step3mobile from "../images/step3-mobile.png";
// import TokenomicsBy from "../images/tokenomics-by.png";
// import LMTokenomicsBy from "../images/tokenomics-by-lm.png";
import FastSecure from "../images/icons/icon-fast-build.png";
import SecureTransfer from "../images/icons/icon-secure-transfer.png";
import SafeSecure from "../images/icons/icon-safe-secure.png";
import Efficient from "../images/icons/icon-efficient.png";
import Decentralised from "../images/icons/icon-decentralised.png";
import SupplyChain from "../images/icons/icon-supply-chain.png";

import LMFastSecure from "../images/icons/icon-fast-build-lm.png";
import LMSecureTransfer from "../images/icons/icon-secure-transfer-lm.png";
import LMSafeSecure from "../images/icons/icon-safe-secure-lm.png";
import LMEfficient from "../images/icons/icon-efficient-lm.png";
import LMDecentralised from "../images/icons/icon-decentralised-lm.png";
import LMSupplyChain from "../images/icons/icon-supply-chain-lm.png";


import PartnerSection from "./partner-section";
import PropTypes from "prop-types";
// import { RoadMap } from "./roadmap";
import { Link } from "react-router-dom";
import PrivateSale from "./private-sale";
// import copy from "copy-to-clipboard";

// import 'react-notifications/lib/notifications.css';
// import Config from "../config/index";


// import { Partner } from "./partner";
// import logger from "../utils/logger";
// let l1xWalletAddress = "0x7D15Fdd604aB782acD56D47058e5e65c493E1882";

/* function copyToClipboard (data) {
  copy(data)
  return true;
}; */
export class BuyL1X extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Invest</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Get A Premium Wallet
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  {/* <p className="pb-0 mb-2 fontsize-26">
                      The Layer One X Private Sale has now closed, We anticipate opening the Public Sale around June 2023.
                  </p> */}
                  <h1 className="mb-16">Get A Premium Wallet</h1>
                  <p className="pb-0 mb-4 fontsize-26">
                    Layer One X is an interoperable, scalable blockchain network that will shape the future of Web3.
                  </p>

                  <div className="buttons mb-0 text-center">
                    <a
                      className="btn btn-style-three m-0"
                      href="https://docsend.com/view/rafdthevuwmzdkjn"
                      target={"_blank"}
                    >
                      <i className="fa-solid fa-file"></i> View Investor Deck
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-blue text-center join-our-community" style={{ "paddingTop": "2em" }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 m-auto">
                {/* Section for Private Sale */}
                <h4>
                  <a href='invest/direct' >
                    {/* <span>Do You Want To Direct Transfer To Wallet?</span> */}
                  </a>
                </h4>
                <hr />
                <PrivateSale />
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-blue partner-section">
          <PartnerSection />
        </section>

        <section
          className="section fastest-low-cost grant-pack text-center"
          id="grants"
        >
          <div className="container">
            <div className="rapid-lowcost">
              <div className="row justify-content-center">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center text-center">
                      <h4>How It Works</h4>
                      <h3>Uniting Web3 systems</h3>
                      <p className="pb-32">
                        Layer One X is a layer one blockchain protocol with
                        interoperability built-in. It allows developers to build
                        dApps with multi-chain transactions secured by Layer One X
                        consensus via farmed mobile devices. Compatible code can
                        be run on Layer One X EVM with coordinated consensus on Layer One X VM.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link
                            className="glow-button gradient-border-mask"
                            to="/invest/how-it-works"
                          >
                            <span>Learn more </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center text-center">
                      <h4>Tokenomics</h4>
                      <h3>Use and value of L1X</h3>
                      <p className="pb-32 pink-color">
                        Integrate Layer 2 solutions using open standards APIs
                        with lower development costs.The L1X platform delivers
                        security, resilience and automation, so it’s easy to
                        build and easy to use while remaining true to the ethos
                        of decentralisation.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <a className="glow-button gradient-border-mask">
                            <span>Read more</span>
                          </a>
                        </div>
                      </div>
                      <figure className="tokenomicsby">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMTokenomicsBy
                              : TokenomicsBy
                          }
                          alt="TokenomicsBy"
                          className="img-fluid"
                          width="152"
                        />
                      </figure>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* <section
          className="section worldsweb3 text-center"
          id="ways-to-participate"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="pink-color lm-dark-color">How to buy L1X</h2>
                  <p className="pb-80">
                    While blockchain networks aren’t solely a financial
                    technology, they can be used to <br />
                    build decentralised financial and investment tools.
                  </p>
                </div>

                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Step 1</h4>
                        <h3>Set up or connect a wallet</h3>
                        <p className="mb-32 pink-color">
                          The L1X Multichain wallet will be the native wallet
                          for the L1X DeX and can hold multichain digital assets
                          at one source. The integrated bridging feature will
                          allow for a token swap between blockchains internally
                          with minimal fees.
                        </p>

                        <div className="buttons d-flex justify-content-start">
                          <div className="btn-style btn-style-one m-0">
                            <a className="glow-button gradient-border-mask">
                              <span>Set up your wallet</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={ConnectAWallet}
                          alt="Connect A Wallet"
                          className="img-fluid desktop"
                        />
                        <img
                          src={ConnectAWalletmobile}
                          alt="ConnectAWallet"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Step 2</h4>
                        <h3>Connect to L1X DeX</h3>
                        <p className="pb-0 pink-color">
                          L1X DeX is a decentralised multichain exchange
                          platform on the L1X protocol that supports multichain
                          token swaps and staking.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 order-last order-md-first order-lg-first">
                      <figure>
                        <img
                          src={L1XDeX}
                          alt="L1X DeX"
                          className="img-fluid desktop"
                        />
                        <img
                          src={L1XDeXmobile}
                          alt="L1XDeX"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Step 3</h4>
                        <h3>Sed odio pellentesque orci blandit nisl</h3>
                        <p className="pb-0 pink-color">
                          Porttitor mi dolor ut purus eu maecenas blandit
                          gravida. Venenatis tortor curabitur neque posuere ut
                          in elementum.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={Step3}
                          alt="Step 3"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Step3mobile}
                          alt="Step 3"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <RoadMap /> */}

        <section className="section web3-usecases text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    A whole new world of Web3 <br />
                    use-cases.
                  </h2>
                  <p className="pb-80">
                    We’re helping developers with the paradigm shift from <br />{" "}
                    centralised to decentralised systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMFastSecure
                            : FastSecure
                        }
                        alt="Fast Secure"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Build fast, secure, inexpensive DeFi technology</h4>
                    <p className="d-none d-md-block">
                      Layer One X’s security design is effectively resilient to
                      hacks and guarantees a solid, robust base model for
                      development by traditional banks to provide digital
                      services.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMSecureTransfer
                            : SecureTransfer
                        }
                        alt="Secure Transfer"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Secure transfer of patient medical records</h4>
                    <p className="d-none d-md-block">
                      Build encrypted EMR systems connected by Healthlink
                      Protocol, giving health providers, insurers and patients
                      greater control and security for sensitive personal
                      medical records.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMSafeSecure
                            : SafeSecure
                        }
                        alt="Safe Secure"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>
                      Safe & secure environment for gamers and developers{" "}
                    </h4>
                    <p className="d-none d-md-block">
                      A market expected to grow to $256B by 2025, Layer One X
                      interoperability will offer improved transactions and
                      security for in-game skins, characters, items and digital
                      twins.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMEfficient
                            : Efficient
                        }
                        alt="Efficient"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>
                      Efficient, automated smart contracts for micropayments
                    </h4>
                    <p className="d-none d-md-block">
                      Microtransactions for everyday purchases are now feasible
                      with close-to-instantaneous processing from the mempool,
                      creating a new era of rights management and commerce.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMDecentralised
                            : Decentralised
                        }
                        alt="Decentralised"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Decentralised digital identity management</h4>
                    <p className="d-none d-md-block">
                      Reduce risks of private data loss with authorisation based
                      on smart contracts. Give users or clients greater
                      portability of their digital identity with ‘self managed’
                      services.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMSupplyChain
                            : SupplyChain
                        }
                        alt="SupplyChain"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>
                      Build and maintain supply chains and contracts at low
                      costs
                    </h4>
                    <p className="d-none d-md-block">
                      Mitigate disruptions with contingency pipelines.
                      Traceability with immutable records of provenance and
                      possession to ensure a consistent chain of authenticity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tect-center">
                <Link className="btn btn-style-four" to="/use-cases">
                  See All Use Cases
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
BuyL1X.propTypes = {
  theme: PropTypes.string,
};
