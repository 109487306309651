import React from "react";
import Config from "../../config";
import copy from "copy-to-clipboard";
// import moment from "moment"

function copyToClipboard(data) {
  copy(data);
  return true;
}

const DirectInvestmentInner = (prop) => {
  return (
    <>
      <div style={container} className="direct_investment_card">
        {prop.showHeading ? (
          <h3 className="pb-18 mb-0" style={{ textAlign: "center" }}>
            Direct Investment
          </h3>
        ) : (
          ""
        )}

        <text style={{ ...pStyle, textAlign: "justify" }}>
          <b>If you do not wish to go through the registration and want to directly transfer the investment amount into the Layer One X wallet, please follow the process below.</b>
        </text>
        <br />
        <br />
        <text style={{ ...pStyle, textAlign: "left", fontWeight: "bold" }}>
          L1X Wallet Address :{" "}
        </text>
        <text style={{...pStyle,wordBreak: 'break-all'}}>{Config.l1xWalletAddress}</text>
        <img
          onClick={() => copyToClipboard(Config.l1xWalletAddress)}
          style={{ cursor: "pointer", marginLeft: 10, marginTop: -5 }}
          src={require("../../images/copy.png")}
          height={20}
          width={20}
          alt="copy"
        />
        <br />
        <br />
        <text style={{ ...pStyle, fontWeight: "bold" }}>
          Network and Tokens Accepted
        </text>
        <ul
          style={{
            ...pStyle,
            lineHeight: "150%",
            padding: 4,
            marginLeft: 20,
          }}
        >
          <li>- Ethereum (USDC/USDT)</li>
          <li>- Avalanche (USDC/USDT)</li>
          <li>- Polygon (USDC/USDT)</li>
          <li>- Binance Smart Chain (USDC/USDT)</li>
        </ul>
        <br />

        <text style={{ ...pStyle, fontWeight: "bold" }}>
          Include this in the mail to :{" "}
        </text>
        <text style={pStyle}>
          {" "}
          <a
            style={{ color: "blue" }}
            href="mailto:invest@l1x.foundation"
          >
            invest@l1x.foundation
          </a>
        </text>

        <ol
          style={{
            ...pStyle,
            lineHeight: "150%",
            padding: 4,
            marginLeft: 20,
            fontStyle: "italic",
          }}
        >
          <li>1. First Name</li>
          <li>2. Last Name </li>
          <li>3. Date of Birth (For example, 1990, 1992)</li>
          <li>4. Tx Hash </li>
          <li>
            5. Referrer Code so we can provide a bonus to who referred you.
          </li>
          <li>
            6. Mention "I agree to the Whitelist Terms and Conditions. Please
            check the link below."
          </li>
        </ol>

        <br />
        <text style={pStyle}>
          Website Link: 
          <a
            style={{ color: "blue"  }}
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.l1x.foundation/whitelist-terms-conditions"
          >
            https://www.l1x.foundation/whitelist-terms-conditions
          </a>
        </text>
        <br />
        <br />
        <text style={{ ...pStyle, fontStyle: "italic" }}>
          Please Note that all investments are subject to KYC.
        </text>
        <br />
        <br />
        <text style={pStyle}>
          <b>
          Once you send an email, our team will get back to you within 72
          Business Hours with an email confirmation that the funds have been
          received.
          </b>
        </text>
      </div>
    </>
  );
};

const DirectInvestment = () => {
  return (
    <>
      <br />
      <br />
      <section className="banner-transparent">
        <div className="text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <div className="tab__btn__section">
                    <DirectInvestmentInner showHeading={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </>
  );
};

export { DirectInvestment, DirectInvestmentInner };

const pStyle = {
  fontWeight: 400,
  fontSize: "1em",
  lineHeight: "130%",
  color: "black",
};

const container = {
  height: " 100%",
  width: " 100%",
  overflow: "auto",
  // paddingRight: 20,
  textAlign: "left",
};
