import React, { useEffect, useState } from "react"
import Logo from "../../images/logol1x-icon.svg";

import ActiveLogo from "../../images/orange-icon-logo.svg";
import SaleCardIcon2 from "../../images/card-icon-2.png";
import SaleCardIcon3 from "../../images/card-icon-3.png";
import SaleCardIcon4 from "../../images/card-icon-4.png";
// import SaleCardIcon5 from "../../images/card-icon-5.png";
import SaleCardIcon6 from "../../images/card-icon-6.png";
import SaleCardIcon7 from "../../images/card-icon-7.svg";
import SaleCardIcon8 from "../../images/card-icon-8.svg";
import Notify from "../../utils/notify";
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { serviceGetCheckProspectKyc, serviceGetCheckProspectTransction, serviceGetPublicSaleProspectExits } from "../../services/apiService";
import Config from "../../config";

import CurrencyFormatter from "../../services/currencyService";


const PublicSaleDetails = (props) => {
    const [purchasedToken, setPurchasedToken] = useState(null);
    const [amountInvested, setAmountInvested] = useState(null);

    useEffect(() => {

        checkProspectKyc();

        // if(checkMetamaskConnection()){
        // window.ethereum.on("accountsChanged", handleAccountsChanged);
        // }

        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
            }
        };

    }, [])

    const handleAccountsChanged = (accounts) => {
        // Handle the updated accounts array
        //window.location.reload();
    };

    async function _checkUSerKycByEmail() {
        try {
            let _email = localStorage.getItem('email') ? localStorage.getItem('email') : "";

            if (_email !== null && typeof _email !== 'undefined') {
                let postEmail = _email.split('"').join('');

                let _checkIsExistEmail = await serviceGetPublicSaleProspectExits(postEmail);

                if (_checkIsExistEmail.status == 'failure') {
                    Notify(_checkIsExistEmail.message, "error")
                    props.handleUserSteps('publicSaleRegistrationForm')
                    return
                }

                if (_checkIsExistEmail.status == 'success') {

                    if (_checkIsExistEmail.data.wallet_address == null) {
                        props.handleUpdateCurrentStep(2)
                        props.handleUserSteps('publicSaleStep')
                        return
                    } else {
                        props.handleUpdateCurrentStep(3)
                        props.handleUserSteps('publicSaleStep')
                        return
                    }
                }
            }
        } catch (error) {
            console.log("---error--------", error);
        }


    }

    async function checkProspectKyc() {
        let _walletAddress = await checkMetamaskConnection();

        if (_walletAddress) {

            _checkUSerKycByEmail();

            // let checkProspectKycRes = await serviceGetCheckProspectKyc(_walletAddress)
            // if (checkProspectKycRes.status === 'success') {
            //     props.handleUpdateCurrentStep(3)
            //     props.handleUserSteps('publicSaleStep')

            //     let checkProspectTxRes = await serviceGetCheckProspectTransction(_walletAddress)

            //     if (checkProspectKycRes.status === 'success') {
            //         setPurchasedToken(checkProspectTxRes.data.purchasedToken);
            //         setAmountInvested(checkProspectTxRes.data.amountInvested);
            //     }
            // }
        }
    }

    // Check if Metamask is installed
    function checkMetamaskInstallation() {
        console.log("window.ethereum", window.ethereum)
        if (!window.ethereum) {
            window.location.href = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-GB';
            return false;
        }

        return handleConnectMetamask()
    }

    // Check if Metamask is installed
    async function checkMetamaskConnection() {
        console.log("window.ethereum", window.ethereum)
        if (!window.ethereum) {
            // window.location.href = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-GB';
            return false;
        }
        return await getMetamaskAddress()
    }

    const getMetamaskAddress = async () => {
        try {
            // Detect if MetaMask is installed
            const provider = await detectEthereumProvider();

            if (provider) {

                // Create an ethers.js provider using MetaMask provider
                const ethersProvider = new ethers.providers.Web3Provider(provider);

                // Get the signer (account) from ethers.js provider
                const signer = ethersProvider.getSigner();

                // You can now use the signer to interact with the Ethereum network
                // For example, you can get the connected account address
                const connectedAddress = await signer.getAddress();
                console.log('Connected address:', connectedAddress);
                props.handleConnectedWallet(connectedAddress)
                props.handleUpdateCurrentStep(2)
                props.handleUserSteps('publicSaleStep');

                return connectedAddress
            } else {
                console.error('MetaMask not found');
                return false
            }
        } catch (error) {

            console.error('Error connecting to MetaMask:', error);
            return false
        }
    };

    const handleConnectMetamask = async () => {
        try {
            // Detect if MetaMask is installed
            const provider = await detectEthereumProvider();

            if (provider) {

                await window.ethereum.enable();

                window.ethereum.on("accountsChanged", handleAccountsChanged);

                // Request access to the user's MetaMask accounts
                await provider.request({ method: 'eth_requestAccounts' });

                // Create an ethers.js provider using MetaMask provider
                const ethersProvider = new ethers.providers.Web3Provider(provider);

                // Get the signer (account) from ethers.js provider
                const signer = ethersProvider.getSigner();

                // You can now use the signer to interact with the Ethereum network
                // For example, you can get the connected account address
                const connectedAddress = await signer.getAddress();
                console.log('Connected address:', connectedAddress);
                props.handleConnectedWallet(connectedAddress)
                props.handleUpdateCurrentStep(2)
                props.handleUserSteps('publicSaleStep');

                return connectedAddress
            } else {
                console.error('MetaMask not found');
                return false
            }
        } catch (error) {
            console.error('Error connecting to MetaMask:', error);
            return false
        }
    };

    async function checkConnection() {
        let _metamaskWalletAddress = await checkMetamaskInstallation();

        if (_metamaskWalletAddress) {

            _checkUSerKycByEmail();

            //let checkProspectKycRes = await serviceGetCheckProspectKyc(_metamaskWalletAddress)

            //if (checkProspectKycRes.status === 'success') {
            //props.handleUpdateCurrentStep(3)
            //props.handleUserSteps('publicSaleStep')

            let checkProspectTxRes = await serviceGetCheckProspectTransction(_metamaskWalletAddress)

            //console.log("checkProspectTxRes", checkProspectTxRes)
            //if (checkProspectKycRes.status === 'success') {
            setPurchasedToken(checkProspectTxRes.data.purchasedToken);
            setAmountInvested(checkProspectTxRes.data.amountInvested);
            //}
            // }
        }
    }

    const Steps = ({ _currentStep }) => {
        return (
            <div className="dashboard__box__num__kyc__form">
                <div className="card__headprofile">
                    <h3>Next Steps</h3>
                </div>
                <div className="l1x__my__task__box">
                    <ul className="StepProgress">
                        <li className={"StepProgress-item " + (_currentStep === 1 ? "current" : _currentStep > 1 ? "is-done" : "")} >
                            <p>Connect Wallet{_currentStep === 1 ? <button onClick={() => { checkConnection() }} className="kyc__a__btn">Connect</button> : ''}</p>
                        </li>
                        <li className={"StepProgress-item " + (_currentStep === 2 ? "current" : _currentStep > 2 ? "is-done" : "")} >
                            <p>Complete Your KYC {_currentStep === 2 ? <button onClick={() => { props.handleUserSteps('publicSaleKyc') }} className="kyc__a__btn">Click Here</button> : ''}</p>
                        </li>
                        <li className={"StepProgress-item " + (_currentStep === 3 ? "current" : _currentStep > 3 ? "is-done" : "")} >

                            <p>Purchase Token
                                {_currentStep === 3 && amountInvested < Config.publicSaleMaxInvestmentAmount ? <button onClick={() => { props.handleUserSteps('publicSaleBuy') }} className="kyc__a__btn">Buy Now</button> : ''} </p>

                            {_currentStep === 3 ? <div>
                                {purchasedToken ? <div className=" mt-3">
                                    <p className="ammount__val__referral">Amount Invested :  {parseFloat(amountInvested).toFixed(4)}</p>
                                    <p className="ammount__val__referral">L1X Token Purchased :  {parseFloat(purchasedToken).toFixed(4)}</p>
                                </div> : ""}

                            </div> : ''}
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    const PublicSaleDetails = (props) => {
        return (
            <>
                <div className={"fixed__card__sale__content"}>
                    <div className="foxed__card__sale">
                        <img src={props.image} alt="logo" className="fixed__card__icon" />
                        <text className="sale_card_icon_label">{props.label}</text>
                    </div>
                    <div className="fixed__card__content__value">
                        <text className="sale_card_icon_label_value">{props.value}</text>
                    </div>
                </div>

                {props.smallValue ?
                    <div className="small__card__sale__content">
                        <div className="small__card__sale">
                            <text className="small_card_icon_label"></text>
                        </div>
                        <div className="small__card__content__value">
                            <text className="small_card_icon_label_value">{props.smallValue}</text>
                        </div>
                    </div>
                    : ""
                }
            </>
        )
    }

    return (
        <div className="banner__fixed__card__box">
            <div className="banner__fixed__card__head">
                <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
            </div>
            <div className="fixed__card__main__content">



                <PublicSaleDetails image={ActiveLogo} label="Ticker:" value={Config.l1xTicker} />
                <PublicSaleDetails css="mt-4" image={SaleCardIcon2} label="ICO Token Price:" value={"1 " + Config.l1xTicker + " = " + Config.publicSaleConversionRate + " USD"} />
                <PublicSaleDetails image={SaleCardIcon7} label="Soft Cap:" value={CurrencyFormatter.format(Config.publicSaleSoftCap)} />
                <PublicSaleDetails image={SaleCardIcon3} label="Fundraising Goal:" value={CurrencyFormatter.format(Config.publicSaleFundRaisingGoal)} />
                <PublicSaleDetails image={SaleCardIcon4} label="Minimum Purchase:" value={CurrencyFormatter.format(Config.publicSaleMinInvestmentAmount)} />
                <PublicSaleDetails image={SaleCardIcon8} label="Maximum Purchase:" value={CurrencyFormatter.format(Config.publicSaleMaxInvestmentAmount)} />


                {/* <PublicSaleDetails image={SaleCardIcon5} label="Public Sale Start:" value="24th July" smallValue="9am WA time" /> */}

                <div className={"fixed__card__sale__content mt-3 mb-4"}>
                    <div className="foxed__card__sale">
                        <img src={SaleCardIcon6} alt="logo" className="fixed__card__icon" />
                        <text className="sale_card_icon_label">Public Sale starts:</text>
                    </div>
                    <div className="fixed__card__content__value">
                        <text className="sale_card_icon_label_value">July 2023</text>
                    </div>
                </div>

                {/* <div className={"fixed__card__sale__content"}>
                    <div className="foxed__card__sale">
                        <img src={SaleCardIcon6} alt="logo" className="fixed__card__icon" />
                        <text className="sale_card_icon_label">Public Sale Closes:</text>
                    </div>
                    <div className="fixed__card__content__value">
                        <text className="sale_card_icon_label_value">August 2023</text>
                    </div>
                </div> */}

                <Steps _currentStep={props.currentStep} />
            </div>
        </div>
    )
}

export default PublicSaleDetails