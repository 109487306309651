import React from "react";

const WhitelistingDone = () => {
  return (
    <>
      {/* <section className="banners-section sub-banner"> */}
      <section className="banner-transparent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="campaign__paused__head">Your Whitelisting Process is Done.</h5>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};


export default WhitelistingDone;

