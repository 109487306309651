import React, { Component } from "react";

import PartnerUWA from "../images/partner-uwa.png";
import PartnerMme from "../images/partner-mme.svg";
import PartnerLx from "../images/partner-l1x-labs.svg";
import PartnerPrysm from "../images/partner-prysm-group.svg";
import LMPartnerUWA from "../images/partner-uwa-lm.png";
import LMPartnerMme from "../images/partner-mme-lm.svg";
import LMPartnerLx from "../images/partner-l1x-labs-lm.svg";
import LMPartnerPrysm from "../images/partner-prysm-group-lm.svg";


export class PartnerSection extends Component {
    render() {
        return <>
        <div className="partner home__page__partner">
            <div className="container">
              <p>Partnering with world leading organisations to enable Web3</p>
              <div className="row">
                {/* <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerMMEModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerMme
                          : PartnerMme
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div> */}
                <div className="col-md-4">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerPrysmModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerPrysm
                          : PartnerPrysm
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="col-md-4">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerL1XLabsModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerLx
                          : PartnerLx
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>

                <div className="col-md-4">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerUWAModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerUWA
                          : PartnerUWA
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>

              <div
                className="modal fade partner-modal"
                id="PartnerPrysmModal"
                tabIndex="-1"
                aria-labelledby="PartnerPrysmModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerPrysm
                                      : PartnerPrysm
                                  }
                                  alt="Prysm"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              Prysm Group is the Tokenomics Partner for I1X,
                              delivering value to platforms by leveraging
                              established economic research.
                            </h4>
                            <p>
                              Prysm Group is an economic consulting and
                              corporate learning firm focused on emerging
                              technologies. Founded by Harvard PhD economists,
                              the firm assists corporates, governments and
                              startups in the adoption and implementation of
                              blockchain, digital asset and the metaverse
                              through its advisory and educational services.
                              Prysm Group areas of expertise include incentive
                              design, monetization and governance for both open
                              and closed blockchain and metaverse platforms.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade partner-modal"
                id="PartnerMMEModal"
                tabIndex="-1"
                aria-labelledby="PartnerMMEModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerMme
                                      : PartnerMme
                                  }
                                  alt="MME"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              MME is the Legal Partner for Layer One X, providing
                              comprehensive and interdisciplinary advice in the
                              areas of legal, tax and compliance.
                            </h4>
                            <p>
                              MME is an innovative business law and tax firm
                              with offices in the two Swiss economic centres,
                              Zurich and Zug.
                            </p>
                            <p>
                              Legal | Tax | Compliance: In these three areas we
                              offer comprehensive, interdisciplinary solutions
                              with speed and efficiency. Our specialists tailor
                              their advice to your needs in order to provide
                              practical, cost-effective solutions that
                              sustainably improve your business — whether in a
                              national or an international environment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade partner-modal"
                id="PartnerUWAModal"
                tabIndex="-1"
                aria-labelledby="PartnerUWAModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerUWA
                                      : PartnerUWA
                                  }
                                  alt="UWA"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              The University of Western Australia (UWA) is the
                              academic partner for Layer One X.
                            </h4>
                            <p>
                              UWA is a leading Australian research university
                              and has an international reputation for
                              excellence, innovation and enterprise. A member of
                              the Australian 'Group of Eight' research
                              universities. Sitting on the banks of the Swan
                              River, the UWA Perth campus is the oldest in
                              Western Australia. UWA offers higher education in
                              many areas, including medicine, architecture and
                              law, in addition to a full Masters program for
                              blockchain.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade partner-modal"
                id="PartnerL1XLabsModal"
                tabIndex="-1"
                aria-labelledby="PartnerL1XLabsModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerLx
                                      : PartnerLx
                                  }
                                  alt="L1X_Labs"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              L1X_LABS is the Development Partner for Layer One X.
                              Working with businesses to build and innovate on
                              the Layer One X blockchain protocol.
                            </h4>
                            <p>
                              At L1.X LABS, we empower businesses to innovate on
                              our proprietary Layer One X blockchain protocol.
                              With our help, clients can build blockchain
                              solutions for business use cases where
                              performance, scalability, security and sustainable
                              growth are key requirements. Every business is
                              unique. We work with you to provide a tailored
                              solution that meets your specific needs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
    }
}

export default PartnerSection;
