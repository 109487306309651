import React from "react"
import closeIcon from "../../images/close-menu-lm.svg";
import tickCircleIcon from "../../images/tick-circle.svg";

const BankTransferEmailDetails = (props) => {

    const backToRegister = () => {
        props.handleUserSteps('publicSaleRegistrationForm')
    }

    return (
        <div className="banner__fixed__card__box thank_you_card_height" style={{ justifyContent: "normal" }}>
            <div className="public_sale_early_access_header">
                <img
                    alt="Transfer $USD from bank account"
                    onClick={backToRegister}
                    className="public_sale_early_acces_close_icon" src={closeIcon} />
            </div>
            <div className="p-30">
                <div className="email__sent">
                    <img src={tickCircleIcon} />
                    <h4 className="email__sent__heading">Email Sent!</h4>
                </div>
                <div className="direct_thank_you_txt">
                    <p className="email__sent__sub__heading"> Please check your email for the next step. </p>
                    <p>Nb. Check your email junk folder in case the email is not in your inbox.</p>
                </div>
            </div>

            {/* <div className="public_sale_check_eligibility_box p-30">
                <button
                    onClick={() => {
                        props.handleUserSteps('publicSaleRegistrationForm')
                    }}
                    type="button"
                    className="btn btn-primary w-100 close__thankyou__btn"
                >
                    Close
                </button>
            </div> */}
        </div>
    )
}

export default BankTransferEmailDetails