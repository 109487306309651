/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.svg";
import Logohover from "../images/logohover.svg";
import Logotext from "../images/logotext.png";
import LMLogotext from "../images/logotext-lm.png";
import BackToTopButton from "./BackToTopButton";
import PropTypes from "prop-types";
import Config from "../config";

import Discord from "../images/footer/Discord.svg";
import DiscordWhite from "../images/footer/Discord_white.svg";
import Facebook from "../images/footer/Facebook.svg";
import FacebookWhite from "../images/footer/Facebook_white.svg";
import Linkedin from "../images/footer/linkedin.svg";
import LinkedinWhite from "../images/footer/linkedin__white.svg";
import Medium from "../images/footer/Medium.svg";
import MediumWhite from "../images/footer/Medium_white.svg";
import Reddit from "../images/footer/Reddit.svg";
import RedditWhite from "../images/footer/Reddit_white.svg";
import Spotify from "../images/footer/Spotify.svg";
import SpotifyWhite from "../images/footer/Spotify_white.svg";
import Telegram from "../images/footer/Telegram.svg";
import TelegramWhite from "../images/footer/Telegram_white.svg";
import YouTube from "../images/footer/YouTube.svg";
import YouTubeWhite from "../images/footer/YouTube_white.svg";
import Twitter from "../images/footer/Twitter.svg";
import TwitterWhite from "../images/footer/Twitter_white.svg";
import InstagramBlack from "../images/footer/Instagram.svg";
import InstagramWhite from "../images/footer/Instagram_white.svg";
import {  animateScroll as scroll } from 'react-scroll';
import BrandGuide from "../images/L1X_Brand_Guide.pdf";


export class FooterNew extends Component {

  scrollToSection = () => {
    scroll.scrollTo('/#poweredBy', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  render() {
    // const theme=this.props

    return (
      <section className="footer footerNew">
        <div className="container">
          <div className="row ">
            <div className="col-md-3">
              <figure className="d-flex">
                <Link to="" className="navbar-brand d-flex">
                  <div className="logo-rotate">
                    <img
                      className="normal"
                      src={Logo}
                      width="47"
                      alt="Layer One X"
                    />
                    <img
                      className="hover"
                      src={Logohover}
                      width="47"
                      alt="Layer One X"
                    />
                  </div>
                  <img
                    src={
                      this.props.themeEnabled === "light"
                        ? LMLogotext
                        : Logotext
                    }
                    alt="Logo"
                    width="82"
                  />
                </Link>
              </figure>
            </div>
            <div className="col-sm-9">
              <div className="row">
                <div className="col d-none d-md-block">
                  <h3>DEVELOPERS</h3>
                  <ul>
                    <li>
                      <Link to='https://dev-portal.l1xapp.com/'
                            target="_blank">Dev Portal</Link>
                    </li>
                    <li>
                      <Link to='https://docsend.com/view/yxxumg97x5mzv77v '
                            target="_blank">Whitepaper</Link>
                    </li>
                    <li>
                      <Link to='https://docsend.com/view/4kbzzm4kvsq7xuv6 '
                            target="_blank">Litepaper</Link>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/ecosystem '
                            target="_blank">Grants Program</Link>
                    </li>
                  </ul>
                </div>
                <div className="col d-none d-md-block">
                  <h3>L1XApp</h3>
                  <ul>
                    <li>
                      <Link to='https://l1xapp.com'
                            target="_blank">Home</Link>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/swap'
                            target="_blank">Swap</Link>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/staking'
                            target="_blank">Stake</Link>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/balancer-pool-v3'
                            target="_blank">Balancer Pool</Link>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/feed'
                            target="_blank">Feed</Link>
                    </li>
                  </ul>
                </div>
                <div className="col d-none d-md-block">
                  <h3>Ecosystem</h3>
                  <ul>
                  <li>
                      <Link to='https://projects.l1x.foundation/'
                            target="_blank">Projects</Link>
                    </li>
                    <li>
                      <a href='#poweredByNetwork'
                            
                            smooth={true}
                            duration={800}
                            // onClick={this.scrollToSection}
                            >L1X Application</a>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/swap/aboutNodeNFT#faq '
                            target="_blank">Become a Validator</Link>
                    </li>
                    <li>
                      <Link to='https://l1xapp.com/explorer'
                            target="_blank">L1X Explorer</Link>
                    </li>
                    <li>
                      <Link to='https://chromewebstore.google.com/detail/xwallet/pofheakpngfbdfeidiippmmckgpdceoh'
                            target="_blank">XWallet</Link>
                    </li>
                  </ul>
                </div>
                <div className="col d-none d-md-block">
                  <h3>Community</h3>
                  <ul>
                    <li>
                      <Link to='https://l1xapp.com/account/assistance '
                            target="_blank">Support</Link>
                    </li>
                    <li>
                      <Link to='https://blog.l1x.foundation/ '
                            target="_blank">Blogs</Link>
                    </li>
                    <li>
                      <Link to={BrandGuide}
                            target="_blank">Brand Guidelines</Link>
                    </li>
                    <li>
                      <Link to='https://twitter.com/L1X_XTalks'
                            target="_blank">XTalks AMA Series</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row ">
              <div className="col-sm-6 d-flex">
                <p>
                   <Link className="quick-links" to="/privacy-policy">Privacy Policy</Link><Link className="quick-links" to='/terms-of-services'>Terms of Services</Link><Link to="/cookie-policies" className="quick-links">Cookies Policies</Link>
                </p>
              </div>
              <div className="col-sm-6">
                <p className="copyright text-end">© 2024 <Link>Layer One X</Link>. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    );
  }
}
FooterNew.propTypes = {
  theme: PropTypes.string,
};
