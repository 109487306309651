import moment from "moment";
import React from "react";

const RecieptDetails = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <p className="reciept__details__label " >
        <strong>{props.label}</strong>
      </p>
      {props.label === 'Transaction Hash' ?
        <p
          onClick={() => {
            getTransactionDetails(props.network, props.txHash)
          }}
          className="reciept__details__value tx__url" >
          <strong>{props.value}</strong>
        </p> :
        <p className="reciept__details__value" >
          <strong>{props.value}</strong>
        </p>}
    </div>
  );
};

function getTransactionDetails(_network, _txHash) {

  switch (_network) {
    case "ETH": let ethExplorerUrl = `https://etherscan.io/tx/${_txHash}`
      window.open(ethExplorerUrl, '_blank');
      break;

    case "BSC": let bscExplorerUrl = `https://bscscan.com/tx/${_txHash}`;
      window.open(bscExplorerUrl, '_blank');
      break;

    case "MATIC": let maticExplorerUrl = `https://polygonscan.com/tx/${_txHash}`
      window.open(maticExplorerUrl, '_blank');
      break;

    case "AVAX": let avaxExplorerUrl = `https://cchain.explorer.avax.network/tx/${_txHash}`;
      window.open(avaxExplorerUrl, '_blank');
      break;
    default:
      break;
  }

}


export default function PublicSalePaymentSuccess(props) {
  return (
    <div className="banner__fixed__card__box">
      <div className="reciept__details__container">
        <span>
          <img
            src="https://www.freeiconspng.com/uploads/success-icon-10.png"
            alt="GO"
            width={50}
            height={50}
          />
        </span>
        <text className="reciept__details__heading">Payment Success !</text>
        <div className="reciept__details__divider" />

        <RecieptDetails label="Transaction Hash" value={props.txData ? props.txData.txHash.substr(0, 8) + '...' + props.txData.txHash.substr(props.txData.txHash.length - 8, 8) : ''} network={props.txData ? props.txData.network : ''} txHash={props.txData ? props.txData.txHash:''} />
        <RecieptDetails label="Network" value={props.txData ? props.txData.network : ''} />
        <RecieptDetails label="Token" value={props.txData ? props.txData.token : ''} />
        <RecieptDetails label="Amount" value={props.txData ? props.txData.amount : ''} />
        <RecieptDetails label="L1X Token" value={props.txData ? props.txData.amount / 0.5 : ''} />
        <RecieptDetails label="Transaction Date" value={props.txData ? moment(new Date()).format('DD-MM-YYYY') : ''} />
        <RecieptDetails label="Transaction Time" value={props.txData ? moment(new Date()).format('hh:mm:ss A') : ''} />

        <button onClick={() => window.location.reload()} className="reciept__details__goBack__btn"  >Go Back</button>
      </div>
    </div>
  );
}
