import React, { useState } from "react";

const Pagination = ({ totalPages, offset, handlePageChange, onPageChange }) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(offset);
  const handleClick = (newPageNumber) => {
    setCurrentPageNumber(newPageNumber);
    onPageChange(newPageNumber);
  };

  return (
    <div className="pagination_premium_wallet">
      {" "}
      {currentPageNumber !== 0 && (
        <button onClick={() => handleClick(currentPageNumber - 1)}>
          {"<"}
        </button>
      )}{" "}
      {currentPageNumber + 1} of {totalPages }{" "}
      {currentPageNumber !== totalPages-1 && (
        <button onClick={() => handleClick(currentPageNumber + 1)}>
          {" "}
          {">"}{" "}
        </button>
      )}{" "}
    </div>
  );
};
export default Pagination;
