import React from "react"
import closeIcon from "../../images/close-menu-lm.svg";

const DirectInvestmentThankYouPage = (props) => {

    return (
        <div className="banner__fixed__card__box thank_you_card_height">
            <div className="p-30 mt-5">
                {/* <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div> */}


                <div className="direct_thank_you_txt">
                    <p className="bank-transfer-validation-message">  We don't recognise this email address.</p>
                    <p>  Please use to the link in the email.</p>
                    <p>  If you have any questions, email us at;<br></br>
                        <strong>invest@l1x.foundation</strong></p>
                </div>
            </div>

            <div className="public_sale_check_eligibility_box p-30">
                <button
                    onClick={() => {
                        window.location.href = '/public-sale'
                        //props.handleUserSteps('publicSaleRegistrationForm')
                    }}
                    type="button"
                    className="btn btn-primary w-100 close__thankyou__btn"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default DirectInvestmentThankYouPage