import React from "react";

const KYCFlagged = () => {
  return (
    <>
      {/* <section className="banners-section sub-banner"> */}
      <section className="banner-transparent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="campaign__paused__head">Your KYC information has been flagged,<br/> Our team will get back to you soon.</h5>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};


export default KYCFlagged;

