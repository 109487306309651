const Config = {

    buyL1X: {
        minInvestmentAmount: 100,
    },
    sectionID: {
        registrationForm: "registrationForm",
        basicDetailsForm: "basicDetailsForm",
        KYCForm: "KYCForm",
        buySectionForm: "buySectionForm",
        pauseCampaign: "pauseCampaign",
        KYCFlagged: "KYCFlagged",
        registrationLanding: "registrationLanding",
        generateOTP: "generateOTP",
        verifyOTP: "verifyOTP",
        whitelistingDone: "whitelistingDone",
    },
    api: {
        url: "https://alpha-gateway.l1x.foundation/",
        //url: "http://localhost:27698/",
        blockchain_url: "http://localhost:25555/api"
    },
    hubspot: {
        formUri: "https://api.hsforms.com/submissions/v3/integration/submit/7639483/cf1a3f27-a277-4042-a655-bc536f5d1810"
    },
    admin: {
        prefixString: "yo1EiV9V-Aem4Ku2a-eiN6eeka-Chaiz5oe-Gophe0oo"
    },
    mainnet: {
        enabledNetworks: [
            "MATIC",
            "ETH",
            "AVAX",
            "BSC"
        ],
        formalNetworkNameMap: {
            "MATIC": "Polygon",
            "ETH": "Ethereum",
            "AVAX": "Avalanche C-Chain",
            "BSC": "Binance Smartchain"
        },
        enabledTokens: [
            "USDT",
            "USDC",
        ],
        chainIdMap: {
            "0x89": "MATIC",
            "0x1": "ETH",
            "0xa86a": "AVAX",
            "0x38": "BSC",
        },
        networkConfig: {
            MATIC: {
                chainId: "0x89",
                rpcUrls: ["https://polygon.llamarpc.com"],
                chainName: "Polygon Mainnet - L1X.org",
                nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                },
                iconUrls: ["https://polygonscan.com/images/svg/brands/poly.png?v=1.3"],
                blockExplorerUrls: ["https://polygonscan.com/"]
            },
            ETH: {
                chainId: '0x1',
                rpcUrls: ['https://eth.llamarpc.com'],
                chainName: 'Ethereum Mainnet - L1X.org ',
                nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18
                },
                iconUrls: ["https://etherscan.io/images/svg/brands/ethereum.svg?v=1.3"],
                blockExplorerUrls: ["https://etherscan.io/"]
            },
            AVAX: {
                chainId: `0xa86a`,
                rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                chainName: 'Avalanche C-Chain - L1X.org',
                nativeCurrency: {
                    name: 'AVAX',
                    symbol: 'AVAX',
                    decimals: 18
                },
                iconUrls: ["https://snowtrace.io/images/svg/brands/main.svg?v=23.1.1.1"],
                blockExplorerUrls: ["https://snowtrace.io/"]
            },
            BSC: {
                chainId: `0x38`,
                rpcUrls: ['https://binance.llamarpc.com'],
                chainName: 'Binance Smartchain - L1X.org',
                nativeCurrency: {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                },
                iconUrls: ["https://bscscan.com/images/svg/brands/bnb.svg?v=1.3"],
                blockExplorerUrls: ["https://bscscan.com/"]
            }

        },

        privateSaleContract: {
            ETH: "0xb51de646Cce8C3fbc3b56e5A4F4B6A39F2AC942a",
            MATIC: "0x871d7b13f412ae022290d43452d1b50aB27Dbc40",
            AVAX: "0xb51de646Cce8C3fbc3b56e5A4F4B6A39F2AC942a",
            BSC: "0xFB2978d1Fa129b1acdD0BA0aD13E91bE77b95e0B",
        },
        tokenAddress: {
            ETH: {
                USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
                USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
            },
            MATIC: {
                USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
                USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
            },
            AVAX: {
                USDT: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
                USDC: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
            },
            BSC: {
                USDT: "0x55d398326f99059ff775485246999027b3197955",
                USDC: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
            }
        },

        publicSaleContract: {
            ETH: "0xac3CafF2C271E5a502184F600bdf8C427782ab9c",
            BSC: "0x4Fe891EB8E1012A6512873Ebd0F47D848E22b04E",
            MATIC: "0xb51de646Cce8C3fbc3b56e5A4F4B6A39F2AC942a",
            AVAX: "0xac3CafF2C271E5a502184F600bdf8C427782ab9c",
        }
    },
    testnet: {
        enabledNetworks: [
            "MATIC",
            "ETH",
            "AVAX",
            "BSC"
        ],
        formalNetworkNameMap: {
            "MATIC": "Polygon",
            "ETH": "Ethereum",
            "AVAX": "Avalanche C-Chain",
            "BSC": "Binance Smartchain"
        },
        enabledTokens: [
            "USDT",
            "USDC",
        ],
        chainIdMap: {
            "0x13881": "MATIC",
            "0x5": "ETH",
            "0xa869": "AVAX",
            "0x61": "BSC",
        },
        networkConfig: {

            MATIC: {
                chainId: "0x13881",
                rpcUrls: ["https://polygon-mumbai.g.alchemy.com/v2/pFhNCledn-dQ4uG753gnRTac9ZbRWsfR"],
                chainName: "Polygon Testnet",
                nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                },
                iconUrls: ["https://polygonscan.com/images/svg/brands/poly.png?v=1.3"],
                blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
            },

            ETH: {
                chainId: '0x5',
                rpcUrls: ['https://eth-goerli.g.alchemy.com/v2/3i0cqmJ6ySeAQV8M03OE2VoasTOgeWU-'],
                chainName: 'Ethereum Testnet',
                nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18
                },
                iconUrls: ["https://etherscan.io/images/svg/brands/ethereum.svg?v=1.3"],
                blockExplorerUrls: ["https://goerli.etherscan.io/"]
            },

            AVAX: {
                chainId: `0xa869`,
                rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
                chainName: 'Avalanche C-Chain Testnet',
                nativeCurrency: {
                    name: 'AVAX',
                    symbol: 'AVAX',
                    decimals: 18
                },
                iconUrls: ["https://snowtrace.io/images/svg/brands/main.svg?v=23.1.1.1"],
                blockExplorerUrls: ["https://testnet.snowtrace.io/"]
            },

            BSC: {
                chainId: `0x61`,
                rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545'],
                chainName: 'Binance Smartchain Testnet',
                nativeCurrency: {
                    name: 'BSC',
                    symbol: 'BSC',
                    decimals: 18
                },
                iconUrls: ["https://testnet.bscscan.com/images/svg/brands/bnb.svg?v=1.3"],
                blockExplorerUrls: ["https://testnet.bscscan.com/"]
            }
        },

        privateSaleContract: {
            ETH: "0x97C39f07c747ff58265795c4BdD76881c928a2e2",
            MATIC: "0x7BC3b0f35170b9a52F8910F40647e66bd60e6D98",
            AVAX: "0xa6eD8dE3E3e924f695A2A924962f27358a0fb549",
            BSC: "0x7BC3b0f35170b9a52F8910F40647e66bd60e6D98",
        },
        tokenAddress: {
            ETH: {
                USDT: "0x6247ad86cc1cc62D04df63f3A9d159FD0aD93f34",
                USDC: "0x0808a2d2b6a9585159555b1A4345A28E1BcFF4B1"
            },
            MATIC: {
                USDT: "0xe70796843F19f616103e5F797B1ef60BE0E5BB40",
                USDC: "0x914353957cD6c3382880bda956a97eE80551f395"
            },
            AVAX: {
                USDT: "0x914353957cD6c3382880bda956a97eE80551f395",
                USDC: "0x7BC3b0f35170b9a52F8910F40647e66bd60e6D98"
            },
            BSC: {
                USDT: "0xe70796843F19f616103e5F797B1ef60BE0E5BB40",
                USDC: "0x914353957cD6c3382880bda956a97eE80551f395"
            }
        },
        publicSaleContract: {
            ETH: "0x907E4040D78db5c80b4BeD01109D72fc5f90cCf4",
            MATIC: "0xFB2978d1Fa129b1acdD0BA0aD13E91bE77b95e0B",
            AVAX: "0x27A6DFdF5D89C66768510c2f3B7Fc44a76bCdAe8",
            BSC: "0xf98F0cC9cdF14F8cCb42EE9871CaE7cf1E3170A0",
        }
    },

    sentry: {
        dsn: 'https://f967f14631c246688faed62cf474c00c@o4504461740015616.ingest.sentry.io/4504604050980864'
    },
    holdBuyingFlow: false,
    networkMode: "mainnet",
    referralBaseUrl: "https://staging-alpha.l1x.foundation/invest/buy-l1x/",
    adminUrlHash: "yo1EiV9V-Aem4Ku2a-eiN6eeka-Chaiz5oe-Gophe0oo",
    minInvestmentAmount: 100,
    maxInvestmentAmount: 50000,
    l1xWalletAddress: '0x7D15Fdd604aB782acD56D47058e5e65c493E1882',
    // publicSaleButtonLabel: 'Register for Public Sale',
    publicSaleButtonLabel: 'Buy',
    publicSaleMinInvestmentAmount: 100,
    publicSaleMaxInvestmentAmount: 1000000,
    publicSaleDirectMinInvestmentAmount: 100,
    publicSaleConversionRate: 0.5,
    publicSalePremiumWalletMinInvestmentAmount: 100,
    publicSaleFundRaisingGoal: 25000000,
    publicSaleSoftCap: 10000000,
    l1xTicker: "L1X"
}


export default Config;