import React, { useState } from "react"

import closeIcon from "../../images/close-menu-lm.svg";
import { serviceCheckPremiumWalletAddress } from "../../services/apiService";
import Notify from "../../utils/notify";

import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import CircularProgress from "../CircularProgress/index";
import Config from "../../config";
// import { Link } from "react-router-dom";

const PublicSaleEarlyAccess = (props) => {

    const [submitStatus, setSubmitStatus] = useState(false)

    const handleAccountsChanged = (accounts) => {
        // Handle the updated accounts array
        window.location.reload();
    };

    async function CheckUserIsPremium() {

        let _walletAddress = await handleConnectMetamask();

        if (_walletAddress) {
            let _txSubmitResponse = await serviceCheckPremiumWalletAddress(_walletAddress);

            if (_txSubmitResponse.status === "success") {
                setSubmitStatus(false)
                props.updatePremiumWalletAccess(true);

                if (props.isUserFromRegistationForm) {
                    props.handleUserSteps('publicSaleStep');
                }
                else {
                    props.handleUserSteps('publicSaleRegistrationForm')
                }

                Notify(_txSubmitResponse.message, "success");
                return true
            }
            else {
                setSubmitStatus(false)
                props.updatePremiumWalletAccess(false)
                props.handleUserSteps('publicSaleNotEligible')
                return false
            }
        }
        else {
            Notify("Unable to connect metamask", "error");
            return false
        }
    }

    const handleConnectMetamask = async () => {
        try {
            // Detect if MetaMask is installed
            const provider = await detectEthereumProvider();

            if (provider) {

                await window.ethereum.enable();
                // window.ethereum.on("accountsChanged", handleAccountsChanged);

                // Request access to the user's MetaMask accounts
                await provider.request({ method: 'eth_requestAccounts' });

                // Create an ethers.js provider using MetaMask provider
                const ethersProvider = new ethers.providers.Web3Provider(provider);

                // Get the signer (account) from ethers.js provider
                const signer = ethersProvider.getSigner();

                // You can now use the signer to interact with the Ethereum network
                // For example, you can get the connected account address
                const connectedAddress = await signer.getAddress();
                console.log('Connected address:', connectedAddress);

                return connectedAddress
            } else {
                console.error('MetaMask not found');
                return false
            }
        } catch (error) {
            console.error('Error connecting to MetaMask:', error);
            return false
        }
    };

    return (
        <div className="banner__fixed__card__box banner__fixed__width">
            <div>
                <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div>

                <div className="public_sale_early_access_heading">
                    Get Early Access
                </div>

                <div className="public_sale_early_access_txt">
                    We’ve made it easy for you to gain early access to the public sale
                </div>

                <div className="public_sale_early_access_eligiable_box">
                    <label>To be eligible</label>
                    <ul className="public_sale_early_access_txt">
                        <li style={{ listStyle: 'disc' }}>Deposit ${Config.publicSalePremiumWalletMinInvestmentAmount.toLocaleString()} USD or more</li>
                        <li style={{ listStyle: 'disc' }}>Be a premium benefits wallet holder or</li>
                        <li style={{ listStyle: 'disc' }}>Gain access through our social competition <a href="https://gleam.io/fgHwl/win-1-of-25-layer-one-x-premium-wallets" rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'underline', cursor: 'pointer', color: "#000" }}>click here for details </a></li>
                    </ul>
                </div>
            </div>

            <div className="public_sale_check_eligibility_box">
                {
                    submitStatus ? (
                        <button
                            disabled
                            className="btn btn-primary buy-btn-loader eligibility_btn_width"
                        >
                            <span className="loader-span">
                                <CircularProgress /> Submiting ...
                            </span>
                        </button>
                    ) : (

                        <button
                            type="button"
                            className="btn btn-primary buy-btn-loader eligibility_btn_width"
                            onClick={() => CheckUserIsPremium()}
                        >
                            Check eligibility
                        </button>
                    )
                }
            </div>
        </div>
    )
}

export default PublicSaleEarlyAccess