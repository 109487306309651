import React, { Component } from "react";
import L1xNftCoin from "../../../src/images/L1X-NFT-coin-EVM-Non-EVM-Final.png";
import L1xQuestionNftCoin from "../../../src/images/L1X-Question-Coin.png";
// import componentOne from "../../../src/images/Component-1.png";
export class NftGiveaway extends Component {
  render() {
    return (
      <>
        <section class="banner__section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="banner__content__box">
                  <div class="banner__header how-it-works__banner">
                    <h2>NFT Giveaway</h2>
                    <h1>
                      X Milestone <span>.NFT </span> Collection
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="row">
                <div className="col-md-5">
                    <div className="banner__image__box nft__content__image">
                        {/* <img src={componentOne} alt="Grab a Part of Crypto HISTORY. "/> */}
                        <iframe title="Grab a Part of Crypto HISTORY" width="420" height="233" src="//www.youtube.com/embed/Foqdlzi7VWc?rel=0&amp;autoplay=1&amp;controls=1&amp;loop=1&amp;mute=1" allowfullscreen="allowfullscreen"></iframe>
                        <h2>Don’t Miss Out On This Opportunity</h2>
                        {/* <div className="btn__box">
                          <a href="/invest/buy-l1x" className="btn btn--white btn--animated">
                            Get a Premium Wallet
                          </a>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="banner__content__box nft__content____box">
                        <div className="banner__header">
                            <h1>How to Own This Moment in Crypto<span> HISTORY. </span></h1>
                        </div>
                        <p>To commemorate this recent epic achievement, all premium wallets will receive a commemorative NFT coin. To qualify for a premium wallet, you must have contributed $1000 USD or more to the Layer One X private sale. If you haven’t contributed, don’t worry you can still participate by clicking the “Buy Layer One X Button” to contribute.</p>
                        <h3>But wait, there’s more!</h3>
                        <p>Each NFT also enters you into a drawing for the grand prize – drumroll please – Our winner will receive the “Milestone X Moment NFT!” Yep, that’s right, the winner will win the ownership rights to the first-ever recorded decentralized transfer of assets between an EVM and Non-EVM chain video. Plus, they’ll get a whopping 100,000 Layer One X tokens too at mainnet launch. Eligible Premium Wallets for drawing cut-off is 11:59 WST time on March 31, 2023.  </p>
                        <p>To celebrate, we’re giving all premium wallets a commemorative NFT coin.</p>
                        
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="banner__content__box nft__content____box">
                        <h3>Want more ways to win?</h3>
                        <p>We’re giving away 5 NFTs to users who create awesome content commemorating this historical blockchain moment. How does it work? Create a video, image, photograph, or song and post it on your favorite social account. Next is to link your social post in our Layer One X Discord channel designated for the creator competition.</p>
                        <p>Once the competition closes on March 31, 2023, at 11:59 WST time, the x_team will select their top ten best posts created. Then our Layer One X community will choose the 5 winners by casting their votes on discord. Each winner will receive two of the highly coveted commemorative NFT coins and also go into the drawing to win the grand prize of the Milestone X Moment NFT and the 100,000 Layer One X tokens at launch. See the terms and conditions for full details.  
                            
                           </p>
                        
                    </div>
                </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <div class="evm__nft__box">
                  <img
                    class="evm__nft__box__img"
                    src={L1xNftCoin}
                    alt="EVM to NON-EVM Transfer without a bridge"
                  />
                  <h3>EVM to NON-EVM </h3>
                  <p>Transfer without a bridge</p>
                  <p class="evm__nft__box__date">March 3, 2023</p>
                  {/* <div className="btn__box text-center">
                    <a href="/invest/buy-l1x" className="btn btn--white btn--animated">
                      Get a Premium Wallet
                    </a>
                  </div> */}
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <div class="questions__coin__img">
                      <img src={L1xQuestionNftCoin} alt="Coin" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="questions__coin__img">
                      <img src={L1xQuestionNftCoin} alt="Coin" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="questions__coin__img">
                      <img src={L1xQuestionNftCoin} alt="Coin" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="questions__coin__img">
                      <img src={L1xQuestionNftCoin} alt="Coin" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="coming__soon__txt__box">
                      <p>Coming Soon!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    );
  }
}

export default NftGiveaway;
