import React, { useState } from "react";
import logger from "../../utils/logger";
import Notify from "../../utils/notify";

const AuthCodePage = (props) => {
  const [authCode, setAuthCode] = useState('');
  const [error, setError] = useState('');

  const submitAuthCode = async () => {
    try {

      if (authCode === '') {
        return setError("Please enter authorization code")
      }
      
      window.localStorage.setItem('premium_wallet_auth_code', authCode)

      props.checkAuthCode(true)
    } catch (e) {
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };


  return (
    <div className="row premium_wallet_list_card">
      <div className="col-md-3" />

      <div className="col-md-6">
        <label className="premium_wallet_title">Authorization Code</label>

        <div className="auth_code_card">

          <div className="premium_wallet_auth_box">
            <label className="premium_wallet_add_form_label">Authorization code</label>
            <input
              name="authCode"
              value={authCode}
              onChange={(event) => setAuthCode(event.target.value)}
              className="form-control"
              placeholder="Enter authorization code *"
            />
            {error && (
              <span className="error">{error}</span>
            )}
          </div>

          <span className="auth_icon_span_btn">
            <button onClick={() => submitAuthCode()} className="add_premimun_wallet_btn">Submit</button>
          </span>
        </div>
      </div>
      <div className="col-md-3" />

    </div>
  );
};

export default AuthCodePage;
