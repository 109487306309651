/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo.svg";
import Logohover from "../images/logohover.svg";
import Logotext from "../images/logotext.png";
import LMLogotext from "../images/logotext-lm.png";
import Menuicon from "../images/hamburger-menu.svg";
import LMMenuicon from "../images/hamburger-menu-lm.svg";
import Menuclose from "../images/close-menu.svg";
import LMMenuclose from "../images/close-menu-lm.svg";
import DMtogglemobile from "../images/dm-toggle-mobile.svg";
import LMDMtogglemobile from "../images/lm-toggle-mobile.svg";
// import Preico from "../images/icon-pre-ico.svg";
// import LMPreico from "../images/icon-pre-ico-lm.svg";


import { useNavigate } from "react-router-dom"

import PropTypes from "prop-types"
import Config from "../config";

function resetHeader() {
  document.getElementById('menu').style.transform = "translate3d(0, 0, 0)";
}

function setHeader() {
  document.getElementById('menu').style.transform = "translate(100%, 0)";
  document.getElementById('menu').style.transform = "translate(100%, 0)";
}

const HeaderMobileMenu = (props) => {
  const navigate = useNavigate();

  return (
    <header className="mobile-header" >
      <div className="topbar d-flex  align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              {/* <span className="d-flex align-items-center">
                  Private Sale Commitments 
                  <div className="progress">
                    <div className="progress-bar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  
                  USDT: $6.5M / $10M
                </span> */}
            </div>
            <div className="col-sm-6 d-none d-lg-block">
              <div className="topnav d-flex justify-content-end">
                <ul>
                  <li>
                    <a href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                      View Litepaper
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                      View Whitepaper
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                  {/* <li>
                          <Link to='faqs'>FAQs</Link>
                    </li> */}
                  <li>
                    <Link to='about-us/join-the-team'>Join The Team</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar mobile-responsive">
        <input type="checkbox" id="main-nav-check" />
        <div id="menu">
          <div className="res-top d-flex align-items-center">
            <a onClick={() => { resetHeader(); navigate("/") }} className="navbar-brand d-flex">
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img src={props.themeEnabled === 'light' ? LMLogotext : Logotext} width="82" alt="Layer One X" />
            </a>

            <div className="btn-style d-md-block d-lg-none d-xl-none d-xxl-none ml-auto mr-20">
              <a onClick={() => { resetHeader(); navigate("/public-sale") }} className="glow-button gradient-border-mask header__mobile__view__btn">
                {/* <span>Buy L1X</span> */}
                <span>{Config.publicSaleButtonLabel}</span>
              </a>
            </div>

            <label
              htmlFor="main-nav-check"
              id="close-menu"
              className="toggle"
              title="Close"
            >
              <img src={props.themeEnabled === 'light' ? LMMenuclose : Menuclose} alt="close"
                onClick={() => { resetHeader(); }} />
            </label>
          </div>
          <ul>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"

                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Buy
              </a>
              <label htmlFor="invest" className="toggle-sub" ></label>
              <input
                type="checkbox"
                name="name"
                id="invest"
                className="sub-nav-check"
              />

              <ul id="invest-sub" className="sub-nav">
                <li className="sub-heading">
                  Buy
                  <label
                    htmlFor="invest"
                    className="toggle"
                    title="Back"
                  >
                    ►
                  </label>
                </li>
                {/* <h3>Start Investing</h3> */}

                <li style={{ "paddingTop": "1em" }}>
                  <a href="https://blog.l1x.foundation/a-step-by-step-guide-to-buying-layer-one-x-l1x-coins/" className="dropdown-item">
                    Guide to buy L1X
                  </a>
                </li>

                <li>
                  <a onClick={() => { resetHeader(); navigate("/invest/how-it-works") }} className="dropdown-item">
                    How it works
                    <span>Uniting web3 systems</span>
                  </a>
                </li>
                {/* <li>
                    <a className="dropdown-item">
                      Tokenomics
                      <span>Use and value of L1X</span>
                    </a>
                  </li> */}
                <hr />
                <li>
                  <a className="dropdown-item" href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                    View Lightpaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                    View Whitepaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"

                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Build
              </a>
              <label htmlFor="build" className="toggle-sub" ></label>
              <input
                type="checkbox"
                name="name"
                id="build"
                className="sub-nav-check"
              />
              <ul id="build-sub" className="sub-nav">
                <li className="sub-heading">
                  Build
                  <label
                    htmlFor="build"
                    className="toggle"

                    title="Back"
                  >
                    ►
                  </label>
                </li>
                <h3>Developers</h3>
                <li>
                  <a onClick={() => { resetHeader(); navigate("build-on-layeronex") }} className="dropdown-item">
                    Build on Layer One X
                    <span>Accelerate your development</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => { resetHeader(); navigate("build/grant-applications") }} className="dropdown-item">
                    Grant Applications
                    <span>Get funding and build</span>
                  </a>
                </li>
                {/* <li>
                    <a className="dropdown-item">
                      Tokenomics
                      <span>Use and value of L1X</span>
                    </a>
                  </li> */}
                <hr />
                <li>
                  <a className="dropdown-item" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                    View Whitepaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
                {/* <li>
                    <a className="dropdown-item">
                      Inspect Testnet
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Developer Resources
                      <i className="fa fa-download"></i>
                    </a>
                  </li> */}
                <hr className="mb-0" />
                <h3 className="pt-16">Explore</h3>
                <li>
                  <a onClick={() => { resetHeader(); navigate("build/consensus-mechanism") }} className="dropdown-item">
                    Consensus Mechanism
                  </a>
                </li>
                <li>
                  <a onClick={() => { resetHeader(); navigate("build/native-transaction-lifecycle") }} className="dropdown-item">
                    Native Transaction Lifecycle
                  </a>
                </li>
                <li>
                  <a onClick={() => { resetHeader(); navigate("build/interoperable-transaction-lifecycle") }} className="dropdown-item">
                    Interoperable Transaction Lifecycle
                  </a>
                </li>

                <li>
                  <a href="https://docsend.com/view/rycxs6bj35r72dfk" className="dropdown-item">
                    Documentation
                  </a>
                </li>

              </ul>
            </li>
            {/* <NavLink to='nft' >
                  Giveaway
                </NavLink>     */}
            {/*    <li>
              <NavLink to='wallet' >
                Layer One X Wallet
              </NavLink>
            </li> */}
            <li>
              <a>About Us</a>
              <label htmlFor="aboutus" className="toggle-sub" ></label>
              <input
                type="checkbox"
                name="name"
                id="aboutus"
                className="sub-nav-check"
              />
              <ul id="aboutus-sub" className="sub-nav">
                <li className="sub-heading">
                  About Us
                  <label
                    htmlFor="aboutus"
                    className="toggle"

                    title="Back"
                  ></label>
                </li>
                <h3>About Us </h3>
                <li>
                  <a onClick={() => { resetHeader(); navigate("about-us/who-we-are") }} className="dropdown-item">
                    Who We Are
                    <span>Our leaders and partners </span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href='https://blog.l1x.foundation/'>
                    Blog
                    <span>Our Latest blog post </span>
                  </a>
                </li>
                {/* <li>
                    <a className="dropdown-item">
                      L1X Labs
                      <span>Meet the dev team</span>
                    </a>
                  </li> */}
              </ul>
            </li>
            <li>
              <a>Ecosystem</a>
              <label
                htmlFor="ecosystem"
                className="toggle-sub"

              ></label>
              <input
                type="checkbox"
                name="name"
                id="ecosystem"
                className="sub-nav-check"
              />
              <ul id="ecosystem-sub" className="sub-nav">
                <li className="sub-heading">
                  Ecosystem
                  <label
                    htmlFor="ecosystem"
                    className="toggle"

                    title="Back"
                  ></label>
                </li>
                <h3><a onClick={() => { resetHeader(); navigate("use-cases") }}>Use Cases</a></h3>
                <li>
                  <a onClick={() => { resetHeader(); navigate("use-cases/defi") }} className="dropdown-item">
                    De-Fi
                  </a>
                </li>
                {/* <li>
                    <a className="dropdown-item">
                      Interoperability
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Digital Identity
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Multi-Chain
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Micro Payments
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Healthcare
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      Gaming
                    </a>
                  </li> */}
                <hr className="mb-0" />
                {/* <h3 className="pt-16">Apps and services </h3>
                  <li>
                  <Link to='integrations/wyde' className="dropdown-item">
                      Wyde
                    </Link>
                  </li> */}
              </ul>
            </li>
            <li>
              <a>Connect</a>
              <label htmlFor="connect" className="toggle-sub" ></label>
              <input
                type="checkbox"
                name="name"
                id="connect"
                className="sub-nav-check"
              />
              <ul id="connect-sub" className="sub-nav">
                <li className="sub-heading">
                  Connect
                  <label
                    htmlFor="connect"
                    className="toggle"

                    title="Back"
                  ></label>
                </li>
                <h3>Connect</h3>
                <li>
                  <a onClick={() => { resetHeader(); navigate("get-in-touch") }} className="dropdown-item">
                    Get in Touch
                  </a>
                </li>
                {/* <li>
                  <a onClick={()=>{resetHeader();navigate("start-building")}} className="dropdown-item">
                            Start Building
                            <span>Connect with L1X Labs</span>
                          </a>
                  </li> */}
                <hr />
                <li>
                  <a onClick={() => { resetHeader(); navigate("referral") }} className="dropdown-item">
                    Get Referral Code
                  </a>
                </li>
                <li>
                  <a onClick={() => { resetHeader(); navigate("forget-referral-code") }} className="dropdown-item">
                    Forget Referral Code ?
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href='https://discord.gg/LayerOneX' target={'_blank'}>
                    Discord Invite
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href='https://t.me/Layer1X' target={'_blank'}>
                    Chat On Telegram
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="https://twitter.com/LayerOneX" target={'_blank'}>
                    Follow On Twitter
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="https://www.linkedin.com/company/layer-one-x" target={'_blank'}>
                    Follow On LinkedIn
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="https://medium.com/@Layeronex" target={'_blank'}>
                    Follow On Medium
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </li>


              </ul>
            </li>
            <li>
              <a onClick={() => { resetHeader(); }}>
                {props.themeEnabled === 'light' ? 'Light Mode' : 'Dark Mode'}
                <div className='switch dark-lightmode-toggle'>
                  <button onClick={props.toggleTheme}  >
                    <img src={props.themeEnabled === 'light' ? LMDMtogglemobile : DMtogglemobile}
                      alt="Toggle"
                    />

                  </button>
                </div>
              </a>
            </li>
            <li>
              <a href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                View Litepaper
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
            </li>
            <li>
              <a href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                View Whitepaper
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
            </li>
            {/* <li>
              <Link to='faqs'>FAQs</Link>
              </li> */}
            <li>
              <a onClick={() => { resetHeader(); navigate("about-us/join-the-team") }}>Join The Team</a>
            </li>
          </ul>
        </div>

        <div className="container">
          <div id="header" className="d-flex">
            <Link to="" className="navbar-brand d-flex">
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img src={props.themeEnabled === 'light' ? LMLogotext : Logotext} width="82" alt="Layer One X" />
            </Link>

            <div className="btn-style d-md-block d-lg-none d-xl-none d-xxl-none ml-auto mr-20">
              <Link to='public-sale' className="glow-button gradient-border-mask header__mobile__view__btn">
                {/* <span>Buy L1X</span> */}
                <span>{Config.publicSaleButtonLabel}</span>
              </Link>
            </div>

            <label
              htmlFor="main-nav-check"
              className="toggle"
              title="Menu"
            >
              <img src={props.themeEnabled === 'light' ? LMMenuicon : Menuicon} alt="Layer One X" width="" onClick={() => { setHeader(); }} />
            </label>
          </div>
        </div>
      </div>
    </header>
  );


}

HeaderMobileMenu.propTypes = {
  theme: PropTypes.string
}


export default HeaderMobileMenu;

