/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Banner from "../../images/public-sale-banner.jpg";
import HorizontalLine from "../../images/horizontal-line.png";
import Make1 from "../../images/make-1.png";
import Make2 from "../../images/make-2.png";
import Make3 from "../../images/make-3.png";
import Make4 from "../../images/make-4.png";

import Roadmap from "../../images/roadmap.png";
import AccordianImage from "../../images/accordian-image.jpg";
import Logo from "../../images/logol1x-icon.svg";

import PropTypes from "prop-types";
import PublicBuySection from "./publicBuySection";
import "./style.css"

const FeaturesCard = (props) => {
  return (
    <div className="col-md-6 mb-4">
      <div className={"make__layeronex__container " + props.divCss}>
        <img src={props.image} alt="layer One X Make" className="make__layeronex__icon" />
        <p className="layeronex__box__o-txt">{props.title}</p>
        <p className="layeronex__card__txt">{props.description}</p>
      </div>
    </div>
  )
}


export class PublicSaleBuySection extends Component {
  state = {
    showPublicSale: false,
    email: '',
    errors: null,
    name: '',
    country: '',
    registrationCompleted: false,
    learnMoreStatus: false
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  handleComponentChange = () => {
    console.log("handleComponentChange called")
    this.setState({ registrationCompleted: true })
    // this.myRef.current.scrollIntoView()
  }

  handleLearnMore = () => {
    console.log("handleLearnMore called")
    this.setState({ learnMoreStatus: true })
    this.myRef.current.scrollIntoView()
  }

  handleGoBack = () => {
    this.setState({ registrationCompleted: false, email: '', name: '', country: '' })
  }

  render() {
    return (
      <>
        <div className="main__container__sale">
          <div className="button__scroll__box sticky-enquiry">
            <a href="#">Buy Now</a>
          </div>
          <div className="header__bg__sale">
            <div className="main__header__box">
              <h1>Layer One X – <span>Public Sale</span></h1>
            </div>
          </div>
          <div className="header__form__sale">
            <div className="container">
              <div className="row flex__firection__mobile">
                <div className="col-md-8">
                  <img src={Banner} className="w-100 banner__img__sale" alt="Layer One X – Public Sale" />
                  <p className="banner__img__content">Unleash Blockchain’s True Potential with Layer One X</p>
                  <h3 className="join__decentralized">The Future of Blockchain</h3>
                  <p className="join__decentralized__txt">Layer One X is the fourth generation of blockchain technology. Seamless cross-chain interoperability has finally arrived.</p>
                  <div className="l1x__revolutionize">
                    <p>A Game Changer for the Crypto World. Layer One X can Transfer Assets, Data and now Logic Between Different Blockchains without Bridges.</p>
                  </div>
                  <div className="what__layeronex__container">
                    <h2>What is Layer One X</h2>
                    <div className="Layeronex__content">
                      <div className="Layeronex__value">
                        <p>100,000</p>
                        <p className="Layeronex__value__orange">Transactions per second</p>
                      </div>
                      <div className="virtical__line__container">
                        <img src={HorizontalLine} alt="line" className="" />
                      </div>
                      <div className="Layeronex__value__txt">
                        <p>At 100,000 transactions per second, Layer One X is <b>the world’s fastest blockchain</b> delivering a new generation of optimized decentralization and uncompromised security.</p>
                      </div>
                    </div>
                    <div className="Layeronex__content">
                      <div className="Layeronex__value__txt">
                        <p>The Layer One X consensus is validated on any smart device, achieves a <b>500ms block creation time</b>, and charges a gas fee of less than one cent.</p>
                      </div>
                      <div className="virtical__line__container">
                        <img src={HorizontalLine} alt="line" className="" />
                      </div>
                      <div className="Layeronex__value">
                        <p>500<sub>ms</sub></p>
                        <p className="Layeronex__value__orange">Block creation time</p>
                      </div>
                    </div>
                    <div className="Layeronex__content">
                      <div className="Layeronex__value">
                        <p>X-Talk</p>
                        <p className="Layeronex__value__orange">Native Interoperability</p>
                      </div>
                      <div className="virtical__line__container">
                        <img src={HorizontalLine} alt="line" className="" />
                      </div>
                      <div className="Layeronex__value__txt">
                        <p>Layer One X connects incompatible blockchains with X-Talk or native interoperability. Data and for the first time logic can be exchanged eliminating the need for risky, centralized bridges.</p>
                      </div>
                    </div>
                    <div className="l1x__revolutionize">
                      <p>Don’t miss your opportunity to join a truly remarkable blockchain movement. You can be part of shaping the decentralized world of tomorrow, uniting blockchains, and changing your future.</p>
                    </div>

                  </div>
                  <div className="what__layeronex__container mb-3">
                    <h2>What makes Layer One X the future of blockchain?</h2>
                    <div className="Layeronex__contributing">
                      <p className="mb-4">Layer One X overcomes traditional blockchain limitations of high costs, slow processing times, centralization and lack of interoperability.</p>
                      <p>The scalable and secure Layer One X blockchain infrastructure is designed for high-performance decentralized applications and offers unprecedented protection for assets.</p>
                      <p className="mb-4">Layer One X will transform the deployment and uptake of Web3 dApps.</p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <FeaturesCard divCss="" image={Make1} title="X-Talk" description="X-Talk or native interoperability, enables the transfer of assets, data, and logic across previously incompatible blockchains, without the need for bridges." />
                    <FeaturesCard divCss="make__layeronex__container_2" image={Make2} title="Scalable" description="Maintain a high-quality user experience and low cost of participation even with millions of daily users and interactions." />
                    <FeaturesCard divCss="make__layeronex__container-3" image={Make3} title="Decentralized" description="A robust consensus that eliminates the centralized accumulation of control and power." />
                    <FeaturesCard divCss="" image={Make4} title="Secure" description="Providing the highest levels of security with data encryption that allows users to participate safely." />

                    <div className="layeronex__roadmap__box">
                      <h3>Layer One X <span>Roadmap</span></h3>
                      <img src={Roadmap} alt="Roadmap" className="roadmap__img w-100" />
                    </div>

                    <div className="layeronex__roadmap__box">
                      <h3 >How is Layer One X different to other blockchains?</h3>
                      <div className="layer0nex__accordion__container mt-4">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button accordion-button__first" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is X-Talk?
                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <img src={AccordianImage} alt="How does X-Talk work?" className="w-100" />
                                <p className="accordian__content__p">
                                  <b>X-Talk</b> - Cross Chain Connection, Communication, Data Transfer, and Verification is only Possible when we attach a Virtual Machine to X-Talk
                                </p>
                                <p className="accordian__content__p">
                                  <b>Decentralized</b> - Layer One X provides a decentralized consensus mechanism to transfer logic securely. Significant checks are done that enable the transfer of wants by knowing what you are going to retrieve.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Logic Transfer</b> - Ability to communicate with other chains based on what condition you want to set, for example, file transfer vs. API call. You were removing dependency and enabling interconnectivity to web3.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Improved Reliability</b> - Reduce the risk of downtime. Dapps can switch to another chain, ensuring that users can continue to access Dapp’s services without interruption.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Cross Chain</b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility.
                                </p>
                                <p className="accordian__content__p">
                                  <b>X-Talk Architecture</b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allows for the secure and efficient exchange of assets, messages, and logic.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Capital Efficiency</b> - Smart contracts collaboration and communication enable more efficient and timely deployment of capital.
                                </p>
                                <p className="accordian__content__p">
                                  <b>Improved user experience</b> - Providing more comprehensive services to users, making Dapps more attractive and increasing adoption. </p>
                                <p className="accordian__content__p">
                                  <b>Aggregation Development</b> - protocols that can aggregate defi services across multiple chains. Interact with various liquidity pools to provide users with the best possible rates for their transactions.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What Is Tokenization of Securities (Assets)</button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p">
                                  <b>Speed</b> - Transaction performance at the Speed of NASDAQ or other traditional markets, settlement can be achieved in. T+5mins (compared with T+2Days)</p>
                                <p className="accordian__content__p">
                                  <b>Tokenize Once & Use on Multiple Chains</b> - Ability to Tokenize multiple forms of data such as ownership records, health data, intellectual web3 creation and multiple others. Use the tokenized data without minting it again on the other chain allowing for a single source of truth for ownership, reducing transaction fees and ease of use for the tokenized data.</p>
                                <p className="accordian__content__p">
                                  <b>Control Access of tokenized data from Layer One X Wallet</b> - Layer One X Wallet is designed and developed to hold multiple forms of data types, and it also provides access to non-L1X Addresses on the Layer One X Chain to get into the ecosystem and fractionally or rent use the tokenized data on the Layer One X Platform, for example, Airbnb.
                                </p>
                                <p className="accordian__content__p"><b>Fractionalized Investment </b> - Provides fractional ownership, which enables investors to own a small portion of an asset, and it reduces the barriers to entry for investors who may not have the necessary capital to invest in large assets.</p>
                                <p className="accordian__content__p"><b>Maintain data Integrity </b> - Prove the authenticity of the tokenized data for multiple purposes and use cases without revealing the actual information, for example, providing credit cards at a hotel.</p>
                                <p className="accordian__content__p"><b>Reduced Cost </b> - Providing faster settlement times, reduced transaction costs, and increased accessibility to a wider range of investors.</p>
                                <p className="accordian__content__p"><b>Market Disruption </b> - Disrupting traditional financial systems to enable new types of asset ownership, investment, and trading on a global scale.</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What Is Universal Decentralized Identity?
                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>L1X.OneIdentity </b> - UDI provides access to one account for Web3. Funnel multiple usernames, emails, and other types of information into one identifier that automatically understands where and what information is to be tapped into</p>
                                <p className="accordian__content__p"><b>One-Decentralized Drive </b> - Store Fungible, Non-Fungible, Other Contract information on the chain linked to one identifier.</p>
                                <p className="accordian__content__p"><b>Access Control your Assets </b> - Provide and implement strategies around using your asset from one place. For example, the amount is limited on the credit amount.</p>
                                <p className="accordian__content__p"><b>Logic Transfer </b> - Logic transfer in blockchain involves transferring application logic using smart contracts, enabling customization of business logic using native transaction Cross-Chain using X-Talk.</p>
                                <p className="accordian__content__p"><b>Cross Chain </b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility.</p>
                                <p className="accordian__content__p"><b>X-Talk Architecture </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of assets, messages, and logic.</p>
                                <p className="accordian__content__p"><b>Smart Contracts </b> - Providing multi-signature access control and sharing using Smart Contracts. By creating a self-sovereign digital identity system where individuals own and control their own personal data rather than relying on third-party identity providers.</p>
                                <p className="accordian__content__p"><b>Fraud Security & Safety </b> - Using Layer One X Blockchain technology to provide security, using Homomorphic Encryption to transact data while it remains encrypted.</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> -  Blockchains' immutability, transparency, and decentralized nature make them extraordinarily secure. There is no single point of failure with blockchain storage because blockchains are decentralized, and the data stored in each block is unalterable, meaning criminals can't access the information.</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                Introducing Web3 as a Service (WaaS)
                              </button>
                            </h2>
                            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Decentralized Websites </b> - Layer One X VM is the custom-built virtual machine that executes eBPF bytecode. The ability for eBPF to access system resources. For example, GPUs can be directly accessed to speed graphic intensive games. Making eBPF 10-20x more efficient and faster than WebAssembly.</p>
                                <p className="accordian__content__p"><b>Distributed Content </b> - Websites utilizing decentralized domains using peer-to-peer networks to distribute content, with no single point of failure or control, resistant to censorship and downtime.</p>
                                <p className="accordian__content__p"><b>DNS </b> - blockchain-based DNS system, domain names are stored on a blockchain and can be accessed and updated by anyone with access to the network. This technology provides a more democratic and decentralized approach to domain name registration and management and can help to prevent domain name hijacking and other types of attacks.</p>
                                <p className="accordian__content__p"><b>Email Security </b> - Securing emails using blockchain verification of addresses and data encryption.</p>
                                <p className="accordian__content__p"><b>Layer One X As Your Passport </b> - Use Layer One X Wallet to sign in to the website so the website can recognize you for access control and data integrity.</p>
                                <p className="accordian__content__p"><b>Components </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of assets, messages, and logic.</p>
                                <p className="accordian__content__p"><b>IPFS </b> - InterPlanetary File System is a distributed system for storing and accessing files, web applications, and other data on a decentralized network. Unlike traditional web hosting, which relies on a centralized server to store and serve data, IPFS uses a peer-to-peer network to distribute data across the network, making it more secure, reliable, and censorship-resistant.</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> - Blockchains' immutability, transparency, and decentralized nature make them extraordinarily secure. There is no single point of failure with blockchain websites. Because blockchains are decentralized, and the data stored in each block is unalterable, criminals can't access the information.</p>

                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                Scalable Enterprise Applications
                              </button>
                            </h2>
                            <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Speed </b> - With a transaction speed of 500ms, the fastest blockchain</p>
                                <p className="accordian__content__p"><b>SaaS Applications </b> - Layer One X VM is the custom-built virtual machine that executes eBPF bytecode. The ability for eBPF to access system resources. For example, GPUs can be directly accessed to speed graphic intensive games. Making eBPF 10-20x more efficient and faster than WebAssembly.</p>
                                <p className="accordian__content__p"><b>Hybrid Consensus Mechanism </b> - Consensus algorithms are used together in a blockchain network to achieve specific goals. Such as scalability, security, or decentralization. Hybrid consensus mechanisms are designed to overcome the limitations and tradeoffs of individual consensus mechanisms and to provide a more efficient and effective way of securing a blockchain network</p>
                                <p className="accordian__content__p"><b>Logic Transfer </b> - Logic transfer in blockchain Applications involves transferring business logic using smart contracts, enabling customization of transaction information and Native Cross-Chain transactions.</p>
                                <p className="accordian__content__p"><b>Cross Chain </b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility</p>
                                <p className="accordian__content__p"><b>X-Talk Architecture </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of assets, messages, and logic</p>
                                <p className="accordian__content__p"><b>Smart Contracts </b> - Deployed on Layer One X, making the application logic transparent and tamper-proof. This process allows users to access and verify the data, ensuring accuracy and transparency. Integrated with smart contracts to allow transactions to operate using business logic. This can include executing business rules using logic.</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> - Blockchains' immutability, transparency, and decentralized nature make them extraordinarily secure. There is no single point of failure with blockchain storage. Because blockchains are decentralized, and the data stored in each block is unalterable, criminals can't access the information. "Using Layer One X Blockchain technology to provide security against online fraud</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                Government Services
                              </button>
                            </h2>
                            <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Taxation </b> - Using the Layer One X decentralized ledger, Governments can foster better transparency in solving tax collection issues</p>
                                <p className="accordian__content__p"><b>GST, VAT, Sales Tax </b> - Automate the collection of tax on invoice payments using smart contracts to automatically send taxes to Tax Office.</p>
                                <p className="accordian__content__p"><b>Bills and Payments </b> - Governments can implement Layer One X Blockchain to improve traditional billing and payments systems</p>
                                <p className="accordian__content__p"><b>Welfare Distribution </b> - Layer One X Blockchain can provide capabilities to improve welfare distribution</p>
                                <p className="accordian__content__p"><b>Digital IDs </b> - Using a decentralized ledger, implementation of true digital identities for all</p>
                                <p className="accordian__content__p"><b>Healthcare Solutions </b> - Improve Healthcare using distributed ledger technology</p>
                                <p className="accordian__content__p"><b>Legal Enforcement </b> - Government can use Layer One X Public Blockchain data to monitor transactions to facilitate legal transactions and stop money laundering</p>
                                <p className="accordian__content__p"><b>Fraud Security & Safety </b> - Using Layer One X Blockchain technology to provide security for Social Security online fraud</p>
                                <p className="accordian__content__p"><b>Cyber-Protection </b> - Governments can use the Layer One X Blockchain to protect vital infrastructure against cyber-attacks and hacks</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed accordion-button__last" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                Gaming
                              </button>
                            </h2>
                            <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="accordian__content__p"><b>Speed </b> - With a transaction speed of 500ms, the fastest blockchain</p>
                                <p className="accordian__content__p"><b>Web3 Games </b> - Layer One X VM is the custom-built virtual machine that executes eBPF bytecode. The ability for eBPF to access system resources. For example, GPUs can be directly accessed to increase speed for more graphic-intensive games. Making eBPF 10-20x more efficient and faster than WebAssembly.</p>
                                <p className="accordian__content__p"><b>Logic Transfer </b> - Logic transfer in blockchain gaming involves transferring game logic or rules using smart contracts, enabling customization of game mechanics and Native Cross-Chain transactions.</p>
                                <p className="accordian__content__p"><b>Cross Chain </b> - Ability to transact cross-chain natively directly between protocols, providing both EVM and Non-EVM compatibility.</p>
                                <p className="accordian__content__p"><b>X-Talk Architecture </b> - Using Decentralized: adapters, translators, signing Library, Execution register, and messaging standards allow for the secure and efficient exchange of NFT assets, messages, and logic.</p>
                                <p className="accordian__content__p"><b>Smart Contracts </b> - Deployed on Layer One X, making the game logic transparent and tamper-proof. This tech allows all players to access and verify the game rules, ensuring fairness and transparency. Integrated with the smart contracts to allow players to interact with the game logic. This process can include executing game mechanics, checking scores, and receiving rewards. Customized to allow for flexibility in in-game rules. For example, Adding new game mechanics or adjusting existing ones to fit the needs of the players.</p>
                                <p className="accordian__content__p"><b>Game Asset Transfers </b> - Game assets include 3D object creations, in-game items, resources, and more. Non-fungible tokens (NFTs) are unique digital assets that can represent anything from in-game items to artwork and music.</p>
                              </div>
                            </div>
                          </div>
                          <div ref={this.myRef} className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed accordion-button__last" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                Didn't Find Your Country
                              </button>
                            </h2>
                            <div id="collapsenine" className={this.state.learnMoreStatus ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body learn_more_accordian">
                                <p className="accordian__content__p learn_more_accordian_txt">
                                  Due to the significant regulatory uncertainty in a number of global jurisdictions, with respect to digital assets and cryptographic tokens, Layer One X has decided to exclude those contributors living in the following countries from participating in our public sale. In the event circumstances change before or during our sale then we will update this FAQ in line with those changes.
                                  <p>
                                    <ol style={{ paddingLeft: 0, marginTop: '1.3rem' }}>
                                      <li>1. Afghanistan</li>
                                      <li>2. Belarus</li>
                                      <li>3. Democratic People's Republic of Korea (DPRK)</li>
                                      <li>4. Iran</li>
                                      <li>5. Myanmar</li>
                                      <li>6. Syria</li>
                                      <li>7. Venezuela</li>
                                      <li>8. China</li>
                                      <li>9. United States of America</li>
                                      <li>10. Sudan</li>
                                    </ol>
                                  </p>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="banner__fixed__card__box">
                    <div className="banner__fixed__card__head">
                      <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
                    </div>
                    <div className="fixed__card__main__content">
                      <PublicBuySection />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
PublicSaleBuySection.propTypes = {
  theme: PropTypes.string,
};
