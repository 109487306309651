import React, { useState, useEffect } from "react";
import {
  serviceGetPrivateSaleInvestors,
  serviceGetStakeData,
} from "../../services/apiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";
import CircularProgress from "../CircularProgress/index";
import { Button } from "react-bootstrap";
import CustomModal from "./CustomModal"; // Import the modal component

const InvestorList = () => {
  const [privateSaleInvestorsByWallet, setPrivateSaleInvestorsByWallet] =
    useState([]);
  const [privateSaleInvestorsByEmail, setPrivateSaleInvestorsByEmail] =
    useState([]);
  const [selectedTab, setSelectedTab] = React.useState("wallet_address");

  const walletHeaderList = [
    "Wallet Address",
    "Total Investment",
    "L1X Allocation",
    "Action",
    // "NFT Allocation",
  ];
  const emailHeaderList = [
    "Name",
    "Email",
    "Wallet Address",
    "Total Investment",
    "L1X Allocation",
    "Action",
    // "NFT Allocation",
  ];

  const stakeHeaderList = ["Amount", "Duration", "Expected Return"];
  const [offset, setOffset] = useState(0);
  const [itemsPerPage] = useState(20);
  const [limit] = useState(10);
  const [totalCountEmailData, setTotalCountEmailData] = useState(0);
  const [totalCountWalletAddressData, setTotalCountWalletAddressData] =
    useState(0);

  const [loader, setLoader] = useState(false);
  const [walletSearch, setWalletSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stakeData, setStakeData] = useState([]);
  const [isStakeLoaded, setIsStakeLoaded] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const handlePageChange = (activePage) => setOffset(activePage * itemsPerPage);

  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleOpenModal = (walletAddress) => {
    getStakeData(walletAddress);
    //setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  const getPrivateSaleInvestors = async () => {
    try {
      setLoader(true);
      setWalletSearch("");
      setEmailSearch("");

      let _res = {};
      if (selectedTab == "wallet_address") {
        _res = await serviceGetPrivateSaleInvestors(
          offset,
          itemsPerPage,
          "wallet_address",
          ""
        );
      }
      if (selectedTab == "email_address") {
        _res = await serviceGetPrivateSaleInvestors(
          offset,
          itemsPerPage,
          "email_address",
          ""
        );
      }

      console.log("_res", _res);

      if (_res.status === "success") {
        setPrivateSaleInvestorsByWallet(_res.data.walletAddressesData);
        setPrivateSaleInvestorsByEmail(_res.data.transaction);
        setTotalCountEmailData(_res.data.totalCountEmailData);
        setTotalCountWalletAddressData(_res.data.totalCountWalletAddressData);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching prospects ,please try agian", "error");
      logger("error", e.message);
    }
  };

  const getStakeData = async (walletAddress) => {
    try {
      let _res = await serviceGetStakeData(walletAddress, offset, itemsPerPage);

      if (_res.status == "success") {
        setStakeData(_res.data.rows);
        //setModalIsOpen(true);
        setShowModal(true);
      } else {
        Notify("Stake details data not found.", "error");
      }
    } catch (e) {
      Notify("Error while fetching prospects ,please try agian", "error");
      logger("error", e.message);
    }
  };

  useEffect(() => {
    async function fetchProspectData() {
      await getPrivateSaleInvestors();
    }

    fetchProspectData();
  }, [offset, selectedTab]); //isSearch

  // useEffect(() => {
  //   async function fetchStakeData() {
  //     await getStakeData();
  //   }

  //   fetchStakeData();
  // }, [isStakeLoaded]);

  function handleTabs(_tab) {
    setOffset(0);
    setSelectedTab(_tab);
  }

  async function handleSearch(_type) {
    try {
      console.log("type", _type);
      setLoader(true);
      setIsSearch(false);
      let _res = "";
      if (_type == "wallet_address") {
        _res = await serviceGetPrivateSaleInvestors(
          offset,
          itemsPerPage,
          _type,
          walletSearch
        );
      }
      if (_type == "email_address") {
        _res = await serviceGetPrivateSaleInvestors(
          offset,
          itemsPerPage,
          _type,
          emailSearch
        );
      }

      //console.log("_res", _res);

      if (_res.status === "success") {
        setPrivateSaleInvestorsByWallet(_res.data.walletAddressesData);
        setPrivateSaleInvestorsByEmail(_res.data.transaction);
        setTotalCountEmailData(_res.data.totalCountEmailData);
        setTotalCountWalletAddressData(_res.data.totalCountWalletAddressData);
        setLoader(false);
        setIsSearch(true);
      } else {
        setLoader(false);
        setPrivateSaleInvestorsByWallet([]);
        setPrivateSaleInvestorsByEmail([]);
        setTotalCountEmailData(0);
        setTotalCountWalletAddressData(0);
        return Notify(_res.message, "");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching prospects ,please try agian", "error");
      logger("error", e.message);
    }
  }


  return (
    <>
      {loader == false ? (
        <div className="row premium_wallet_list_card private-sale-investers-wrapper">
          <div className="col-md-12">
            {/*  */}
            <div className="tab__btn__section">
              <div className="tab__btn__box">
                <button
                  onClick={() => handleTabs("wallet_address")}
                  className={
                    selectedTab === "wallet_address"
                      ? "btn btn__tab active"
                      : "btn btn__tab"
                  }
                >
                  Wallet Address
                </button>
              </div>
              <div className="tab__btn__box">
                <button
                  onClick={() => handleTabs("email_address")}
                  className={
                    selectedTab === "email_address"
                      ? "btn btn__tab active"
                      : "btn btn__tab"
                  }
                >
                  Email Address
                </button>
              </div>
            </div>
            {selectedTab === "wallet_address" ? (
              <div className="number__box__head">
                <div className="premium_wallet_card_title">
                  Private Sale Investors (Wallet Address)
                </div>
                <div className="serachbar-block">
                  <input
                    type="text"
                    value={walletSearch}
                    onChange={async (e) => {
                      setWalletSearch(e.target.value);

                      // await getPremiumWalletList(data)
                    }}
                    placeholder="Search by wallet address"
                  />
                  <button
                    className="btn btn-search"
                    onClick={() => handleSearch("wallet_address")}
                  >
                    Search
                  </button>
                  &nbsp;
                  <button
                    className="btn add_premimun_wallet_btn"
                    onClick={() => getPrivateSaleInvestors()}
                  >
                    Reset
                  </button>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {walletHeaderList.map((item, index) => (
                          <th>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    {loader === false ? (
                      <tbody>
                        {privateSaleInvestorsByWallet.length > 0 ? (
                          <>
                            {privateSaleInvestorsByWallet.map((item, index) => (
                              <tr key={index}>
                                <td>{item.walletAddress}</td>
                                <td>
                                  {item.totalAmount}
                                </td>
                                <td>{item.totalAmount * 100}</td>
                                {/* <td>{item.nft_allocations}</td> */}
                                <td
                                  style={{ cursor: "pointer" }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#termsAndConditionModal"
                                  //onClick={() => openModal(item.walletAddress)}
                                  onClick={() =>
                                    handleOpenModal(item.walletAddress)
                                  }
                                >
                                  View Stake
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <div className="no-data-found-text">
                            <p>No Data Found</p>
                          </div>
                        )}
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
                <Pagination
                  offset={offset}
                  onPageChange={handlePageChange}
                  totalPages={totalCountWalletAddressData}
                />
              </div>
            ) : (
              <div className="number__box__head">
                <div className="premium_wallet_card_title">
                  Private Sale Investors (Email Address)
                </div>
                <div className="serachbar-block">
                  <input
                    type="text"
                    value={emailSearch}
                    onChange={async (e) => {
                      setEmailSearch(e.target.value);
                    }}
                    placeholder="Search by email"
                  />
                  <button
                    className="btn btn-search"
                    onClick={() => handleSearch("email_address")}
                  >
                    Search
                  </button>
                  &nbsp;
                  <button
                    className="btn add_premimun_wallet_btn"
                    onClick={() => getPrivateSaleInvestors()}
                  >
                    Reset
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {emailHeaderList.map((item, index) => (
                          <th>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    {loader === false ? (
                      <tbody>
                        {privateSaleInvestorsByEmail.length > 0 ? (
                          <>
                            {privateSaleInvestorsByEmail.map((item, index) => (
                              <tr key={index}>
                                <td>{item.firstName + " " + item.lastName}</td>
                                <td>{item.email}</td>
                                <td>{item.walletAddress}</td>
                                <td>
                                  {item.totalAmount}
                                </td>
                                <td>{item.totalAmount * 100}</td>
                                {/* <td>{item.nft_allocations}</td> */}
                                <td
                                  style={{ cursor: "pointer" }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#termsAndConditionModal"
                                  //onClick={() => openModal(item.walletAddress)}
                                  onClick={() =>
                                    handleOpenModal(item.walletAddress)
                                  }
                                >
                                  View Stake
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <div className="no-data-found-text">
                            <p>No Data Found</p>
                          </div>
                        )}
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
                <Pagination
                  offset={offset}
                  onPageChange={handlePageChange}
                  totalPages={totalCountEmailData}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={30} />
        </div>
      )}
      {
        // <MyModal isOpen={modalIsOpen} onRequestClose={closeModal} /> : ""
        <div>
          {/* <Button onClick={handleOpenModal}>Open Modal</Button> */}
          <CustomModal
            show={showModal}
            stakeData={stakeData}
            stakeHeaderList={stakeHeaderList}
            onHide={handleCloseModal}
          />
        </div>
      }
    </>
  );
};

export default InvestorList;
