import React, { useState } from "react";
import { NumericFormat } from "react-number-format";


const TokenCalulator = () => {
  const [totalAmount, setTotalAmount] = useState();
  const [totalToken, setTotalToken] = useState();
  const [estimatedValue, setEstimatedValue] = useState();


  console.log("totalAmount", totalAmount)
  return (
    <>

      <section className="banners-section sub-banner">
        <div className="pro-ico-live text-center d-flex align-self-center p-118">
          <div className="container">

            <div className="row justify-content-center">
              <div className="col-md-12 mb-3">
                <h1>Private Sale Token Calculator</h1>
              </div>
            
              <div className="row mb-3 justify-content-center">
                <div className="col-md-4">
                  <div className="CalculatorMainWrapper">
                    <p className="pb-0 mb-0 fontsize-26 text-end txtCalculator">Total amount invested In Private Sale (USD/USDT/USDC)</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inputCalculator">
                    <NumericFormat
                      onValueChange={(values) => {
                        setTotalAmount(
                          values.value,
                        );
                        
                        let _tokenAmount = values.value * 10;

                        setTotalToken(_tokenAmount + ' L1X Tokens');
                        setEstimatedValue(_tokenAmount * 0.5);
                        if (values.value === '') {
                          setTotalToken('');
                          setEstimatedValue('');
                        }
                      }}

                      thousandSeparator={true}
                      className="form-control"
                      inputmode="numeric"
                      prefix={'$'}
                    />
                  </div>
                </div>
              </div>



              <div className="row mb-3 justify-content-center">
                <div className="col-md-4">
                  <div className="CalculatorMainWrapper">
                    <p className="pb-0 mb-0 fontsize-26 text-end txtCalculator">Total Tokens you will receive on Launch</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inputCalculator">
                    <NumericFormat
                      disabled
                      value={totalToken}
                      thousandSeparator={true}
                      className="form-control"
                      inputmode="numeric"
                      suffix={'L1X Tokens'}
                    />
                  </div>
                </div>
              </div>


              <div className="row mb-3 justify-content-center">
                <div className="col-md-4">
                  <div className="CalculatorMainWrapper">
                    <p className="pb-0 mb-0 fontsize-26 text-end txtCalculator">Estimated value at Public Sale Pricing ($0.50 USD)</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inputCalculator">
                    <NumericFormat
                      disabled
                      value={estimatedValue}
                      thousandSeparator={true}
                      className="form-control"
                      inputmode="numeric"
                      prefix={'$'}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <div className="col-md-8">

                <ul className="calulatorNotes text-start">
                  <li>
                    For those who contributed AUD$ via a term sheet, please refer to your email showing the exchange rate calculation to confirm your equivalent USD holding.
                  </li>

                  <li>
                    Private Sale contributors will receive 1/15th of their token allocation each month for 15 months.
                  </li>

                  <li>
                    Private Sale contributors will be able to stake against Layer One X Nodes on launch to earn rewards.
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TokenCalulator;
