/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import StandardisedInteroperability from "../images/standardised-interoperability.png";
import StandardisedInteroperabilitymobile from "../images/standardised-interoperability-mobile.png";

import Easyconfiguration from "../images/icons/icon-design-with-ease.png";
import LMEasyconfiguration from "../images/icons/icon-design-with-ease-lm.png";

import Reductionincosts from "../images/icons/icon-reduction.png";
import LMReductionincosts from "../images/icons/icon-reduction-lm.png";

import Fasttimetomarket from "../images/icons/icon-easy-learn.png";
import LMFasttimetomarket from "../images/icons/icon-easy-learn-lm.png";

import Seamlesstransfers from "../images/icons/icon-secure-transfer.png";
import LMSeamlesstransfers from "../images/icons/icon-secure-transfer-lm.png";

import LeverageL1Xforpowerfuldigitalservices from "../images/leverage-l1x-for-powerful-digital-services.png";
import LeverageL1Xforpowerfuldigitalservicesmobile from "../images/leverage-l1x-for-powerful-digital-services-mobile.png";
import IntegratedSmartContracts from "../images/integrated-smart-contracts.png";
import IntegratedSmartContractsmobile from "../images/integrated-smart-contracts-mobile.png";
import BlockchainasaService from "../images/blockchain-as-a-service.png";
import BlockchainasaServicemobile from "../images/blockchain-as-a-service-mobile.png";



import Wyde from "../images/wyde.svg";
import L1XDex from "../images/l1xdex.svg";
import HealthLink from "../images/healthlink.svg";
import LMWyde from "../images/wyde-lm.svg";
import LML1XDex from "../images/l1xdex-lm.svg";
import LMHealthLink from "../images/healthlink-lm.svg";
import AppsLayeronexLogo2 from "../images/builton-layeronex.png";
import LMAppsLayeronexLogo2 from "../images/builton-layeronex-lm.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Config from "../config";

export class DeFi extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Use Cases</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  DeFi
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>DeFi</h4>
                  <h1 className="mb-16">
                    DeFi to connect traditional <br />
                    financial services with Web3.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="main-sticky">
          {/* <nav className="sticky-top section-sticky-top w-auto use-cases-sticky">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 m-auto">
                <ul style={{width:"auto"}}>
                  <span>Use Cases</span>
                  <li className="active">
                    <a>
                    De-Fi
                    </a>
                  </li>
                  {/* <li>
                    <a href="use-cases/#interoperability">
                    Interoperability
                    </a>
                  </li>
                  <li>
                    <a href="use-cases/#digital-identity">
                    Digital Identity
                    </a>
                  </li> 
                  <li>
                    <a href="use-cases/#multi-chain">
                    Multi-Chain
                    </a>
                  </li> 
                  <li>
                    <a href="use-cases/#micro-payments">
                    Micro Payments
                    </a>
                  </li> 
                  <li>
                    <a href="use-cases/#healthcare">
                    Healthcare
                    </a>
                  </li>
                  <li>
                    <a href="use-cases/#gaming">
                    Gaming
                    </a>
                  </li>                  
                </ul>
              </div>
            </div>
          </div>
        </nav> */}


          <section className="section web3-usecases our-goals state-of-the-art text-center" id="interoperability">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-left">
                    Plug into emerging markets and outcompete the NeoBanks
                  </h3>
                </div>
                <div className="col-md-6">
                  <p className="pb-80 text-left">
                    Layer One X offers institutional banks the ability to easily interact with existing and emerging blockchain networks, offering DeFi (Decentralised Finance) services to clients and a low-code way to connect  digital assets with secure traditional banking.</p>
                </div>
              </div>

              <div className="cards-section">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMEasyconfiguration
                              : Easyconfiguration
                          }
                          alt="Easy configuration"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Easy configuration</h4>
                        <p>
                          Decentralised finance that is out of the box
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMReductionincosts
                              : Reductionincosts
                          }
                          alt="Reduction in costs"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Reduction in costs</h4>
                        <p>
                          Significant reduction in overheads from day one
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMFasttimetomarket
                              : Fasttimetomarket
                          }
                          alt="Fast time to market"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Fast time to market</h4>
                        <p>
                          Significantly lower development timelines with no need to ‘reinvent the wheel’
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMSeamlesstransfers
                              : Seamlesstransfers
                          }
                          alt="Seamless transfers"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Seamless transfers</h4>
                        <p>
                          Immediate access to multiple blockchains and emerging markets
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="section worldsweb3 web3-systems text-left leverage-l1x">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-xl-8 col-xxl-9 d-flex">
                  <div className="content align-self-center">
                    <h3>
                      Leverage Layer One X for powerful digital services and asset diversification
                    </h3>
                    <p>
                      DeFi has thrown down the gauntlet before traditional banking providers. The challenge is now how to promote portfolio diversification into digital assets for the growing market of tech-savvy clients—while managing risk.
                    </p>
                    <p>
                      Until now, this inability for institutional firms to easily engage in DeFi—an umbrella term for delivering decentralised peer-to-peer financial  services—has been a missed opportunity in accessing new markets and attracting a 'millennial' clientele.
                    </p>
                    <p>

                      Layer One X provides onramp/offramp extensibility for existing banking services ti connect with digital markets. Gain access to smart contract tech to power finance and asset procurement, earn interest, access loans, trade assets and derivatives and more.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 p-0">
                  <figure>
                    <img
                      src={LeverageL1Xforpowerfuldigitalservices}
                      alt="LeverageL1Xforpowerfuldigitalservices"
                      className="img-fluid desktop"
                    />
                    <img
                      src={LeverageL1Xforpowerfuldigitalservicesmobile}
                      alt="LeverageL1Xforpowerfuldigitalservices"
                      className="img-fluid mobile"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>


          <section className="section worldsweb3 fastest-low-cost" id="validation">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">


                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="text-left">
                        Addressing critical industry adoption factors
                      </h3>
                    </div>
                    <div className="col-md-6">
                      <p className="pb-80 text-left">
                        Layer One X isn’t a cryptocurrency or exchange, though it can be used to create financial products. It’s the underlying (layer one) blockchain technology on which to build decentralised banking business networks and transact across borders.</p>
                    </div>
                  </div>


                  <div className="web3 mb-32">
                    <div className="row">
                      <div className="col-md-6 d-flex">
                        <div className="cont align-self-center cont-tb-48">
                          <h4>Standardised Interoperability</h4>
                          <h3>
                            Connect with multiple markets using a single blockchain identity
                          </h3>
                          <p>
                            The standardised interoperability layer of Layer One X enables traditional banks to provide a wider range of digital services. Give customers a single means of interacting with multiple blockchain financial markets using a single identity, wallet and portfolio.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <figure>
                          <img
                            src={StandardisedInteroperability}
                            alt="Standardised Interoperability"
                            className="img-fluid desktop"
                          />
                          <img
                            src={StandardisedInteroperabilitymobile}
                            alt="Standardised Interoperability"
                            className="img-fluid mobile"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rapid-lowcost">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="web3">
                      <div className="cont align-self-center">
                        <h4>Blockchain as a Service</h4>
                        <h3>Allow customers to trade  and store digital assets </h3>
                        <p className="pb-0">
                          Layer One X can be adopted as a service, reducing operational overheads, whilst opening up a whole new generation of users who have been unable to connect their traditional banking services to their digital assets.</p>
                      </div>
                      <figure>
                        <img
                          src={BlockchainasaService}
                          alt="Blockchain as a Service"
                          className="img-fluid desktop"
                        />
                        <img
                          src={BlockchainasaServicemobile}
                          alt="Blockchain as a Service"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="web3 integrated-smart-contracts">
                      <div className="cont align-self-center">
                        <h4>Integrated Smart Contracts</h4>
                        <h3>Secure, cross-border trading and banking</h3>
                        <p className="pb-0">
                          Layer One X Labs (our developer team) can help banks deploy a secure, smart contract policy-based integration of both internal and external blockchain networks with traditional banking networks.
                        </p>
                      </div>
                      <figure>
                        <img
                          src={IntegratedSmartContracts}
                          alt="Integrated Smart Contracts"
                          className="img-fluid desktop"
                        />
                        <img
                          src={IntegratedSmartContractsmobile}
                          alt="Integrated Smart Contracts"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="section powering-apps text-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="title">
                    <h2>
                      Powering Apps and services <br />
                      on the blockchain.
                    </h2>
                    <p className="pb-80">
                      Layer One X is being embraced by these industry leaders.
                    </p>
                  </div>
                  <p className="d-block d-md-none d-lg-none appslayeronelogo">
                    <img
                      src={this.props.themeEnabled === 'light' ? LMAppsLayeronexLogo2 : AppsLayeronexLogo2}
                      alt="AppsLayeronexLogo"
                      className="img-fluid"
                    />
                  </p>
                </div>
              </div>
              <div className="cards-section">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="bg wyde d-flex justify-content-center">
                        <figure className="d-flex">
                          <img src={this.props.themeEnabled === 'light' ? LMWyde : Wyde} alt="WYDE" className="img-fluid" />
                        </figure>
                        <div className="hover-labs">
                          <p className="d-flex d-none d-md-block">
                            <img
                              src={this.props.themeEnabled === 'light' ? LMAppsLayeronexLogo2 : AppsLayeronexLogo2}
                              alt="AppsLayeronexLogo"
                              className="img-fluid"
                            />
                          </p>
                          <h5>By L1XLABS</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="bg mathwallet d-flex justify-content-center align-item-center">
                        <figure className="d-flex">
                          <img
                            src={this.props.themeEnabled === 'light' ? LML1XDex : L1XDex}
                            alt="L1XDex"
                            className="img-fluid"
                          />
                        </figure>
                        <div className="hover-labs">
                          <p className="d-flex d-none d-md-block">
                            <img
                              src={this.props.themeEnabled === 'light' ? LMAppsLayeronexLogo2 : AppsLayeronexLogo2}
                              alt="AppsLayeronexLogo"
                              className="img-fluid"
                            />
                          </p>
                          <h5>By L1XLABS</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="bg manadr d-flex justify-content-center">
                        <figure className="d-flex">
                          <img
                            src={this.props.themeEnabled === 'light' ? LMHealthLink : HealthLink}
                            alt="HealthLink" className="img-fluid" />
                        </figure>
                        <div className="hover-labs">
                          <p className="d-flex d-none d-md-block">
                            <img
                              src={this.props.themeEnabled === 'light' ? LMAppsLayeronexLogo2 : AppsLayeronexLogo2}
                              alt="AppsLayeronexLogo"
                              className="img-fluid"
                            />
                          </p>
                          <h5>By L1XLABS</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>



        </div>


        <section className="decentralised-section text-center d-flex align-self-center" id="buy-l1x">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-24">
                    Built to unite a  <br /> decentralised future.
                  </h2>
                  <p className="pb-40">
                    With over 7500 cryptocurrencies in the world and counting, <br />choose the one that brings them all together.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <Link className="glow-button gradient-border-mask" to="/public-sale">
                      {/* <span>Buy L1X </span>  */}
                      <span>{Config.publicSaleButtonLabel}</span>
                    </Link>
                  </div>
                  <div className="btn-style btn-style-two">
                    <Link className="glow-button gradient-border-mask" to="/build-on-layeronex">
                      <span>Build on Layer One X</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
DeFi.propTypes = {
  theme: PropTypes.string,
};
