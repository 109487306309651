import React, { useState, useEffect } from "react";
import ChainService from "../../services/chainService";
import {
  serviceGetAdminDashboardStats,
  serviceGetKycOverrideStatus,
  serviceGetPrivateSaleReferralPercent,
  serviceGetPublicSaleReferralPercent,
  serviceGetWhiteListingStatus,
  serviceUpdateKycOverrideStatus,
  serviceUpdatePrivateSaleReferralReward,
  serviceUpdatePublicSaleReferralReward,
  serviceUpdateWhiteListingStatus,
  serviceGetDirectInvestmentWalletAddress,
  serviceUpdateDirectInvestmentWalletAddress
} from "../../services/adminApiService";
import logger from "../../utils/logger";
import CircularProgress from "../CircularProgress/index";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import Config from "../../config/index";
import Notify from "../../utils/notify";

const PrivateSaleAbi = require("../../config/abi/private-sale-contract.json");
const ERC20Token = require("../../config/abi/ERC20Token.json");

const OnChainConfig = ({ saleType }) => {
  const networkList = ChainService.getNetworks();
  const tokenList = ChainService.getTokens();
  const [configData, setConfigData] = useState({});
  const [loader, setLoader] = useState(false);
  const [connectedProvider, setConnectedProvider] = React.useState(null);
  const [selectedNetwork, setSelectedNetwork] = React.useState(null);
  const [selectedToken, setSelectedToken] = React.useState(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [whiteListStatus, setWhiteListStatus] = useState("");
  const [privateSaleReferralPercent, setPrivateSaleReferralPercent] = useState("");
  const [publicSaleReferralPercent, setPublicSaleReferralPercent] = useState("");
  const [directInvestmentAddress, setDirectInvestmentAddress] = useState("");

  const [kycOverrideStatus, setKycOverrideStatus] = useState("");

  console.log("isWalletConnected",isWalletConnected)
  const IconButton = (props) => {
    return (
      <div className="ibuttonInAdmin">
        <img
          alt="Icon button"
          style={{ cursor: "pointer", marginLeft: 10 }}
          src={require("../../images/info.png")}
          height={15}
          width={17}
        />
        <span className="tooltiptext">{props.text}</span>
      </div>
    );
  };

  const getConfigDetails = async (_selectedNetwork) => {
    try {
      setLoader(true);
      let _res = await serviceGetAdminDashboardStats(_selectedNetwork, saleType);

      if (_res.status === "success") {
        setConfigData({
          ..._res.data,
          minimumKycInvestment: _res.data.minimumKycInvestment.toString(),
          maximumKycInvestment: _res.data.maximumKycInvestment.toString(),
        });
        setLoader(false);
      } else {
        setLoader(false);
        Notify(_res.message, "error");
      }
    } catch (error) {
      setLoader(false);
      logger("error", error.message);
      return Notify("Error while fetching data ,please try agian", "error");
    }
  };

  async function handleStatusChange(status) {
    try {
      setLoader(true);
      const signer = connectedProvider.getSigner();
      const tokenContract = new ethers.Contract(
        Config[Config.networkMode][saleType][selectedNetwork],
        PrivateSaleAbi,
        connectedProvider
      );
      const tokenSigner = tokenContract.connect(signer);

      await tokenSigner.updateSaleStatus(status);
      setLoader(false);

      Notify("Private sale status updated successfully", "success");
    } catch (error) {
      setLoader(false);
      logger("error", error.message);
      return Notify(
        "Error while updating the status,please try again",
        "error"
      );
    }
  }

  const handleWhiteListStatusChange = async (_value) => {
    try {
      let status = _value ? "true" : "false";
      let data = {
        status: status,
        sale_type: saleType
      };

      let _res = await serviceUpdateWhiteListingStatus(data, saleType);

      if (_res.status === "success") {
        setWhiteListStatus(_res.data);
        return Notify(_res.message, "success");
      } else {
        return Notify(_res.message, "error");
      }
    } catch (e) {
      Notify("Error while fetching white listing status", "error");
    }
  };

  const handleKycOverrideStatusChange = async (_value) => {
    try {
      let status = _value ? "true" : "false";
      let data = {
        status: status,
        sale_type: saleType
      };

      let _res = await serviceUpdateKycOverrideStatus(data);

      if (_res.status === "success") {
        setWhiteListStatus(_res.data);
        return Notify(_res.message, "success");
      } else {
        return Notify(_res.message, "error");
      }
    } catch (e) {
      Notify("Error while fetching white listing status", "error");
    }
  };

  async function handleKycInvestmentChange() {
    try {
      setLoader(true);
      const signer = connectedProvider.getSigner();

      const tokenContract = new ethers.Contract(
        Config[Config.networkMode][saleType][selectedNetwork],
        PrivateSaleAbi,
        connectedProvider
      );
      const tokenSigner = tokenContract.connect(signer);

      // Create Instance for Token
      let _tokenAddress =
        Config[Config.networkMode].tokenAddress[selectedNetwork]["USDT"];
      const tokenAddressContract = new ethers.Contract(
        _tokenAddress,
        ERC20Token.abi,
        connectedProvider
      );
      const tokenAddressSigner = tokenAddressContract.connect(signer);

      // Fetch Decimal for Selected Token
      let _tokenDecimals = await tokenAddressSigner.decimals();

      let minimumKycInvestment = 0;
      let maximumKycInvestment = 0;

      // if (selectedNetwork == "BSC") {
      //   minimumKycInvestment = ethers.utils.parseUnits(
      //     configData.minimumKycInvestment,
      //     _tokenDecimals
      //   );
      //   maximumKycInvestment = ethers.utils.parseUnits(
      //     configData.maximumKycInvestment,
      //     _tokenDecimals
      //   );
      // }
      // else {
      minimumKycInvestment = ethers.utils.parseUnits(
        configData.minimumKycInvestment,
        _tokenDecimals
      );
      maximumKycInvestment = ethers.utils.parseUnits(
        configData.maximumKycInvestment,
        _tokenDecimals
      );
      // }

      await tokenSigner.updateInvestmentLimit(
        minimumKycInvestment,
        maximumKycInvestment
      );

      setLoader(false);
      return Notify("Investment limit updated successfully", "success");
    } catch (error) {
      setLoader(false);
      logger("error", error.message);
      return Notify(
        "Error while updating the Investment limit,please try again",
        "error"
      );
    }
  }

  async function handleConversionRateChange() {
    try {
      // setLoader(true);
      const signer = connectedProvider.getSigner();


      const tokenContract = new ethers.Contract(
        Config[Config.networkMode][saleType][selectedNetwork],
        PrivateSaleAbi,
        connectedProvider
      );
      const tokenSigner = tokenContract.connect(signer);

      // Get Ether Signer
      //  const signer = connectedProvider.getSigner();

      // Create Instance for Token
      let _tokenAddress =
        Config[Config.networkMode].tokenAddress[selectedNetwork]["USDT"];
      const tokenAddressContract = new ethers.Contract(
        _tokenAddress,
        ERC20Token.abi,
        connectedProvider
      );
      const tokenAddressSigner = tokenAddressContract.connect(signer);

      // Fetch Decimal for Selected Token
      let _tokenDecimals = await tokenAddressSigner.decimals();

      // await tokenSigner.updateConversionFactor(
      //   configData.conversionFactor * 10 ** 6
      // );

      // if (selectedNetwork == "BSC") {
      await tokenSigner.updateConversionFactor(
        ethers.utils.parseUnits(configData.conversionFactor, _tokenDecimals)
      );
      // }
      // else {
      //   await tokenSigner.updateConversionFactor(
      //     ethers.utils.parseUnits(configData.conversionFactor, _tokenDecimals)
      //   );
      // }

      setLoader(false);
      return Notify("Conversion rate updated successfully", "success");
    } catch (error) {
      setLoader(false);
      logger("error", error.message);
      return Notify(
        "Error while updating the conversion rate,please try again",
        "error"
      );
    }
  }

  async function handleWithdrawToken() {
    try {
      setLoader(true);
      const signer = connectedProvider.getSigner();
      const tokenContract = new ethers.Contract(
        Config[Config.networkMode][saleType][selectedNetwork],
        PrivateSaleAbi,
        connectedProvider
      );
      const tokenSigner = tokenContract.connect(signer);

      let tokenAddress =
        Config[Config.networkMode].tokenAddress[selectedNetwork][selectedToken];

      await tokenSigner.withdrawTokenBalance(tokenAddress);
      setLoader(false);
      return Notify("Token withdraw successfully", "success");
    } catch (error) {
      setLoader(false);
      logger("error", error.message);
      return Notify(
        "Error while withdrawing the token,please try again",
        "error"
      );
    }
  }

  async function handleWalletConnection() {
    try {
      if (window.ethereum) {
        try {
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then(async (res) => {
              let _networkId = window.ethereum.networkVersion;
              let _networkName = ChainService.getNetworkById(_networkId);
              setSelectedNetwork(_networkName);
              const provider = await detectEthereumProvider();
              setConnectedProvider(new ethers.providers.Web3Provider(provider));
              checkNetwork(_networkId);
              await getConfigDetails(_networkName);
            });
        } catch (e) { }
      } else {
        return Notify("Please install metamask extension", "error");
      }
    } catch (e) {
      return Notify(
        "Error while connecting metamask,please try again",
        "error"
      );
    }
  }

  function checkNetwork(networkId) {
    let _networkName = ChainService.getNetworkById(networkId);

    if (_networkName !== null) {
      setIsWalletConnected(true);
    } else {
      setIsWalletConnected(false);
      return Notify("Please switch your network to Allowed Networks", "error");
    }
  }

  const handleNetworkChange = async (e) => {
    setSelectedNetwork(e.target.value);
    await handleMetamaskAccounchange(e.target.value);
  };

  const handleTokenChange = async (e) => {
    setSelectedToken(e.target.value);
  };

  const handleMetamaskAccounchange = async (_network) => {
    if (window.ethereum) {
      try {
        let _chainConfig = ChainService.getNetworkConfig(_network);

        if (_chainConfig === null) {
          logger(
            "error",
            "Error Occured while getting network config for " + _network
          );
          return;
        }

        if (_network === "ETH") {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: _chainConfig.chainId,
              },
            ],
          });
        } else {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [_chainConfig],
          });
        }
        const provider = await detectEthereumProvider();
        setConnectedProvider(new ethers.providers.Web3Provider(provider));
        await getConfigDetails(_network);
        logger("window.ethereum.chainId", window.ethereum.chainId);
      } catch (error) {
        logger("error", error);
      }
    }
  };

  const getWhiteListingStatus = async () => {
    try {
      setLoader(true);
      let _res = await serviceGetWhiteListingStatus(saleType);

      if (_res.status === "success") {
        setWhiteListStatus(_res.data);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching white listing status", "error");
    }
  };

  const getKycOverrideStatus = async () => {
    try {
      setLoader(true);
      let _res = await serviceGetKycOverrideStatus(saleType);

      if (_res.status === "success") {
        setKycOverrideStatus(_res.data);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching white listing status", "error");
    }
  };


  const getPrivateSaleReferralReward = async () => {
    try {
      setLoader(true);
      // serviceGetPrivateSaleReferralPercent
      let _res = await serviceGetPrivateSaleReferralPercent();

      if (_res.status === "success") {
        setPrivateSaleReferralPercent(_res.data);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching white listing status", "error");
    }
  };

  const setPrivateSaleReferralReward = async () => {
    try {
      setLoader(true);

      let data = {
        percent: privateSaleReferralPercent
      }
      let _res = await serviceUpdatePrivateSaleReferralReward(data);

      if (_res.status === "success") {
        // setWhiteListStatus(_res.data);
        Notify("Private sale referral reward updated successfully", "success");

        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching updating private sale referral reward percent", "error");
    }
  };

  const getPublicSaleReferralReward = async () => {
    try {
      setLoader(true);

      let _res = await serviceGetPublicSaleReferralPercent();

      if (_res.status === "success") {
        setPublicSaleReferralPercent(_res.data);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching public sale referral reward percentage", "error");
    }
  };

  const setPublicSaleReferralReward = async () => {
    try {
      setLoader(true);

      let data = {
        percent: publicSaleReferralPercent
      }
      let _res = await serviceUpdatePublicSaleReferralReward(data);

      if (_res.status === "success") {
        Notify("Public sale referral reward updated successfully", "success");
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching public sale referral reward percentage", "error");
    }
  };

  const getPublicSaleDirectInvestmentAddress = async () => {
    try {
      setLoader(true);

      let _res = await serviceGetDirectInvestmentWalletAddress();

      if (_res.status === "success") {
        setDirectInvestmentAddress(_res.data);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching public sale referral reward percentage", "error");
    }
  };

  const setPublicSaleDirectInvestmentAddress = async () => {
    try {
      setLoader(true);

      let data = {
        walletAddress: directInvestmentAddress
      }
      let _res = await serviceUpdateDirectInvestmentWalletAddress(data);

      if (_res.status === "success") {
        Notify("Direct Investment address updated successfully", "success");
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching public sale referral reward percentage", "error");
    }
  };


  useEffect(() => {
    async function fetchConfigData() {
      if (saleType) {
        await handleWalletConnection();
        await getWhiteListingStatus();
        await getKycOverrideStatus();
        await getPrivateSaleReferralReward();
        await getPublicSaleReferralReward();

        if (saleType === 'publicSaleContract') {
          await getPublicSaleDirectInvestmentAddress()
        }
        // logger("isWalletConnected", isWalletConnected);
      }
    }

    fetchConfigData();
  }, [whiteListStatus, saleType]);

  return (
    <>
      <div className="admin-container-fluid">
        <div className="row ">
          <div className="col-md-12">
            <div className="onchange__config__box">
              <div className="row mb-5">
                <div className="col-md-9 head__center">
                  <div className="head__main">
                    <h2>OnChain Configuration</h2>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="head__main">
                    <form>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={handleNetworkChange}
                        value={selectedNetwork}
                      >
                        <option selected>Select Network </option>
                        {networkList.map((_network) => {
                          return (
                            <option value={_network}>
                              {ChainService.getFormalNetworkName(_network)}
                            </option>
                          );
                        })}
                      </select>
                    </form>
                  </div>
                </div>
              </div>
              {loader === false ? (
                <>
                  <div className="number__box__head">
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>
                            Is pause sale
                            <IconButton text="Is pause sale" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input input__switch__btn"
                              type="checkbox"
                              // role="switch"
                              value={configData.isPrivateSalePause}
                              checked={configData.isPrivateSalePause}
                              onChange={async (e) => {
                                setConfigData({
                                  ...configData,
                                  isPrivateSalePause: e.target.checked,
                                });
                                await handleStatusChange(e.target.checked);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>

                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>Minimum KYC Investment</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="onchange__config__txt">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control mb-0"
                              placeholder="Enter minimum KYC investment"
                              name="minimumKycInvestment"
                              value={configData.minimumKycInvestment}
                              onChange={async (e) => {
                                setConfigData({
                                  ...configData,
                                  minimumKycInvestment: e.target.value,
                                });
                              }}
                            />
                            <span
                              class="input-group-text button__with__txtbox"
                              id="basic-addon2"
                              onClick={() => {
                                handleKycInvestmentChange();
                              }}
                            >
                              Update
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>Maximum KYC Investment</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="onchange__config__txt">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control mb-0"
                              placeholder="Enter maximum KYC investment"
                              value={configData.maximumKycInvestment}
                              onChange={(e) => {
                                setConfigData({
                                  ...configData,
                                  maximumKycInvestment: e.target.value,
                                });
                              }}
                            />
                            <span
                              class="input-group-text button__with__txtbox"
                              id="basic-addon2"
                              onClick={() => {
                                handleKycInvestmentChange();
                              }}
                            >
                              Update
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>Conversion Rate</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="onchange__config__txt">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control mb-0"
                              placeholder="Enter Conversion rate"
                              value={configData.conversionFactor}
                              onChange={(e) => {
                                setConfigData({
                                  ...configData,
                                  conversionFactor: e.target.value,
                                });
                              }}
                            />
                            <span
                              class="input-group-text button__with__txtbox"
                              id="basic-addon2"
                              onClick={() => {
                                handleConversionRateChange();
                              }}
                            >
                              Update
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>Withdraw Token's</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="onchange__config__txt">
                          <div class="input-group mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={handleTokenChange}
                              value={selectedToken}
                            >
                              <option selected>Select Token </option>
                              {tokenList.map((element) => (
                                <option value={element}>{element}</option>
                              ))}
                            </select>
                            <span
                              class="input-group-text button__with__txtbox"
                              id="basic-addon2"
                              onClick={() => {
                                handleWithdrawToken();
                              }}
                            >
                              Withdraw
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>

                  <div className="number__box__head">
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>
                            <b>White Listing Status</b>
                            <IconButton text="White Listing Status" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <div class="form-check form-switch">
                            <input
                              className="form-check-input input__switch__btn"
                              type="checkbox"
                              // role="switch"
                              value={whiteListStatus}
                              checked={
                                whiteListStatus === "true" ? true : false
                              }
                              onChange={async (e) => {
                                setWhiteListStatus(e.target.checked);
                                await handleWhiteListStatusChange(
                                  e.target.checked
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>
                  </div>

                  <div className="number__box__head">
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>
                            <b>Kyc Override Status</b>
                            <IconButton text="KYC override status" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <div class="form-check form-switch">
                            <input
                              className="form-check-input input__switch__btn"
                              type="checkbox"
                              // role="switch"
                              value={kycOverrideStatus}
                              checked={
                                kycOverrideStatus === "true" ? true : false
                              }
                              onChange={async (e) => {
                                setKycOverrideStatus(e.target.checked);
                                await handleKycOverrideStatusChange(
                                  e.target.checked
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>
                  </div>

                  <div className="number__box__head">
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>Private Sale Referral Reward (%)</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="onchange__config__txt">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control mb-0"
                              placeholder="Private Sale Referral Reward"
                              value={privateSaleReferralPercent}
                              onChange={(e) => {
                                setPrivateSaleReferralPercent(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text button__with__txtbox"
                              id="basic-addon2"
                              onClick={() => {
                                setPrivateSaleReferralReward();
                              }}
                            >
                              Update
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="onchange__config__txt">
                          <p>Public Sale Referral Reward (%)</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="onchange__config__txt">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control mb-0"
                              placeholder="Public Sale Referral Reward"
                              value={publicSaleReferralPercent}
                              onChange={(e) => {
                                setPublicSaleReferralPercent(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text button__with__txtbox"
                              id="basic-addon2"
                              onClick={() => {
                                setPublicSaleReferralReward();
                              }}
                            >
                              Update
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>


                    {
                      saleType === 'publicSaleContract' ? <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="onchange__config__txt">
                            <p>Direct investment wallet address</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="onchange__config__txt">
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                class="form-control mb-0"
                                placeholder="Direct Investment Wallet Address"
                                value={directInvestmentAddress}
                                onChange={(e) => {
                                  setDirectInvestmentAddress(e.target.value);
                                }}
                              />
                              <span
                                class="input-group-text button__with__txtbox"
                                id="basic-addon2"
                                onClick={() => {
                                  setPublicSaleDirectInvestmentAddress();
                                }}
                              >
                                Update
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div> : ""
                    }
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8%",
                  }}
                >
                  {" "}
                  <CircularProgress size={30} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnChainConfig;
