/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import PropTypes from "prop-types";
import DeFi from "../images/icons/icon-defi.png";
import LMDeFi from "../images/icons/icon-defi-lm.png";
// import Interoperability from "../images/icons/icon-interoperability.png";
// import LMInteroperability from "../images/icons/icon-interoperability-lm.png";
// import DigitalIdentity from "../images/icons/icon-digital-identity.png";
// import LMDigitalIdentity from "../images/icons/icon-digital-identity-lm.png";
// import MultiChain from "../images/icons/icon-multi-chain.png";
// import LMMultiChain from "../images/icons/icon-multi-chain-lm.png";
// import MicroPayments from "../images/icons/icon-micro-payments.png";
// import LMMicroPayments from "../images/icons/icon-micro-payments-lm.png";
// import Healthcare from "../images/icons/icon-health-care.png";
// import LMHealthcare from "../images/icons/icon-health-care-lm.png";
// import Gaming from "../images/icons/icon-gaming.png";
// import LMGaming from "../images/icons/icon-gaming-lm.png";
import { Link } from "react-router-dom";



export class UseCases extends Component {
  render() {
    // const theme=this.props

    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Use Cases
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>Use Cases</h4>
                  <h1>
                  Develop, scale and launch <br />
                  projects faster.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section powering-apps use-cases text-center">
          <div className="container">
            <div className="cards-section">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg defi d-flex justify-content-center flex-column">
                      <Link to='defi'>
                      <figure>
                        <img src={this.props.themeEnabled === 'light'? LMDeFi:DeFi} alt="DeFi" className="img-fluid" />
                      </figure>
                      <h3>De-Fi</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        Asset management, compliance, synthetic assets, trading and financial tools, banking APIs.
                        </p>
                      </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg interoperability d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                            src={this.props.themeEnabled === 'light'? LMInteroperability:Interoperability}
                          alt="Interoperability"
                          className="img-fluid"
                        />
                      </figure>
                      <h3 className="d-flex">Interoperability</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        Freedom in data exchange, trading liquidity and assets between different blockchains.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg digital-identity d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img 
                        src={this.props.themeEnabled === 'light'? LMDigitalIdentity:DigitalIdentity}
                             alt="Digital Identity" className="img-fluid" />
                      </figure>
                      <h3 className="d-flex">Digital Identity</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        Self-managed data security and access; insights and control over data sharing and with whom.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg multi-chain d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img 
                        src={this.props.themeEnabled === 'light'? LMMultiChain:MultiChain}
                             alt="Multi-Chain" className="img-fluid" />
                      </figure>
                      <h3 className="d-flex">Multi-Chain</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        Interact directly with holdings across blockchains through a singular wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg micro-payments d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img 
                        src={this.props.themeEnabled === 'light'? LMMicroPayments:MicroPayments}
                             alt="Micro Payments" className="img-fluid" />
                      </figure>
                      <h3 className="d-flex">Micro Payments</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        Fast, trusted online purchasing and retail point-of-sale systems. IP royalty payments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg healthcare d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img 
                        src={this.props.themeEnabled === 'light'? LMHealthcare:Healthcare}
                             alt="Healthcare" className="img-fluid" />
                      </figure>
                      <h3 className="d-flex">Healthcare</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        Management, security and portability of medical records between different providers. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="card mb-48">
                    <div className="bg gaming d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img 
                        src={this.props.themeEnabled === 'light'? LMGaming:Gaming}
                             alt="Gaming" className="img-fluid" />
                      </figure>
                      <h3 className="d-flex">Gaming</h3>
                      <div className="hover-labs">
                        <p className="d-flex">
                        In-game transactions, digital twins, storage of digital assets in and beyond the metaverse.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>


      </>
    );
  }
}
UseCases.propTypes = {
  theme: PropTypes.string,
};
