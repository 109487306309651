import React, { useState } from "react"
import copyIcon from "../images/copy-orange.png"


const NonFungible = () => {
    const [selectedTab, setSelectedTab] = useState('token')
    const [currentTokenInnerTab, setCurrentTokenInnerTab] = useState('create')
    const [currentNFTInnerTab, setCurrentNFTInnerTab] = useState('create')

    const handleTabClick = (_tabName) => {
        setSelectedTab(_tabName)
    }

    const handleTokenInnerTabClick = (_tabName) => {
        setCurrentTokenInnerTab(_tabName)
    }

    const handleNFTInnerTabClick = (_tabName) => {
        setCurrentNFTInnerTab(_tabName)
    }

    function connectL1xWallet() {
        const message = {
          data: 'testing',
          type: "l1xExplorer",
          action: "open_extension",
        };
    
        window.postMessage(message, '*');
    
        console.log("postMessage called")
    
      }
    

    return (
        <>
            <div className="non__fungible__token__container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="non__fungible__token__head">
                                <div className="wallet__balance__box">
                                    <h1>Wallet Balance</h1>
                                    <h2>114,857</h2>
                                </div>

                                <div className="connect__btn__box">
                                    <div className="btn__box__nft__token">
                                        <button onClick={()=>connectL1xWallet()} className="non__fungible__token__btn btn__l1x__wallet">Connect</button>
                                    </div>
                                </div>
                            </div>
                            <div className="token__wallet__key__nft">
                                <p>Cjscbsj...Ghsjcshck <button className="non__fungible__token__btn btn__token__copy__nft"><img src={copyIcon} alt="Copy Key" /></button></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="non__fungible__token__main__card">
                                <div className="non__fungible__token__card">
                                    <div className="non__fungible__token__main__tab">
                                        <button onClick={() => handleTabClick('token')} className={"non__fungible__token__btn btn__non__fungible__tab " + (selectedTab === 'token' ? 'active' : "")} >Fungible Token</button>
                                        <button onClick={() => handleTabClick('nft')} className={"non__fungible__token__btn btn__non__fungible__tab " + (selectedTab === 'token' ? '' : "active")}>Non - Fungible Token</button>
                                    </div>

                                    {selectedTab === 'token' ?
                                        <div className="non__fungible__token__content">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {/* <button onClick={() => handleTokenInnerTabClick('initialize')} className={"non__fungible__token__btn btn__side__tab__nft " + (currentTokenInnerTab === 'initialize' ? 'active' : ' ')}>Initialize Supply</button> */}
                                                    <button onClick={() => handleTokenInnerTabClick('create')} className={"non__fungible__token__btn btn__side__tab__nft " + (currentTokenInnerTab === 'create' ? 'active' : ' ')}>Create Token</button>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="non__fungible__token__content__box">
                                                        {/* fungible token initialize supply  */}
                                                        {currentTokenInnerTab === 'initialize' ?
                                                            <div className="card__nft__main__box__frm" >
                                                                <div className="row mb-3 align-items-center">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Address</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Address" />
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Amount</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Amount" />
                                                                    </div>
                                                                </div>

                                                                <div className="btn__box__nft__token mt-4">
                                                                    <button className="non__fungible__token__btn btn__generate__nft__token">Initialize Token</button>
                                                                </div>

                                                            </div>
                                                            : ""}

                                                        {/* fungible token Create Token  */}
                                                        {currentTokenInnerTab === 'create' ?
                                                            <div className="card__nft__main__box__frm">
                                                                <div className="row mb-3 align-items-center">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Name</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Name" />
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center mb-3">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Decimal</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Decimal" />
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Symbol</label>
                                                                    </div>
                                                                    <div className="col-md-10 mb-3">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Symbol" />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Total Supply</label>
                                                                    </div>
                                                                    <div className="col-md-10 mb-3">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Total Supply" />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Icon</label>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <button className="non__fungible__token__btn btn__plus__nft">+</button>
                                                                    </div>
                                                                </div>
                                                                <div className="btn__box__nft__token">
                                                                    <button className="non__fungible__token__btn btn__generate__nft__token">Create Token</button>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}



                                    {selectedTab === 'nft' ?
                                        <div className="non__fungible__token__content">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <button onClick={() => handleNFTInnerTabClick('create')} className={"non__fungible__token__btn btn__side__tab__nft " + (currentNFTInnerTab === "create" ? 'active' : '')}>Create Token</button>
                                                    <button onClick={() => handleNFTInnerTabClick('mint')} className={"non__fungible__token__btn btn__side__tab__nft " + (currentNFTInnerTab === "create" ? '' : 'active')}>Mint Token</button>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="non__fungible__token__content__box">


                                                        {/* non fungible token Create Token  */}
                                                        {currentNFTInnerTab === "create" ?
                                                            <div className="card__nft__main__box__frm" >
                                                                <div className="row mb-3 align-items-center">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Name</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Name" />
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Symbol</label>
                                                                    </div>
                                                                    <div className="col-md-10 mb-3  ">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Symbol" />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Icon</label>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <button className="non__fungible__token__btn btn__plus__nft">+</button>
                                                                    </div>
                                                                </div>
                                                                <div className="btn__box__nft__token">
                                                                    <button className="non__fungible__token__btn btn__generate__nft__token">Create Token</button>
                                                                </div>
                                                            </div>
                                                            : ""}

                                                        {/* non fungible token mint Token  */}
                                                        {currentNFTInnerTab === "mint" ?
                                                            <div className="card__nft__main__box__frm" >
                                                                <div className="row mb-3 align-items-center">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">Address</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="text" class="form-control cust__form__nft" placeholder="Address" />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3 align-items-center">
                                                                    <div className="col-md-2">
                                                                        <label class="col-form-label">MetaData</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <textarea rows="5" class="form-control cust__form__textarea__nft" placeholder="MetaData"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="btn__box__nft__token">
                                                                    <button className="non__fungible__token__btn btn__generate__nft__token">Mint Token</button>
                                                                </div>
                                                            </div>
                                                            : ""}


                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default NonFungible