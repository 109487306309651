import React from "react";
import { Modal } from "react-bootstrap";

const MessageModal = (props) => {
  // const [loginModalShow, setLoginModalShow] = useState(props.showModal);


  return (
    <>
      {props.title === "login" ? (
        <Modal
          show={props.show}
          cancel={props.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Congratulations!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> You are registered! More details have been emailed to you.</h4>
            <h4> If you do not see the email in your inbox, Please check your “junk mail” folder or “spam” folder.</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={props.close}
              type="button"
              className="btn btn-primary"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      ) : ''}

      {props.title==="referral"?
            <Modal
            show={props.show}
            cancel={props.close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header></Modal.Header>
            <Modal.Body>
              <h4> Want your own Referral code?</h4>
            </Modal.Body>
            <Modal.Footer>
            <button
                onClick={props.yes}
                type="button"
                className="btn btn-primary"
              >
                Yes
              </button>
              <button
                onClick={props.close}
                type="button"
                className="btn btn-primary"
              >
                No
              </button>
             
            </Modal.Footer>
          </Modal>
      :""}
    </>
  );
};

export default MessageModal;
