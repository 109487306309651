import React, { Component } from "react";
import L1xNftCoin from "../../../src/images/L1X-NFT-coin-EVM-Non-EVM-Final.png";

export class NftHowItWorks extends Component {
  render() {
    return (
      <>
        <section class="banner__section how-it-works__section">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="banner__content__box">
                  <div class="banner__header how-it-works__banner">
                    <h2>How It Works</h2>
                    <h1>
                      Historial X Milestone <span>.NFT </span> Collection
                    </h1>
                  </div>
                  <p>
                    The masterminds at Layer One X have outdone themselves with
                    their EVM to Non-EVM chain transfer without using a bridge
                    feat.
                  </p>
                  <p>
                    To commemorate this epic achievement, we’re immortalizing it
                    in NFT format. And if you’re lucky enough to own a premium
                    wallet, then boy, have we got a treat for you! All premium
                    wallets will receive a commemorative NFT coin that’ll be the
                    envy of all your crypto-crazed friends. But wait, there’s
                    more!
                  </p>
                  <p>
                    We’re paying homage to every single blockchain first that we
                    do in the crypto world and transforming it into a
                    collectible NFT. Every moment will be immortalized in our
                    exclusive “Historical X Moments” collection. And if your
                    wallet is a premium wallet ($1000 or more contributed into
                    the private sale) and you’re feeling serendipitous, you
                    might just be able to snag each of them to own the whole NFT
                    set.
                  </p>
                  <p>
                    So let’s raise a glass to Layer One X’s genius minds and
                    celebrate these historic moments in style! Cheers!{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="banner__image__box how-it-works__img__box">
                  <img src={L1xNftCoin} alt="l1x-nft-coin"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NftHowItWorks;
