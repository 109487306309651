function getAuthToken() {
    return localStorage.getItem("token");
}

function storeAuthToken(_data){
    return localStorage.setItem("token",_data);
}

function getAdminAuthToken() {
    return localStorage.getItem("admin_auth_token");
}

function storeAdminAuthToken(_data){
    return localStorage.setItem("admin_auth_token",_data);
}

export default {
    getAuthToken,
    storeAuthToken,
    getAdminAuthToken,
    storeAdminAuthToken
}