import React, { useState, useEffect } from "react";
import {
  serviceGetTokenSaleBalance,
  servicePrivateSaleStats,
} from "../../services/adminApiService";
import CircularProgress from "../CircularProgress/index";
import Notify from "../../utils/notify";
const Dashboard = ({saleType}) => {
  const [dashboardData, setDashboardData] = useState([]);
  const [networkStats, setNetworkStats] = useState([]);
  const [totalAmountInvested, setTotalAmountInvested] = useState(0);
  const [loader, setLoader] = useState(false);
  const [networkStatsLoader, setNetworkStatsLoader] = useState(false);
 


  const privateSaleStats = async () => {
    try {
      // let saleType = "privateSaleContract";
      // if (localStorage.getItem("saleType")) {
      //   saleType = localStorage.getItem("saleType");
      // }

      let _res = await servicePrivateSaleStats(saleType);

      console.log("servicePrivateSaleStats",_res)
      if (_res.status === "success") {
        try {
          let _tmpTotal = 0;
          _res.data.map((stats) => {
            _tmpTotal += stats.totalInvestment?parseFloat(stats.totalInvestment):0;
            return _tmpTotal;
          });

          console.log("setTotalAmountInvested",_tmpTotal)
          setTotalAmountInvested(_tmpTotal);
        } catch (e) {}

        setNetworkStats(_res.data);
        setNetworkStatsLoader(false);
      } else {
        setNetworkStatsLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setNetworkStatsLoader(false);
      Notify("Error while fetching network data ,please try agian", "error");
    }
  };

  const privateSaleTokenData = async () => {
    try {
      setLoader(true);
      // let saleType = "privateSaleContract";
      // if (localStorage.getItem("saleType")) {
      //   saleType = localStorage.getItem("saleType");
      // }

      let _res = await serviceGetTokenSaleBalance(saleType);

      if (_res.status === "success") {
        setDashboardData(_res.data);
        setLoader(false);

        if (networkStats.length === 0) {
          setNetworkStatsLoader(true);
        }
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching token data,please try agian", "error");
    }
  };

  let statsLoaded = false;
  useEffect(() => {
    if (!statsLoaded) {
      /*All network balance api */
      privateSaleStats();

      /*All network stats api */
      privateSaleTokenData();

      statsLoaded = true;
    }
  }, [saleType]);

  return (
    <>
      <div className="admin-container-fluid main__container__wrapper">
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "8%",
            }}
          >
            {" "}
            <CircularProgress size={30} />
          </div>
        ) : (
          <div className="row" style={{ display: "none" }}>
            {dashboardData.map((element) => (
              <>
                <div className="col-md-3">
                  <div className="dashboard__box__num">
                    <div className="number__box__head">
                      <h3>{element.network} </h3>
                      <p>
                        USDT: <span>{element.usdt}</span>
                      </p>
                      <p>
                        USDC: <span>{element.usdc}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}

        {networkStatsLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "8%",
            }}
          >
            <CircularProgress size={30} />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 text-center">
                Total Investment: {totalAmountInvested}
              </div>
            </div>
            <div className="row">
              {networkStats.map((stats) => (
                <>
                  <div className="col-md-6">
                    <div className="dashboard__box__num">
                      <div className="number__box__head dashboard__balance__box">
                        <h3>{stats.network} </h3>
                        <div className="row">
                          <div className="col-md-6 border__right__box">
                            <p>
                              Private Sale Status:{" "}
                              <span>
                                {stats.isPrivateSalePause ? "True" : "false"}
                              </span>
                            </p>
                            <p>
                              Total L1x Allocated:{" "}
                              <span>{stats.totalL1xAllocated}</span>
                            </p>
                            <p>
                              Total Investment:{" "}
                              <span>{stats.totalInvestment}</span>
                            </p>
                            <p>
                              USDC Investment:{" "}
                              <span>{stats.usdcInvestMent}</span>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              USDT Investment:{" "}
                              <span>{stats.usdtInvestMent}</span>
                            </p>
                            <p>
                              Minimum Kyc Investment:{" "}
                              <span>{stats.minimumKycInvestment}</span>
                            </p>
                            <p>
                              Maximum Kyc Investment:{" "}
                              <span>{stats.maximumKycInvestment}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}

              {/* <div className="col-md-6">
                <div className="dashboard__box__num">
                  <div className="number__box__head">
                    <h3>ETH </h3>
                    <p>USDT: 500000000000000</p>
                    <p>USDC: 499990000000000</p>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6">
                <div className="dashboard__box__num">
                  <div className="number__box__head">
                    <h3>ETH </h3>
                    <p>USDT: 500000000000000</p>
                    <p>USDC: 499990000000000</p>
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
