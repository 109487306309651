import React, {  useRef, useState } from "react"
import WalletGif from "../images/l1x-gif-logo.gif"
// import Make4 from "../images/slider-1.png"
// import Make5 from "../images/slider-2.png"
import WalletNew1 from "../images/new-wallet-1.png"
import WalletNew2 from "../images/new-wallet-2.png"
import WalletNew3 from "../images/new-wallet-3.png"
import WalletNew4 from "../images/new-wallet-4.png"
import WalletNew5 from "../images/new-wallet-5.png"
import WalletNew6 from "../images/new-wallet-6.png"
import CloseIcon from "../images/close-lm.svg"

// import WalletFromBg from "../images/new-wallet-bg.svg"

import validator from "validator";
// import { Link } from "react-router-dom"
import Notify from "../utils/notify"
import { serviceRegisterL1xWalletWhiteListing } from "../services/apiService"

import CircularProgress from "./CircularProgress/index";
import { Link } from "react-router-dom"

const WalletPage = () => {

    const walletPreRegisterRef = useRef(null);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState(null);
    const [submitStatus, setSubmitStatus] = useState(false);

    const [showSuccessfulWalletPreRegister, setShowSuccessfulWalletPreRegister] = useState(false);

  

    const handleCharValidation = (evt) => {
        let theEvent = evt || window.event;
        const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Tab"];
        const regex = /^[A-Za-z.\s]+$/;

        if (
            !regex.test(theEvent.key) &&
            !allowedKeys.includes(theEvent.key)
        ) {
            theEvent.preventDefault();
        }
    }

    const handleSubmitForm = async (event) => {
        // event.target.preventDefault()
        // this.setstate({ showPublicSale: true })
        let errors = {};
        console.log("eventevent", email)

        if (validator.isEmpty(firstName)) {
            errors.firstName = "Please enter first name";
        }

        if (validator.isEmpty(lastName)) {
            errors.lastName = "Please enter last name";
        }

        if (validator.isEmpty(email)) {
            errors.email = "Please enter a email address";
        }
        else if (!validator.isEmail(email)) {
            errors.email = "Please enter a valid email address";
        }


        setErrors(errors)

        if (Object.keys(errors).length === 0) {
            
            setSubmitStatus(true);

            let data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
            }

            let _txSubmitResponse = await serviceRegisterL1xWalletWhiteListing(data);


            if (_txSubmitResponse.status === "success") {
                setSubmitStatus(false);
                setShowSuccessfulWalletPreRegister(true);

                // props.handleComponentChange()
                // Notify(_txSubmitResponse.message, "success");
                return
            }
            else {
                Notify(_txSubmitResponse.message, "error");

                setSubmitStatus(false);
                setShowSuccessfulWalletPreRegister(false);

                return
            }
            //   this.setState({ showPublicSale: !this.state.showPublicSale })
        }

        return errors;
    }

    const clearWalletPreRegister = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrors(null);

        setShowSuccessfulWalletPreRegister(false);
    }

    const scrollToWalletPreRegister = () => {
        clearWalletPreRegister();
        if(walletPreRegisterRef !== null)
        {
            walletPreRegisterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <>
            <div ref={walletPreRegisterRef}>
                <section className="wallet__new__banner__section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="wallet__new__gif__box">
                                    <img src={WalletGif} alt="L1X Gif" />
                                </div>
                                <div className="wallet__new__head__box">
                                    <h2>Layer One X Wallet<br /> The Gateway to <span>Web3</span></h2>
                                    <p>Unleash the power of Web3 with the Layer One X Wallet. {showSuccessfulWalletPreRegister}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wallet__new__form__section" >
                    <div className="container">
                        <div className="row justify-content-center"  >
                            <div className="col-md-10">
                                <div className="wallet__new__form__box">
                                    
                                    {
                                        showSuccessfulWalletPreRegister === true ? 

                                        
                                        <div className="row">
                                            <div className="col-md-12 join__apportunity__container">
                                                <div className="join__apportunity__box">
                                                    <h3>We've received your request for early Wallet access</h3>
                                                    <p>In the mean time, register for the public sale here </p>
                                                    <button className="btn btn__publict__sale__here">
                                                        <Link to='/public-sale'>
                                                            Register - Whitelist Now
                                                        </Link>
                                                    </button>
                                                </div>
                                                <img src={CloseIcon} className="close__box__form__wallet" alt="close" onClick={() => clearWalletPreRegister()}></img>
                                            </div>
                                        </div>
                                        
                                        
                                        :
                                        
                                        <div className="row" >
                                            <div className="col-md-7 white__bg__form">
                                                <div className="form__wallet__content">
                                                    <h3>Don't miss out on this exciting opportunity to join the Web3 revolution.</h3>
                                                    <p><span>APPLY NOW</span> for early access* to the Layer One X Wallet and secure your spot at the forefront of the next generation blockchain ecosystem.</p>
                                                    <p className="wallet__new__sub__note">*Not all requests will be granted, certain conditions apply.</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-5 sky__blue__bg__form">
                                                <div className="wallet__new__form">

                                                    <div className="mb-3">
                                                        <input
                                                            value={firstName}
                                                            onChange={(event) => setFirstName(event.target.value)}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            onKeyDown={handleCharValidation}
                                                        />
                                                        {errors && errors.firstName && (
                                                            <span className="error">{errors.firstName}</span>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <input
                                                            value={lastName} onChange={(event) => setLastName(event.target.value)}
                                                            type="text" className="form-control"
                                                            placeholder="Last Name"
                                                            onKeyDown={handleCharValidation}
                                                        />
                                                        {errors && errors.lastName && (
                                                            <span className="error">{errors.lastName}</span>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <input
                                                            value={email}
                                                            onChange={(event) => setEmail(event.target.value)}
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Email"
                                                        />
                                                        {errors && errors.email && (
                                                            <span className="error">{errors.email}</span>
                                                        )}
                                                    </div>
                                                    <div className="">
                                                        {/* <button onClick={() => handleSubmitForm()} className="btn btn__register__new">Register - Whitelist Now</button> */}


                                                        {submitStatus ?
                                                            <button disabled className="btn btn__register__new">
                                                                <span className="loader-span">
                                                                    <CircularProgress /> Submiting ...
                                                                </span>
                                                            </button>
                                                            :


                                                            <button onClick={() => handleSubmitForm()} className="btn btn__register__new">Register - Whitelist Now</button>

                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>   
                                         
                                    }
                                    

                                                    
                                    

                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4 mb-4">
                                <div className="wallet__new__card__box">
                                    <img src={WalletNew1} className="icon__crd__wallet__card" alt="Access Decentralised Exchange from Your Wallet Directly"></img>
                                    <h6 className="">1. Access Decentralised Exchange from Your Wallet Directly</h6>
                                    <p>Easily access many cryptocurrencies, NFT's and other Web3 Assets and seamlessly swap and leverage Layer One X as the Universal Gas fee. Hold and check all multiple forms of assets in one spot while you enjoy the benefit of X-Talk to send and receive the currency of your choice.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="wallet__new__card__box wallet__new__card__box__bg__light">
                                    <img src={WalletNew2} className="icon__crd__wallet__card" alt="Multiply your Staking Potential"></img>
                                    <h6 className="">2. Multiply your Staking Potential</h6>
                                    <p>Maximize rewards, minimize risk, and embrace the future with Layer One X Wallet's multi-chain token staking. Unlock synergy across networks, optimize your strategy, and future-proof your staking activities.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="wallet__new__card__box">
                                    <img src={WalletNew3} className="icon__crd__wallet__card" alt="Transform your crypto wealth"></img>
                                    <h6 className="">3. Transform your crypto wealth</h6>
                                    <p>Experience a world of multichain financial opportunities with Layer One X Wallet's dynamic lending and borrowing ecosystem. Explore a connected financial landscape, where collaboration thrives and possibilities abound.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="wallet__new__card__box wallet__new__card__box__bg__light">
                                    <img src={WalletNew5} className="icon__crd__wallet__card" alt="Ignite your NFT assets"></img>
                                    <h6 className="">4. Ignite your NFT assets</h6>
                                    <p>Amplify the liquidity of your NFT portfolio. Seamlessly trade, protect and diversify your digital collectibles across multiple blockchains. Maximize liquidity, explore new opportunities and secure your valuable NFT assets.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="wallet__new__card__box">
                                    <img src={WalletNew4} className="icon__crd__wallet__card" alt="Lease NFTs, experience Ownership on your Terms"></img>
                                    <h6 className="">5. Lease NFTs, experience Ownership on your Terms  </h6>
                                    <p>Empowering users with the ability to lease premium NFT assets. Experience the thrill of ownership without long-term commitments, exploring a diverse range of NFT opportunities. Test the market demand, mitigate risks, and access valuable assets cost-effectively.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="wallet__new__card__box wallet__new__card__box__bg__light">
                                    <img src={WalletNew6} className="icon__crd__wallet__card" alt="Health Smart Contract"></img>
                                    <h6 className="">6. Health Smart Contract</h6>
                                    <p>Control and allow access to your health records to your doctors seamlessly and securely from one wallet. View your health records at anytime, anywhere.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4 mb-5">
                            <div className="col-md-9">
                                <div className="wallet__pre-register__content__box__">
                                    <p> Pre-register NOW for early access to the Layer One X Wallet and secure your spot at the forefront of the next generation blockchain ecosystem.</p>
                                    <button className="btn btn__pre__register" onClick={() => scrollToWalletPreRegister()}>Pre-Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wallet__banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="wallet__banner__box">
                                    <h1 className="wallet__head__banner">L1X Wallet <br />The Gateway to Web3</h1>
                                    <p className="wallet__head__sub__head">Unleash the power of Web3 with the L1X Wallet.</p>
                                    <p className="wallet__head__para">Experience a new era of seamless, secure and user-friendly <br />blockchain interactions across multiple chains.</p>
                                    <Link
                                        to="/public-sale"
                                    ><button className="btn btn__wallet__banner">Pre Register</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="w-100 phone__wallet__desk" src={PhoneWallet} alt="Phone" />
                    <img className="w-100 phone__wallet__phone" src={PhoneWalletPhone} alt="Phone" />
                </section> */}
                {/* <section className="web__3__section">
                    <div className="web__3__head__box">
                        <div className="container">
                            <h2 className="web__3__head">Don't miss out this exciting<br /> opportunity to join the Web3 revolution.</h2>
                        </div>
                    </div>
                    <div className="web__3__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="web__3__main__box">
                                        <div className="web__3__icon__box">
                                            <img src={Wallet1} alt="" />
                                        </div>
                                        <div className="web__3__icon__content__box">
                                            <h4>Access Decentralised Exchange from Your Wallet Directly</h4>
                                            <p>Easily access many cryptocurrencies, NFT’s and other Web3 Assets and seamlessly swap and leverage L1X as the Universal Gas fee. Hold and check all multiple forms of assets in one spot while you enjoy the benefit of X-Talk to send and receive the currency of your choice.</p>
                                        </div>
                                    </div>
                                    <div className="web__3__main__box">
                                        <div className="web__3__icon__box">
                                            <img src={Wallet2} alt="" />
                                        </div>
                                        <div className="web__3__icon__content__box">
                                            <h4>Multiply your Staking Potential</h4>
                                            <p>Maximize rewards, minimize risk, and embrace the future with L1X Wallet’s multi-chain token staking. Unlock synergy across networks, optimize your strategy, and future-proof your staking activities</p>
                                        </div>
                                    </div>
                                    <div className="web__3__main__box">
                                        <div className="web__3__icon__box">
                                            <img src={Wallet3} alt="" />
                                        </div>
                                        <div className="web__3__icon__content__box">
                                            <h4>Transform your crypto wealth</h4>
                                            <p>Experience a world of multichain financial opportunities with L1X Wallet’s dynamic lending and borrowing ecosystem. Explore a connected financial landscape, where collaboration thrives and possibilities abound.</p>
                                        </div>
                                    </div>
                                    <div className="web__3__main__box">
                                        <div className="web__3__icon__box">
                                            <img src={Wallet4} alt="" />
                                        </div>
                                        <div className="web__3__icon__content__box">
                                            <h4>Ignite your NFT assets</h4>
                                            <p>Amplify the liquidity of your NFT portfolio. Seamlessly trade, protect and diversify your digital collectibles across multiple blockchains. Maximize liquidity, explore new opportunities and secure your valuable NFT assets.</p>
                                        </div>
                                    </div>
                                    <div className="web__3__main__box">
                                        <div className="web__3__icon__box">
                                            <img src={Wallet4} alt="" />
                                        </div>
                                        <div className="web__3__icon__content__box">
                                            <h4>Lease NFTs, experience Ownership on your Terms</h4>
                                            <p>Empowering users with the ability to lease premium NFT assets. Experience the thrill of ownership without long-term commitments, exploring a diverse range of NFT opportunities. Test the market demand, mitigate risks, and access valuable assets cost-effectively.</p>
                                        </div>
                                    </div>
                                    <div className="web__3__main__box">
                                        <div className="web__3__icon__box">
                                            <img src={Wallet5} alt="" />
                                        </div>
                                        <div className="web__3__icon__content__box">
                                            <h4>Health Smart Contract</h4>
                                            <p>Control and allow access to your health records to your doctors seamlessly and securely from one wallet. View your health records at anytime, anywhere.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="pre__register__section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="pre__register__box">
                                    <div className="pre__register__content">
                                        <p>Pre-register NOW for early access to the L1X Wallet and secure your spot at the forefront of the next generation blockchain ecosystem.</p>
                                        <Link to="/public-sale">  <button className="btn btn__wallet__banner btn__pre--register">Pre Register</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        </>
    )
}
export default WalletPage