/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Accesstofunding from "../images/access-to-funding.png";
import Accesstofundingmobile from "../images/access-to-funding-mobile.png";
import Technicalsupport from "../images/technical-support.png";
import Technicalsupportmobile from "../images/technical-support-mobile.png";
import Shareinourgrowth from "../images/share-in-our-growth.png";
import Shareinourgrowthmobile from "../images/share-in-our-growth-mobile.png";
import Quicktocode from "../images/icons/icon-quick-to-code.png";
import LMQuicktocode from "../images/icons/icon-quick-to-code-lm.png";
import Designwithease from "../images/icons/icon-design-with-ease.png";
import LMDesignwithease from "../images/icons/icon-design-with-ease-lm.png";
import Adviceandsupport from "../images/icons/icon-advice-and-support.png";
import LMAdviceandsupport from "../images/icons/icon-advice-and-support-lm.png";

import PartnerUWA from "../images/partner-uwa.png";
import PartnerMme from "../images/partner-mme.svg";
import PartnerLx from "../images/partner-l1x-labs.svg";
import PartnerPrysm from "../images/partner-prysm-group.svg";
import LMPartnerUWA from "../images/partner-uwa-lm.png";
import LMPartnerMme from "../images/partner-mme-lm.svg";
import LMPartnerLx from "../images/partner-l1x-labs-lm.svg";
import LMPartnerPrysm from "../images/partner-prysm-group-lm.svg";


import TickIcon from "../images/icons/tick-icon.png";
import LMTickIcon from "../images/icons/tick-icon-lm.png";


import PropTypes from "prop-types";
// import { Partner } from "./partner";
import { Link } from "react-router-dom";

export class GrantApplications extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Build</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Grant Applications
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>Grant Applications</h4>
                  <h1 className="mb-32">
                    Funding to build and unite
                    <br /> Web3 technology.
                  </h1>
                  <p className="pb-24 mb-0">The Layer One X Grants Program recognizes and rewards development, projects and communities that further build the Layer One X protocol and community. The grants are for the projects that are looking to deploy projects on Layer One X only.</p>
                  <p className="pb-40 mb-0">The combined $5 Million co-development grants are given to projects that demonstrate utility application in the web3 space, have a community following, are determined to create a dent in the web3 space and tickbox the Layer One X Grants Program checklist. The funds will be used mainly for equity investments, token purchases, partnership efforts and technology.

                  </p>



                  <div className="buttons d-flex justify-content-center">
                    <div className="btn-style btn-style-one">
                      <a href="https://l1x.typeform.com/to/wXqxgLU0?typeform-source=l1xapp.com" rel="noopener noreferrer" target="_blank" className="glow-button gradient-border-mask">
                        <span>Apply Now</span>
                      </a>
                    </div>

                    <div className="btn-style  btn-style-two">
                      <a className="glow-button gradient-border-mask" href="https://discord.gg/X67bKBZJYe" target={"_blank"}>
                        <span>Layer One X Discord Server</span>
                      </a>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section web3-usecases our-goals state-of-the-art text-center key-areas">
          <div className="container">
            <div className="cards-section">
              <div class="title"><h2 class="mb-80">Key Areas</h2></div>
              <div className="row justify-content-center">
                <div className="col-sm-3">
                  <div className="card mb-32">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Interoperable Demonstration Projects
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card mb-32">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>DeFi</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card mb-32">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Demonstrating Web3 advantages over Web2 Projects
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Micropayments on the Web3 space</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-3">
                  <div className="card mb-32">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Identity Management Projects / Establishing Self Sovereignty</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card mb-32">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>NFT Utility Projects
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card mb-32">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Enterprise Applications</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <figure className="mb-8">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Metaverse</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </section>




        <section className="section worldsweb3 interoperable text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-80">
                    What these grants offer:
                  </h2>
                </div>

                <div className="web3 mb-32">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Join the mission</h4>
                        <h3>
                          Access to funding
                        </h3>
                        <p className="pb-0">
                          Layer One X Labs provides milestone-based funding to support projects that increase the functionality and utility of our interoperable blockchain network. Your application should include your central idea/thesis, proven capabilities, current progress, foreseen roadblocks and a clear use for funds. Submit your application at any time via our <Link to="../start-building"><u>contact page</u></Link>.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={Accesstofunding}
                          alt="Access to funding"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Accesstofundingmobile}
                          alt="Access to funding"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>


                <div className="rapid-lowcost hover-top-100">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="web3">
                        <div className="cont align-self-center height-210">
                          <h4>Collaborators in Success</h4>
                          <h3>Technical support</h3>
                          <p className="pb-0">
                            Access a growing number fo developer resources to building on the Layer One X protocol via <a><u>GitHub</u></a>, or join our <a href="https://discord.gg/X67bKBZJYe" target={"_blank"}><u>Discord</u></a>.
                          </p>
                        </div>
                        <figure>
                          <img
                            src={Technicalsupport}
                            alt="Technical support"
                            className="img-fluid desktop"
                          />
                          <img
                            src={Technicalsupportmobile}
                            alt="Technical support"
                            className="img-fluid mobile"
                          />
                        </figure>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="web3">
                        <div className="cont align-self-center height-210">
                          <h4>Growth Opportunities</h4>
                          <h3>Share in our growth</h3>
                          <p className="pb-0">
                            The developers in Layer One X DAO get 50/50 profit share on the applications that are built by the DAO.
                          </p>
                        </div>
                        <figure className="w-full">
                          <img src={Shareinourgrowth} alt="Share in our growth" className="img-fluid desktop" />

                          <img
                            src={Shareinourgrowthmobile}
                            alt="Share in our growth"
                            className="img-fluid mobile"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section web3-usecases our-goals state-of-the-art text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-left">
                  Fund your project and join a <br />community of builders at <br /> Layer One X Labs
                </h3>
              </div>
              <div className="col-md-6">
                <p className="text-left pb-80">
                  Anyone can apply for a grant from Layer One X to fund a project or initiative. Our team will review all applications and may contact you with further questions.
                </p>
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMQuicktocode
                            : Quicktocode
                        }
                        alt="Quicktocode"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4 className="mb-sm-8">
                        Quick to code
                      </h4>
                      <p>
                        Accelerate up the learning curve by building dApps directly using standard smart interoperable contracts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMDesignwithease
                            : Designwithease
                        }
                        alt="Design with ease"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4 className="mb-sm-8">
                        Design with ease
                      </h4>
                      <p>
                        Make application development less complex by synchronously achieving  responses from multiple blockchains.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMAdviceandsupport
                            : Adviceandsupport
                        }
                        alt="Advice and support"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4 className="mb-sm-8">
                        Advice and support
                      </h4>
                      <p>
                        Plug into our team’s expertise for developer support while building on Layer One X to fast-track your progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section bg-blue partner-section">
          <div className="partner">
            <div className="container">
              <p>Partnering with world leading organisations to enable Web3</p>
              <div className="row">
                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerMMEModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerMme
                          : PartnerMme
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerPrysmModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerPrysm
                          : PartnerPrysm
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerL1XLabsModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerLx
                          : PartnerLx
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>

                <div className="col-3">
                  <figure
                    data-bs-toggle="modal"
                    data-bs-target="#PartnerUWAModal"
                  >
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LMPartnerUWA
                          : PartnerUWA
                      }
                      alt="Layer One X"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>

              <div
                className="modal fade partner-modal"
                id="PartnerPrysmModal"
                tabIndex="-1"
                aria-labelledby="PartnerPrysmModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerPrysm
                                      : PartnerPrysm
                                  }
                                  alt="Prysm"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              Prysm Group is the Tokenomics Partner for I1X, delivering value to platforms by leveraging established economic research.
                            </h4>
                            <p>
                              Prysm Group is an economic consulting and corporate learning firm focused on emerging technologies. Founded by Harvard PhD economists, the firm assists corporates, governments and startups in the adoption and implementation of blockchain, digital asset and the metaverse through its advisory and educational services. Prysm Group areas of expertise include incentive design, monetization and governance for both open and closed blockchain and metaverse platforms.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade partner-modal"
                id="PartnerMMEModal"
                tabIndex="-1"
                aria-labelledby="PartnerMMEModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerMme
                                      : PartnerMme
                                  }
                                  alt="MME"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              MME is the Legal Partner for Layer One X, providing comprehensive and interdisciplinary advice in the areas of legal, tax and compliance.

                            </h4>
                            <p>
                              MME is an innovative business law and tax firm with offices in the two Swiss economic centres, Zurich and Zug.

                            </p>
                            <p>Legal | Tax | Compliance: In these three areas we offer comprehensive, interdisciplinary solutions with speed and efficiency. Our specialists tailor their advice to your needs in order to provide practical, cost-effective solutions that sustainably improve your business — whether in a national or an international environment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade partner-modal"
                id="PartnerUWAModal"
                tabIndex="-1"
                aria-labelledby="PartnerUWAModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerUWA
                                      : PartnerUWA
                                  }
                                  alt="UWA"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              The University of Western Australia (UWA) is the academic partner for  Layer One X.
                            </h4>
                            <p>
                              UWA is a leading Australian research university and has an international reputation for excellence, innovation and enterprise. A member of the Australian 'Group of Eight' research universities. Sitting on the banks of the Swan River, the UWA Perth campus is the oldest in Western Australia. UWA offers higher education in many areas, including medicine, architecture and law, in addition to a full Masters program for blockchain.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade partner-modal"
                id="PartnerL1XLabsModal"
                tabIndex="-1"
                aria-labelledby="PartnerL1XLabsModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="bg-card-bg">
                            <div className="bg-card d-flex justify-content-center align-items-center">
                              <figure>
                                <img
                                  src={
                                    this.props.themeEnabled === "light"
                                      ? LMPartnerLx
                                      : PartnerLx
                                  }
                                  alt="L1X_Labs"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content">
                            <h4>
                              L1X_LABS is the Development Partner for Layer One X. Working with businesses to build and innovate on the Layer One X blockchain protocol.

                            </h4>
                            <p>
                              At L1.X LABS, we empower businesses to innovate on our proprietary Layer One X blockchain protocol. With our help, clients can build blockchain solutions for business use cases where performance, scalability, security and sustainable growth are key requirements. Every business is unique. We work with you to provide a tailored solution that meets your specific needs.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-24">
                    Get access to funding and  <br />
                    start building.
                  </h2>
                  <p className="pb-40">
                    Create applications built on the foundation of interoperability between <br />
                    blockchain networks.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    {/* <a href="https://6qcds7xqh6e.typeform.com/l1xgrant" rel="noopener noreferrer" target="_blank" className="glow-button gradient-border-mask">
                      <span>Apply Now</span>
                    </a> */}
                    <a href="https://form.typeform.com/to/pBcXaPCK" rel="noopener noreferrer" target="_blank" className="glow-button gradient-border-mask">
                      <span>Apply Now</span>
                    </a>
                  </div>
                  <div className="btn-style btn-style-two">
                    <Link className="glow-button gradient-border-mask" to="../about-us/join-the-team">
                      <span>Join The Team</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
GrantApplications.propTypes = {
  theme: PropTypes.string,
};
