import React from "react";
import { ethers } from "ethers";
import { NumericFormat } from "react-number-format";
import detectEthereumProvider from "@metamask/detect-provider";
import CircularProgress from "../CircularProgress/index";

import {
  serviceCheckBalance,
  serviceSubmitProspectTransaction,
  serviceKycInvestmentLimit,
  serviceProspectTotalInvestment,
  serviceReconcileProspectTransaction,
} from "../../services/apiService";

import logger from "../../utils/logger";
import ChainService from "../../services/chainService";
import Notify from "../../utils/notify";
// import BuyTransactionHistory from "./buyTransactionHistory"
import ThankYouMessageModal from "./buySectionThankyouMessage";
import Config from "../../config";
import { DirectInvestmentInner } from "./directInvestment";

/** Smart contract abi files */
const PrivateSaleAbi = require("../../config/abi/private-sale-contract.json");
const ERC20Token = require("../../config/abi/ERC20Token.json");

/**Buy section currentInvestment and maxInvestment progress bar component */
const InvestmentProgressBar = (props) => {
  return (
    <>
      <h6 className="pb-18 mb-0">Current Investment / Maximum Investment</h6>
      {
        /*  props._isInvestmentStatsLoaded === false ?
          <h5 className="pb-18 mb-0">Loading ...</h5> :
          <h5 className="pb-18 mb-0">${props._totalInvestment} / ${props._maxInvestment}</h5> */

        <h5 className="pb-18 mb-0">
          ${props._totalInvestment} / ${props._maxInvestment}
        </h5>
      }

      <div className="progress">
        <div
          className="progress-bar"
          style={{
            width: `${(props._totalInvestment * 100) / props._maxInvestment}%`,
          }}
          aria-valuenow="65"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </>
  );
};

const BuySection = () => {
  const networkList = ChainService.getNetworks();
  const tokenList = ChainService.getTokens();
  // let selectedNetworkId = false;

  let baseStats = {
    currentInvestment: 0,
    maxInvestment: Config.maxInvestmentAmount,
    netowrkMaximumInvestment: { ETH: 0, MATIC: 0, AVAX: 0, BSC: 0 },
    networkMinimumInvestment: { ETH: 0, MATIC: 0, AVAX: 0, BSC: 0 },
  };

  /*  const baseFormValue = {
    amount: 0,
    networkName: "",
    tokenName: ""
  };

  const baseFormErrors = {
    amount: 0,
    networkName: "",
    tokenName: "",
    showErrors: false
  }; */

  const [selectedTab, setSelectedTab] = React.useState("buy");
  const [submitButtonStatus, setSubmitButtonStatus] = React.useState("Approve");

  const [investmentStats, setInvestStats] = React.useState(baseStats);

  const [isInvestmentStatsLoaded, setIsInvestmentStatsLoaded] = React.useState(
    false
  );
  const [isMetamaskInstalled, setIsMetamaskInstalled] = React.useState(false);

  const [detectedWalletAddress, setDetectedWalletAddress] = React.useState(
    false
  );

  const [preferredNetwork, setPreferredNetwork] = React.useState("");
  const [preferredAmount, setPreferredAmount] = React.useState(0);
  const [preferredToken, setPreferredToken] = React.useState("");
  const [preferredChainId, setPreferredChainId] = React.useState(false);

  const [preferredNetworkError, setPreferredNetworkError] = React.useState("");
  const [preferredAmountError, setPreferredAmountError] = React.useState("");
  const [preferredTokenError, setPreferredTokenError] = React.useState("");

  const [showFormError, setShowFormError] = React.useState(false);

  const [networkSwitchInProgress, setNetworkSwitchInProgress] = React.useState(
    false
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  //Thank you message modal variable
  const [visibleThankModal, setVisibleThankModal] = React.useState(false);

  React.useEffect(() => {
    // prospectTransactionHistory().then();

    // Check Metamask Installation
    if (checkMetamaskInstalled()) {
      setIsMetamaskInstalled(true);
    }

    // Get Default Wallet Address

    // Get Default ChainId
    if (checkMetamaskInstalled()) {
      let _defaultChainId = getCurrentMetamaskChainId();

      if (_defaultChainId !== false) {
        handleNetworkChange(_defaultChainId);
      }

      // Detect Metamask
      window.ethereum.on("chainChanged", (_newChainId) => {
        // setDetectedChainId(_newChainId);
        handleNetworkChange(_newChainId);
      });

      // On Account Change
      window.ethereum.on("accountsChanged", function(accounts) {
        setDetectedWalletAddress(accounts[0]);
      });
    }

    // Load Investment Stats
    loadInvestmentStats().then();
  }, []);

  async function loadInvestmentStats() {
    try {
      setIsInvestmentStatsLoaded(false);
      let _stats = await getInvestmentStats();
      setInvestStats(_stats);
      setIsInvestmentStatsLoaded(true);
    } catch (e) {
      setIsInvestmentStatsLoaded(true);
    }
  }

  async function handleNetworkChange(_newChainId) {
    let _selectedNetworkName = getNetworkName(_newChainId);

    //if change network request received multiple times for same chainId return false
    // if (_newChainId === preferredChainId) {
    //   return false;
    // }

    setPreferredChainId(_newChainId);
    logger("preferredChainId",preferredChainId)
    updatePreferredNetwork(_selectedNetworkName);

    if (_selectedNetworkName !== false) {
      let _defaultWalletAddress = await getCurrentMetamaskWalletAddress();
      if (_defaultWalletAddress !== false) {
        setDetectedWalletAddress(_defaultWalletAddress);

        // Load Balances
      }
    } else {
      // Ask User to Selected Allowed Network
      updatePreferredNetwork(false);
    }
  }

  // Investment Stats
  async function getInvestmentStats() {
    let _stats = baseStats;

    try {
      let _kycReponse = await serviceKycInvestmentLimit();
      if (_kycReponse.status === "success") {
        _stats.maxInvestment = _kycReponse.data.maximumInvestmentLimit;
      }

      let _totalInvestmentResponse = await serviceProspectTotalInvestment();
      if (_totalInvestmentResponse.status === "success") {
        _stats.currentInvestment =
          _totalInvestmentResponse.data.totalInvestment;
      }

      return {
        maxInvestment: _stats.maxInvestment,
        currentInvestment: _stats.currentInvestment,
        netowrkMaximumInvestment: {
          ETH: _kycReponse.data.netowrkMaximumInvestment.ETH,
          MATIC: _kycReponse.data.netowrkMaximumInvestment.MATIC,
          AVAX: _kycReponse.data.netowrkMaximumInvestment.AVAX,
          BSC: _kycReponse.data.netowrkMaximumInvestment.BSC,
        },
        networkMinimumInvestment: {
          ETH: _kycReponse.data.networkMinimumInvestment.ETH,
          MATIC: _kycReponse.data.networkMinimumInvestment.MATIC,
          AVAX: _kycReponse.data.networkMinimumInvestment.AVAX,
          BSC: _kycReponse.data.networkMinimumInvestment.BSC,
        },
      };
    } catch (e) {
      logger("exception", e);
      return _stats;
    }
  }

  function getNetworkName(_chainId) {
    let _networkName = ChainService.getNetworkById(_chainId);
    if (_networkName !== null) {
      return _networkName;
    } else {
      return false;
    }
  }

  async function updatePreferredNetwork(_newNetwork) {
    setNetworkSwitchInProgress(true);
    setPreferredNetwork("network-switch-ongoing");

    // Dont Switch Network
    if (_newNetwork === preferredNetwork) {
      return false;
    }

    if (_newNetwork !== null) {
      let _response = await requestMetasmaskNetworkChange(_newNetwork);

      setNetworkSwitchInProgress(false);
      if (_response === true) {
        setPreferredNetwork(_newNetwork);
      }
    }
  }

  async function requestMetasmaskNetworkChange(_networkName) {
    try {
      let _chainConfig = ChainService.getNetworkConfig(_networkName);
      if (_chainConfig === null) {
        logger(
          "error",
          "Error Occured while getting network config for " + _networkName
        );
        return false;
      }

      if (_networkName === "ETH") {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: _chainConfig.chainId,
            },
          ],
        });
      } else {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [_chainConfig],
        });
      }

      // redetect wallet address
      let _defaultWalletAddress = await getCurrentMetamaskWalletAddress();
      if (_defaultWalletAddress !== false) {
        setDetectedWalletAddress(_defaultWalletAddress);

        // Load Balances
      }

      return true;
    } catch (e) {
      logger("exception", e);
      return false;
    }
  }

  // Check if Metamask is installed
  function checkMetamaskInstalled() {
    if (!window.ethereum) {
      // Notify("Please install metamask extension", "error");
      return false;
    }

    return true;
  }

  function getCurrentMetamaskChainId() {
    try {
      let _defaultChainId = window.ethereum.networkVersion;
      return _defaultChainId;
    } catch (e) {
      logger("exception", e);
      return false;
    }
  }

  async function getCurrentMetamaskWalletAddress() {
    try {
      if (!checkMetamaskInstalled()) {
        Notify("Please install metamask");
        return false;
      }

      let _walletResponse = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      let _accountAddress = _walletResponse[0];
      let _networkName = ChainService.getNetworkById(
        window.ethereum.networkVersion
      );

      if (_networkName === false) {
        Notify("Network Selected, currently is not supported ", "error");
        return false;
      } else {
        return _accountAddress;
      }
    } catch (e) {
      // Notify("Unable to Access Metamask Wallet Address", "error")
      logger("Unable to Access Metamask Wallet Address", e);
      return false;
    }
  }

  function isValidSubmission() {
    let _isErrorFree = false;
    let _minInvestment = Config.minInvestmentAmount;
    let _maxInvestment = Config.maxInvestmentAmount;

    // Validation for Network
    if (preferredNetwork === "") {
      setPreferredNetworkError("Please select Network from the list");
      _isErrorFree = false;
    } else {
      setPreferredNetworkError("");
      _isErrorFree = true;
    }

    // Validation for Token
    if (preferredToken === "") {
      setPreferredTokenError("Please select Token from the list");
      _isErrorFree = false;
    } else {
      setPreferredTokenError("");
      _isErrorFree = true;
    }

    // Validation for Amount
    if (parseFloat(preferredAmount) <= 0) {
      setPreferredAmountError("Please enter amount");
      _isErrorFree = false;
    } else {
      // Validation for Min. Investment Amount
      if (_minInvestment !== 0 && _maxInvestment !== 0) {
        if (
          parseFloat(preferredAmount) < _minInvestment ||
          parseFloat(preferredAmount) > _maxInvestment
        ) {
          setPreferredAmountError(
            "Please enter amount between " +
              _minInvestment +
              " and " +
              _maxInvestment +
              " " +
              preferredToken
          );
          _isErrorFree = false;
        } else {
          setPreferredAmountError("");
          _isErrorFree = true;
        }
      }
    }

    if (_isErrorFree === false) {
      return false;
    } else {
      return true;
    }
  }

  async function processPurchase() {
    let validateForm = isValidSubmission();

    if (validateForm === false) {
      setShowFormError(true);
      return false;
    }

    let formValues = {
      tokenName: preferredToken,
      amount: preferredAmount,
      networkName: preferredNetwork,
    };

    await approveTransaction(formValues);
  }

  async function approveTransaction(formValues) {
    try {
      const { tokenName, amount, networkName } = { ...formValues };

      if (detectedWalletAddress === "" || detectedWalletAddress === false) {
        Notify("Please connect metamask wallet", "error");
        return false;
      }

      if (networkName === "network-switch-ongoing") {
        Notify("Please Switch Network on Metamask", "error");
        return false;
      }

      setIsSubmitting(true);
      setSubmitButtonStatus("Approving Your Tx");

      //Getting token Address
      const _tokenAddress = ChainService.getTokenAddress(
        networkName,
        tokenName
      );

      // Get Ether Signer
      const provider = await detectEthereumProvider();
      const tmpProvider = new ethers.providers.Web3Provider(provider);
      const signer = tmpProvider.getSigner();

      // Create Instance for Token
      const tokenContract = new ethers.Contract(
        _tokenAddress,
        ERC20Token.abi,
        tmpProvider
      );
      const tokenSigner = tokenContract.connect(signer);

      // Fetch Decimal for Selected Token
      const _tokenDecimals = await tokenSigner.decimals();
      const _srcAmountBN = ethers.utils.parseUnits(
        String(amount),
        _tokenDecimals
      );

      //Getting private sale address from network name
      const _privateSaleAddress = ChainService.getPrivateSaleAddress(
        networkName
      );

      // Check Balance
      const _balance = await serviceCheckBalance(
        detectedWalletAddress,
        networkName
      );
      if (_balance.status === "success") {
        // Convert into Bignumber
        let _balanceBN = ethers.BigNumber.from(
          String(_balance.data[tokenName.toLowerCase() + "Decimal"])
        );
        if (_balanceBN.lt(_srcAmountBN)) {
          Notify("Insufficient Balance", "error");
          setIsSubmitting(false);
          setSubmitButtonStatus("Approve");
          return false;
        }
      }

      // Check for allowance
      const _allowanceBN = await tokenSigner.allowance(
        detectedWalletAddress,
        _privateSaleAddress
      );

      // Greater than equal to
      if (_allowanceBN.gte(_srcAmountBN)) {
        await buyL1X(
          _privateSaleAddress,
          networkName,
          tokenName.toLowerCase(),
          _tokenAddress,
          _srcAmountBN
        );
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");
        return true;
      } else {
        const _approveData = await tokenSigner.approve(
          _privateSaleAddress,
          _srcAmountBN
        );
        const _approvalReceipt = await _approveData.wait();

        logger("_approvalReceipt", _approvalReceipt);
        Notify("Transaction approved successfully", "success");

        await buyL1X(
          _privateSaleAddress,
          networkName,
          tokenName.toLowerCase(),
          _tokenAddress,
          _srcAmountBN
        );
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        return true;
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: buySection.js:562 ~ approveTransaction ~ error:",
        error
      );

      logger("exception", error);

      if (error.message.includes("user rejected transaction")) {
        Notify("Transaction cancelled in Metamask by you ", "error");
      } else {
        Notify("Error Occured: " + error.message, "error");
      }

      setIsSubmitting(false);
      setSubmitButtonStatus("Approve");

      return false;
    }
  }

  async function buyL1X(
    _privateSaleAddress,
    _network,
    _token,
    _tokenAddress,
    _amountBN
  ) {
    try {
      setSubmitButtonStatus("Submit");

      const provider = await detectEthereumProvider();
      const tmpProvider = new ethers.providers.Web3Provider(provider);
      const signer = tmpProvider.getSigner();

      // Create Instance for Token
      // const tokenContract = new ethers.Contract(_tokenAddress, ERC20Token.abi, tmpProvider);
      // const tokenSigner = tokenContract.connect(signer);

      // Fetch Decimal for Selected Token
      // let _tokenDecimals = await tokenSigner.decimals();

      const privateSaleContract = new ethers.Contract(
        _privateSaleAddress,
        PrivateSaleAbi,
        tmpProvider
      );
      const privateSaleSigner = privateSaleContract.connect(signer);

      let _balance = await serviceCheckBalance(detectedWalletAddress, _network);

      if (_balance.status !== "success") {
        Notify("Error while fetching user balance", "error");
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        return false;
      }

      // Convert into Bignumber
      let _balanceBN = ethers.BigNumber.from(
        String(_balance.data[_token + "Decimal"])
      );

      if (_balanceBN.gte(_amountBN)) {
        setSubmitButtonStatus("Submitting Transaction");

        const _investResponse = await privateSaleSigner.invest(
          _balance.data.kyc_id,
          _amountBN,
          _tokenAddress
        );

        logger("_investResponse", _investResponse);

        // Notify("Transaction broadcast successfully", "success")

        let _submitTxData = {
          network: _network.toUpperCase(),
          wallet_address: detectedWalletAddress,
          token_address: _tokenAddress,
          token: _token.toUpperCase(),
          tx_hash: _investResponse.hash,
        };
        let _txSubmitResponse = await serviceSubmitProspectTransaction(
          _submitTxData
        );

        logger("_txSubmitResponse", _txSubmitResponse);

        if (_txSubmitResponse.status !== "success") {
          Notify("Error while submitting data", "error");
          setIsSubmitting(false);
          setSubmitButtonStatus("Approve");

          return false;
        }

        const _investReceipt = await _investResponse.wait();
        logger("_investReceipt", _investReceipt);

        let _reconcileTxData = {
          tx_hash: _submitTxData.tx_hash,
          network: _network.toUpperCase(),
        };

        serviceReconcileProspectTransaction(_reconcileTxData).then();

        // Notify("Transaction mine successfully", "success")

        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        setVisibleThankModal(true);

        // Update Stats

        loadInvestmentStats().then();

        // await prospectTransactionHistory();

        // Update Balances
        // let _tokenBalances = await getTokenBalances(detectedWalletAddress, formValues.networkName);
        // setTokenBalances(_tokenBalances);

        return true;
      } else {
        Notify("Insufficient Balance", "error");
        setVisibleThankModal(false);
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        return false;
      }
    } catch (e) {
      logger("exception", e);

      if (e.message.includes("user rejected transaction")) {
        Notify("Transaction cancelled in Metamask by you ", "error");
      } else {
        Notify("Error Occured: " + e.message, "error");
      }

      setVisibleThankModal(false);
      setIsSubmitting(false);
      return false;
    }
  }

  function handleTabs(_tab) {
    setSelectedTab(_tab);
  }

  return (
    <>
      <section className="banner-transparent">
        <div className="text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <div className="tab__btn__section">
                    <div className="tab__btn__box">
                      <button
                        onClick={() => handleTabs("buy")}
                        className={
                          selectedTab === "buy"
                            ? "btn btn__tab active"
                            : "btn btn__tab"
                        }
                      >
                        Buy Section
                      </button>
                    </div>
                    <div className="tab__btn__box">
                      <button
                        onClick={() => handleTabs("direct_investment")}
                        className={
                          selectedTab === "direct_investment"
                            ? "btn btn__tab active"
                            : "btn btn__tab"
                        }
                      >
                        Direct Investment
                      </button>
                    </div>
                  </div>

                  {selectedTab === "buy" ? (
                    <>
                      {/* <h3 className="pb-18 mb-0">Buy Section</h3> */}

                      {isMetamaskInstalled === false ? (
                        <p>
                          Please install Metamask first or <br />
                          Transfer directly with this{" "}
                          <span
                            style={{ color: "#0a58ca", cursor: "pointer" }}
                            onClick={() => handleTabs("direct_investment")}
                          >
                            link
                          </span>
                        </p>
                      ) : (
                        <>
                          <a
                            href="https://humanbotcomau-my.sharepoint.com/:v:/g/personal/kevin_humanbot_com_au/EV_WajMFPnRDmCCiO-Qt0SABNdOYX3A5Qo_SV5-I1q-02Q?e=wIQgYD"
                            className="btn btn-style-three m-0"
                            target={"_blank"}
                          >
                            <span>
                              Click this link if you want to access a demo Buy Video. 
                            </span>
                          </a>
                          <hr />
                          <InvestmentProgressBar
                            _isInvestmentStatsLoaded={isInvestmentStatsLoaded}
                            _totalInvestment={investmentStats.currentInvestment}
                            _maxInvestment={investmentStats.maxInvestment}
                          />

                          <span>{preferredNetwork}</span>
                          <div className="mb-3">
                            <label className="form-label">Select Network</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="network"
                              value={preferredNetwork}
                              onChange={(event) => {
                                updatePreferredNetwork(event.target.value);
                              }}
                              disabled={networkSwitchInProgress || isSubmitting}
                            >
                              <option key={"default-network"} value="">
                                Please Select Network
                              </option>

                              {networkSwitchInProgress ? (
                                <option
                                  key={"network-switch-ongoing"}
                                  value="network-switch-ongoing"
                                >
                                  Please Switch Network on Metamask
                                </option>
                              ) : (
                                ""
                              )}

                              {networkList.map((_network) => {
                                return (
                                  <option key={_network} value={_network}>
                                    {ChainService.getFormalNetworkName(
                                      _network
                                    )}
                                  </option>
                                );
                              })}
                            </select>
                            {showFormError && preferredNetworkError && (
                              <span className="error">
                                {preferredNetworkError}
                              </span>
                            )}
                          </div>

                          <div className="mb-3">
                            <label className="form-label">Select Token</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="token"
                              value={preferredToken}
                              disabled={isSubmitting}
                              onChange={(event) => {
                                setPreferredToken(event.target.value);
                              }}
                            >
                              <option key={"default-token"} value="">
                                Select Token
                              </option>
                              {tokenList.map((element) => (
                                <option key={element} value={element}>
                                  {element}
                                </option>
                              ))}
                            </select>
                            {showFormError && preferredTokenError && (
                              <span className="error">
                                {preferredTokenError}
                              </span>
                            )}
                          </div>

                          <div className="mb-3">
                            <label className="form-label">Enter Amount ({
                                "Min : " +
                                Config.minInvestmentAmount +
                                ", Max : " +
                                Config.maxInvestmentAmount.toLocaleString()
                              })</label>
                            <input
                              type="hidden"
                              name="amount"
                              value={preferredAmount}
                              className="form-control"
                              id=""
                            />
                            <NumericFormat
                              onValueChange={(values) => {
                                setPreferredAmount(values.value);
                              }}
                              thousandSeparator={true}
                              className="form-control"
                              disabled={isSubmitting}
                              placeholder=""
                              inputMode="numeric"
                            />

                            {showFormError && preferredAmountError && (
                              <span className="error">
                                {preferredAmountError}
                              </span>
                            )}
                          </div>

                          {/* Submit Button */}
                          {isSubmitting ? (
                            <button
                              disabled
                              onClick={() => logger("button", "Clicked")}
                              className="btn btn-primary buy-btn-loader btn__buy__section"
                              style={{ height: 70 }}
                            >
                              <span className="loader-span">
                                <CircularProgress /> {submitButtonStatus}
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={processPurchase}
                              className="btn btn-primary buy-btn-loader"
                              style={{ height: 70 }}
                            >
                              {submitButtonStatus}
                            </button>
                          )}
                          <hr />
                          <span
                            onClick={() => handleTabs("direct_investment")}
                            style={{ color: "#0a58ca", cursor: "pointer" }}
                          >
                            <span>
                              Having Issues? Directly Transfer to Us Here
                            </span>
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {selectedTab === "direct_investment" ? (
                    <DirectInvestmentInner
                      showHeading={false}
                    ></DirectInvestmentInner>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ThankYouMessageModal
        close={() => {
          setVisibleThankModal(false);
        }}
        show={visibleThankModal}
        header="Congratulations!"
        text={`You have successfully bought ${preferredAmount} ${preferredToken} and the transaction details have been emailed to you. Please check your junk mail if you havent received it or raise a ticket on discord.`}
      />
    </>
  );
};

export default BuySection;
