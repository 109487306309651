import React, { useState, useEffect } from "react";
import { serviceAddBankTxProcess, serviceGetBankTxDetails } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";
import deleteIcon from "../../images/delete.png"
const { ethers } = require('ethers');

const Prospects = ({ saleType }) => {

  const [walletAddress, setWalletAddress] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [authCodeBtnTxt, setAuthCodeBtnTxt] = useState('Reset');
  const [autoCodeFieldDisable, setAutoCodeFieldDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isClaimed, setClaimed] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [txDetails, setTxDetails] = useState("")
  const [amount, setAmount] = useState(0)
  const [txData, setTxData] = useState(false);

  // fuction get prospect details
  const getProspectDetails = async () => {
    try {
      setLoader(true);
      setClaimed(false)
      setTxData(false)

      if (keyword == "") {
        return Notify("Please enter transaction ref", "error");
      }

      let internalRef = keyword.split('"').join('');

      let _res = await serviceGetBankTxDetails(internalRef);

      if (_res.status === "success") {
        Notify("Transaction details fetched successfully", 'success')
        setTxData(true)
        setTxDetails(_res.data);
        setKeyword(_res.data.internal_ref);
      } else {
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching transaction details ,please try agian",
        "error"
      );
    }



  }

  const addWalletAddress = async () => {
    try {
      setLoader(true);

      // const isValid = ethers.utils.isAddress(walletAddress);

      // if (!isValid) {
      //   return Notify("Please enter valid wallet address", 'error')
      // }

      if (amount == "") {
        return Notify("Please enter amount", 'error')
      }

      let data = {
        internal_ref: keyword.split('"').join(''),
        //wallet_address: walletAddress,
        amount_in_usd: amount,
        auth_code: authCode
      }
      let _res = await serviceAddBankTxProcess(data);

      if (_res.status === "success") {
        Notify("Wallet address added successfully", 'success')
        setClaimed(false)
        setTxData(false)
        setAmount(0)
        setWalletAddress("")
        //getProspectDetails();

      } else {
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };

  useEffect(() => {

    let _authCode = localStorage.getItem('premium_wallet_auth_code')
    if (_authCode !== null) {
      setAuthCode(_authCode)
    }

  }, []);

  function resetAuthCode() {

    if (authCodeBtnTxt === 'Save') {
      setAutoCodeFieldDisable(true)
      setAuthCode(authCode)
      setAuthCodeBtnTxt('Reset')
      window.localStorage.setItem("premium_wallet_auth_code", authCode)
    }
    else {
      setAutoCodeFieldDisable(false)
      setAuthCode('')
      setAuthCodeBtnTxt('Save')
    }

  }

  const handleClaim = () => {
    setClaimed(true)
  }

  // Only Allow 4 digits number
  const handleNumberValidation = (evt) => {

    let theEvent = evt || window.event;
    // const currentValue = theEvent.target.value;
    const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Tab", "."];

    //const regex = /^\d$/;
    const regex = /^\d+(\.\d+)?$/;

    if (
      !regex.test(theEvent.key) &&
      !allowedKeys.includes(theEvent.key)
    ) {
      theEvent.preventDefault();
    }
  }

  return (
    <div className="row premium_wallet_list_card">
      <div className="col-md-12">
        <label className="premium_wallet_title">Bank Transfer Reconciliation</label>

        <div className="add_premium_wallet_container">

          <div className="col-md-5">
            <div className="add_premium_wallet_form">
              <div className="premium_wallet_form_box">
                <label className="premium_wallet_add_form_label">Internal Ref</label>
                <input
                  name="keyword"
                  value={keyword}
                  onChange={(event) => setKeyword(event.target.value)}
                  className="form-control"
                  placeholder="Search by internal ref"
                />
              </div>
              <span className="add_icon_span">
                <button onClick={() => getProspectDetails()} className="add_premimun_wallet_btn"> Search</button>
              </span>
            </div>
          </div>

          <div className="col-md-2" />
          <div className="col-md-5">
            <div className="add_premium_wallet_form">
              <div className="premium_wallet_form_auth_box">
                <label className="premium_wallet_add_form_label">Authorization code</label>
                <input
                  disabled={autoCodeFieldDisable}
                  name="authCode"
                  value={authCode}
                  onChange={(event) => setAuthCode(event.target.value)}
                  className="form-control"
                  placeholder="Enter authorization code *"
                />
              </div>

              <span className="add_icon_span">
                <button onClick={() => resetAuthCode()} className="add_premimun_wallet_btn"> {authCodeBtnTxt}</button>
              </span>
            </div>
          </div>
        </div>
        {isClaimed ?
          <div className="add_premium_wallet_container">
            <div className="col-md-5">
              <div className="add_premium_wallet_form">
                <div className="premium_wallet_form_box">
                  {/* <label className="premium_wallet_add_form_label">Investor Wallet Address</label>
                  <input
                    name="walletAddress"
                    value={walletAddress}
                    onChange={(event) => setWalletAddress(event.target.value)}
                    className="form-control"
                    placeholder="Wallet address *"
                  /> */}
                  <label className="premium_wallet_add_form_label">Amount</label>
                  <input
                    name="amount"
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                    className="form-control"
                    placeholder="Amount *"
                    onKeyDown={handleNumberValidation}
                  />
                  <div>
                    <span className="add_icon_span">
                      <button onClick={() => addWalletAddress()} className="add_premimun_wallet_btn"> Submit</button>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div> : ""
        }

        {txData ?
          // txDetails ?
          <div>
            <div className="premium_wallet_card_title">
              Sale Details
            </div>
            <div className="container">
              <div className="table-responsive">
                <table className="table table-striped sale-details-table">
                  <thead>
                    <tr>
                      <th scope="col" width="30%">Fields</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>{txDetails.email ? txDetails.email : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Document Link</td>
                      <td>{txDetails.document_link ? txDetails.document_link : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Transaction Ref</td>
                      <td>{txDetails.transaction_ref ? txDetails.transaction_ref : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Internal Ref</td>
                      <td>{txDetails.internal_ref ? txDetails.internal_ref : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Conversion Rate</td>
                      <td>{txDetails.conversion_rate ? txDetails.conversion_rate : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Final Amount</td>
                      <td>{txDetails.final_amount ? txDetails.final_amount : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Token Allocated</td>
                      <td>{txDetails.tokens_alloted ? txDetails.tokens_alloted : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Wallet Address</td>
                      <td>{txDetails.wallet_address ? txDetails.wallet_address : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Transaction Date</td>
                      <td>{txDetails.created_at ? txDetails.created_at : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Claim Status</td>
                      <td>
                        {
                          txDetails.is_claimed == 0 ?
                            <button onClick={handleClaim} className="btn btn-pending" >Click here to claim</button> : <p>Claimed</p>
                        }

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> : ""
        }


        <div>

        </div>

      </div>
    </div>
  );
};

export default Prospects;
