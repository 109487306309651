import React, { useEffect, useState } from "react"

import closeIcon from "../../images/close-menu-lm.svg";
import { serviceGetCheckProspectKyc, serviceGetPublicSaleProspectExits } from "../../services/apiService";
import Notify from "../../utils/notify";

import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import ContributeOne from "../../images/contribute-1.svg";
import ContributeTwo from "../../images/contribute-2.svg";
import ContributeThree from "../../images/contribute-3.svg";
import ContributeFour from "../../images/contribute-4.svg";

const PublicSaleHowToContribute = (props) => {

    const [email, setEmail] = useState(null);

    useEffect(() => {
        const _email = localStorage.getItem('email')
        setEmail(_email);

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                // window.location.reload();
            });
        }
    }, [])

    // Check if Metamask is installed
    function checkMetamaskConnection() {
        if (!window.ethereum) {
            window.location.href = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-GB';
            return false;
        }
        return handleConnectMetamask()
    }

    const handleConnectMetamask = async () => {
        try {
            // Detect if MetaMask is installed
            const provider = await detectEthereumProvider();

            if (provider) {

                await window.ethereum.enable();
                // window.ethereum.on("accountsChanged", handleAccountsChanged);

                // Request access to the user's MetaMask accounts
                await provider.request({ method: 'eth_requestAccounts' });

                // Create an ethers.js provider using MetaMask provider
                const ethersProvider = new ethers.providers.Web3Provider(provider);

                // Get the signer (account) from ethers.js provider
                const signer = ethersProvider.getSigner();

                // You can now use the signer to interact with the Ethereum network
                // For example, you can get the connected account address
                const connectedAddress = await signer.getAddress();

                return connectedAddress
            } else {
                console.error('MetaMask not found');
                return false
            }
        } catch (error) {
            console.error('Error connecting to MetaMask:', error);
            return false
        }
    };



    async function handleMetmaskConnection() {
        props.updateUserPaymentType('ProccedByMetamask')
        if (email == null) {
            props.handleUserSteps('publicSaleValidateCustomer')
        } else {
            //props.handleUserSteps('publicSaleDirectInvestment')
            props.handleUserSteps('publicSaleStep')
        }

        //let _walletAddress = await checkMetamaskConnection();

        //if (_walletAddress) {

        // let _email = localStorage.getItem('email');
        // let postEmail = _email.split('"').join('');

        // let _checkIsExistEmail = await serviceGetPublicSaleProspectExits(postEmail);

        // if (_checkIsExistEmail.status == 'failure') {
        //     Notify(_checkIsExistEmail.message, "error")
        //     props.handleUserSteps('publicSaleRegistrationForm')
        //     return
        // }

        // let checkProspectKycRes = await serviceGetCheckProspectKyc(_walletAddress)

        // if (checkProspectKycRes.status === 'success') {
        //     props.handleUserSteps('publicSaleStep')
        // } else {
        //     Notify(checkProspectKycRes.message, "error");
        //     props.handleUserSteps('publicSaleRegistrationForm')
        // }
        //}
    }

    // Direct Diposite
    const handleDirectDeposite = async () => {
        props.updateUserPaymentType('DirectPayment')
        if (email == null) {
            props.handleUserSteps('publicSaleValidateCustomer')
        } else {
            //props.handleUserSteps('publicSaleDirectInvestment')
            props.handleUserSteps('publicSaleStepDirectTransfer')
            props.updatePaymentTransactionType('1');
        }

    }

    // Debit Card
    const handleDebitCard = async () => {
        props.updateUserPaymentType('DebitCard')
        if (email == null) {
            props.handleUserSteps('publicSaleValidateCustomer')
        } else {
            props.handleUserSteps('publicSaleMoonPaymentGateway')
        }
    }

    // Bank Transfer
    const handleBankTransfer = async () => {
        props.updateUserPaymentType('BankTransfer')
        console.log("🚀 ~ file: publicSaleHowToContribute.js:136 ~ handleBankTransfer ~ props:", props)

        if (props.isUserFromRegistationForm) {
            if (window.hj) {
                window.hj('vpv', '/public-sale');
            }

            props.handleUserSteps('publicSaleBankTransfer')
            return
        }

        if (window.hj) {
            window.hj('vpv', '/public-sale');
        }

        props.handleUserSteps('publicSaleValidateCustomer')
    }

    return (
        <div className="banner__fixed__card__box banner__fixed__width">
            <div>
                <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div>

                <div className="public_sale_early_access_heading">
                    How to Contribute
                </div>

                <div className="public_sale_early_access_txt">
                    We've made it easy for you to contribute to the public sale.
                </div>
            </div>

            <div className="public_sale_check_eligibility_box public_sale_box_extended">
                <button
                    onClick={handleBankTransfer}
                    className="public_sale_how_to_contribute"
                ><img src={ContributeOne} alt="Contribute" />
                </button>
                <button
                    onClick={handleDebitCard}
                    className="public_sale_how_to_contribute"
                >
                    <img src={ContributeTwo} alt="Debit-Card" />
                </button>
                <button
                    onClick={handleMetmaskConnection}
                    className="public_sale_how_to_contribute"
                >
                    <img src={ContributeThree} alt="Connect-Metamask" />
                </button>
                <button
                    onClick={handleDirectDeposite}
                    className="public_sale_how_to_contribute"
                >
                    <img src={ContributeFour} alt="Transfer-Crypto" />
                </button>
            </div>
        </div>
    )
}

export default PublicSaleHowToContribute