// import "./styles.css";
import Confetti from "react-confetti";
import React, { useState, useRef, useEffect } from "react";

export default function DeclaredWinner(props) {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const confetiRef = useRef(null);

  useEffect(() => {
    setHeight(confetiRef.current.clientHeight);
    setWidth(confetiRef.current.clientWidth);
  }, []);

  return (
    <div className="winner-container container-fluid " >
      
      <div className="confettie-wrap" ref={confetiRef}>
        <h3 className="winner-title">Congratulations! And the lucky winner is </h3>
        <h5 className="winner-address">{props.userAddress}</h5>
        <Confetti numberOfPieces={150} width={width} height={height} />
      </div>
    </div>
  );
}
