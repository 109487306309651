import React, { useEffect, useState } from "react"
import validator from "validator";
import CountryCode from "../../config/countryCode";

import Logo from "../../images/logol1x-icon.svg";
import { servicePostProspectKYC } from "../../services/apiService";
import Notify from "../../utils/notify";

import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import CircularProgress from "../CircularProgress/index";
import StorageService from "../../services/storageService";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const PublicSaleKycForm = (props) => {

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [country, setCountry] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitStatus, setsubmitStatus] = useState(false);


    useEffect(() => {

        let parseContactData = StorageService.getData('PREFILLED_USER_DETAILS');

        // let _email = StorageService.getData('email');
        // if (_email !== null && typeof _email !== 'undefined') {
        //     let postEmail = _email.split('"').join('');
        //     setEmailAddress(postEmail)
        // }

        if (parseContactData) {

            setFirstName(parseContactData.firstName)
            setLastName(parseContactData.lastName)
            setEmailAddress(parseContactData.email)
            setReferralCode(parseContactData.referralCode)
            setCountry(parseContactData.country)
        }



    }, [])
    // Check if Metamask is installed
    function checkMetamaskInstallation() {
        console.log("window.ethereum", window.ethereum)
        if (!window.ethereum) {
            return false;
        }

        return handleConnectMetamask()
    }

    const handleConnectMetamask = async () => {
        try {
            // Detect if MetaMask is installed
            const provider = await detectEthereumProvider();

            if (provider) {
                await window.ethereum.enable();

                // Request access to the user's MetaMask accounts
                await provider.request({ method: 'eth_requestAccounts' });

                // Create an ethers.js provider using MetaMask provider
                const ethersProvider = new ethers.providers.Web3Provider(provider);

                // Get the signer (account) from ethers.js provider
                const signer = ethersProvider.getSigner();

                // You can now use the signer to interact with the Ethereum network
                // For example, you can get the connected account address
                const connectedAddress = await signer.getAddress();

                return connectedAddress
            } else {
                console.error('MetaMask not found');
                return false
            }
        } catch (error) {

            console.error('Error connecting to MetaMask:', error);
            return false

        }
    };

    const handleCharValidation = (evt) => {
        let theEvent = evt || window.event;
        const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Tab"];
        const regex = /^[A-Za-z.\s]+$/;

        if (
            !regex.test(theEvent.key) &&
            !allowedKeys.includes(theEvent.key)
        ) {
            theEvent.preventDefault();
        }
    }

    // Only Allow 4 digits number
    const handleNumberValidation = (evt) => {
        let theEvent = evt || window.event;
        // const currentValue = theEvent.target.value;
        const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Tab"];

        const regex = /^\d$/;

        if (
            !regex.test(theEvent.key) &&
            !allowedKeys.includes(theEvent.key)
        ) {
            theEvent.preventDefault();
        }
    }

    const processKYC = async () => {
        let errors = {};

        let _metamaskAddress = await checkMetamaskInstallation()
        if (!_metamaskAddress) {
            return Notify('Please connect metamask', 'error')
        }

        if (validator.isEmpty(_metamaskAddress)) {
            return Notify("Please connect metamask wallet", 'error');
        }
        if (validator.isEmpty(emailAddress)) {
            errors.emailAddress = "Please enter email address";
        }
        else if (!validator.isEmail(emailAddress)) {
            errors.emailAddress = "Please enter valid email address";
        }

        if (validator.isEmpty(firstName)) {
            errors.firstName = "Please enter first name";
        }

        if (validator.isEmpty(lastName)) {
            errors.lastName = "Please enter last name";
        }

        if (validator.isEmpty(birthYear)) {
            errors.birthYear = "Please enter your birth year";
        }
        // else  if (!validator.isDecimal(parseInt(birthYear))) {
        //     errors.birthYear = "Please enter valid birth year";
        // }
        // else  if (birthYear.length <=4) {
        //     errors.birthYear = "Please enter valid birth year";
        // }

        if (validator.isEmpty(country)) {
            errors.country = "Please select country";
        }


        setErrors(errors)

        if (Object.keys(errors).length === 0) {

            setsubmitStatus(true)
            let data = {
                email: emailAddress.toLocaleLowerCase(),
                firstName: capitalizeFirstLetter(firstName),
                middleName: middleName !== '' ? capitalizeFirstLetter(middleName) : middleName,
                lastName: capitalizeFirstLetter(lastName),
                birthYear: birthYear,
                country: country,
                walletAddress: _metamaskAddress,
                referralCode: referralCode
            }
            let _txSubmitResponse = await servicePostProspectKYC(data);

            if (_txSubmitResponse.status === "success") {

                // Remove Localstorage 
                // StorageService.removeData('PREFILLED_USER_DETAILS');

                setsubmitStatus(false)
                props.handleUpdateCurrentStep(3)
                if (props.paymentType == "DirectPayment" && props.paymentType != "") {
                    props.handleUserSteps('publicSaleStepDirectTransfer')
                } else {
                    props.handleUserSteps('publicSaleStep')
                }

                return
            }
            else {
                Notify("Error while submitting prospect data", "error");
                setsubmitStatus(false)
                return
            }
        }
        return errors;
    }

    // const handleLearnMoreClick = async () => {
    //     props.handleLearnMore()
    // }

    const handleGoBack = () => {
        if (props.paymentType == "DirectPayment" && props.paymentType != "") {
            props.handleUserSteps('publicSaleStepDirectTransfer')
        } else {
            props.handleUserSteps('publicSaleStep')
        }
    }

    return (
        <div className="banner__fixed__card__box public_sale_kyc_form">
            <div className="banner__fixed__card__head">
                <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
            </div>

            <div className="fixed__card__main__content">
                <div className="tab__btn__section">
                    <div className="tab__btn__box">
                        <h4 className="public_sale_card_heading">KYC Section</h4>
                        <p><small>Details entered once cannot be changed</small></p>
                    </div>
                </div>

                <div>
                    <div className="mb-3">
                        <input
                            name="emailAddress"
                            value={emailAddress}
                            onChange={(event) => setEmailAddress(event.target.value.toLocaleLowerCase())}
                            className="form-control public_sale_input"
                            placeholder="Email address *"
                        />
                        {errors && errors.emailAddress && (
                            <span className="error">{errors.emailAddress}</span>
                        )}
                    </div>

                    <div className="mb-3">
                        <input
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={(event) => setFirstName(capitalizeFirstLetter(event.target.value))}
                            className="form-control public_sale_input"
                            id=""
                            placeholder="First Name *"
                            onKeyDown={handleCharValidation}
                        />
                        {errors && errors.firstName && (
                            <span className="error">{errors.firstName}</span>
                        )}
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            name="middleName"
                            value={middleName}
                            onChange={(event) => setMiddleName(capitalizeFirstLetter(event.target.value))}
                            className="form-control public_sale_input"
                            id=""
                            placeholder="Middle Name"
                            onKeyDown={handleCharValidation}
                        />
                        {errors && errors.middleName && (
                            <span className="error">{errors.middleName}</span>
                        )}
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={(event) => setLastName(capitalizeFirstLetter(event.target.value))}
                            className="form-control public_sale_input"
                            id=""
                            placeholder="Last Name *"
                            onKeyDown={handleCharValidation}
                        />
                        {errors && errors.lastName && (
                            <span className="error">{errors.lastName}</span>
                        )}
                    </div>

                    <div className="mb-3">
                        <input
                            type="text"
                            name="birthYear"
                            value={birthYear}
                            onChange={(event) => {
                                // if (birthYear.length < 4) {
                                setBirthYear(event.target.value)
                                // }
                            }}
                            className="form-control public_sale_input"
                            id=""
                            placeholder=" Birth Year (Example: 1990) *"
                            onKeyDown={handleNumberValidation}
                        />
                        {errors && errors.birthYear && (
                            <span className="error">{errors.birthYear}</span>
                        )}
                    </div>

                    <div className="mb-3">
                        <select
                            className="form-select public_sale_input"
                            aria-label="Default select example"
                            name="country"
                            value={country}
                            onChange={(event) => setCountry(event.target.value)}
                        >
                            <option key={"default-country"} value="">
                                Select Country
                            </option>
                            {
                                CountryCode.COUNTRIES.map((_country) => {
                                    return <option key={_country.name + _country.code} value={_country.code}>{_country.name}</option>
                                })
                            }
                        </select>
                        {errors && errors.country && (
                            <span className="error">{errors.country}</span>
                        )}
                    </div>

                    <div className="mb-3">
                        <input
                            type="text"
                            name="referralCode"
                            value={referralCode}
                            onChange={(event) => setReferralCode(event.target.value)}
                            className="form-control public_sale_input"
                            id=""
                            placeholder="Referral Code"
                        // onKeyDown={handleCharValidation}
                        />
                    </div>
                    {/* <div  className="fixed__card__btn__box">
                        <button onClick={() => { handleButtonClick() }} disabled={submitStatus} className="fixed__card__btn public_sale_register_btn">{submitStatus ? "Submiting ..." : "Submit"}</button>
                    </div> */}

                    {/* Submit Button */}
                    {submitStatus ? (
                        <button
                            disabled
                            className="btn btn-primary buy-btn-loader"

                        >
                            <span className="loader-span">
                                <CircularProgress /> Submiting ...
                            </span>
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={processKYC}
                            className="btn btn-primary buy-btn-loader"

                        >
                            Submit
                        </button>
                    )}

                    <button disabled={submitStatus} onClick={() => {
                        props.handleUpdateCurrentStep(2)
                        //props.handleUserSteps('publicSaleStep')
                        handleGoBack()
                    }} className="reciept__details__goBack__btn">Go Back</button>

                </div>
            </div>
        </div>
    )
}

export default PublicSaleKycForm