/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo.svg";
import BrandGuide from "../images/L1X_Brand_Guide.pdf";
import Logohover from "../images/logohover.svg";
import Logotext from "../images/logotext.png";
import LMLogotext from "../images/logotext-lm.png";
// import Menuicon from "../images/hamburger-menu.svg";
// import LMMenuicon from "../images/hamburger-menu-lm.svg";
// import Menuclose from "../images/close-menu.svg";
// import LMMenuclose from "../images/close-menu-lm.svg";
// import Preico from "../images/icon-pre-ico.svg";
// import LMPreico from "../images/icon-pre-ico-lm.svg";
import LMtoggle from "../images/dm-toggle.svg";
import DMtoggle from "../images/lm-toggle.svg";

import PropTypes from "prop-types";
import Config from "../config";
import { animateScroll as scroll } from "react-scroll";

export class HeaderNew extends React.Component {
  // scrollToSection = () => {
  //   scroll.scrollTo('poweredBy', {
  //     duration: 800,
  //     delay: 0,
  //     smooth: 'easeInOutQuart'
  //   });
  // };

  render() {
    return (
      <header className="desktop-header desktop-header-new">
        <div className="topbar d-flex  align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                {/* <p className="d-flex align-items-center">
                Private Sale Commitments 
                  <span className="progress">
                    <span className="progress-bar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></span>
                  </span>
                  
                  USDT: $6.5M / $10M
                </p> */}
              </div>
              <div className="col-sm-5 d-none d-lg-block">
                <div className="topnav d-flex justify-content-end">
                  <ul>
                    <li>
                      <a
                        href="https://docsend.com/view/4kbzzm4kvsq7xuv6 "
                        target={"_blank"}
                      >
                        View Litepaper
                        <i className="externamIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>

                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docsend.com/view/yxxumg97x5mzv77v"
                        target={"_blank"}
                      >
                        View Whitepaper
                        <i className="externamIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>

                        </i>
                      </a>
                    </li>
                    {/* <li>
                          <NavLink className={({isActive}) => (isActive ? "active" : '')} to='faqs'>FAQs</Link>
                    </li> */}
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="about-us/join-the-team"
                      >
                        Join The Team
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg" id="navbar_top">
          <div className="container">
            <Link exact="true" to="" className="navbar-brand d-flex">
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img
                src={
                  this.props.themeEnabled === "light" ? LMLogotext : Logotext
                }
                alt="Logo"
                width="82"
              />
            </Link>

            <div className="btn-style d-md-block d-lg-none d-xl-none d-xxl-none ml-auto mr-20">
              <Link
                to="public-sale"
                className="glow-button gradient-border-mask "
              >
                {/* <span>Buy L1X</span> */}
                <span>{Config.publicSaleButtonLabel}</span>
              </Link>
            </div>

            <button
              className="btn btn-primary navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span className="">
                <i className="bi bi-list"></i>
              </span>
            </button>

            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <div id="offcanvasRightLabel">
                  <Link to="" className="">
                    {" "}
                  </Link>
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    className="navbar-brand"
                  >
                    <img src={Logo} width="130" alt="Layer One X" />
                  </a>
                </div>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Developers
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://dev-portal.l1xapp.com/"
                            target="_blank"
                          >
                            Dev Portal
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://docsend.com/view/yxxumg97x5mzv77v"
                            target="_blank"
                          >
                            Whitepaper
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://docsend.com/view/4kbzzm4kvsq7xuv6"
                            target="_blank"
                          >
                            Lightpaper
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/ecosystem"
                            target="_blank"
                          >
                            Grants program
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        L1X App
                      </a>
                      <ul className="dropdown-menu ">
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com"
                            target="_blank"
                          >
                            Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/swap"
                            target="_blank"
                          >
                            Swap
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/staking"
                            target="_blank"
                          >
                            Stake
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/balancer-pool-v3"
                            target="_blank"
                          >
                            Balancer Pool
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/feed"
                            target="_blank"
                          >
                            Feed
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Ecosystem
                      </a>
                      <ul className="dropdown-menu ">
                      <li>
                          <a
                            className="dropdown-item"
                            href="https://projects.l1x.foundation/"
                            smooth={true}
                            duration={800}
                            target="_blank"
                          >
                            Projects
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#poweredByNetwork"
                            smooth={true}
                            duration={800}
                            // onClick={this.scrollToSection}
                          >
                            Partners
                          </a>
                        </li>
                        
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/swap/aboutNodeNFT#faq"
                            target="_blank"
                          >
                            Become a Validator
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/explorer"
                            target="_blank"
                          >
                            L1X Explorer
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://chromewebstore.google.com/detail/xwallet/pofheakpngfbdfeidiippmmckgpdceoh"
                            target="_blank"
                          >
                            XWallet
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Community
                      </a>
                      <ul className="dropdown-menu ">
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://l1xapp.com/account/assistance "
                            target="_blank"
                          >
                            Support
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://blog.l1x.foundation/ "
                            target="_blank"
                          >
                            Blog
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to={BrandGuide}
                            target="_blank"
                          >
                            Brand Guidelines
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://twitter.com/LayerOneX "
                            target="_blank"
                          >
                            Twitter
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://discord.com/invite/LayerOneX "
                            target="_blank"
                          >
                            Discord
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://t.me/Layer1X "
                            target="_blank"
                          >
                            Telegram
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "dropdown-item " : "dropdown-item"
                            }
                            to="https://twitter.com/L1X_XTalks "
                            target="_blank"
                          >
                            XTalks AMA Series
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="https://l1xapp.com/home" target="_blank" className="common-btn-style btn-orange" style={{minWidth: 'auto'}}>Launch App</a>
                    </li>
                  </ul>

                  {/* <div className="dark-lightmode-toggle"></div> */}

                  <div className="switch dark-lightmode-toggle d-none">
                    {/* {theme.themeEnabled}
                    {this.props.toggleTheme} */}
                    <button onClick={this.props.toggleTheme}>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMtoggle
                            : DMtoggle
                        }
                        alt="Logo"
                      />
                    </button>
                  </div>

                  <div className="d-md-block d-lg-none d-xl-none d-xxl-none">
                    <div className="topnav">
                      <ul>
                        <li>
                          <a
                            href="https://docsend.com/view/4kbzzm4kvsq7xuv6 "
                            target={"_blank"}
                          >
                            View Litepaper
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://docsend.com/view/yxxumg97x5mzv77v "
                            target={"_blank"}
                          >
                            View Whitepaper
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        {/* <li>
                          <NavLink className={({isActive}) => (isActive ? "active" : '')} to='faqs'>FAQs</Link>
                        </li> */}
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                            to="about-us/join-the-team"
                          >
                            Join The Team
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
HeaderNew.propTypes = {
  theme: PropTypes.string,
};
HeaderNew.contextTypes = {
  router: PropTypes.object,
};
