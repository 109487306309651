/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import "../styels.css";
import "../responsive.css";
import globeImg from "./../images/homeNew/Earth_Nodes.jpg";
import GlobeDiff from "./../images/homeNew/globeDiff.png";

import globeVideo from "./../images/homeNew/globeVideo.mp4";
import xTalkImage from "./../images/homeNew/x-talk.png";
import xTalkImageTwo from "./../images/homeNew/x-talk2.png";
import InteroperabilityImage from "./../images/homeNew/interoperability.png";
import l1xLogo from "./../images/homeNew/l1x-logo.svg";
import crossChain from "./../images/homeNew/cross-chaine.svg";
import reliableOracle from "./../images/homeNew/reliable-oracles.svg";
import crossChainMessage from "./../images/homeNew/crosschaine-messaging.svg";
import HashLock from "./../images/homeNew/hashlock.png";
import envNonEvm from "./../images/homeNew/emv-non-emv.svg";
import CrossChainConnectivityImg from "./../images/homeNew/crossChain-connectivity.png";
import fastIcon from "./../images/homeNew/fast.png";
import scalableIcon from "./../images/homeNew/scalable.png";
import effertlessIcon from "./../images/homeNew/effortlessIntegration.png";
import checkIcon from "./../images/homeNew/checkicon.svg";
import web3DevelopmentPortal from "./../images/homeNew/web3DevelopmentPortal-img.png";
import { Link } from "react-router-dom";
import externalLinkIcon from "./../images/homeNew/external-link.svg";
import swapMobile from "./../images/homeNew/swap-mobile.png";

import TelegramIcon from "./../images/homeNew/telegram-logo.svg";
import TwitterIcon from "./../images/homeNew/twitter-logo.svg";
import DiscordIcon from "./../images/homeNew/discord-logo.svg";
import YoutubeIcon from "./../images/homeNew/youtube-logo.svg";

import amchainIcon from "./../images/homeNew/amchain.png";
import healthLinkIcon from "./../images/homeNew/healthlinkk.png";
import digaLabsIcon from "./../images/homeNew/digalab.png";
import l1dexIcon from "./../images/homeNew/l1xlabs.png";
import poolPartyIcon from "./../images/homeNew/poolparty.png";
import ORedCurryIcon from "./../images/homeNew/o-red-curry.png";

import prysmLogo from "./../images/homeNew/prysm-logo.png";
import westernAustraliaLogo from "./../images/homeNew/western-australia-logo.png";
import mmeLogo from "./../images/homeNew/mme-logo.png";
import copyButton from "./../images/homeNew/copy-icon.svg";


import GetBestDealsIcon from "./../images/homeNew/get-the-best-deals-icon.svg";
import LowestGasFeesIcon from "./../images/homeNew/lowest-gas-fees-icon.svg";
import MaximizeSavingIcon from "./../images/homeNew/maximize-your-savings-icon.svg";
import PrizePoolImg from "./../images/homeNew/prize-pool-img.svg";
import DollerMicon from "./../images/homeNew/doller-m-icon.svg";
import ReferEarnImg from "./../images/homeNew/refer-earn-img.svg";



import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

import copyToClipboard from "copy-to-clipboard";

const codeString = `function _l1xSend(address recipientAddress, uint256 amount, address tokenAddress, string memory network)
  external {
    RegisterToken memory myMessage = RegisterToken({ recipientAddress: recipientAddress, tokenAddress: tokenAddress, amount: amount });
    // Convert the struct to bytes
    bytes memory messageBytes = abi.encode(myMessage);
    IERC20(tokenAddress).transfer(recipientAddress, amount);
    emit XTalkMessageInitiated(messageBytes, network, address(this));
}`;

const codeString1 = `function _l1xSend(address recipientAddress, uint256 amount, address tokenAddress, string memory network)
  external {
    function _l1xSend( string memory message, address destinationSmartContractAddress, string memory destinationNetwork )
    external { XCDPSendMessage memory myMessage = XCDPSendMessage({ message: message });
    // Convert the struct to bytes bytes memory 
    messageBytes = abi.encode(myMessage); 
    emit XTalkMessageInitiated(messageBytes, destinationNetwork, destinationSmartContractAddress); } 
    function _l1xReceive(bytes32 globalTxId, bytes memory message) external 
    { XCDPReceiveMessage memory XCDPReceiveMessageData; 
      (XCDPReceiveMessageData.message) = abi.decode(message, (string));
       XCDPData[globalTxId] = XCDPReceiveMessageData; }
}`;
const codeString2 = `function _l1xSend(address senderAddress, address recipientAddress,
  address sourceTokenAddress, address destinationTokenAddress,
  uint256 sourceAmount, uint256 destinationAmount,
  address destinationSmartContractAddress,
  string memory destinationNetwork) external {
SwapInitiated memory myMessage = SwapInitiated({
senderAddress : senderAddress,
recipientAddress : recipientAddress,
sourceAmount : sourceAmount,
destinationAmount : destinationAmount,
sourceTokenAddress : sourceTokenAddress,
destinationTokenAddress : destinationTokenAddress
});  // Convert the struct to bytes bytes memory messageBytes =
// abi.encode(myMessage);
// IERC20(sourceTokenAddress).safeTransferFrom(senderAddress,address(this),
// sourceAmount); emit XTalkMessageInitiated(messageBytes,
// destinationNetwork, destinationSmartContractAddress); } function
// _l1xReceive(bytes32 globalTxId, bytes memory message) external {
// executeSwap memory executeSwapData; (executeSwapData.recipientAddress,
// executeSwapData.destinationAmount,
// executeSwapData.destinationTokenAddress) = abi.decode(message,
// (address, uint256, address)); swapData[globalTxId] = executeSwapData;
// IERC20(executeSwapData.destinationTokenAddress).safeTransfer(executeSwapData.recipientAddress,
// executeSwapData.destinationAmount); }`;

const codeString3 = `#[derive(Clone, Debug, BorshSerialize, BorshDeserialize, Serialize,          
  Deserialize)] pub struct RegisterToken{                             
recipient_address : l1x_sdk::types::Address,                             
token_address : l1x_sdk::types::Address,                                 
amount : l1x_sdk::types::U256 } #[derive(                                
 Clone, Debug, Serialize,                                             
 Deserialize)] pub struct RegisterTokenSolidity{                      
 recipient_address : ethers::types::Address,                          
 token_address : ethers::types::Address,                              
 amount : ethers::types::U256 } impl From <RegisterTokenSolidity>     
 for RegisterToken{fn from(event : RegisterTokenSolidity)->Self{Self{ 
     recipient_address : l1x_sdk::types::Address::from(               
         event.recipient_address .0),                                 
         token_address : l1x_sdk::types::Address::from(               
             event.token_address .0),                                 
             amount : OmniChain::from_ethers_u256_to_l1x_u256(        
                 event.amount), } } }

fn from_ethers_u256_to_l1x_u256(number
                         : ethers::types::U256)
->l1x_sdk::types::U256 {
let mut tmp = vec ![0u8; 32];
number.to_little_endian(&mut tmp);
let destination_amount = l1x_sdk::types::U256::from_little_endian(&tmp);
destination_amount
}`;

export class HomeNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSnippet: 0,
      copied: false,
      activeTab: "tab1",
    };

    this.countupRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
    this.countUpAnims = [];
  }

  handleClick = (index) => {
    this.setState({ activeSnippet: index });
  };

  tabHandleClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleCopy = (codeString) => {
    navigator.clipboard.writeText(codeString);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  handleCopy2 = (blockchainData) => {
    navigator.clipboard.writeText(blockchainData);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  componentDidMount() {
    this.initCountUp();
  }

  async initCountUp() {
    const countUpModule = await import("countup.js");

    const values = [1300000, 12000000, 126000, 0.003, 1500];

    this.countUpAnims = values.map((value, index) => {
      let formattedValue = value;
      let suffix = "";

      if (value >= 1000000) {
        formattedValue = value / 1000000;
        suffix = "M+";
      } else if (value >= 1000) {
        formattedValue = value / 1000;
        suffix = "K";
      } else if (value < 1 && value > 0) {
        formattedValue = value.toFixed(3);
      }

      if (index === 3) {
        formattedValue = 0;
        suffix = "$";
      }

      const anim = new countUpModule.CountUp(
        this.countupRefs[index].current,
        formattedValue,
        {
          decimal: ".",
          separator: ",",
          suffix: suffix,
          decimalPlaces: index === 1 || index === 2 ? 0 : 1,
          useGrouping: true,
        }
      );

      if (!anim.error) {
        anim.start();
      } else {
        console.error(anim.error);
      }
      return anim;
    });
  }

  renderCountUp(index, label) {
    return (
      <li key={index}>
        {index == 3 ? <h3>$0.003</h3> : <h3 ref={this.countupRefs[index]}></h3>}
        <p>{label}</p>
      </li>
    );
  }

  render() {
    return (
      <div className="home-main">
        <div className="buildWithoutBuondaries bg-upside bg-overlay">
          <div className="container">
            <div className="row align-items-center buildWithoutBuondaries-inner ">
              <div className="col-md-6 order-2 order-md-1">
                <div className="buildWithoutBuondaries-content">
                  <h1>
                    <span>Build Without</span>
                    Boundaries
                  </h1>
                  <p>
                    Welcome to the fourth generation of blockchain
                    interoperability technology. Layer One X (L1X) is a powerful
                    layer one protocol, allowing developers to build once and
                    access users and liquidity across any other blockchain.
                    Supported by its custom-built Virtual Machine and Consensus
                    Mechanism, L1X gives developers and projects an edge over
                    the competition and the ability to provide users with a
                    superior experience.
                  </p>
                  <ul className="btn-wrapper">
                    <li>
                      <a
                        className="common-btn-style btn-orange"
                        href="https://dev-portal.l1xapp.com/"
                        target="_blank"
                      >
                        Start Building Now
                      </a>
                    </li>
                    <li>
                      <a
                        className="common-btn-style btn-white-outline"
                        href=" https://docsend.com/view/yxxumg97x5mzv77v "
                        target="_blank"
                      >
                        Whitepaper
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6  order-1 order-md-2">
                <div className="buildWithoutBuondaries-img">
                  <img
                    src={globeImg}
                    className="img-fluid rotating-element"
                    style={{ borderRadius: "50%" }}
                  />
                  {/* <video src={globeVideo} autoPlay loop></video> */}
                </div>
              </div>
            </div>
          </div>
          
        </div>
       
       
        <div className="blockchainInteroperability bg-downside bg-overlay">
        <div className="competition-banner-main ">
          <div className="container">
            <div className="grid-bg-img">
            <div className="cross-chain-swap">
              <div className="lowest-gas-class">
                  <img src={LowestGasFeesIcon} />
                  Lowest Gas Fees
              </div>
              <h2>Cross-Chain Swap</h2>
              <h4>Effortless & Affordable!</h4>
              <ul>
                <li>
                  <img src={GetBestDealsIcon} />
                  Get the Best Deals
                </li>
                <li>
                  <img src={MaximizeSavingIcon} />
                  Maximize Your Savings
                </li>
              </ul>
             <div>
             <a href="https://l1xapp.com/swap-regular" target="_blank" className="btn-orange-bg">Start Swapping</a>
             </div>
            </div>
            <div className="swap-a-thon-main">
              <div className="prize-pool-img">
                 <img src={PrizePoolImg} />
              </div>
              <h2>Swap-a-Thon</h2>
              <h3>Draw & Competition</h3>
              <div>
                <a href="https://l1xapp.com/swap-direct" target="_blank" className="participate-now-btn">Participate Now</a>
              </div>
              <p className="swap-note">*A minimum of 350,000 swaps in total must be completed for prize release. </p>
            </div>
            </div>
          </div>
        </div>
          <div className="ctaSection">
            <div className="container ctaSection-relative">
              <h2>Join a growing cross-chain community</h2>
              <ul className="ctaSection-inner">
                {this.renderCountUp(0, "Blocks Generated")}
                {this.renderCountUp(1, "Transactions")}
                {this.renderCountUp(2, "Total Accounts")}
                {this.renderCountUp(3, "Fees / Transaction")}
                {this.renderCountUp(4, "Total Validators")}
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="blockchainInteroperability-inner grid-bg">
              <h2>
                Unlock the Power of Blockchain Interoperability with Layer One X
              </h2>

              <p className="mb-4">
                Layer One X is the solution to unite all blockchains. Our
                next-generation protocol breaks down barriers between chains,
                enabling lightning-fast, secure, and affordable transactions
                across any blockchain.
              </p>
              <div className="text-center mb-4">
                <p className="mb-2">Auditing Partner</p>
                <img src={HashLock} className="mb-3" />
              </div>
              <div className="blockchainInteroperability-blocks">
                <div className="blockchainInteroperability-blocks-left">
                  <div className="img-wrapper mb60">
                    <img src={xTalkImage} alt="x talk " />
                  </div>
                  <div className="content-wrapper d-none d-md-block">
                    <h4>Want to know about X-Talk?</h4>
                    <p className="mb-3 d-block">
                      Let’s get started with X-Talk SDK
                    </p>
                    <a
                      className="common-btn-style btn-white d-inline-block"
                      href="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/multi-chain-with-x-talk"
                      target="_blank"
                    >
                      Start With X-Talk SDK
                    </a>
                  </div>
                </div>
                <div className="blockchainInteroperability-blocks-right">
                  <div className="img-wrapper">
                    <img src={InteroperabilityImage} alt="x talk " />
                  </div>
                  <div className="content-wrapper d-block d-md-none">
                    <h4>Want to know about X-Talk?</h4>
                    <p className="mb-3 d-block">
                      Let’s get started with X-Talk SDK
                    </p>
                    <a
                      className="common-btn-style btn-white d-inline-block"
                      href="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/multi-chain-with-x-talk"
                      target="_blank"
                    >
                      Start With X-Talk SDK
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-upside bg-overlay">
          <div className="features grid-bg">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div className="features-content">
                    <div className="logo-wrap">
                      <img src={l1xLogo} alt="logo" />
                    </div>
                    <h3>
                      Key Features <br />
                      That Set Us Apart
                    </h3>
                    <p>
                      We're not just about connecting chains; Layer One X
                      provides the decentralization, speed, and security your
                      projects demand. Experience the power of true
                      interoperability!
                    </p>
                  </div>
                </div>
                <div className="col-lg-8">
                  <ul className="feature-block-wrapper">
                    <li>
                      <div className="feature-block">
                        <div className="icon-wrapper">
                          <img src={crossChain} className="icon" />
                        </div>
                        <h4>Cross-Chain Smart Contracts</h4>
                        <p>
                          Execute logic across multiple blockchains, unlocking
                          new possibilities in DeFi, gaming, and more.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="feature-block">
                        <div className="icon-wrapper">
                          <img src={reliableOracle} className="icon" />
                        </div>
                        <h4>Reliable Oracles</h4>
                        <p>
                          Bring real-world data to your dApps, driving informed
                          decision-making.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="feature-block">
                        <div className="icon-wrapper">
                          <img src={crossChainMessage} className="icon" />
                        </div>
                        <h4>Seamless Cross-Chain Messaging </h4>
                        <p>
                          Send data and instructions effortlessly between
                          chains.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="feature-block">
                        <div className="icon-wrapper">
                          <img src={envNonEvm} className="icon" />
                        </div>
                        <h4>EVM and Non-EVM Compatibility</h4>
                        <p>Integrate with the broadest range of blockchains.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="CrossChainConnectivity">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-2 order-lg-1">
                  {/* <div className="img-wrapper">
                    <img
                      src={CrossChainConnectivityImg}
                      alt="Cross Chain Connectivity"
                    />
                  </div> */}
                  <div className="codepalet">
                    <div className="codepalet-header">
                      <h3>Integrate any Blockchain</h3>
                      <button className="copy-btn">
                        {this.state.copied && (
                          <span className="copiedText me-1">Copied!</span>
                        )}
                        <img
                          src={copyButton}
                          onClick={() => this.handleCopy2(codeString)}
                        />
                      </button>
                    </div>
                    <div className="codepalet-body">
                      <SyntaxHighlighter
                        language="solidity"
                        className="brakeAll"
                        style={vs2015}
                      >
                        {codeString}
                      </SyntaxHighlighter>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="CrossChainConnectivity-content">
                    <h2>
                      <span className="mb-2">
                        Experience Seamless
                      </span>
                      Cross-Chain Connectivity
                    </h2>
                    <div className="text-left mb-4">
                      <p className="mb-2 pb-0">Auditing Partner</p>
                      <img src={HashLock} className="mb-3" />
                    </div>
                    <p>
                      With Layer One X, you never have to lock your project to
                      one blockchain. Now, you can expand to become an
                      omni-chain project without barriers or the need for risky
                      bridge integrations.
                    </p>
                    <h3>
                      Don’t just connect – <br />
                      Do more with your connection!
                    </h3>
                    <p>
                      Connect to any chain with only 20 lines of code using
                      X-talk technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-downside bg-overlay">
          <div className="buildScale">
            <div className="container">
              <div className="buildScale-heading">
                <h2>Build Without Limits, Scale Without Barriers</h2>
                <h3>
                  Helping Web3 projects access millions of users with Layer One
                  X's cutting-edge X-Talk technology.
                </h3>
              </div>
              <div className="row offset-lg-1">
                <div className="col-md-3 ">
                  <div className="img-wrapper">
                    <img src={xTalkImageTwo} alt="x talk " />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="buildScale-content">
                    <h4>
                      X-Talk enables powerful L1X partnerships helping to
                      onboard new users on to the L1X platform, such as the L1X
                      and Omchain digital ID partnership onboarding 15 million
                      users in 2024-2025.
                    </h4>
                    <p>
                      X-Talk is Layer One X's unparalleled interoperability
                      technology. It enables frictionless transfer of assets,
                      data, and logic through bridge-less communication across
                      EVM and non-EVM blockchains.
                    </p>
                    <ul className="btn-wrapper ">
                      <li>
                        <a
                          className="common-btn-style btn-orange d-inline-block text-center"
                          href=" https://docsend.com/view/yxxumg97x5mzv77v "
                          target="_blank"
                        >
                          Whitepaper
                        </a>
                      </li>
                      <li>
                        <a
                          className="common-btn-style btn-white-outline d-inline-block text-center"
                          href=" https://docsend.com/view/4kbzzm4kvsq7xuv6 "
                          target="_blank"
                        >
                          Lite paper
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="BuildMasses grid-bg">
            <div className="container">
              <h2>Build for the Masses</h2>
              <div className="row">
                <div className="col-sm-4">
                  <div className="BuildMasses-block">
                    <div className="icon">
                      <img src={fastIcon} alt="icons" />
                    </div>
                    <h3>Fast</h3>
                    <p>
                      Reward your users with seamless transactions. Layer One X
                      has a transaction finality of 1.5 seconds and a block
                      creation time of 500ms.
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="BuildMasses-block">
                    <div className="icon">
                      <img src={scalableIcon} alt="icons" />
                    </div>
                    <h3>Scalable</h3>
                    <p>
                      L1X design allows projects to grow as fast as they want.
                      L1X can process thousands of transactions per second, with
                      gas fees at a fraction of a cent and a fee limit of no
                      greater than $0.01.
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="BuildMasses-block">
                    <div className="icon">
                      <img src={effertlessIcon} alt="icons" />
                    </div>
                    <h3>Effortless Integration</h3>
                    <p>
                      Integrating X-talk and L1X core into your existing or new
                      project is a breeze. Our intuitive SDKs and
                      well-documented APIs require minimal coding effort. Get
                      started quickly and focus on what matters - building
                      innovative applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quickStart bg-upside bg-overlay">
          <div className="container">
            <div className="quickStart-header">
              <h2>Get a Quick Start!</h2>
              <p>
                Select from 3 of our most popular code snippets (smart
                contracts) to get started
              </p>
            </div>
            <div className="quickStart-inner">
              <div className="row">
                <div className="col-md-6">
                  <ul className="quickStart-inner-list">
                    <li
                      className={this.state.activeSnippet === 0 ? "active" : ""}
                      onClick={() => this.handleClick(0)}
                    >
                      <h4>
                      XCDP (Cross Chain Data and Message Passing)
                      </h4>
                      <span>
                        <img src={checkIcon} alt="check icon" />
                      </span>
                    </li>
                    <li
                      className={this.state.activeSnippet === 1 ? "active" : ""}
                      onClick={() => this.handleClick(1)}
                    >
                      <h4>
                      XTalk Swaps (Swap tokens between EVM and Non-EVM Chains)
                      </h4>
                      <span>
                        <img src={checkIcon} alt="check icon" />
                      </span>
                    </li>
                    <li
                      className={this.state.activeSnippet === 2 ? "active" : ""}
                      onClick={() => this.handleClick(2)}
                    >
                      <h4 style={{lineHeight: 1.6}}>
                      Omnichain Token (Tokens that can exist on and move <br/> seemlessly across different blockchains)
                      </h4>
                      <span>
                        <img src={checkIcon} alt="check icon" />
                      </span>
                    </li>
                  </ul>

                  <div className="quickStart-inner-content d-none d-md-block">
                    <h3>Looking for Something Else? </h3>
                    <p>Join the L1X Dev Portal and customise your own code</p>
                    <a
                      className="common-btn-style btn-orange mb-2 d-inline-block text-center"
                      href="https://dev-portal.l1xapp.com/"
                      target="_blank"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="codepalet">
                    <div className="codepalet-header">
                    {this.state.activeSnippet === 0
                          ?<h3>XCDP</h3>
                          : this.state.activeSnippet === 1
                          ? <h3>XTalk Swaps</h3>
                          : <h3>Omnichain Token</h3>}
                      
                      <button className="copy-btn">
                        {this.state.copied && (
                          <span className="copiedText me-1">Copied!</span>
                        )}
                        <img
                          src={copyButton}
                          onClick={() =>
                            this.handleCopy(
                              this.state.activeSnippet === 0
                                ? codeString1
                                : this.state.activeSnippet === 1
                                ? codeString2
                                : codeString3
                            )
                          }
                        />
                      </button>
                    </div>
                    <div className="codepalet-body">
                      <SyntaxHighlighter
                        language="solidity"
                        className="brakeAll"
                        style={vs2015}
                      >
                        {this.state.activeSnippet === 0
                          ? codeString1
                          : this.state.activeSnippet === 1
                          ? codeString2
                          : codeString3}
                      </SyntaxHighlighter>
                    </div>
                  </div>
                  <div className="quickStart-inner-content d-block d-md-none">
                    <h3>Looking for Something Else? </h3>
                    <p>Join the L1X Dev Portal and customise your own code</p>
                    <button className="common-btn-style btn-orange">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-downside bg-overlay">
          <div className="web3DevelopmentPortal grid-bg gradientBg ">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="web3DevelopmentPortal-img">
                    <img
                      src={web3DevelopmentPortal}
                      alt="Web3 Development Portal images"
                      className="img-fluid"
                    />
                  </div>
                  <h2>The Ultimate Web3 Development Portal. </h2>
                  <ul class="btn-wrapper">
                    <li>
                      <a
                        class="common-btn-style btn-orange"
                        href="https://dev-portal.l1xapp.com/"
                        target="_blank"
                      >
                        Start Building
                      </a>
                    </li>
                    <li>
                      <a
                        class="common-btn-style btn-white-outline"
                        href="https://l1xapp.com/ecosystem"
                        target="_blank"
                      >
                        L1X Grants
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <h3>Your Gateway to Layer One X</h3>
                  <p>
                    One-Stop-Shop for Building and Managing dApps: The Layer One
                    X developer portal is your resource hub for creating
                    cutting-edge applications on the L1X blockchain—access
                    comprehensive documentation, tutorials, and intuitive tools
                    that streamline your development process.
                  </p>
                  <h3>Accelerate Development, Simplify Deployment</h3>
                  <p>
                    Find robust SDKs, detailed API references, and a workspace
                    to help you launch your dApps faster. Spend less time on
                    configuration and more time building groundbreaking
                    features.
                  </p>
                  <h3>Tap into a Thriving Community</h3>
                  <p>
                    Be part of a vibrant and supportive community, collaborate
                    on projects, submit protocol improvement proposals, and gain
                    insights from fellow Web3 builders in our active developer
                    community. Join us and be part of the thriving ecosystem of
                    Layer One X.
                  </p>
                  <h3>Access Developer Grants and Airdrop</h3>
                  <p>
                    L1X is currently offering a unique ecosystem grants pool for
                    select projects and teams. Alongside grants, projects and
                    users are also rewarded with a unique airdrop program to
                    incentivise adoption and ecosystem activity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="optionsForIntergartion">
            <div className="container">
              <div className="optionsForIntergartion-TopSection l1x-logo-bg cirecle-shape">
                <div className="TopSection-content">
                  <h2>Get started today!</h2>
                  <p>
                    Layer One X allows projects and developers to integrate the
                    L1X technology at a level that suits their needs. For
                    example, they can build directly on the L1X protocol
                    infrastructure. Alternatively, developers can leverage L1X
                    technology while staying on their current project chain.
                    Both options will leverage our X-talk capabilities.
                  </p>
                  <h3>
                    Select an option below to get step-by-step instructions for
                    integrating with Layer One X.
                  </h3>
                </div>
              </div>
              <div className="optionsForIntergartion-BottomSection">
                <div className="BottomSection-inner">
                  <div className="tabs-wrapper">
                    <ul className="tabs-link">
                      <li>
                        <button
                          className={
                            this.state.activeTab === "tab1" ? "active" : ""
                          }
                          onClick={() => this.tabHandleClick("tab1")}
                        >
                          Developers
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            this.state.activeTab === "tab2" ? "active" : ""
                          }
                          onClick={() => this.tabHandleClick("tab2")}
                        >
                          Projects
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            this.state.activeTab === "tab3" ? "active" : ""
                          }
                          onClick={() => this.tabHandleClick("tab3")}
                        >
                          Node Operators
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            this.state.activeTab === "tab4" ? "active" : ""
                          }
                          onClick={() => this.tabHandleClick("tab4")}
                        >
                          Users
                        </button>
                      </li>
                    </ul>
                    <div className="tabs-content">
                      {this.state.activeTab === "tab1" && (
                        <ul className="links-wrapper">
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://dev-portal.l1xapp.com/"
                            >
                              Visit the L1X Developer Portal
                              <img src={externalLinkIcon} />
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://dev-portal.l1xapp.com/interact"
                            >
                              Join the L1X Development Community
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/interface-essentials/l1x-vm-sdk/l1x-native-sdk-for-l1x-vm/set-up-environment"
                            >
                              Access the L1X Development SDK
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/interface-essentials/l1x-vm-sdk/l1x-native-sdk-for-l1x-vm/build-your-first-smart-contract-on-l1x-vm"
                            >
                              Build your first L1X Smart contract
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1x-sdk.gitbook.io/l1x-developer-interface/v/multi-chain-with-x-talk/start-building-with-x-talk/cross-chain-data-and-message-passing-xcdp/build-your-first-xcdp-contract"
                            >
                              Build your first L1X cross-chain contract
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.state.activeTab === "tab2" && (
                        <ul className="links-wrapper">
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://dev-portal.l1xapp.com/"
                            >
                              Visit the L1X Developer Portal
                              <img src={externalLinkIcon} />
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://dev-portal.l1xapp.com/developer-document"
                            >
                              View L1X Developer Documents
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1xapp.com/ecosystem"
                            >
                              Learn about the L1X Ecosystem Grants Pool
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1xapp.com/ecosystem/list"
                            >
                              View current grant applications
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.state.activeTab === "tab3" && (
                        <ul className="links-wrapper">
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1x-sdk.gitbook.io/l1x-foundation/node-hosting-on-l1x"
                            >
                              Learn about hosting a Node on L1X network
                              <img src={externalLinkIcon} />
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1xapp.com/swap/howToBuyNode"
                            >
                              Buy a Node NFT and get the right to host a Node
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1xapp.com/swap/nodeRewardCalculator"
                            >
                              Calculate your Node rewards
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1x-sdk.gitbook.io/l1x-foundation/node-hosting-on-l1x/node-architecture-and-consensus-mechanism"
                            >
                              Learn about the L1X Node infrastructure and
                              Consensus Mechanism
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                        </ul>
                      )}
                      {this.state.activeTab === "tab4" && (
                        <ul className="links-wrapper">
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://chromewebstore.google.com/search/x_Wallet"
                            >
                              Download L1X XWallet{" "}
                              <img src={externalLinkIcon} />
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1xapp.com/swap"
                            >
                              Do cheaper and faster cross-chain swaps with
                              L1XApp
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              className="link"
                              to="https://l1xapp.com/mint"
                            >
                              Get you own L1X Web3 Username
                              <img src={externalLinkIcon} />{" "}
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="partnersInnovators" id="poweredByNetwork">
          <div className="container">
            <div className="partnersInnovators-inner">
              <div className="partnersInnovators-heading">
                <h2>Powered by a Network of</h2>
              </div>
              <div className="innovators-wrapper grid-bg">
                <div className="row">
                  <div className="col-lg-3">
                    <h3 className="title">Projects</h3>
                  </div>
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-6 col-sm-4">
                        <div className="innovators-block">
                          <img src={amchainIcon} />
                        </div>
                      </div>
                      <div className="col-6 col-sm-4">
                        <div className="innovators-block">
                          <img src={healthLinkIcon} />
                        </div>
                      </div>
                      {/* <div className="col-6 col-sm-4">
                        <div className="innovators-block">
                          <img src={digaLabsIcon} />
                        </div>
                      </div> */}
                      <div className="col-6 col-sm-4">
                        <div className="innovators-block">
                          <img src={l1dexIcon} />
                        </div>
                      </div>
                      <div className="col-6 col-sm-4">
                        <div className="innovators-block">
                          <img src={ORedCurryIcon} />
                        </div>
                      </div>
                      <div className="col-6 col-sm-4">
                        <Link to="https://projects.l1x.foundation" target="_blank" className="innovators-block">
                          <span>View All</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="innovators-wrapper mb-0">
                <div className="row">
                  <div className="col-lg-3">
                    <h3 className="title">Partners</h3>
                  </div>
                  <div className="col-lg-9">
                    <div className="row partners-wrapper">
                      <div className="col-md-3">
                        <div className="partners-logo">
                          <img src={prysmLogo} alt="logo" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="partners-content">
                          <h4>PRYSM GROUP </h4>
                          <p>
                            Prysm Group is the Tokenomics Partner for L1X,
                            delivering value by leveraging established economic
                            research.
                          </p>
                          <p>
                            Prysm Group is an economic consulting and corporate
                            learning firm focused on emerging technologies.
                            Founded by Harvard PhD economists, the firm assists
                            corporates, governments and startups in the adoption
                            and implementation of blockchain, digital asset and
                            the metaverse through its advisory and educational
                            services.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row partners-wrapper">
                      <div className="col-md-3">
                        <div className="partners-logo">
                          <img src={westernAustraliaLogo} alt="logo" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="partners-content">
                          <h4>UWA</h4>
                          <p>
                            The University of Western Australia (UWA) is the
                            academic and research partner for Layer One X.
                          </p>
                          <p>
                            UWA is a leading Australian research university and
                            has an international reputation for excellence,
                            innovation and enterprise. A member of the
                            Australian 'Group of Eight' research universities.
                            UWA is in the top 100 universities globally and
                            offers higher education in many areas, including a
                            full Masters program for blockchain. {" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row partners-wrapper">
                      <div className="col-md-3">
                        <div className="partners-logo">
                          <img src={mmeLogo} alt="logo" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="partners-content mb-0">
                          <h4>MME</h4>
                          <p>
                            MME Law, is a leading Swiss law firm, guiding
                            Foundations in the world of blockchain. Ranked as
                            one of the top tier law firms in fintech globally
                            and consistently recognized for excellence in
                            technology law. Their team of experts boasts a
                            world-wide reputation for navigating complex
                            blockchain and FinTech regulations.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="earn-big-referring">
            <div className="container"> 
              <div className="inner-referring-div">
                <div className="inner-referring-div-content">
                  <h2>Earn Big by Referring:</h2>
                  <h4>Share, Earn, Repeat <div className="earn-dollr-flex"><img src={DollerMicon} /> <img src={DollerMicon} /></div> </h4>
                  <p>Bring Your Friends Onboard and Reap Exclusive Rewards Instantly</p>
                  <div>
                    <a href="https://l1xapp.com/account/portfolio/referral-rewards" target="_blank" className="common-btn-style btn-orange">Start Earning Now</a>
                  </div>
                </div>
                <div className="referearn-class">
                  <img src={ReferEarnImg} />
                </div>
              </div>
            </div>
        </div>
        <div className="bg-upside bg-overlay bg-centerd ">
          
          <div className="circuler-bg">
            <div className="crossChainSwap">
              <div className="container">
                <div className="crossChainSwap-inner">
                  <div className="img-wrapper">
                    <img src={swapMobile} alt="swap mobile" />
                  </div>
                  <div className="content-wrapper">
                    <h2>
                      <span>Cheaper, Faster, Safer</span>Cross-Chain Swaps
                    </h2>
                    <p>
                      Months of competitor comparison analysis has shown that
                      L1X technology empowers cross chain swap applications that
                      are cheaper and faster than competitors built on
                      technology such as layer Zero, Axelar and Chainlink CCIP.
                      And all facilitated through L1X’s bridgeless X-Talk
                      system.
                    </p>
                    <div className="btn-wrapper">
                      <a
                        className="common-btn-style btn-white d-inline-block text-center"
                        target="_blank"
                        href="https://l1xapp.com/swap"
                      >
                        Swap Now
                      </a>
                    </div>
                  </div>
                  <div className="circle"></div>
                </div>
              </div>
            </div>
            <div className="getConnected ">
              <div className="container">
                <div className="getConnected-inner text-center">
                  <h2>Get Connected</h2>
                  <h5>Stay Tuned & Get Latest Updates</h5>
                  <ul className="social-wrapper">
                    <li>
                      <a
                        className="social-btn bg-telegram"
                        target="_blank"
                        href="https://t.me/Layer1X "
                      >
                        <img src={TelegramIcon} alt="TelegramIcon" /> Join
                        Telegram
                      </a>
                    </li>
                    <li>
                      <a
                        className="social-btn bg-twiter"
                        target="_blank"
                        href="https://twitter.com/LayerOneX "
                      >
                        <img src={TwitterIcon} alt="TwitterIcon" /> Follow on
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        className="social-btn bg-discord"
                        target="_blank"
                        href="https://discord.com/invite/LayerOneX "
                      >
                        <img src={DiscordIcon} alt="DiscordIcon" /> Join Discord
                        Community
                      </a>
                    </li>
                    <li>
                      <a
                        className="social-btn bg-youtube"
                        target="_blank"
                        href="https://www.youtube.com/@LayerOneX/videos?view=0&sort=dd&shelf_id=2"
                      >
                        <img src={YoutubeIcon} alt="YoutubeIcon" /> Subscribe on
                        YouTube
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
HomeNew.propTypes = {
  theme: PropTypes.string,
};
