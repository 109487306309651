import React, { useEffect, useState } from "react"
import validator from "validator";

import Logo from "../../images/logol1x-icon.svg";
import { serviceGetDirectInvestmentAddress, servicePostDirectInvestment } from "../../services/apiService";
import Notify from "../../utils/notify";

import copy from "copy-to-clipboard";
import ChainService from "../../services/chainService";
import CircularProgress from "../CircularProgress/index";
// import { NumericFormat } from "react-number-format";
import Config from "../../config";
import { Link } from "react-router-dom";

const TermsComponents = (props) => {
    return (<div className="mb-1 form-check text-left">
        <input
            type="checkbox"
            name="acknowledge"
            value={props.value}
            onChange={props.handleClick}
            className="form-check-input whiteList_terms_checkbox"
            style={{ width: "1.5em", height: "1.5em" }}
        />
        {
            props.hyperLinkText ?
                <Link className="termandcondition__btn" target="_blank" to={props.link}>
                    <label
                        className="form-check-label form-label cursor__pointer"
                        style={{ "verticalAlign": "sub", marginLeft: 10, fontWeight: 600 }}
                    >
                        {props.text} <u>{props.hyperLinkText}</u>
                    </label>
                </Link> :
                <div className="termandcondition__btn" >
                    <label
                        className="form-check-label form-label cursor__pointer"
                        style={{ "verticalAlign": "sub", marginLeft: 10, fontWeight: 600 }}
                    >
                        {props.text}
                    </label>
                </div>
        }

    </div>
    )
}


const PublicSaleDirectInvestment = (props) => {
    // const [walletAddress, setWalletAddress] = useState('');
    const [directInvestmentAddress, setDirectInvestmentAddress] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [selectedToken, setSelectedToken] = useState('');
    // const [amount, setAmount] = useState('');
    const [transactionHash, setTransactionHash] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitStatus, setsubmitStatus] = useState(false);
    const [disableForm, setDisableForm] = useState(false);

    const networkList = ChainService.getNetworks();
    const tokenList = ChainService.getTokens();

    const [acknowledge, setAcknowledge] = React.useState(false);
    const [mainnetTerms, setMainnetTerms] = React.useState(false);
    const [terms, setTerms] = React.useState(false);

    const [disableApproveButton, setDisableApproveButton] = React.useState(true);
    const [goBack, setGoBack] = useState('publicSaleStep');

    useEffect(() => {

        if (props.paymentType != "") {
            setGoBack('publicSaleHowToContribute')
        }

        async function getDirectInvesmentAddress() {
            let _investmentAddressRes = await serviceGetDirectInvestmentAddress()
            if (_investmentAddressRes.status === 'success') {
                setDirectInvestmentAddress(_investmentAddressRes.data.address)
            }
        }

        if (directInvestmentAddress === "") {
            getDirectInvesmentAddress()
        }

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                window.location.reload();
            });
        }


    }, []);


    // const checkDirectInvestTx = async () => {
    //     let errors = {};

    //     if (validator.isEmpty(selectedNetwork)) {
    //         errors.network = "Please select network";
    //     }

    //     if (validator.isEmpty(transactionHash)) {
    //         errors.transactionHash = "Please enter transaction hash";
    //     }

    //     setErrors(errors)

    //     if (Object.keys(errors).length === 0) {

    //         setsubmitStatus(true)
    //         setDisableForm(true)
    //         let data = {
    //             network: selectedNetwork,
    //             txHash: transactionHash
    //         }

    //         let _txSubmitResponse = await serviceCheckDirectInvestment(data);
    //         console.log("🚀 ~ file: publicSaleDirectInvestment.js:108 ~ processDirectInvestTx ~ _txSubmitResponse:", _txSubmitResponse)

    //         if (_txSubmitResponse.status === "success") {
    //             setsubmitStatus(false)
    //             setAmount(_txSubmitResponse.data.amount)
    //             setSelectedToken(_txSubmitResponse.data.token)
    //             setWalletAddress(_txSubmitResponse.data.wallet_address)
    //             setDisableForm(true);
    //             Notify(_txSubmitResponse.message, "success");
    //             return
    //         }
    //         else {
    //             Notify(_txSubmitResponse.message, "error");
    //             setsubmitStatus(false)
    //             setDisableForm(false);
    //             return
    //         }
    //     }

    //     return errors;
    // }

    const processDirectInvestTx = async () => {
        let errors = {};

        // if (validator.isEmpty(walletAddress)) {
        //     errors.walletAddress = "Please enter wallet address";
        // }

        if (validator.isEmpty(selectedNetwork)) {
            errors.network = "Please select network";
        }

        if (validator.isEmpty(selectedToken)) {
            errors.token = "Please select token";
        }

        // if (validator.isEmpty(amount.toString())) {
        //     errors.amount = "Please enter amount";
        // }
        // else if (parseFloat(amount.toString()) <= 0) {
        //     errors.amount = "Please enter valid amount";
        // }

        if (validator.isEmpty(transactionHash)) {
            errors.transactionHash = "Please enter transaction hash";
        }


        setErrors(errors)

        if (Object.keys(errors).length === 0) {

            let _email = localStorage.getItem('email');
            _email = _email.replace(/"/g, '');


            setsubmitStatus(true)
            setDisableForm(true)
            let data = {
                // walletAddress: walletAddress,
                network: selectedNetwork,
                token: selectedToken,
                // amount: amount,
                txHash: transactionHash,
                email: _email,
                sale_transaction_type: props.paymentTransactionType ? props.paymentTransactionType : 1
            }
            // console.log("---------- data", data); return

            let _txSubmitResponse = await servicePostDirectInvestment(data);
            console.log("🚀 ~ file: publicSaleDirectInvestment.js:108 ~ processDirectInvestTx ~ _txSubmitResponse:", _txSubmitResponse)

            if (_txSubmitResponse.status === "success") {
                setsubmitStatus(false)
                setDisableForm(false)
                Notify(_txSubmitResponse.message, "success");

                if (props.paymentType == "DebitCard") {
                    props.handleUpdateCurrentStep(1)
                    props.handleUserSteps('publicSaleMoonPaymentThankYou')
                    return
                }
                else {
                    props.handleUpdateCurrentStep(1)
                    props.handleUserSteps('directInvestmentThankYouPage')
                    return
                }
            }
            else {
                Notify(_txSubmitResponse.message, "error");
                setsubmitStatus(false);
                setDisableForm(false);
                return
            }
        }
        return errors;
    }


    // const handleResetFrom = () => {
    //     // setAmount('');
    //     // setWalletAddress('');
    //     setSelectedNetwork('');
    //     setSelectedToken('');
    //     setTransactionHash('');
    //     setDisableForm(false);
    //     setDisableApproveButton(false);
    // }

    const copyToClipboard = (data) => {
        copy(data);
        return Notify("Copied!", "success")
    };

    return (
        <div className="banner__fixed__card__box">
            <div className="banner__fixed__card__head">
                <h2><img src={Logo} alt="logo" className="card__fixed__icon" /> Layer One X – Public Sale</h2>
            </div>

            <div className="fixed__card__main__content">
                <div className="direct__tab__btn__section">
                    <div className="tab__btn__box">
                        <h4 className="public_sale_card_heading">Verify Tx Hash:</h4>
                        <p className="public_sale_card_p"><small>Confirm your transaction details and verify your Transaction Hash.</small></p>
                        <h4 className="public_sale_card_heading">Direct Contribution</h4>
                        <p className="public_sale_card_p"><small>Details entered once cannot be changed</small></p>
                        <text className="direct__section__subheading">Minimum Contribution ${Config.publicSaleDirectMinInvestmentAmount}</text>
                        {/* {directInvestmentAddress ?
                            selectedNetwork === '' || selectedToken === '' ? '' : <div className="deposite_addres_box">
                                <text className="deposite_to_this_address_txt_label"><small>Desposit at this address : </small></text>
                                <text className="deposite_to_this_address_txt">  <small>{directInvestmentAddress}
                                    <img
                                        onClick={() => copyToClipboard(directInvestmentAddress)}
                                        className="deposite_address_icon"
                                        src={require("../../images/copy.png")}
                                        alt="copy"
                                    /></small></text>
                            </div>
                            : ""} */}
                        <div className=" direct__dist__id__container">
                            <span className="deposite-address-fix deposite_to_this_address_txt_label">Deposit address :</span>
                            <span className=" deposite-address-fix-id deposite_to_this_address_txt">{directInvestmentAddress} <img
                                onClick={() => copyToClipboard(directInvestmentAddress)}
                                className="deposite_address_icon"
                                src={require("../../images/copy.png")}
                                alt="copy"
                            /></span>
                        </div>
                        {/* <text className="deposite_to_this_address_txt_label"><small> </small></text>
                        <text className="deposite_to_this_address_txt">  <small>
                        </small></text> */}
                    </div>
                </div>
                <div>

                    <div className="mb-3 direct_address_margin">
                        <label className="form-label">Select Network <span className="red-astric">*</span></label>
                        <select
                            disabled={disableForm}
                            className="form-select public_sale_input"
                            aria-label="Default select example"
                            name="network"
                            value={selectedNetwork}
                            onChange={(event) => {
                                setSelectedNetwork(event.target.value);
                            }}
                        >
                            <option key={"default-network"} value="">
                                Please Select Network
                            </option>

                            {networkList.map((_network) => {
                                return (
                                    <option key={_network} value={_network}>
                                        {ChainService.getFormalNetworkName(_network)}
                                    </option>
                                );
                            })}
                        </select>
                        {errors && errors.network && (
                            <span className="error">{errors.network}</span>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Select Token <span className="red-astric">*</span> </label>
                        <select
                            disabled={disableForm}
                            className="form-select public_sale_input"
                            aria-label="Default select example"
                            name="token"
                            value={selectedToken}
                            onChange={(event) => {
                                setSelectedToken(event.target.value);
                            }}
                        >
                            <option key={"default-token"} value="">
                                Select Token
                            </option>
                            {tokenList.map((element) => (
                                <option key={element} value={element}>
                                    {element}
                                </option>
                            ))}
                        </select>
                        {errors && errors.token && (
                            <span className="error">{errors.token}</span>
                        )}
                    </div>

                    {selectedNetwork === '' || selectedToken === '' ? '' :
                        <div className="mb-3">
                            <label className="form-label">
                                Transaction Hash <span className="red-astric">*</span> (Please wait until tx is confirmed)
                            </label>

                            <input
                                disabled={disableForm}
                                name="transactionHash"
                                value={transactionHash}
                                onChange={(event) => setTransactionHash(event.target.value)}
                                className="form-control public_sale_input"
                                placeholder="Enter transaction Hash"
                            />
                            {errors && errors.transactionHash && (
                                <span className="error">{errors.transactionHash}</span>
                            )}
                        </div>
                    }

                    {/* {walletAddress !== '' ? <div className="mb-3">
                        <label className="form-label">
                            Wallet Address <span className="red-astric">*</span>
                        </label>

                        <input
                            disabled={disableForm}
                            name="walletAddress"
                            value={walletAddress}
                            onChange={(event) => setWalletAddress(event.target.value)}
                            className="form-control public_sale_input"
                            placeholder="Please enter wallet address "
                        />
                        {errors && errors.walletAddress && (
                            <span className="error">{errors.walletAddress}</span>
                        )}
                    </div> : ""} */}



                    {/* {amount !== '' ? <div className="mb-3">
                        <label className="form-label">
                            Enter Amount <span className="red-astric">*</span>
                        </label>
                        <input
                            type="hidden"
                            name="amount"
                            value={amount}
                            className="form-control public_sale_input"
                            id=""
                        />

                        <NumericFormat
                            onValueChange={(values) => {
                                setAmount(values.value);
                            }}
                            thousandSeparator={true}
                            className="form-control public_sale_input"
                            disabled={disableForm}
                            placeholder="Please enter amount"
                            inputMode="numeric"
                            value={amount}
                        />
                        {errors && errors.amount && (
                            <span className="error">{errors.amount}</span>
                        )}
                    </div> : ""} */}

                    <span className="error">
                        <div className="expected_amoun_error">
                            <p className="expected_amoun_error_txt">*Min contribution amount is  ${props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount.toLocaleString() : Config.publicSalePremiumWalletMinInvestmentAmount.toLocaleString()} USD</p>
                            <p className="expected_amoun_error_txt">*Max contribution amount is {`<=`} ${Config.publicSaleMaxInvestmentAmount} USD</p>
                        </div>
                    </span>


                    <>
                        <TermsComponents handleClick={() => {
                            setAcknowledge(!acknowledge)

                            if (!acknowledge && terms && mainnetTerms) {
                                setDisableApproveButton(false)
                            }
                            else {
                                setDisableApproveButton(true)
                            }
                        }}
                            text="Acknowledge that the tokens will be released upon the Genesis block"
                            hyperLinkText={null}
                        />

                        <TermsComponents handleClick={() => {
                            setMainnetTerms(!mainnetTerms)
                            if (terms && acknowledge && !mainnetTerms) {
                                setDisableApproveButton(false)
                            }
                            else {
                                setDisableApproveButton(true)
                            }
                        }}
                            text="I have read and understand the details of the"
                            hyperLinkText="Mainnet Beta Documentation"
                            link="https://blog.l1x.foundation/what-is-layer-one-x-mainnet-beta/"
                        />


                        <TermsComponents handleClick={() => {
                            setTerms(!terms)
                            if (!terms && acknowledge && mainnetTerms) {
                                setDisableApproveButton(false)
                            }
                            else {
                                setDisableApproveButton(true)
                            }
                        }}
                            text="I accept the "
                            hyperLinkText="Terms & Conditions"
                            link="https://blog.l1x.foundation/terms-conditions/"

                        />

                    </>
                    {

                        submitStatus ?
                            <button
                                disabled
                                className="btn btn-primary buy-btn-loader"
                            >
                                <span className="loader-span">
                                    <CircularProgress /> Submiting ...
                                </span>
                            </button>
                            :
                            <div className="direct_investment_confirm_btn_box">
                                <button
                                    type="button"
                                    onClick={processDirectInvestTx}
                                    disabled={disableApproveButton}
                                    className="btn btn-primary buy-btn-loader"
                                >
                                    Proceed
                                </button>
                            </div>
                    }

                    <button disabled={submitStatus} onClick={() => {
                        props.handleUpdateCurrentStep(2)
                        props.handleUserSteps(goBack)
                    }} className="reciept__details__goBack__btn">
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PublicSaleDirectInvestment