/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import PropTypes from "prop-types";

export class JoinourWhitelisting extends Component {
  render() {
    return (
      <>
  

        <section className="section bg-blue min-height-600">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 m-auto">
              

              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
JoinourWhitelisting.propTypes = {
  theme: PropTypes.string,
};
