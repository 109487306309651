import React from "react"
import closeIcon from "../../images/close-menu-lm.svg";

const TermsheetInvestmentThankYouPage = (props) => {

    return (
        <div className="banner__fixed__card__box thank_you_card_height">
            <div>
                <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div>

                <div className="direct_thank_you_txt">
                    Received your form submission. Thank you! Check your inbox for our termsheet.
                </div>
            </div>

            <div className="public_sale_check_eligibility_box">
                <button
                    onClick={() => {
                        props.handleUserSteps('publicSaleRegistrationForm')
                    }}
                    type="button"
                    className="btn btn-primary not_eligible_close_btn"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default TermsheetInvestmentThankYouPage