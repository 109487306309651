/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import L1XPlatformStack from "../images/l1x-platform-stack.png";
import LML1XPlatformStack from "../images/l1x-platform-stack.png";
import ConsensusMechanism from "../images/icons/icon-consensus-mechanism.png";
import LMConsensusMechanism from "../images/icons/icon-consensus-mechanism-lm.png";
import Nativechain from "../images/icons/icon-native-chain-development.png";
import LMNativechain from "../images/icons/icon-native-chain-development-lm.png";
import TickIcon from "../images/icons/tick-icon.png";
import LMTickIcon from "../images/icons/tick-icon-lm.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class InteroperableTransactionLifecycle extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Build</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Interoperable Transaction Lifecycle
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>Interoperable Transaction Lifecycle</h4>
                  <h1 className="mb-32">
                  Secure transfer of assets 
                    <br /> across blockchains.
                  </h1>
                  <div className="buttons d-flex justify-content-center">
                    <div className="btn-style btn-style-one">
                      <Link to={"../start-building"} className="glow-button gradient-border-mask">
                        <span>Apply Now</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="main-sticky">
          <nav className="sticky-top section-sticky-top w-auto">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <ul>
                    <span>Jump to</span>
                    <li>
                      <a href="build/interoperable-transaction-lifecycle/#introduction">
                        Introduction
                      </a>
                    </li>
                    <li>
                      <a href="build/interoperable-transaction-lifecycle/#our-transaction-lifecycle-simplified">
                        Our transaction lifecycle, simplified.
                      </a>
                    </li>
                    <li>
                      <a href="build/interoperable-transaction-lifecycle/#ways-to-participate">
                        Ways to participate
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          <section
            className="section web3-usecases our-goals state-of-the-art text-center bg-blue"
            id="introduction"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-left">
                  Additional validations are <br />
                  performed for interoperability <br />
                  transactions 
                  </h3>
                </div>
                <div className="col-md-6">
                  <p className="pb-80 text-left">
                  Blockchain interoperability refers to a composition of homogeneous and heterogeneous blockchains among which a source blockchain is able to change the state of a target blockchain, empowered by cross-chain or cross-blockchain communications and/or transactions. Layer One X provides interoperability at the base layer of blockchain.
                  </p>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                            ? LMTickIcon
                            : TickIcon
                          }
                          alt="Pool Contract Validation"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Pool Contract Validation</h4>
                        <p>
                        The authenticity of the contract that has generated the event is validated. This validation ensures that event generated is for a genuine interoperability transaction. Malicious users may generate fake events for the interoperability transactions to gain the asset in a specified destination address.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                            ? LMTickIcon
                            : TickIcon
                          }
                          alt="Event Validation"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Event Validation</h4>
                        <p>
                        In this validation, the event details are validated. The authenticity of the transaction hash of the transaction depositing assets in the random Ethereum address is validated from the source blockchain.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <figure>
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMTickIcon
                              : TickIcon
                          }
                          alt="Leader Validation"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="box">
                        <h4>Leader Validation</h4>
                        <p>
                        A leader sends an aggregated response to the constructor. The constructor validates the authenticity of the leader as malicious users may submit fake transaction details to acquire assets in the destination blockchain.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-blue">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  
              <div className="row">
                <div className="col-md-12">
                  <figure className="transaction-validation-img">
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? LML1XPlatformStack
                          : L1XPlatformStack
                      }
                      alt="L1X Platform Stack"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="section web3-usecases text-left lifecycle"
            id="our-transaction-lifecycle-simplified"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="mb-80">
                  How interoperable 
                  <br /> transactions work 
                  </h2>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>1.</span> User initiates transaction
                        </h3>
                        <ul>
                          <li>
                          User initiates token swap on Layer One X from source to target blockchain.
                          </li>
                          <li>
                          User approves to transfer assets from source network to source pool.
                          </li>
                          <li>User deposits assets on source pool.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>2.</span> Relayer 
                        </h3>
                        <ul>
                          <li>
                          Authorised relayer performs Pool Contract validation and event validation. 
                          </li>
                          <li>
                          Relayer initiates claim on destination pool.
                          </li>
                          <li>
                          Transaction on destination blockchain for dispersal of assets submitted.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>3.</span> Confirmation and broadcast
                        </h3>
                        <ul>
                          <li>
                          On destination transaction confirmation, include both Source Transaction Hash and Destination Transaction Hash in Layer One X interoperability transaction.
                          </li>
                          <li>
                          Broadcast the interoperability transaction on the Layer One X network.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>4.</span> Leader selection and validation
                        </h3>
                        <ul>
                          <li>
                          Dynamic groups are formed with random leader selection.
                          </li>
                          <li>
                          Each leader performs event validation and Pool Contract validation.
                          </li>
                          <li>
                          The Leader creates validation proof and partial integrity.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>5.</span> Transaction broadcast to Validator nodes
                        </h3>
                        <ul>
                          <li>
                          Each Validator sends transaction response to the Leader.
                          </li>
                          <li>
                          Leader aggregates response from Validator. 
                          </li>
                          <li>
                          Leader aggregates response from Validator nodes and computes final integrity.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="box">
                        <h3>
                          <span>6.</span> Final response sent to Constructor
                        </h3>
                        <ul>
                          <li>
                          Constructor aggregates response from all leaders of the group and verifies final integrity.
                          </li>
                          <li>
                          Mints block for the validated transaction and broadcasts the block.
                          </li>
                          <li>
                          Full nodes validate the block.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section powering-apps power-unity text-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="title">
                    <h2>
                    Explore the power of unity  <br />
                      with Layer One X
                    </h2>
                    <p className="pb-80">
                    Get fast confirmation and instant finality with native transactions, and additional validations for secure interoperability at the base layer of blockchain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="cards-section">
                <div className="row">
                  <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde consensus-mechanism d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMConsensusMechanism
                              : ConsensusMechanism
                          }
                          alt="Consensus Mechanism"
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Consensus Mechanism</p>
                      <h3 className="pb-16">
                        A truly decentralised <br />consensus mechanism
                      </h3>
                      <p className="pb-24">
                        Mobile-enabled transaction validation means more<br/>
                         than 10,000 nodes can serve as validators in the Layer One X <br/>
                         network.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/consensus-mechanism">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde nativechain d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMNativechain
                              : Nativechain
                          }
                          alt="Native chain development "
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Native chain development </p>
                      <h3 className="pb-16">
                      Native Transaction <br/>Lifecycle
                      </h3>
                      <p className="pb-24">
                      Use Layer One X to accommodate transactions using a range <br/>of assets without the need for smart contracts.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/native-transaction-lifecycle">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
          </section>
        </div>
        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Read more in the <br /> whitepaper.
                  </h2>
                  <p>
                    Read the technical documentation and architectural overview
                    of the Layer <br /> One X blockchain network.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <a className="glow-button gradient-border-mask" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                      <span>View Whitepaper </span>{" "}
                      <i className="fa-solid fa-arrow-up-right-from-square pl-8"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
InteroperableTransactionLifecycle.propTypes = {
  theme: PropTypes.string,
};
