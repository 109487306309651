import React, { useState } from "react";
import "../../../src/nft-style.css";
import "../../../src/nft-fonts/stylesheet.css";
import NftInvest from "./nftInvest";
import NftHowItWorks from "./nftHowItWorks";
import NftGiveaway from "./nftGiveaway";
import NftTerms from "./nftTerms";

const NftComponent = () => {
  const [selectedTab, setSelectedTab] = useState("index");
  const [showNftMenu, setShowNftMenu] = useState(false);

  function toggleNftMenu(){
    return showNftMenu ?  setShowNftMenu(false) :  setShowNftMenu(true);
  }
  return (
    <>
      <header className="nav__bar__head">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse nft-tab" id="navbarText">
              <span className="navbar-text">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <button 
                      className={
                        selectedTab === "index" ? "nav-link nft-button active" : "nav-link nft-button"
                      }
                      aria-current="page"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTab("index");
                        setShowNftMenu(false);
                      }}
                    >
                      Contribute
                    </button>
                  </li>
                  <li className="nav-item">
                    <button 
                      className={
                        selectedTab === "how-it-works"
                          ? "nav-link nft-button active"
                          : "nav-link nft-button"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTab("how-it-works");
                        setShowNftMenu(false);
                      }}
                    >
                      How It Works
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        selectedTab === "nft-giveaway"
                          ? "nav-link nft-button active"
                          : "nav-link nft-button "
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTab("nft-giveaway");
                        setShowNftMenu(false);
                      }}
                    >
                      NFT Giveaway
                    </button>
                  </li>
                  <li className="nav-item teams__margin__right">
                    <button
                      className={
                        selectedTab === "terms" ? "nav-link nft-button active" : "nav-link nft-button"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTab("terms");
                        setShowNftMenu(false);
                      }}
                    >
                      Terms
                    </button>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </nav>
      </header>

      <div className="hamburger__menu__header">
        <div className="navigation">
          <input type="checkbox" id="navi-toggle" className="navigation__checkbox" checked={showNftMenu}/> 

          <label for="navi-toggle" className="navigation__button">
            <span className="navigation__icon" onClick={(e) => {
              toggleNftMenu();
            }}>
              <span className="navigation__icon-span">&nbsp;</span>
              <span className="navigation__icon-span">&nbsp;</span>
              <span className="navigation__icon-span">&nbsp;</span>
              <span className="navigation__icon-span">&nbsp;</span>
              <span className="navigation__icon-span">&nbsp;</span>
              <span className="navigation__icon-span">&nbsp;</span>
            </span>
          </label>

          <div className="navigation__background">&nbsp;</div>

          <nav className="navigation__nav">
            <ul className="navigation__list">
              <li className="navigation__item">
                

                <button 
                  className={"navigation__link"}
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTab("index");
                    setShowNftMenu(false);
                  }}
                >
                  Contribute
                </button>

              </li>
              <li className="navigation__item">
               
                <button 
                  className={"navigation__link"}
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTab("how-it-works");
                    setShowNftMenu(false);
                  }}
                >
                  How it works
                </button>

              </li>
              <li className="navigation__item">
                <button 
                  className={"navigation__link"}
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTab("nft-giveaway");
                    setShowNftMenu(false);
                  }}
                >
                  NFT Giveaway
                </button>
              </li>
              <li className="navigation__item">
                <button 
                  className={"navigation__link"}
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTab("terms");
                    setShowNftMenu(false);
                  }}
                >
                  
                  Terms
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {selectedTab === "index" ? <NftInvest /> : ""}
      {selectedTab === "how-it-works" ? <NftHowItWorks /> : ""}
      {selectedTab === "nft-giveaway" ? <NftGiveaway /> : ""}
      {selectedTab === "terms" ? <NftTerms /> : ""}

      {/* <NftHowItWorks/> */}
      {/* <NftGiveaway/> */}
      {/* <NftTerms/> */}
    </>
  );
};

export default NftComponent;
