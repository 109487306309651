import React from "react";

const RegistrationMessage = () => {
  return (
    <>
      <section className="banner-transparent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="campaign__paused__head">Thank you for your interest. We will get back to you soon.</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default RegistrationMessage;

