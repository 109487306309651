import React, { useState, useEffect } from "react";
import { serviceGetProspectExits, servicePostGenerateEmailOtp, servicePostVerifyEmailOtp } from "../../services/apiService";
import AuthService from "../../services/authService";
import CircularProgress from "../CircularProgress/index";
import validator from "validator";
import Config from "../../config/index"
import logger from "../../utils/logger";
import Notify from "../../utils/notify";
import Countdown from "react-countdown";

const Registration = (props) => {
  const intialValues = { email: "", otp: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [regenerateOTP, setRegenerateOTP] = useState(false);
  const [showForm, setShowForm] = useState(Config.sectionID.registrationLanding)
  const [otpButtonTime] = useState(15000)
  async function callGenerateOtp() {
    await generateOtp();
  }

  useEffect(() => {
    if (props.formValue) {
      setShowForm(props.formValue)
      intialValues.email = props.email
      callGenerateOtp()
    }

  }, []);

  useEffect(() => {


    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      // submitForm();
      if (showForm === Config.sectionID.registrationLanding)
        submitEmail();

      if (showForm === Config.sectionID.generateOTP)
        generateOtp()

      if (showForm === Config.sectionID.verifyOTP)
        submitEmailWithOtp()
    }
  }, [formErrors]);


  const submitEmail = async () => {
    try {
      logger("formValues", formValues);
      let form_data = {
        "email": formValues.email
      }
      // return setShowForm(1)
      let _res = await serviceGetProspectExits(form_data);

      if (_res.status === "success") {


        await generateOtp();

        setIsSubmitting(false);

        return setShowForm(Config.sectionID.verifyOTP);

      }
      setIsSubmitting(false);
      logger("serviceGetProspectExits", _res);
      return props.handleForm(Config.sectionID.basicDetailsForm, formValues.email)

    } catch (e) {
      setIsSubmitting(false);
      Notify('Error while submitting form', 'error');
      logger("exception", e.message);
    }
  };

  const generateOtp = async () => {
    try {
      logger("formValues", formValues);
      let form_data = {
        "email": formValues.email
      }

      // setShowForm(2)
      let _res = await servicePostGenerateEmailOtp(form_data);

      if (_res.status === "success") {
        setIsSubmitting(false);
        setRegenerateOTP(false);

        Notify(`${_res.message}`, 'success')
        return setShowForm(Config.sectionID.verifyOTP)
      }

      setIsSubmitting(false);
      return Notify(`${_res.message}`, "error")
    } catch (e) {
      setIsSubmitting(false);
      return Notify('Error while submitting form', 'error')
    }
  };


  const submitEmailWithOtp = async () => {
    try {
      logger("formValues", formValues);
      let form_data = {
        "email": formValues.email,
        "verification_code": formValues.otp
      }



      let _res = await servicePostVerifyEmailOtp(form_data);

      logger("servicePostVerifyEmailOtp", _res)

      if (_res.status === "success") {
        setIsSubmitting(false);

        AuthService.storeAuthToken(_res.data.token);
        if (parseInt(_res.data.completion_stage) === 1) {
          if (Config.holdBuyingFlow === true) {
            props.handleForm(Config.sectionID.whitelistingDone);
          }
          else {
            props.handleForm(Config.sectionID.KYCForm);
          }
        }

        if (parseInt(_res.data.completion_stage) === 2) {
          props.handleForm(Config.sectionID.buySectionForm)
        }
        if (parseInt(_res.data.completion_stage) === 3) {
          props.handleForm(Config.sectionID.KYCFlagged)
        }
        return Notify(`${_res.message}`, 'success');
      }

      setIsSubmitting(false);
      return Notify(`${_res.message}`, 'error');

    } catch (e) {
      setIsSubmitting(false);
      return Notify('Error while submitting form', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateEmailForm(formValues));

  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateEmail(formValues));


  };

  function validateEmailForm(values) {
    let errors = {};
    if (validator.isEmpty(values.email)) {
      errors.email = "Please enter a email address";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Invalid email format";
    } else if (validator.isEmpty(values.otp)) {
      errors.otp = "Please enter otp";
    } else if (!validator.isLength(values.otp, { min: 4, max: 10 })) {
      errors.otp = "Invalid opt";
    }

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    }
    return errors;
  }

  function validateEmail(values) {
    let errors = {};
    if (validator.isEmpty(values.email)) {
      errors.email = "Please enter a email address";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Invalid email format";
    }
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    }
    return errors;
  }


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setRegenerateOTP(true)
    } else {
      // Render a countdown
      return (
        <span>
          {seconds} sec
        </span>
      );
    }
  };

  return (
    <>
      {/* <section className="banners-section sub-banner"> */}
      <section className="banner-transparent">
        <div className="text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <h3 className="pb-18 mb-0">{showForm === Config.sectionID.registrationLanding ? "Buy Now" : "Verify your email"}</h3>
                  <h5>Limited spots available </h5>
                  {/* Registation form with Email*/}
                  {showForm === Config.sectionID.registrationLanding ? <form
                    id="registrationForm"
                    onSubmit={handleEmailSubmit}
                    noValidate
                  >
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email address
                      </label>
                      <div className="d-flex position__relative">
                        <input
                          type="email"
                          value={formValues.email}
                          name="email"
                          onChange={handleChange}
                          className="form-control email__generate__otp__txt__box"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Please provide your email address"
                        />
                      </div>
                      {formErrors.email && (
                        <span className="error">{formErrors.email}</span>
                      )}
                    </div>


                    {isSubmitting === true ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    )}
                  </form> : ""}


                  {/* Registation form with Email with otp button*/}
                  {showForm === Config.sectionID.generateOTP ? <form
                    id="registrationForm"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email address  <span className="asteric">*</span>
                      </label>
                      <div className="d-flex position__relative">
                        <input
                          type="email"
                          value={formValues.email}
                          name="email"
                          onChange={handleChange}
                          className="form-control email__generate__otp__txt__box"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        // disabled
                        />
                        <button
                          // href="/#"
                          type="button"
                          onClick={() => generateOtp()}
                          className="generate__otp__a"
                        >
                          Generate Verification Code
                        </button>
                      </div>
                      {formErrors.email && (
                        <span className="error">{formErrors.email}</span>
                      )}
                    </div>
                  </form> : ""}




                  {/* Registation form with Email and otp fields */}
                  {showForm === Config.sectionID.verifyOTP ? <form
                    id="registrationForm"
                    onSubmit={handleSubmit}
                    noValidate
                  >

                    <p style={{ textAlign: 'justify' }}>
                      {/* <small>If you do not see the email in a few minutes, Please check your “junk mail” folder or “spam” folder. </small> */}
                      <small>
                        In order to verify you, please enter the verification code sent to the email address you entered below. If you do not see your email within a few minutes, please check your ‘junk mail’ or ‘spam’ folder. If you still do not see the email, please click ‘Regenerate Verification Code’
                      </small>
                    </p>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email address  <span className="asteric">*</span>
                      </label>
                      <div className="d-flex position__relative">
                        <input
                          type="email"
                          value={formValues.email}
                          name="email"
                          onChange={handleChange}
                          className="form-control email__generate__otp__txt__box"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        // disabled
                        />
                        {regenerateOTP ? <button
                          // href="/#"
                          type="button"
                          onClick={() => generateOtp()}
                          className="generate__otp__a"
                        >
                          Regenerate Verification Code
                        </button> : <button className="generate__otp__a" ><Countdown date={Date.now() + otpButtonTime} renderer={renderer} ></Countdown></button>}
                      </div>
                      {formErrors.email && (
                        <span className="error">{formErrors.email}</span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label for="" className="form-label">
                        Enter Verification Code  <span className="asteric">*</span>
                      </label>
                      <input
                        type="text"
                        name="otp"
                        value={formValues.otp}
                        onChange={handleChange}
                        className="form-control otp-input"
                        id=""
                      />

                      {formErrors.otp && (
                        <span className="error">{formErrors.otp}</span>
                      )}
                    </div>

                    {isSubmitting === true ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    )}
                  </form> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registration;
