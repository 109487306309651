import React, { useState, useEffect } from "react";
import { serviceAddPremiumWallet, serviceDeletePremiumWallet, serviceGetPremiumWalletList } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";
import deleteIcon from "../../images/delete.png"
const { ethers } = require('ethers');

const Prospects = ({ saleType }) => {
  const [walletList, setWalletList] = useState([]);
  const headerList = [
    "Wallet Address",
    "Created At",
    "Action"
  ];
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [authCodeBtnTxt, setAuthCodeBtnTxt] = useState('Reset');
  const [autoCodeFieldDisable, setAutoCodeFieldDisable] = useState(true);


  const handlePageChange = (activePage) => setOffset(activePage * limit);

  const getPremiumWalletList = async (data) => {
    try {
      setLoader(true);

      let _res = await serviceGetPremiumWalletList(data);

      if (_res.status === "success") {
        setWalletList(_res.data.walletList);
        setTotalPages(_res.data.totalPages);
        // logger("prospects Data", _res.data.prospectList);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };

  const deleteWalletAddress = async (_walletAddress) => {
    try {
      setLoader(true);

      console.log("_walletAddress", _walletAddress)
      let data = {
        wallet_address: _walletAddress,
        auth_code: authCode
      }

      let _res = await serviceDeletePremiumWallet(data);

      if (_res.status === "success") {
        Notify("Wallet address deleted successfully", 'success')

        let data = {
          limit: limit,
          offset: offset,
          walletAddress: ''
        }
        getPremiumWalletList(data);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };

  const addWalletAddress = async () => {
    try {
      setLoader(true);

      const isValid = ethers.utils.isAddress(walletAddress);

      if (!isValid) {
        return Notify("Please enter valid wallet address", 'error')
      }

      let data = {
        wallet_address: walletAddress,
        auth_code: authCode
      }
      let _res = await serviceAddPremiumWallet(data);

      if (_res.status === "success") {
        Notify("Wallet address added successfully", 'success')

        let data = {
          limit: limit,
          offset: offset,
          walletAddress: ''
        }
        getPremiumWalletList(data);

      } else {
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };
  useEffect(() => {
    let data = {
      limit: limit,
      offset: offset,
      walletAddress: walletAddress ? walletAddress : ''
    }
    getPremiumWalletList(data);

  }, [offset]);

  useEffect(() => {
    let data = {
      limit: limit,
      offset: offset,
      walletAddress: walletAddress ? walletAddress : ''
    }

    let _authCode = localStorage.getItem('premium_wallet_auth_code')
    if (_authCode !== null) {
      setAuthCode(_authCode)
    }


    getPremiumWalletList(data);
  }, []);

  function resetAuthCode() {

    if (authCodeBtnTxt === 'Save') {
      setAutoCodeFieldDisable(true)
      setAuthCode(authCode)
      setAuthCodeBtnTxt('Reset')
      window.localStorage.setItem("premium_wallet_auth_code", authCode)
    }
    else {
      setAutoCodeFieldDisable(false)
      setAuthCode('')
      setAuthCodeBtnTxt('Save')
    }

  }

  return (
    <div className="row premium_wallet_list_card">
      <div className="col-md-12">
        <label className="premium_wallet_title">Premium Wallet</label>

        <div className="add_premium_wallet_container">

          <div className="col-md-5">
            <div className="add_premium_wallet_form">
              <div className="premium_wallet_form_box">
                <label className="premium_wallet_add_form_label">Wallet Address</label>
                <input
                  name="walletAddress"
                  value={walletAddress}
                  onChange={(event) => setWalletAddress(event.target.value)}
                  className="form-control"
                  placeholder="Wallet address *"
                />
              </div>

              {/* <div className="premium_wallet_form_box">
              <label className="premium_wallet_add_form_label">Authorization code</label>
              <input
                name="authCode"
                value={authCode}
                onChange={(event) => setAuthCode(event.target.value)}
                className="form-control"
                placeholder="Enter authorization code *"
              />
            </div> */}

              <span className="add_icon_span">
                <button onClick={() => addWalletAddress()} className="add_premimun_wallet_btn"> Add Wallet</button>
              </span>
            </div>
          </div>
          <div className="col-md-2" />
          <div className="col-md-5">
            <div className="add_premium_wallet_form">
              <div className="premium_wallet_form_auth_box">
                <label className="premium_wallet_add_form_label">Authorization code</label>
                <input
                  disabled={autoCodeFieldDisable}
                  name="authCode"
                  value={authCode}
                  onChange={(event) => setAuthCode(event.target.value)}
                  className="form-control"
                  placeholder="Enter authorization code *"
                />
              </div>

              <span className="add_icon_span">
                <button onClick={() => resetAuthCode()} className="add_premimun_wallet_btn"> {authCodeBtnTxt}</button>
              </span>
            </div>
          </div>
        </div>

        <div className="number__box__head">
          <div
            className="searchContainer"
            style={{ display: "flex", color: '#000', alignContent: 'center', justifyContent: "space-between" }}
          >
            <div className="premium_wallet_card_title">
              Wallet List
            </div>
            <input
              type="text"
              onChange={async (e) => {
                let data = {
                  limit: limit,
                  offset: offset,
                  walletAddress: e.target.value
                }

                await getPremiumWalletList(data)
              }
              }
              style={{
                fontSize: 17,
                padding: 6,
              }}
              placeholder="Search.."
            />
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  {headerList.map((item, index) => (
                    <th>{item}</th>
                  ))}
                </tr>
              </thead>
              {loader === false ? (
                <tbody>
                  {walletList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.wallet_address}</td>
                      <td>
                        {item.created_at}
                      </td>
                      <td>
                        <span className="delete_icon_span">
                          <img onClick={() => { deleteWalletAddress(item.wallet_address) }} src={deleteIcon} alt="delete icon" className="delete_icon_premium" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                ""
              )}
            </table>
          </div>
          <Pagination
            offset={offset}
            onPageChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Prospects;
