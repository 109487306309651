import logger from "../utils/logger";
import apiCall from "./apiWrapper";
import Config from "../config";
/**
 *
 * @param post admin auth code
 * @returns success or error
 */
async function serviceAdminLogin(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/${Config.admin.prefixString}/admin/verify_auth_code`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns prospects data
 */
async function serviceAdminGetProspectsData(
  offset = 1,
  limit = 5,
  email = "",
  sale_type
) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/prospect_list?offset=${offset}&limit=${limit}&email=${email}&sale_type=${sale_type}`
  );
  return _response;
}

/**
 *
 * @returns prospect transaction data
 */
async function serviceAdminGetProspectTransactions(
  offset = 1,
  limit = 5,
  sale_type
) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/prospect_transactions_list?offset=${offset}&limit=${limit}&sale_type=${sale_type}`
  );
  return _response;
}

/**
 * @returns get admin dashboard stats
 */
async function serviceGetAdminDashboardStats(network = "ETH", sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/dashboard_stats?network=${network}&sale_type=${sale_type}`
  );
  return _response;
}

/**
 *
 * @returns get admin dashboard token_sale_balance
 */
async function serviceGetTokenSaleBalance(sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/token_sale_balance?sale_type=${sale_type}`
  );
  return _response;
}

/**
 *
 * @returns get admin dashboard stats for all networks
 */
async function servicePrivateSaleStats(sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/private_sale_stats?sale_type=${sale_type}`
  );
  return _response;
}

/**
 *
 * @returns get white listing status
 */
async function serviceGetWhiteListingStatus(sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/white_list_status?saleType=${sale_type}`
  );
  return _response;
}

/**
 *
 * @param post admin white listing status
 * @returns success or error
 */
async function serviceUpdateWhiteListingStatus(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/${Config.admin.prefixString}/admin/update_whitelist_status`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns get kyc override status
 */
async function serviceGetKycOverrideStatus(sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/kyc_override_status?saleType=${sale_type}`
  );
  return _response;
}

/**
 *
 * @param post admin kyc override status
 * @returns success or error
 */
async function serviceUpdateKycOverrideStatus(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/${Config.admin.prefixString}/admin/update_kyc_override_status`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}



/**
 *
 * @returns get kyc override status
 */
async function serviceGetPrivateSaleReferralPercent(sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/private_sale_referral_percent`
  );
  return _response;
}

/**
 *
 * @param post admin kyc override status
 * @returns success or error
 */
async function serviceUpdatePrivateSaleReferralReward(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/${Config.admin.prefixString}/admin/update_private_sale_referral_percent`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}


/**
 *
 * @returns get kyc override status
 */
async function serviceGetPublicSaleReferralPercent(sale_type) {
  let _response = await apiCall.adminGetData(
    `api/${Config.admin.prefixString}/admin/public_sale_referral_percent`
  );
  return _response;
}

/**
 *
 * @param post admin kyc override status
 * @returns success or error
 */
async function serviceUpdatePublicSaleReferralReward(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/${Config.admin.prefixString}/admin/update_public_sale_referral_percent`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param check transaction hash details
 * @returns transaction data
 */
async function serviceGetDirectInvestmentWalletAddress(_data) {

  let _response = await apiCall.adminGetData(`api/${Config.admin.prefixString}/admin/public_sale_direct_investment_address`);
  logger("serviceResponse", _response)
  return _response;
}


/**
 *
 * @param post  update direct investment wallet address
 * @returns success or error
 */
async function serviceUpdateDirectInvestmentWalletAddress(data) {
  logger("data", data)
  let _response = await apiCall.postRawJsonData(`api/${Config.admin.prefixString}/admin/update_public_sale_direct_investment_address`, data);
  logger("serviceResponse", _response)
  return _response;
}


/**
 *
 * @param
 * @returns list of wallet address
 */
async function serviceGetPremiumWalletList(_data) {

  let _response = await apiCall.getData(`api/wallet/list?offset=${_data.offset}&limit=${_data.limit}&walletAddress=${_data.walletAddress}`);
  logger("serviceResponse", _response)
  return _response;
}

/**
 * @param wallet_address 
 * @returns success / failure
 */
async function serviceAddPremiumWallet(_data) {

  let _response = await apiCall.postRawJsonData(`api/wallet/add`, _data);
  logger("serviceResponse", _response)
  return _response;
}

/**
 *
 * @param wallet_address
 * @returns success/ failure
 */
async function serviceDeletePremiumWallet(_data) {

  let _response = await apiCall.postRawJsonData(`api/wallet/delete`, _data);
  logger("serviceResponse", _response)
  return _response;
}

/**
 *
 * @param wallet_address
 * @returns success/ failure
 */
async function serviceGetMoonpayTxDetails(tx_hash) {

  let _response = await apiCall.getData(`api/ops_reconcile/moonpay/get_tx_details?tx_hash=${tx_hash}`);
  logger("serviceResponse", _response)
  return _response;
}

/**
 *
 * @param wallet_address
 * @returns success/ failure
 */
async function serviceAddCorrectedWalletAddress(_data) {

  let _response = await apiCall.postRawJsonData(`api/ops_reconcile/moonpay/process_tx`, _data);
  logger("serviceResponse", _response)
  return _response;
}

/**
 *
 * @param wallet_address
 * @returns success/ failure
 */
async function serviceGetBankTxDetails(internalRef) {

  let _response = await apiCall.getData(`api/ops_reconcile/bank_transfer/get_tx_details?internal_ref=${internalRef}`);
  logger("serviceResponse", _response)
  return _response;
}

/**
 *
 * @param wallet_address
 * @returns success/ failure
 */
async function serviceAddBankTxProcess(_data) {

  let _response = await apiCall.postRawJsonData(`api/ops_reconcile/bank_transfer/process_tx`, _data);
  logger("serviceResponse", _response)
  return _response;
}


/**
 *
 * @returns private sale investors
 */
async function serviceAdminGetPrivateSaleInvestors(
  offset = 1,
  limit = 5,
  sale_type
) {
  let _response = await apiCall.adminGetData(
    `api/private_sale_investments?offset=${offset}&limit=${limit}`
  );
  return _response;
}

/**
 * @param wallet_address 
 * @returns success / failure
 */
async function serviceUploadEarlyAccessCsv(_data) {

  let _response = await apiCall.postImage(`api/ops_reconcile/wallet/early_access/import_invite`, _data);
  logger("serviceResponse", _response)
  return _response;
}

/**
 * @param wallet_address 
 * @returns success / failure
 */
async function servicegetEarlyAccessList(data) {

  let _response = await apiCall.adminGetData(
    `api/ops_reconcile/wallet/early_access/list?offset=${data.offset}&limit=${data.limit}&auth_code=${data.authCode}&access_key=${data.accessKey}`
  );
  logger("serviceResponse", _response)
  return _response;
}

/**
 * @param wallet_address 
 * @returns success / failure
 */
async function serviceCheckEarlyAccess(accessKey) {

  let _response = await apiCall.adminGetData(
    `api/wallet/early_access/check_invite?access_key=${accessKey}`
  );
  logger("serviceResponse", _response)
  return _response;
}

/**
 * @param wallet_address 
 * @returns success / failure
 */
async function serviceGetEarlyAccessExplicitList(data) {

  let _response = await apiCall.adminGetData(
    `api/ops_reconcile/wallet/early_access/explicit_request/list?offset=${data.offset}&limit=${data.limit}&auth_code=${data.authCode}&email=${data.email}`
  );
  logger("serviceResponse", _response)
  return _response;
}

export {
  serviceAdminLogin,
  serviceAdminGetProspectsData,
  serviceAdminGetProspectTransactions,
  serviceGetAdminDashboardStats,
  serviceGetTokenSaleBalance,
  servicePrivateSaleStats,
  serviceGetWhiteListingStatus,
  serviceUpdateWhiteListingStatus,
  serviceGetKycOverrideStatus,
  serviceUpdateKycOverrideStatus,
  serviceGetPrivateSaleReferralPercent,
  serviceUpdatePrivateSaleReferralReward,
  serviceGetPublicSaleReferralPercent,
  serviceUpdatePublicSaleReferralReward,
  serviceGetDirectInvestmentWalletAddress,
  serviceUpdateDirectInvestmentWalletAddress,
  serviceGetPremiumWalletList,
  serviceAddPremiumWallet,
  serviceDeletePremiumWallet,
  serviceGetMoonpayTxDetails,
  serviceAddCorrectedWalletAddress,
  serviceGetBankTxDetails,
  serviceAddBankTxProcess,
  serviceAdminGetPrivateSaleInvestors,
  serviceUploadEarlyAccessCsv,
  servicegetEarlyAccessList,
  serviceCheckEarlyAccess,
  serviceGetEarlyAccessExplicitList
};
