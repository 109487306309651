function setData(_key,_data){
    try
    {
        window.localStorage.setItem(_key, JSON.stringify(_data))
        return true;
    }
    catch(e)
    {
        return false;
    }
}

function getData(_key,_data){
    try
    {
        let _data = window.localStorage.getItem(_key);
        return JSON.parse(_data);
    }
    catch(e)
    {
        return false;
    }
    
}

function removeData(_key){
    try
    {
        window.localStorage.removeItem(_key);
        return true;
    }
    catch(e)
    {
        return false;
    }
    
}

export default {
    setData,
    getData,
    removeData
}