import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Config from "../../config";

const NavBar = ({onValueChange}) => {
  const [isActive] = useState(true);

  const [saleType, setSaleType] = useState("");

  function handleLogout() {
    localStorage.removeItem("admin_auth_token");
  }

  function handleSaleChange(event) {
    localStorage.setItem("saleType", event.target.value);
    setSaleType(event.target.value);
  }

  useEffect(() => {
   
    setSaleType(localStorage.getItem("saleType"));
    onValueChange(saleType);
  }, [saleType]);

  return (
    <>
      <header className={isActive ? "header body-pd" : "header"} id="header">
        {/* <div className="header_toggle">
          {" "}
          <i class="bx bx-menu" id="header-toggle" onClick={() => setIsActive(!isActive)}></i>{" "}
        </div> */}
        <div>
          <select
            className="form-control"
            style={{ width: "150px" }}
            value={saleType}
            onChange={handleSaleChange}
          >
            <option  value="privateSaleContract">
              Private Sale
            </option>
            <option default value="publicSaleContract">Public Sale</option>
          </select>
        </div>
        <div className="header_img log__out__box">
          <div className="dropdown">
            <Link to={"/" + Config.adminUrlHash + "/admin-login"}>
              <button
                onClick={() => handleLogout}
                style={{ border: "none", backgroundColor: "none" }}
              >
                <i class="bx bx-log-in nav_icon lougout__icon"></i>
              </button>
            </Link>
            {/* <button
              className="dropdown-toggle profile__drpdown__btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button> */}
            {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Logout
                </a>
              </li>
            </ul> */}
          </div>

          {/* <div class="dropdown__head__profile">
            <a href="#">Dropdown</a>
            <div class="dropdown-content__head__profile">
              <a href="#">Profile</a><br/>
              <a href="#">Logout</a>
            </div>
          </div> */}
        </div>
      </header>
    </>
  );
};

export default NavBar;
