import logger from "../utils/logger";
import apiCall from "./apiWrapper";
import AuthService from "./authService";

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostReferralData(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    "api/prospect/register_as_referrer",
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function serviceGetProspectExits(data) {
  logger("data", data);
  let _response = await apiCall.getData(
    `api/prospect/exists?email=${data.email}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function serviceGetVerifyToken() {
  let _response = await apiCall.getData(
    `api/prospect/verify_token?token=${AuthService.getAuthToken()}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostGenerateEmailOtp(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/prospect/generate_otp`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostVerifyEmailOtp(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/prospect/verify_otp`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
async function servicePostRegisterAsInvestor(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/prospect/register_as_investor`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
async function servicePostBuySectionData(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/prospect/register_as_investor`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns generated referral code
 */
async function serviceGenerateReferralCode() {
  let _response = await apiCall.getData(`api/prospect/generate_referral_code`);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
async function servicePostKycData(data) {
  logger("data", data);
  let _response = await apiCall.postTokenJsonData(
    `api/prospect/process_kyc`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
async function serviceCheckBalance(accountAddress, network = "ETH") {
  let _response = await apiCall.getData(
    `api/prospect/check_balance?accountAddress=${accountAddress}&network=${network}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param {*} data
 * @returns
 */
async function serviceSubmitProspectTransaction(data) {
  logger("data", data);
  let _response = await apiCall.postTokenJsonData(
    `api/prospect_transaction/transaction_details`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param {*} data
 * @returns
 */
async function serviceReconcileProspectTransaction(data) {
  logger("data", data);
  let _response = await apiCall.postTokenJsonData(
    `api/prospect_transaction/transaction_reconcile`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns total investment done by the prospect
 */
async function serviceProspectTotalInvestment() {
  let _response = await apiCall.getData(`api/prospect/total_investment`);
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns Investment limit per kyc
 */
async function serviceKycInvestmentLimit() {
  let _response = await apiCall.getData(`api/prospect/kyc_investment_limit`);
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns prospect Investment history
 */
async function serviceProspectTransactions() {
  let _response = await apiCall.getData(
    `api/prospect_transaction/history?offset=0&limit=20`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 * @returns get white listing status
 */
async function serviceGetWhiteListingStatus() {
  let _response = await apiCall.getData(`api/prospect/white_list_status`);
  return _response;
}

/**
 * @returns get nft winner
 */
async function serviceGetNFTWinner() {
  let _response = await apiCall.getBlockchainData("/l1xpl_nft/randomWinner");
  return _response;
}

/**
 *
 * @param post data for joining the waiting list
 * @returns success or error
 */
async function serviceJoinWaitingList(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    "api/hackathon/join-waitlist",
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
// async function serviceCheckBalance(accountAddress, network = "ETH") {
//   let _response = await apiCall.getData(`api/prospect/check_balance?accountAddress=${accountAddress}&network=${network}`);
//   logger("serviceResponse", _response)
//   return _response;
// }

/**
 *
 * @param {*} data
 * @returns
 */
async function serviceSubmitPublicSaleTransaction(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/transaction_details`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param {*} data
 * @returns
 */
async function serviceReconcilePublicSaleTransaction(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/transaction_reconcile`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
async function serviceCheckAccountBalance(accountAddress, network = "ETH") {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/check_balance?accountAddress=${accountAddress}&network=${network}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a investor
 * @returns success or error
 */
async function servicePublicSaleProspect(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/register_as_investor`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostPublicSaleReferralData(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    "api/public_sale/prospect_transaction/register_as_referrer",
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function serviceRegisterL1xWalletWhiteListing(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    "api/wallet/register_l1x_wallet__whitelisting",
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

async function serviceGetPublicSaleProspectExits(email) {
  logger("serviceGetPublicSaleProspectExits", email);
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/exists?email=${email}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function serviceGetPublicSaleVerifyToken() {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/verify_token?token=${AuthService.getAuthToken()}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostPublicSaleOtp(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/generate_otp`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostPublicSaleVerifyOtp(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/verify_otp`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data for become a referral form
 * @returns success or error
 */
async function servicePostProspectKYC(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/prospect_kyc`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param get returb for become a referral form
 * @returns success or error
 */
async function serviceGetCheckProspectKyc(_address) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/check_prospect_kyc?address=${_address}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param get prospect transaction
 * @returns prospect totalInvestment
 */
async function serviceGetCheckProspectTransction(_address) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/check_prospect_transaction?address=${_address}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param get referral reward details
 * @returns referral dta
 */
async function serviceGetReferralReward(_referralCode) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/referrer_allocation_details?referral_code=${_referralCode}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param check transaction hash details
 * @returns transaction data
 */
async function serviceCheckDirectInvestment(_data) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/check_direct_investment?network=${_data.network}&txHash=${_data.txHash}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data direct investment
 * @returns success or error
 */
async function servicePostDirectInvestment(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/direct_investment`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param check transaction hash details
 * @returns transaction data
 */
async function serviceGetDirectInvestmentAddress() {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/direct_investment_address`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param check transaction hash details
 * @returns transaction data
 */
async function serviceCheckPremiumWalletAddress(_walletAddress) {
  let _response = await apiCall.getData(
    `api/wallet/check_wallet_address?wallet_address=${_walletAddress}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param contact id
 * @returns prospect contact detials
 */
async function serviceGetContactData(_contactId) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/fetch_contact_data?contact_id=${_contactId}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param email
 * @returns referral code
 */
async function serviceGetReferralCode(_email) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/fetch_referral_code?email=${_email}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param referral_code
 * @returns referral code
 */
async function serviceCheckReferralCode(_referralCode) {
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/check_referral?referral_code=${_referralCode}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data termsheet investor
 * @returns success or error
 */
async function servicePostTermsheetInvestor(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/termsheet_investor`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post bank transfer proof image
 * @returns success or error
 */
async function servicePublicSaleBankTransferConfirmation(data) {
  logger("data", data);
  let _response = await apiCall.postImage(
    `api/public_sale/prospect_transaction/bank_transfer/process`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post bank transfer get ref id
 * @returns success or error
 */
async function servicePublicSaleValidateBankRefID(refID) {
  logger("refID", refID);
  let _response = await apiCall.getData(
    `api/public_sale/prospect_transaction/bank_transfer/validate_bank_ref_id?internal_ref=${refID}`
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @param post data termsheet investor
 * @returns success or error
 */
async function servicePublicSaleBankTransferInitiate(data) {
  logger("data", data);
  let _response = await apiCall.postRawJsonData(
    `api/public_sale/prospect_transaction/bank_transfer/initiate`,
    data
  );
  logger("serviceResponse", _response);
  return _response;
}

/**
 *
 * @returns private sale investors
 */
async function serviceGetPrivateSaleInvestors(
  offset = 1,
  limit = 5,
  type = "wallet_address",
  search = ""
) {
  let _response = {};
  if (type == "wallet_address") {
    _response = await apiCall.getData(
      `api/private_sale/private_sale_investments?offset=${offset}&limit=${limit}&type=${type}&wallet_address=${search}`
    );
  }
  if (type == "email_address") {
    _response = await apiCall.getData(
      `api/private_sale/private_sale_investments?offset=${offset}&limit=${limit}&type=${type}&email=${search}`
    );
  }
  return _response;
}

/**
 *
 * @returns private sale investors
 */
async function serviceGetStakeData(_walletAddress, offset = 1, limit = 5) {
  let _response = await apiCall.getData(
    `api/dex/prospect_staking_list?wallet_address=${_walletAddress}&offset=${offset}&limit=${limit}`
  );

  return _response;
}

export {
  servicePostReferralData,
  serviceGetProspectExits,
  servicePostGenerateEmailOtp,
  servicePostVerifyEmailOtp,
  servicePostRegisterAsInvestor,
  servicePostBuySectionData,
  serviceGenerateReferralCode,
  servicePostKycData,
  serviceCheckBalance,
  serviceSubmitProspectTransaction,
  serviceGetVerifyToken,
  serviceKycInvestmentLimit,
  serviceProspectTotalInvestment,
  serviceProspectTransactions,
  serviceGetWhiteListingStatus,
  serviceReconcileProspectTransaction,
  serviceGetNFTWinner,
  serviceJoinWaitingList,
  serviceReconcilePublicSaleTransaction,
  serviceSubmitPublicSaleTransaction,
  serviceCheckAccountBalance,
  servicePublicSaleProspect,
  serviceRegisterL1xWalletWhiteListing,
  serviceGetPublicSaleProspectExits,
  serviceGetPublicSaleVerifyToken,
  servicePostPublicSaleOtp,
  servicePostPublicSaleVerifyOtp,
  servicePostProspectKYC,
  serviceGetCheckProspectKyc,
  serviceGetCheckProspectTransction,
  serviceGetReferralReward,
  serviceCheckDirectInvestment,
  servicePostDirectInvestment,
  servicePostPublicSaleReferralData,
  serviceGetDirectInvestmentAddress,
  serviceCheckPremiumWalletAddress,
  serviceGetContactData,
  serviceGetReferralCode,
  serviceCheckReferralCode,
  servicePostTermsheetInvestor,
  servicePublicSaleBankTransferConfirmation,
  servicePublicSaleBankTransferInitiate,
  servicePublicSaleValidateBankRefID,
  serviceGetPrivateSaleInvestors,
  serviceGetStakeData
};
