import React, { useState, useEffect } from "react";
import { serviceAdminGetProspectTransactions } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";


const ProspectTransactions = ({saleType}) => {
  const [transactionData, setTransactionData] = useState([]);
  const headerList = [
    "Transaction Hash",
    "Network",
    "Wallet Address",
    "Token",
    "Final Amount",
    "Conversion Rate",
    "Allocated Token",
    "Transaction Date",
  ];
  const [offset, setOffset] = useState(0);
  const [itemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);

  const handlePageChange = (activePage) => setOffset(activePage * itemsPerPage);

  const getTransactionData = async () => {
    try {
      setLoader(true);
      let saleType = "privateSaleContract"
      if(localStorage.getItem("saleType"))
      {
        saleType=localStorage.getItem("saleType");
      }
      let _res = await serviceAdminGetProspectTransactions(
        offset,
        itemsPerPage,
        saleType
      );

      console.log("_res",_res)

      if (_res.status === "success") {
        setTransactionData(_res.data.transactionsList.rows);
        setTotalPages(_res.data.totalPages);
        logger("prospects Data", _res.data.transactionsList.rows);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message,"");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching prospects ,please try agian","error")
      logger("error", e.message);
    }
  };

  useEffect(() => {
    async function fetchProspectData() {
      await getTransactionData();
    }

    fetchProspectData();
  }, [offset,saleType]);

  return (
    <>

      
      <div className="admin-container-fluid">
        <div className="row">
          {/* <div className="col-md-1"></div> */}
          <div className="col-md-12">
            <div className="dashboard__box__num">
              <div className="number__box__head">
                <div>
                  <div className="searchContainer">Prospect Transactions</div>
                <div className="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        {headerList.map((item, index) => (
                          <th>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    {loader === false ? (
                      <tbody>
                                 {" "}
                        {transactionData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.tx_hash ? item.tx_hash : "-"}</td>
                            <td>{item.network ? item.network : "-"}</td>
                            <td>
                              {item.wallet_address ? item.wallet_address : "-"}
                            </td>
                            <td>{item.token ? item.token : "-"}</td>
                            <td>
                              {item.final_amount ? item.final_amount : "-"}
                            </td>
                            <td>
                              {item.conversion_rate
                                ? item.conversion_rate
                                : "-"}
                            </td>
                            <td>
                              {item.tokens_alloted ? item.tokens_alloted : "-"}
                            </td>
                            <td>{item.tx_date ? item.tx_date : "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                  </div>
                  <Pagination
                    offset={offset}
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-1"></div> */}
        </div>
      </div>
    </>
  );
};

export default ProspectTransactions;
