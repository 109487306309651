import React, { useState } from "react";
import NavBar from "./navBar";
import InvestorList from "./investorList";
import "./style.css";
import AuthCodePage from "./authCodePage";

const PrivateSaleInvestors = (props) => {

  let [isAuthCodePresent, setAuthCodePresent] = useState(false);

  useState(() => {

    if (localStorage.getItem('premium_wallet_auth_code')) {
      setAuthCodePresent(true)
    }

  }, [])

  function checkAuthCode(_value) {
    setAuthCodePresent(_value)
  }

  return (
    <>
      <NavBar />
      <div className="premium_wallet_list_box">
        {isAuthCodePresent ? <InvestorList /> : <AuthCodePage checkAuthCode={(_value) => { checkAuthCode(_value) }} />}
      </div>

    </>
  );
};

export default PrivateSaleInvestors;
