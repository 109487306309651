/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Logo from "../images/logo.svg";
import Logohover from "../images/logohover.svg";
import Logotext from "../images/logotext.png";
import LMLogotext from "../images/logotext-lm.png";
import BackToTopButton from './BackToTopButton';
import PropTypes from "prop-types"
import Config from '../config';

import Discord from "../images/footer/Discord.svg";
import DiscordWhite from "../images/footer/Discord_white.svg";
import Facebook from "../images/footer/Facebook.svg";
import FacebookWhite from "../images/footer/Facebook_white.svg";
import Linkedin from "../images/footer/linkedin.svg";
import LinkedinWhite from "../images/footer/linkedin__white.svg";
import Medium from "../images/footer/Medium.svg";
import MediumWhite from "../images/footer/Medium_white.svg";
import Reddit from "../images/footer/Reddit.svg";
import RedditWhite from "../images/footer/Reddit_white.svg";
import Spotify from "../images/footer/Spotify.svg";
import SpotifyWhite from "../images/footer/Spotify_white.svg";
import Telegram from "../images/footer/Telegram.svg";
import TelegramWhite from "../images/footer/Telegram_white.svg";
import YouTube from "../images/footer/YouTube.svg";
import YouTubeWhite from "../images/footer/YouTube_white.svg";
import Twitter from "../images/footer/Twitter.svg";
import TwitterWhite from "../images/footer/Twitter_white.svg";
import InstagramBlack from "../images/footer/Instagram.svg";
import InstagramWhite from "../images/footer/Instagram_white.svg";

export class Footer extends Component {
  render() {
    // const theme=this.props

    return (


      <section className="footer">
        <div className="container">
          <div className="row ">
            <div className="col">

              <figure className="d-flex">
                <Link to="" className="navbar-brand d-flex">
                  <div className="logo-rotate">
                    <img
                      className="normal"
                      src={Logo}
                      width="47"
                      alt="Layer One X"
                    />
                    <img
                      className="hover"
                      src={Logohover}
                      width="47"
                      alt="Layer One X"
                    />
                  </div>
                  <img src={this.props.themeEnabled === 'light' ? LMLogotext : Logotext}
                    alt="Logo"
                    width="82"
                  />
                </Link>
              </figure>
            </div>
            <div className="col d-none d-md-block">
              <h3>DEVELOPERS</h3>
              <ul>
                <li><Link to='build-on-layeronex'>Build on Layer One X</Link></li>
                <li><Link to='build/grant-applications'>Grant Applications</Link></li>
                <li><a href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>View Whitepaper <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
                {/* <li><a >Inspect <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
        <li><a >Developer Resources <i className="fa fa-download"></i></a></li> */}
              </ul>
            </div>
            <div className="col d-none d-md-block">
              <h3>INVESTORS</h3>
              <ul>
                <li><Link to='public-sale'>{Config.publicSaleButtonLabel}</Link></li>
                <li><Link to='how-it-works'>How it Works</Link></li>
                {/* <li><a >Tokenomics</a></li> */}
              </ul>
            </div>
            <div className="col d-none d-md-block">
              <h3>USE CASES</h3>
              <ul>
                <li><Link to='use-cases/defi'> De-Fi</Link></li>
                {/* <li><a >Interoperability</a></li>
        <li><a >Digital Identity</a></li>
        <li><a >Micropayments</a></li>
        <li><a >Multi-Chain</a></li>
        <li><a >Healthcare</a></li>
        <li><a >Gaming</a></li> */}
              </ul>
            </div>
            <div className="col d-none d-md-block">
              {/* <h3>Apps and services </h3>
      <ul>
        <li> <Link to='integrations/wyde'>Wyde</Link></li>
      </ul> */}
              <h3>GENERAL</h3>
              <ul>
                <li><Link to="about-us/who-we-are">Who We Are</Link></li>
                {/* <li><a >L1X Labs</a></li> */}
                <li><Link to='about-us/join-the-team'>Join The Team</Link></li>
                <li><Link to="start-building">Contact</Link></li>
                <li><Link to="/terms-of-services">Terms of service</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/whitelist-terms-conditions">Whitelist Terms & Conditions</Link></li>
                <li><Link to="/cookie-policies">Cookie Policies</Link></li>
              </ul>
            </div>


          </div>
        </div>
        <div className='footer-bottom'>
          <div className="container">
            <div className="row ">
              <div className="col-md-6 d-flex">
                <p>© 2023 Layer One X. All rights reserved.</p>
                {/* <p><a >Terms of Use</a> <a >Privacy Policy</a></p> */}
              </div>
              <div className="col-md-6">
                <ul className='d-flex justify-content-start justify-content-md-end social-icon_wrapper'>
                  {/* <li>
          <a ><i className="fa-brands fa-twitter"></i></a>
        </li> */}
                  {/* 
                  <li>
                    <a href='https://discord.gg/LayerOneX' target={'_blank'}><i className="fa-brands fa-discord"></i></a>
                  </li>
                  <li>
                    <a href='https://t.me/Layer1X' target={'_blank'}><i className="fa-brands fa-telegram"></i></a>
                  </li>
                  <li>
                    <a href='https://twitter.com/LayerOneX' target={'_blank'}><i className="fa-brands fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href='https://www.linkedin.com/company/layer-one-x' target={'_blank'}><i className="fa-brands fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a href='https://medium.com/@Layeronex' target={'_blank'}><i className="fa-brands fa-medium"></i></a>
                  </li> */}

                  {/* <li>
                    <a href='https://medium.com/@Layeronex' target={'_blank'}><i className="fa-brands fa-medium"></i></a>
                  </li> */}

                  <li>
                    <a className='footer__icon__height' href='https://www.facebook.com/LayerOneX/' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Facebook : FacebookWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://www.instagram.com/layerone_x/' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? InstagramBlack : InstagramWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://twitter.com/LayerOneX' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Twitter : TwitterWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://discord.com/invite/LayerOneX' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Discord : DiscordWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://www.youtube.com/@LayerOneX' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? YouTube : YouTubeWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://medium.com/@Layeronex' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Medium : MediumWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://www.reddit.com/r/LayerOneX/' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Reddit : RedditWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://telegram.me/Layer1X' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Telegram : TelegramWhite}
                        alt="Logo"
                      // width="45"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://www.linkedin.com/company/74970324/admin/feed/posts/' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Linkedin : LinkedinWhite}
                        alt="Logo"
                      // width="25"
                      />
                    </a>
                  </li>

                  <li>
                    <a className='footer__icon__height' href='https://open.spotify.com/show/03qULl4aEnBIR1VYJqxWx7' target={'_blank'}>
                      <img src={this.props.themeEnabled === 'light' ? Spotify : SpotifyWhite}
                        alt="Logo"
                      // width="25"
                      />
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>



    )
  }

}
Footer.propTypes = {
  theme: PropTypes.string
}
