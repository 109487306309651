import React, { useState, useEffect } from "react";
import { serviceAdminGetProspectsData } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";

const Prospects = ({ saleType }) => {
  const [prospectsData, setProspectsData] = useState([]);
  const headerList = [
    "Email",
    "Name",
    "Final Amount",
    "Referral Code",
    "Referrar Code",
  ];
  const [offset, setOffset] = useState(0);
  const [itemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);

  const handlePageChange = (activePage) => setOffset(activePage * itemsPerPage);

  const getProspectsData = async (_email) => {
    try {
      setLoader(true);
      // let saleType = "privateSaleContract";
      // if (localStorage.getItem("saleType")) {
      //   saleType = localStorage.getItem("saleType");
      // }

      let _res = await serviceAdminGetProspectsData(
        offset,
        itemsPerPage,
        _email,
        saleType
      );

      if (_res.status === "success") {
        setProspectsData(_res.data.prospectList.rows);
        setTotalPages(_res.data.totalPages);
        logger("prospects Data", _res.data.prospectList);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "error");
      }
    } catch (e) {
      setLoader(false);
      logger("error", e.message);
      return Notify(
        "Error while fetching prospects ,please try agian",
        "error"
      );
    }
  };

  async function fetchProspectData() {
    await getProspectsData();
  }

  

  useEffect(() => {
  
    console.log("saleType", saleType)
    if (saleType === 'privateSaleContract') {
      fetchProspectData();
    }
  }, [offset]);

  useEffect(() => {
  
    console.log("saleType", saleType)
    if (saleType === 'privateSaleContract') {
      fetchProspectData();
    }
  }, [saleType]);


  return (
    <>
      <div className="admin-container-fluid admin_container_custom">
        <div className="row">
          {/* <div className="col-md-1"></div> */}

          {
            saleType === "privateSaleContract" ?

              <div className="col-md-12">
                <div className="dashboard__box__num">
                  <div className="number__box__head">
                    <div>
                      <div
                        className="searchContainer"
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        Prospects
                        <input
                          type="text"
                          onChange={async (e) =>
                            await getProspectsData(e.target.value)
                          }
                          style={{
                            fontSize: 17,
                            padding: 6,
                          }}
                          placeholder="Search.."
                        />
                      </div>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {headerList.map((item, index) => (
                                <th>{item}</th>
                              ))}
                            </tr>
                          </thead>
                          {loader === false ? (
                            <tbody>
                              {prospectsData.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.email}</td>
                                  <td>
                                    {item.first_name} {item.last_name}
                                  </td>
                                  <td>{item.total_amount}</td>
                                  <td>
                                    {item.referral_code
                                      ? item.referral_code
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {item.reffered_by ? item.reffered_by : "N/A"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            ""
                          )}
                        </table>
                      </div>
                      <Pagination
                        offset={offset}
                        onPageChange={handlePageChange}
                        totalPages={totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div> : <div
                className="searchContainer"
                style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
              >
                Prospects data is not available for public sale.
              </div>}

          {/* <div className="col-md-1"></div> */}
        </div>
      </div>
    </>
  );
};

export default Prospects;
