import React from "react";
import copy from "copy-to-clipboard";
import { Link } from "react-router-dom";
import Notify from "../../utils/notify"
import Config from "../../config";

const UserMessage = (props) => {
  const copyToClipboard = (data) => {
    copy(data);
    return Notify("Copied!","success")
  };
  return (
    <>
      <section className="banners-section sub-banner">
        {/* <div className="pro-ico-live text-center d-flex align-self-center p-118">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="pro-ico form__box__container">
                  <h3 className="pb-18 mb-0">Thank you for becoming referres, your referrer code is:<span className="asteric">{props.message}</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="campaign__paused__head referral__code__box">
                Thank you for becoming a referral,
                <br></br>Your referral code is :{" "}
                <span className="referral__code">{props.referral_code} </span>
                <img
                  onClick={() => copyToClipboard(props.referral_code)}
                  style={{ cursor: "pointer", marginLeft: 15 }}
                  src={require("../../images/copy.png")}
                  height={20}
                  width={20}
                  alt="copy"
                />
                <br></br>Your referral link is :{" "}
                <Link
                  className="referral__link"
                  target="_blank"
                  to={"/public-sale/" + props.referral_code}
                >
                  Click here!
                </Link>
                 <img
                  onClick={() => copyToClipboard(Config.referralBaseUrl+props.referral_link)}
                  style={{ cursor: "pointer", marginLeft: 15 }}
                  src={require("../../images/copy.png")}
                  height={20}
                  width={20}
                  alt="copy"
                />
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserMessage;
