/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import PropTypes from "prop-types";

const options = {
  responsiveClass: true,
  nav: true,
  dots: true,
  autoplay: true,
  navText: [
    '<span class="arrow prev"><i class="fa-solid fa-arrow-left-long"></i></span>',
    '<span class="arrow next"><i class="fa-solid fa-arrow-right-long"></i></span>',
  ],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
      margin: 16,
    },
    400: {
      items: 1,
      margin: 24,
    },
    600: {
      items: 2,
      margin: 24,
    },
    767: {
      items: 3,
      margin: 32,
    },
    1200: {
      items: 4,
      margin: 32,
    },
  },
};

export class RoadMap extends Component {
  render() {
    return (
      <>
        <section className="section roadmap text-left">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="mb-16">Roadmap for Layer One X</h2>
                  <p className="pb-48">Oct 2022 - Aug 2023</p>
                </div>
              </div>
            </div>
            <div className="cards-section">
              <OwlCarousel className="owl-theme" nav {...options}>
                <div className="item">
                  <div className="card">
                    <div className="check checked mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>October 2022</h4>
                    <ul>
                      <li>Foundation Setup</li>
                      <li>Layer One X PL Token</li>
                      <li>Brand Identity & Website Updates</li>
                      <li>Optimising Consensus Mechanism Code</li>
                      <li>Layer One X - EVM Chain Integration</li>
                      <li>
                        Interoperability - New Networks Introduced on TestNet
                        V2.0
                      </li>
                      <li>
                        API & Documentation for Wallet, Token & Interoperability
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div className="check checked mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>November 2022</h4>
                    <ul>
                      <li>Layer One X - EVM Chain Integration</li>
                      <li>Tokenomics Implementation</li>
                      <li>
                        Interoperability - New Networks Introduced on TestNet
                        V2.0
                      </li>
                      <li>NFT Implementation</li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div className="check checked mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>December 2022</h4>
                    <ul>
                      <li>Brand Identity & Website Updates</li>
                      <li>Optimising Consensus Mechanism Code</li>
                      <li>Layer One X - EVM Chain Integration</li>
                      <li>
                        Interoperability - New Networks Introduced on TestNet
                        V2.0
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div className="check mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>January 2023</h4>
                    <ul>
                      <li>Layer One X DEX Launch</li>
                      <li>Layer One X Multichain Wallet Launch</li>
                      <li>Developer Invitation V1.0</li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div className="check mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>Feburary 2023</h4>
                    <ul>
                      <li>Mainnet Launch V1.0</li>
                      <li>DAO Setup</li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div className="check mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>June 2023</h4>
                    <ul>
                      <li>Projected Public Token Sale</li>
                      <li>Bug Bounty Program</li>
                      <li>Developer Invitation V2.0</li>
                    </ul>
                  </div>
                </div>
                {/* <div className="item">
                  <div className="card">
                    <div className="check mb-24">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <h4>March 2023</h4>
                    <ul>
                      <li>Mainnet Public Token Sale</li>
                    </ul>
                  </div>
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </section>
      </>
    );
  }
}
RoadMap.propTypes = {
  theme: PropTypes.string,
};
