import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeclaredWinner from "./declared-winner";
import { serviceGetNFTWinner } from "../services/apiService";
import Notify from "../utils/notify";

const RevealNFTWinner = () => {
  const [winnerClass, setWinnerClass] = useState(false);
  const [userAddress, setUserAddress] = useState('');


  // Random User NFT Address 
  async function getNftWinnerId() {
    try {
      let _nftData = await serviceGetNFTWinner();

      if (_nftData.status === "success") {
        setWinnerClass(true)
        setUserAddress(_nftData.data)
        window.localStorage.setItem('nftWinnerAddress', _nftData.data)

        Notify(_nftData.data,"winner");
      }
    } catch (e) {
      console.log("Error", e.message)
      Notify("Error while getting winner address","error");
    }
  }

  useEffect(() => {

    try {
      let winnerNftAddress = window.localStorage.getItem('nftWinnerAddress');

      if (winnerNftAddress != null) {
        setUserAddress(winnerNftAddress)
        setWinnerClass(true)
      }
    } catch (error) {
      console.log("error", error)
      Notify("Error while getting winner address","error");
    }

  }, [])

  return (
    <>
      <div className="winner-background-img">
        {winnerClass ? <DeclaredWinner userAddress={userAddress} /> :
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 m-auto">
                <div className="buttons winner-reveal-btn-box" >
                  <h3 className="winner-title">Reveal NFT Winner </h3>

                  <Link
                    className={"btn btn-style-one "}
                    onClick={() => {
                      getNftWinnerId()
                    }}
                  >
                    Reveal
                  </Link>
                </div>
              </div>
            </div>
          </div>}
      </div>
    </>
  );
};

export default RevealNFTWinner;
