import React from "react"
import closeIcon from "../../images/close-menu-lm.svg";
// import Config from "../../config";

const CountryNotListed = (props) => {

    return (
        <div className="banner__fixed__card__box thank_you_card_height ">
            <div className="public_sale_early_access_header">
                <img
                    onClick={() => {
                        props.handleUserSteps('publicSaleRegistrationForm')
                    }}
                    className="public_sale_early_acces_close_icon"
                    src={closeIcon}
                    alt="public sale early access close icon"
                />
            </div>

            <div className="public_sale_not_aligible_txt">
                Your country is not listed to participate in Public Sale. Please get in touch with us at <a href="mailto:buy@l1x.foundation" className="not_listed_mail">buy@l1x.foundation</a> to discuss further on your investments.
            </div>

            <div className="public_sale_early_access_eligiable_box">
                {/* <label className="public_sale_early_access_txt">However you can still gain early access by</label>
                <ul className="public_sale_early_access_txt">
                    <li style={{ listStyle: 'disc' }}>Deposit ${Config.publicSalePremiumWalletMinInvestmentAmount.toLocaleString()} USD or more</li>
                    <li style={{ listStyle: 'disc' }}>Be a premium benefits wallet holder or</li>
                    <li style={{ listStyle: 'disc' }}>Gain access through our social competition
                        &nbsp;
                        <a rel="noopener noreferrer" style={{ cursor: 'pointer', color: 'black', textDecoration: "underline" }} href="https://gleam.io/fgHwl/win-1-of-25-layer-one-x-premium-wallets" target="_blank">
                            click here for details
                        </a>


                    </li>
                </ul> */}
            </div>

            {/* <div className="not_eligible_thanks_container "> */}
            {/* <img src={WalletIcon} className="early_access_wallet" alt="Early Access available"/> */}
            {/* <label className="not_eligible_thanks_label">Thank you for your interest, stay tuned for further information via email.</label> */}
            {/* <label onClick={() => handleAccessLearnMore()} className="early_access_learn_more">Learn more</label> */}
            {/* </div> */}

            <div className="public_sale_not_eligibility_box">
                <button
                    onClick={() => {
                        props.handleUserSteps('publicSaleRegistrationForm')
                    }}
                    type="button"
                    className="btn btn-primary not_eligible_close_btn"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default CountryNotListed