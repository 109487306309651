import React from "react";
import { ethers } from "ethers";
import { NumericFormat } from "react-number-format";
import detectEthereumProvider from "@metamask/detect-provider";
import CircularProgress from "../CircularProgress/index";
import {
  serviceCheckAccountBalance,
  serviceSubmitPublicSaleTransaction,
  serviceReconcilePublicSaleTransaction,
  serviceGetCheckProspectKyc,
} from "../../services/apiService";

import logger from "../../utils/logger";
import ChainService from "../../services/chainService";
import Notify from "../../utils/notify";
import Config from "../../config";
import { v4 as uuidv4 } from 'uuid';
import PublicSalePaymentSuccess from "./publicSalePaymentSuccess";
import { Link } from "react-router-dom";
/** Smart contract abi files */
// const PrivateSaleAbi = require("../../config/abi/private-sale-contract.json");
const PublicSaleAbi = require("../../config/abi/public-sale-contract.json");
const ERC20Token = require("../../config/abi/ERC20Token.json");


const TermsComponents = (props) => {
  return (<div className="mb-1 form-check text-left">
    <input
      type="checkbox"
      name="acknowledge"
      value={props.value}
      onChange={props.handleClick}
      className="form-check-input whiteList_terms_checkbox"
      style={{ width: "1.5em", height: "1.5em" }}
    />
    {
      props.hyperLinkText ?
        <Link className="termandcondition__btn" target="_blank" to={props.link}>
          <label
            className="form-check-label form-label cursor__pointer"
            style={{ "verticalAlign": "sub", marginLeft: 10, fontWeight: 600 }}
          >
            {props.text} <u>{props.hyperLinkText}</u>
          </label>
        </Link> :
        <div className="termandcondition__btn" >
          <label
            className="form-check-label form-label cursor__pointer"
            style={{ "verticalAlign": "sub", marginLeft: 10, fontWeight: 600 }}
          >
            {props.text}
          </label>
        </div>
    }

  </div>
  )
}

const PublicBuySection = (props) => {
  const networkList = ChainService.getNetworks();
  const tokenList = ChainService.getTokens();

  const [submitButtonStatus, setSubmitButtonStatus] = React.useState("Approve");

  const [isMetamaskInstalled, setIsMetamaskInstalled] = React.useState(false);

  const [detectedWalletAddress, setDetectedWalletAddress] = React.useState(
    false
  );

  const [preferredNetwork, setPreferredNetwork] = React.useState("");
  const [preferredAmount, setPreferredAmount] = React.useState('');
  const [preferredToken, setPreferredToken] = React.useState("");
  const [preferredChainId, setPreferredChainId] = React.useState(false);

  const [preferredNetworkError, setPreferredNetworkError] = React.useState("");
  const [preferredAmountError, setPreferredAmountError] = React.useState("1");
  const [preferredTokenError, setPreferredTokenError] = React.useState("");

  const [showFormError, setShowFormError] = React.useState(false);

  const [networkSwitchInProgress, setNetworkSwitchInProgress] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [txReciept, setTxReciept] = React.useState(null);
  const [txComplete, setTxComplete] = React.useState(false);

  const [acknowledge, setAcknowledge] = React.useState(false);
  const [mainnetTerms, setMainnetTerms] = React.useState(false);
  const [terms, setTerms] = React.useState(false);

  const [disableApproveButton, setDisableApproveButton] = React.useState(true);

  React.useEffect(() => {

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        // window.location.reload();
      });
    }

    async function checkProspectKyc() {

      let checkProspectKycRes = await serviceGetCheckProspectKyc(props.walletAddress)
      console.log("checkProspectKycRes", checkProspectKycRes)
      if (checkProspectKycRes.status === 'success') {
        // props.handleUpdateCurrentStep(3)
        // props.handleUserSteps('publicSaleStep')
      }
    }

    checkProspectKyc();


    // Check Metamask Installation
    if (checkMetamaskInstalled()) {
      setIsMetamaskInstalled(true);
    }

    // Get Default Wallet Address

    // Get Default ChainId
    if (checkMetamaskInstalled()) {
      let _defaultChainId = getCurrentMetamaskChainId();

      console.log("useEffect > _defaultChainId ", _defaultChainId)
      if (_defaultChainId !== false && _defaultChainId !== null) {
        handleNetworkChange(_defaultChainId);
        return
      }

      // Detect Metamask
      window.ethereum.on("chainChanged", (_newChainId) => {
        // setDetectedChainId(_newChainId);
        handleNetworkChange(_newChainId);
      });

      // On Account Change
      window.ethereum.on("accountsChanged", function (accounts) {
        setDetectedWalletAddress(accounts[0]);
      });
    }
  }, [isMetamaskInstalled]);

  async function handleNetworkChange(_newChainId) {
    let _selectedNetworkName = getNetworkName(_newChainId);

    //if change network request received multiple times for same chainId return false
    // if (_newChainId === preferredChainId) {
    //   return false;
    // }

    setPreferredChainId(_newChainId);
    logger("preferredChainId", preferredChainId);
    updatePreferredNetwork(_selectedNetworkName);

    if (_selectedNetworkName !== false) {
      let _defaultWalletAddress = await getCurrentMetamaskWalletAddress();
      if (_defaultWalletAddress !== false) {
        setDetectedWalletAddress(_defaultWalletAddress);

        // Load Balances
      }
    } else {
      // Ask User to Selected Allowed Network
      updatePreferredNetwork(false);
    }
  }

  function getNetworkName(_chainId) {
    console.log("getNetworkName > getNetworkById ", _chainId)

    let _networkName = ChainService.getNetworkById(_chainId);
    if (_networkName !== null) {
      return _networkName;
    } else {
      return false;
    }
  }

  async function updatePreferredNetwork(_newNetwork) {
    console.log("_newNetwork", _newNetwork);

    setNetworkSwitchInProgress(true);
    setPreferredNetwork("Network-switch-ongoing");

    // Dont Switch Network
    if (_newNetwork === preferredNetwork) {
      return false;
    }

    if (_newNetwork !== null) {
      let _response = await requestMetasmaskNetworkChange(_newNetwork);

      setNetworkSwitchInProgress(false);
      if (_response === true) {
        setPreferredNetwork(_newNetwork);
      }
    }
  }

  async function requestMetasmaskNetworkChange(_networkName) {
    try {
      let _chainConfig = ChainService.getNetworkConfig(_networkName);
      if (_chainConfig === null) {
        logger(
          "error",
          "Error Occured while getting network config for " + _networkName
        );
        return false;
      }

      // Try Switching First and Then Try Adding


      if (_networkName === "ETH") {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: (_chainConfig.chainId),
            },
          ],
        });
      }
      else {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [_chainConfig],
        });
      }

      /* let _switchSucessfull = false;
      try
      {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: (_chainConfig.chainId),
            },
          ],
        });

        _switchSucessfull = true;
      }
      catch(e){

        _switchSucessfull = false;
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [_chainConfig],
        });
        
      } */


      // redetect wallet address
      let _defaultWalletAddress = await getCurrentMetamaskWalletAddress();
      if (_defaultWalletAddress !== false) {
        setDetectedWalletAddress(_defaultWalletAddress);

        // Load Balances
      }

      return true;
    } catch (e) {
      logger("exception", e);
      return false;
    }
  }

  // Check if Metamask is installed
  function checkMetamaskInstalled() {
    if (!window.ethereum) {
      // Notify("Please install metamask extension", "error");
      return false;
    }

    return true;
  }

  function getCurrentMetamaskChainId() {
    try {
      let _defaultChainId = window.ethereum.networkVersion;
      return _defaultChainId;
    } catch (e) {
      logger("exception", e);
      return false;
    }
  }

  async function getCurrentMetamaskWalletAddress() {
    try {
      if (!checkMetamaskInstalled()) {
        Notify("Please install metamask");
        return false;
      }

      let _walletResponse = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      let _accountAddress = _walletResponse[0];

      console.log("getCurrentMetamaskWalletAddress > getNetworkById ", window.ethereum.networkVersion)
      let _networkName = ChainService.getNetworkById(
        window.ethereum.networkVersion
      );

      if (_networkName === false) {
        Notify("Network Selected, currently is not supported ", "error");
        return false;
      } else {
        return _accountAddress;
      }
    } catch (e) {
      // Notify("Unable to Access Metamask Wallet Address", "error")
      logger("Unable to Access Metamask Wallet Address", e);
      return false;
    }
  }

  function isValidSubmission() {
    let _isErrorFree = true;
    let _minInvestment = props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount : Config.publicSalePremiumWalletMinInvestmentAmount;
    let _maxInvestment = Config.publicSaleMaxInvestmentAmount;

    // Validation for Network
    if (preferredNetwork === "") {
      setPreferredNetworkError("Please select Network from the list");
      _isErrorFree = false;
    } else {
      setPreferredNetworkError("");
      // _isErrorFree = true;
    }

    // Validation for Token
    if (preferredToken === "") {
      setPreferredTokenError("Please select Token from the list");
      _isErrorFree = false;
    } else {
      setPreferredTokenError("");
      _isErrorFree = true;
    }

    // Validation for Amount
    if (preferredAmount === '') {
      setPreferredAmountError("Please enter amount");
      _isErrorFree = false;
    } else {
      // Validation for Min. Investment Amount
      if (_minInvestment !== 0 && _maxInvestment !== 0) {
        if (
          parseFloat(preferredAmount) < _minInvestment ||
          parseFloat(preferredAmount) > _maxInvestment
        ) {
          setPreferredAmountError(
            "Please enter amount between " +
            _minInvestment +
            " and " +
            _maxInvestment +
            " " +
            preferredToken
          );
          _isErrorFree = false;
        } else {
          setPreferredAmountError("");
          _isErrorFree = true;
        }
      }
    }

    if (_isErrorFree === false) {
      return false;
    } else {
      return true;
    }
  }

  async function processPurchase() {
    let validateForm = isValidSubmission();

    console.log("validateForm", validateForm)
    if (validateForm === false) {
      setShowFormError(true);
      return false;
    }

    let formValues = {
      tokenName: preferredToken,
      amount: preferredAmount,
      networkName: preferredNetwork,
    };

    logger("processPurchase > formValues ", formValues)
    await approveTransaction(formValues);
  }

  async function approveTransaction(formValues) {
    logger("approveTransaction > formValues ", formValues)

    try {
      const { tokenName, amount, networkName } = { ...formValues };

      if (detectedWalletAddress === "" || detectedWalletAddress === false) {
        Notify("Please connect metamask wallet", "error");
        return false;
      }

      if (networkName === "network-switch-ongoing") {
        Notify("Please Switch Network on Metamask", "error");
        return false;
      }

      logger("approveTransaction > formValues ", formValues)

      setIsSubmitting(true);
      setSubmitButtonStatus("Approving Your Tx");

      //Getting token Address
      const _tokenAddress = ChainService.getTokenAddress(
        networkName,
        tokenName
      );

      logger("approveTransaction > getTokenAddress ", _tokenAddress)


      // Get Ether Signer
      const provider = await detectEthereumProvider();
      const tmpProvider = new ethers.providers.Web3Provider(provider);
      const signer = tmpProvider.getSigner();

      logger("approveTransaction > signer ", signer)

      logger("approveTransaction > _tokenAddress ", _tokenAddress)
      logger("approveTransaction > ERC20Token ABI ", ERC20Token.abi)
      logger("approveTransaction > tmpProvider ", tmpProvider)

      // Create Instance for Token
      const tokenContract = new ethers.Contract(
        _tokenAddress,
        ERC20Token.abi,
        tmpProvider
      );
      const tokenSigner = tokenContract.connect(signer);

      logger("approveTransaction > tokenSigner ", tokenSigner)


      // Fetch Decimal for Selected Token
      const _tokenDecimals = await tokenSigner.decimals();

      logger("approveTransaction > _tokenDecimals ", _tokenDecimals)

      const _srcAmountBN = ethers.utils.parseUnits(
        String(amount),
        _tokenDecimals
      );

      logger("approveTransaction > _srcAmountBN ", _srcAmountBN)



      //Getting private sale address from network name
      const _privateSaleAddress = ChainService.getPublicSaleAddress(
        networkName
      );

      logger("approveTransaction > _privateSaleAddress ", _privateSaleAddress)

      // Check Balance
      const _balance = await serviceCheckAccountBalance(
        detectedWalletAddress,
        networkName
      );

      logger("approveTransaction > _balance ", _balance)

      if (_balance.status === "success") {
        // Convert into Bignumber
        let _balanceBN = ethers.BigNumber.from(
          String(_balance.data[tokenName.toLowerCase() + "Decimal"])
        );
        if (_balanceBN.lt(_srcAmountBN)) {
          Notify("Insufficient Balance", "error");
          setIsSubmitting(false);
          setSubmitButtonStatus("Approve");
          return false;
        }
      }

      // Check for allowance
      const _allowanceBN = await tokenSigner.allowance(
        detectedWalletAddress,
        _privateSaleAddress
      );

      logger("approveTransaction > _allowanceBN", _allowanceBN)
      logger("approveTransaction > _srcAmountBN", _srcAmountBN)
      logger("approveTransaction > _allowanceBN.gte(_srcAmountBN)", _allowanceBN.gte(_srcAmountBN))

      // Greater than equal to
      if (_allowanceBN.gte(_srcAmountBN)) {
        await buyL1X(
          _privateSaleAddress,
          networkName,
          tokenName.toLowerCase(),
          _tokenAddress,
          _srcAmountBN
        );
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");
        return true;
      }
      else {

        // Patch for ETH-USDT pair if the previous allowance is low than current request
        if (tokenName === "USDT" && networkName === "ETH") {

          logger("approveTransaction > revoke previous allowance > Params > _privateSaleAddress", _privateSaleAddress)


          const _approveData = await tokenSigner.approve(
            _privateSaleAddress,
            ethers.BigNumber.from("0")
          );

          await _approveData.wait();
        }


        logger("approveTransaction > approve > Params > _privateSaleAddress", _privateSaleAddress)
        logger("approveTransaction > approve > Params > _srcAmountBN", _srcAmountBN.toString())

        const _approveData = await tokenSigner.approve(
          _privateSaleAddress,
          _srcAmountBN
        );

        logger("approveTransaction > _approveData", _approveData)

        const _approvalReceipt = await _approveData.wait();

        logger("approveTransaction > _approvalReceipt", _approvalReceipt)

        Notify("Transaction approved successfully", "success");

        await buyL1X(
          _privateSaleAddress,
          networkName,
          tokenName.toLowerCase(),
          _tokenAddress,
          _srcAmountBN
        );
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        return true;
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: buySection.js:562 ~ approveTransaction ~ error:",
        error
      );

      logger("exception", error);

      if (error.message.includes("user rejected transaction")) {
        Notify("Transaction cancelled in Metamask by you ", "error");
      } else {
        Notify("Error Occured: " + error.message, "error");
      }

      setIsSubmitting(false);
      setSubmitButtonStatus("Approve");

      return false;
    }
  }

  async function buyL1X(
    _privateSaleAddress,
    _network,
    _token,
    _tokenAddress,
    _amountBN
  ) {
    try {

      logger("buyL1X > _privateSaleAddress", _privateSaleAddress)
      logger("buyL1X > _network", _network)
      logger("buyL1X > _token", _token)
      logger("buyL1X > _tokenAddress", _tokenAddress)
      logger("buyL1X > _amountBN", _amountBN)

      setSubmitButtonStatus("Submit");

      const provider = await detectEthereumProvider();
      logger("buyL1X > provider", provider)

      const tmpProvider = new ethers.providers.Web3Provider(provider);
      logger("buyL1X > tmpProvider", tmpProvider)

      const signer = tmpProvider.getSigner();
      logger("buyL1X > signer", signer)

      const publicSaleContract = new ethers.Contract(
        _privateSaleAddress,
        PublicSaleAbi,
        tmpProvider
      );
      logger("buyL1X > publicSaleContract", publicSaleContract)

      const publicSaleSigner = publicSaleContract.connect(signer);
      logger("buyL1X > publicSaleSigner", publicSaleSigner)

      logger("buyL1X > serviceCheckBalance > params > detectedWalletAddress", detectedWalletAddress)
      logger("buyL1X > serviceCheckBalance > params > _network", _network)
      let _balance = await serviceCheckAccountBalance(detectedWalletAddress, _network);
      logger("buyL1X > _balance", _balance)

      if (_balance.status !== "success") {
        Notify("Error while fetching user balance", "error");
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        return false;
      }

      // Convert into Bignumber
      let _balanceBN = ethers.BigNumber.from(
        String(_balance.data[_token + "Decimal"])
      );

      logger("buyL1X > _balanceBN", _balanceBN)
      logger("buyL1X > _amountBN", _amountBN)
      logger("buyL1X > _balanceBN.gte(_amountBN)", _balanceBN.gte(_amountBN))

      if (_balanceBN.gte(_amountBN)) {
        setSubmitButtonStatus("Submitting Transaction");

        let _uuid = uuidv4();

        logger("buyL1X > privateSaleSigner invest > balance.data.kyc_id", _uuid)
        logger("buyL1X > privateSaleSigner invest > _amountBN ", _amountBN.toString())
        logger("buyL1X > privateSaleSigner invest > _tokenAddress ", _tokenAddress)



        const _investResponse = await publicSaleSigner.invest(
          _uuid,
          _amountBN,
          _tokenAddress
        );

        logger("buyL1X > _investResponse", _investResponse)

        Notify("Transaction broadcast successfully", "success")

        logger("buyL1X > serviceSubmitPublicSaleTransaction params >  _network", _network.toUpperCase())
        logger("buyL1X > serviceSubmitPublicSaleTransaction params >  wallet_address", detectedWalletAddress)
        logger("buyL1X > serviceSubmitPublicSaleTransaction params >  token_address", _tokenAddress)
        logger("buyL1X > serviceSubmitPublicSaleTransaction params >  token", _token.toUpperCase())
        logger("buyL1X > serviceSubmitPublicSaleTransaction params >  tx_hash", _investResponse.hash)

        let _submitTxData = {
          network: _network.toUpperCase(),
          wallet_address: detectedWalletAddress,
          token_address: _tokenAddress,
          token: _token.toUpperCase(),
          tx_hash: _investResponse.hash,
        };
        let _txSubmitResponse = await serviceSubmitPublicSaleTransaction(
          _submitTxData
        );

        logger("buyL1X > _txSubmitResponse", _txSubmitResponse);

        if (_txSubmitResponse.status !== "success") {
          Notify("Error while submitting data", "error");
          setIsSubmitting(false);
          setSubmitButtonStatus("Approve");

          return false;
        }



        const _investReceipt = await _investResponse.wait();
        Notify("Transaction added successfully", "success")

        logger("buyL1X > _investReceipt", _investReceipt);

        let _reconcileTxData = {
          tx_hash: _submitTxData.tx_hash,
          network: _network.toUpperCase(),
        };

        logger("buyL1X > _reconcileTxData > params >  tx_hash", _submitTxData.tx_hash);
        logger("buyL1X > _reconcileTxData > params > network", _network.toUpperCase());


        serviceReconcilePublicSaleTransaction(_reconcileTxData).then();

        // Notify("Transaction mine successfully", "success")

        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");
        setPreferredAmount('')
        setPreferredToken('')
        // setVisibleThankModal(true);

        // Update Stats

        let _receiptData = {
          txHash: _investResponse.hash,
          token: _token.toUpperCase(),
          network: _network.toUpperCase(),
          time: new Date(),
          amount: preferredAmount
        }

        setTxReciept(_receiptData);
        setTxComplete(true)

        // await prospectTransactionHistory();

        // Update Balances
        // let _tokenBalances = await getTokenBalances(detectedWalletAddress, formValues.networkName);
        // setTokenBalances(_tokenBalances);

        return true;
      } else {
        Notify("Insufficient Balance", "error");
        // setVisibleThankModal(false);
        setIsSubmitting(false);
        setSubmitButtonStatus("Approve");

        return false;
      }
    } catch (e) {
      logger("exception", e);

      if (e.message.includes("user rejected transaction")) {
        Notify("Transaction cancelled in Metamask by you ", "error");
      } else {
        Notify("Error Occured: " + e.message, "error");
      }

      // setVisibleThankModal(false);
      setIsSubmitting(false);
      return false;
    }
  }

  return (
    <>
      {txComplete ?
        <PublicSalePaymentSuccess txData={txReciept} /> :
        <>

          <div className="public__sale__tab__btn__section">
            <p className="public__sale__direct__investment">
              Unable to transfer {" "}
              <span
                className="direct_investment_click_link"
                onClick={() => props.handleUserSteps('publicSaleDirectInvestment')}
              >
                Click Here
              </span>
            </p>
            {/* <p className="public__sale__direct__investment">
              Are you a Termsheet investments {" "}
              <span
                className="direct_investment_click_link"
                onClick={() => props.handleUserSteps('termsheetInvestor')}
              >
                Click Here
              </span>
            </p> */}
            <div className="tab__btn__box">
              <h4 className="public_sale_card_heading">Buy Section</h4>
              <text className="buy__section__subheading">1 L1X = ${Config.publicSaleConversionRate}</text>
            </div>
          </div>

          <>
            {/* <h3 className="pb-18 mb-0">Buy Section</h3> */}

            {isMetamaskInstalled === false ? (
              <p>Please install Metamask first</p>
            ) : (
              <>

                <span className="buy__section__preferred_network ">{preferredNetwork}</span>
                <div className="buy_section_form_input_margin buy__section__form_box">
                  <label className="form-label buy_section_form_label">Select Network</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="network"
                    value={preferredNetwork}
                    onChange={(event) => {
                      updatePreferredNetwork(event.target.value);
                    }}
                    disabled={networkSwitchInProgress || isSubmitting}
                  >
                    <option key={"default-network"} value="">
                      Please Select Network
                    </option>

                    {networkSwitchInProgress ? (
                      <option
                        key={"network-switch-ongoing"}
                        value="network-switch-ongoing"
                      >
                        Please Switch Network on Metamask
                      </option>
                    ) : (
                      ""
                    )}

                    {networkList.map((_network) => {
                      return (
                        <option key={_network} value={_network}>
                          {ChainService.getFormalNetworkName(_network)}
                        </option>
                      );
                    })}
                  </select>
                  {showFormError && preferredNetworkError && (
                    <span className="error">
                      {preferredNetworkError}
                    </span>
                  )}
                </div>

                <div className="buy_section_form_input_margin">
                  <label className="form-label buy_section_form_label">Select Token</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="token"
                    value={preferredToken}
                    disabled={isSubmitting}
                    onChange={(event) => {
                      setPreferredToken(event.target.value);
                    }}
                  >
                    <option key={"default-token"} value="">
                      Select Token
                    </option>
                    {tokenList.map((element) => (
                      <option key={element} value={element}>
                        {element}
                      </option>
                    ))}
                  </select>
                  {showFormError && preferredTokenError && (
                    <span className="error">{preferredTokenError}</span>
                  )}
                </div>

                <div className="buy_section_form_input_margin">
                  <label className="form-label buy_section_form_label">
                    Enter Amount (
                    {"Min : $" +
                      (props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount.toLocaleString() : Config.publicSalePremiumWalletMinInvestmentAmount.toLocaleString()) +
                      ", Max : $" +
                      Config.publicSaleMaxInvestmentAmount.toLocaleString()}
                    )
                  </label>
                  <input
                    type="hidden"
                    name="amount"
                    value={preferredAmount}
                    className="form-control"
                    id=""
                  />

                  <NumericFormat
                    onValueChange={(values) => {
                      setPreferredAmount(values.value);
                    }}
                    thousandSeparator={true}
                    className="form-control"
                    disabled={isSubmitting}
                    placeholder=""
                    inputMode="numeric"
                    value={preferredAmount}
                  />
                  {preferredAmount !== '' && preferredAmount !== 0 ? <div className="amount__conversion__container mt-2">
                    <text className="amount__conversion__label">You will get </text>
                    <text className="amount__conversion__value">{parseFloat(preferredAmount) / Config.publicSaleConversionRate} L1X</text>
                  </div> : ''}

                  {showFormError && preferredAmountError && (
                    // <span className="error">
                    //   {preferredAmountError}
                    // </span>
                    <span className="error">
                      <div className="expected_amoun_error">
                        <p className="expected_amoun_error_txt">Please enter amount  </p>
                        <p className="expected_amoun_error_txt">*Min contribution amount is  ${props.premiumWalletAccess ? Config.publicSaleMinInvestmentAmount.toLocaleString() : Config.publicSalePremiumWalletMinInvestmentAmount.toLocaleString()} USD</p>
                        <p className="expected_amoun_error_txt">*Max contribution amount is {`<=`} ${Config.publicSaleMaxInvestmentAmount.toLocaleString()} USD</p>
                      </div>
                    </span>
                  )}
                </div>

                <TermsComponents handleClick={() => {
                  setAcknowledge(!acknowledge)

                  if (!acknowledge && terms && mainnetTerms) {
                    setDisableApproveButton(false)
                  }
                  else {
                    setDisableApproveButton(true)
                  }
                }}
                  text="Acknowledge that the tokens will be released upon the Genesis block"
                  hyperLinkText={null}
                // link="https://blog.l1x.foundation/terms-conditions/"
                />

                <TermsComponents handleClick={() => {
                  setMainnetTerms(!mainnetTerms)
                  if (terms && acknowledge && !mainnetTerms) {
                    setDisableApproveButton(false)
                  }
                  else {
                    setDisableApproveButton(true)
                  }
                }}
                  text="I have read and understand the details of the"
                  hyperLinkText="Mainnet Beta Documentation"
                  link="https://blog.l1x.foundation/what-is-layer-one-x-mainnet-beta/"
                />


                <TermsComponents handleClick={() => {
                  setTerms(!terms)
                  if (!terms && acknowledge && mainnetTerms) {
                    setDisableApproveButton(false)
                  }
                  else {
                    setDisableApproveButton(true)
                  }
                }}
                  text="I accept the "
                  hyperLinkText="Terms & Conditions"
                  link="https://blog.l1x.foundation/terms-conditions/"
                />


                {/* Submit Button */}
                {isSubmitting ? (
                  <button
                    disabled
                    onClick={() => logger("button", "Clicked")}
                    className="btn btn-primary buy-btn-loader mt-3"

                  >
                    <span className="loader-span">
                      <CircularProgress /> {submitButtonStatus}
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={disableApproveButton}
                    onClick={processPurchase}
                    className="btn btn-primary buy-btn-loader btn__buy__section mt-3"
                  >
                    {submitButtonStatus}
                  </button>
                )}

                <button disabled={isSubmitting} onClick={() => {
                  props.handleUpdateCurrentStep(3)
                  props.handleUserSteps('publicSaleStep')
                }} className="reciept__details__goBack__btn">Go Back</button>

                <hr />
              </>
            )}
          </>
        </>
      }
    </>
  );
};

export default PublicBuySection;
