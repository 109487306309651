import React from "react"

const PrivacyPolicy = () => {
    return <>
        <section className="banners-section sub-banner terms__servives__section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="terms__services__content__head">Layer One X Privacy Policy </h4>
                        <div className="terms__services__box__content">
                            <div className="web3 terms__services__content privacy__policy__main__container__bx">
                                <p>Layer One X is governed by the L1X Foundation (a Swiss Foundation with registration number CHE-311.270.462), under the name “L1X Foundation” or “Layer One X”, and its associated entities and any related bodies corporate (hereinafter referred to as 'us' 'we' or 'our'). We are committed to respecting and protecting your privacy.  </p>
                                <p>Our Privacy Policy sets out how we collect, use, store and disclose your personal information. By providing personal information to us, you consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy and any other arrangements that apply between us. </p>
                                <p>We may change our Privacy Policy from time to time by publishing changes to it on our website without prior notice. We encourage you to check our website periodically to ensure that you are aware of our current Privacy Policy. </p>
                                <h6>What Personal Information Do We Collect? </h6>
                                <p>Personal information means information which identifies you personally, or by which your identity can reasonably be ascertained, the information we may collect is listed below. We may collect personal information about you in order to facilitate your use of <a href="https://l1x.foundation" target="_blank">https://l1x.foundation</a>   (the “Site”) and to provide you with our services. </p>
                                <p>You are not obliged to, or may choose not to, provide personal information to us, however, please note that this may result in us limiting your access to the Site, limiting the provision of our services to you or being unable to provide our services to you. </p>
                                <p>We may collect the following types of personal information: </p>
                                    <ul>
                                        <li>Name; </li>
                                        <li>Date of birth;</li>
                                        <li>Mailing and residential address;</li>
                                        <li>Email address;</li>
                                        <li>Telephone number and other contact details;</li>
                                        <li>Your photographic identification, including (but not limited to) your driver’s licence or passport;</li>
                                        <li>Your banking details, including any account numbers and other details relevant to our services;</li>
                                        <li>Information about any your use of the Site;</li>
                                        <li>Your device ID, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information for the purposes of system administration and statistical data analysis;</li>
                                        <li>Details of the products and services we have provided to you or that you have enquired about, including any additional information necessary to deliver those products and services and respond to your enquiries;</li>
                                        <li>Any additional information relating to you that you provide to us through your use of our website, in person, from telephone conversations (which may be recorded), or through other websites or accounts from which you permit us to collect information;</li>
                                        <li>Information you provide to us through customer surveys; or</li>
                                        <li>Any other personal information that may be required in order to facilitate your dealings with us.</li>
                                        
                                    </ul>
                                    <h6>We may collect this information when you:</h6>
                                    <ul>
                                        
                                        <li>Register on the Site;</li>
                                        <li>Make any transaction of the Site;</li>
                                        <li>Communicate with us through correspondence, chats, email, or when you share information with us from other social applications, services or websites; or</li>
                                        <li>Interact with our sites, services, content and advertising.</li>
                                    </ul>
                              



                                <p className="mb-9">In addition, when you apply for a job or position with us, we may collect certain information from you (including your name, contact details, working history and relevant records checks) from any recruitment consultant, your previous employers and others who may be able to provide information to us to assist in our decision on whether or not to make you an offer of employment or engage you under a contract. This Privacy Policy does not apply to acts and practices in relation to employee records of our current and former employees.</p>

                                <h6>Cookies</h6>

                                <p>We may use a browser feature known as a ‘cookie’ which assigns a unique identifier to your computer.</p>

                                <p>Cookies are typically stored on your computer's hard drive. Information collected from cookies is used by us to evaluate the effectiveness of our site, analyse trends, and administer the Site.</p>

                                <p>Information collected from cookies allows us to determine for example which parts of our site are most visited, or any difficulties in accessing our Site.</p>

                                <p>With this knowledge, we can improve the quality of your experience on the Site.</p>

                                <p>We also use cookies and/or a technology known as web bugs or clear GIFs. They are typically stored in emails to confirm your receipt and response to our emails. It also provides a more personalised experience when using our Site.</p>

                                <p>We may use third party service provider(s), to assist us in better understanding the use of our Site. Our service provider(s) may place cookies on the hard drive of your computer. It may receive information that we select on how visitors navigate our site, what products are browsed, and general transaction information.</p>
                                                                                            
                                <p>Our service provider(s) analyses this information and provides us with aggregate reports. This information will be used to understand visitor interest in our Site and how to better serve those interests. The information collected by our service provider(s) may be linked to and combined with information that we collect about you while you are using the Site. Our service providers are contractually restricted from using information they receive from our Site other than to assist us.</p>

                                <p className="mb-3">By using our Site you agree that we may use cookies for the purposes set out above and as further set out in our Cookie Policy.</p>

                                <h6>How Do We Use Your Personal Information?</h6>

                                <p>Most of the personal information we collect is required to meet legal and regulatory obligations (e.g. to verify your identity). Otherwise, the personal information we collect is required to provide our services efficiently and effectively We may collect and use your personal information for the following purposes:</p>

                                <ul>
                                    <li>to enable you to access and use the Site and our services;</li>
                                    <li>to enable you to make transactions on the Site;</li>
                                    <li>to verify your identity, including via a third party identity verification provider;</li>
                                    <li>to send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you;</li>
                                    <li>to send you marketing and promotional messages and other information that may be of interest to you;</li>
                                    <li>to operate, protect, improve and optimise our website, services, business and our users’ experience, such as to perform analytics, conduct research and for advertising and marketing;</li>
                                    <li>to comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties; and</li>
                                    <li>to consider your employment application.</li>
                                </ul>
                                <h6>To Whom Do We Disclose Your Personal Information?</h6>
                                <p>We may disclose personal information for the purposes described in this Privacy Policy to:</p>
                                <ul>
                                    <li>Our employees and agents;</li>
                                    <li>Third party suppliers and service providers;</li>
                                    <li>Payment or transaction systems operators</li>
                                    <li>Our existing or potential agents, business partners or partners;</li>
                                    <li>Anyone to whom our assets or businesses (or any part of them) are transferred, or will potentially be transferred to;</li>
                                    <li>Other entities, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law.</li>
                                </ul>

                                <p>We may share users' personal information with law enforcement bodies; regulatory agencies; courts; arbitration bodies; and dispute resolution schemes.</p>

                                <p>If you request in writing, we may share your personal information with your nominee. Except where disclosure of your personal information is required by law or requested by you, we will generally require any third party which receives, or has access to, personal information to protect it. Also to use it only to carry out the services they are performing for you or for us, unless otherwise required or permitted by law.</p>

                                <p>We will ensure that any such third party is aware of our obligations under this Privacy Policy.</p>

                                <p>We will take reasonable steps to ensure that contracts we enter with such third parties binds them to terms no less protective of any personal information disclosed to them than the obligations we undertake to you under this Privacy Policy, or which are imposed on us under applicable data protection laws.</p>


                                <h6>Links</h6>

                                <p>Our Site may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on, those linked websites, and have no control over or rights in those linked websites. The privacy policies that apply to those other websites may differ substantially from our Privacy Policy, so we encourage you to read them before using those websites.</p>

                                <h6>Storage and Security</h6>

                                <p>We undertake a number of physical, administrative, personnel and technical measures to protect your personal information and prevent it from misuse, interference and loss, as well as unauthorised access, modification or disclosure.</p>

                                <p>However, we cannot guarantee the secure transmission of data over the Internet nor complete safety of electronic storage. As such, we request that you understand the responsibility to independently take safety precautions to protect your own personal information.</p>

                                <p>If you suspect that your personal information has been compromised, please notify us immediately.</p>

                                <h6>Accessing or Amending Your Personal Information</h6>

                                <p>We respect your privacy and will provide you with reasonable access to the personal information you have provided to us through your use of our services and the Site. If we hold personal information about you that is not accurate, complete or up-to-date, you may request us to change that information. We will respond to your request within a reasonable time, usually within 14 days. Please note that we may need to verify your identity when you request your personal information.</p>

                                <p>Personal information provided by you must not be false or misleading. We assume that the information you provide to us is accurate and will be updated when the relevant personal information changes.</p>

                                <p>We reserve the right to deny you access for any reason permitted under applicable law. If we deny access or amendment, we will provide you with written reasons for such denial unless it is unreasonable to do so and, where required by local data protection laws, will note your request and the denial in our records.</p>

                                <p>If you would like to access or amend any information we hold about you, please contact us on the details provided at the end of this Privacy Policy.</p>

                                <h6>Data Retention</h6>

                                <p>If you are subscribed to the mailing list, the data we collect is retained until you opt out of the mailing list. If you are not subscribed to the mailing list but have provided personal information, data is stored for a limited period of time to fulfil the purposes for which the data was originally collected. The data will be used and retained as necessary to meet our legal obligations.</p>

                                <p>If you would like to opt-out of receiving marketing communications from us, please click the unsubscribe button at the footer of our emails or contact us on the details provided at the end of this Privacy Policy.</p>

                                <h6>Amendments</h6>

                                <p>Our policies, content, information, promotions, disclosures, disclaimers and features may be revised, modified, updated, and/or supplemented at any time and without prior notice at the sole and absolute discretion of us. If we change this Privacy Policy, we will take steps to notify all users by a notice on our website and will post the amended Privacy Policy on the Site.</p>

                                <h6>Privacy Complaints</h6>

                                <p>If you have any questions about our Privacy Policy, or if you have a complaint about our handling of your personal information, please contact us using the contact information below. We aim to resolve your query as soon as practicable.</p>

                                <p>If we cannot resolve your complaint to your satisfaction, you may complain to the Privacy Commissioner who may investigate your complaint further.</p>

                                <h6>Contact Us</h6>

                                <p>To contact us use <a href="mailto:info@l1x.foundation">info@l1x.foundation</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default PrivacyPolicy