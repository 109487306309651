import * as Sentry from "@sentry/react";
import Config from "../config/index"

export default function logger(name = 'error', data = "log error") {

    //if loggger is error send to sentry
    if (name === 'error') {
        
        //Sentry Initialization
        Sentry.init({
            dsn: Config.sentry.dsn
        });

        Sentry.captureMessage(data);
    }

    return  console.log(name, data)

}

