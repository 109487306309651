/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PartialIntegrity from "../images/icons/partial-integrity.png";
import LMPartialIntegrity from "../images/icons/partial-integrity-lm.png";
import FinalIntegrity from "../images/icons/final-integrity.png";
import LMFinalIntegrity from "../images/icons/final-integrity-lm.png";
import BlockValidation from "../images/icons/icon-decentralisation.png";
import LMBlockValidation from "../images/icons/icon-decentralisation-lm.png";
import FullNodes from "../images/full-nodes.png";
import FullNodesmobile from "../images/full-nodes-mobile.png";
import Validators from "../images/validators.png";
import Validatorsmobile from "../images/validators-mobile.png";
import DelegatedStakers from "../images/delegated-stakers.png";
import DelegatedStakersmobile from "../images/delegated-stakers-mobile.png";
import Transactionvalidation from "../images/transaction-validation.png";
import LMTransactionvalidation from "../images/transaction-validation-lm.png";

import Nativechain from "../images/icons/icon-native-chain-development.png";
import LMNativechain from "../images/icons/icon-native-chain-development-lm.png";
import Interoperate from "../images/icons/icon-interoperate-without-oracles.png";
import LMInteroperate from "../images/icons/icon-interoperate-without-oracles-lm.png";


import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class ConsensusMechanism extends Component {
  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Build</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Consensus Mechanism
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4>Consensus Mechanism</h4>
                  <h1 className="mb-32">
                  A consensus mechanism to 
                  <br /> support interoperability and  <br />
                     scalability.
                  </h1>
                  <div className="buttons d-flex justify-content-center">
                    <div className="btn-style btn-style-one">
                      <a className="glow-button gradient-border-mask">
                        <span>Apply to Validate</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
<div className="main-sticky">
        <nav className="sticky-top section-sticky-top w-auto">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 m-auto">
                <ul>
                  <span>Jump to</span>
                  <li>
                    <a href="build/consensus-mechanism/#introduction">
                    Introduction
                    </a>
                  </li>
                  <li>
                    <a href="build/consensus-mechanism/#transaction-lifecycle">
                    Transaction Lifecycle
                    </a>
                  </li>
                  <li>
                    <a href="build/consensus-mechanism/#ways-to-participate">
                    Ways to participate
                    </a>
                  </li>                  
                </ul>
              </div>
            </div>
          </div>
        </nav>


        <section className="section web3-usecases our-goals state-of-the-art text-center bg-blue" id="introduction">
          <div className="container">
            <div className="row pb-80">
              <div className="col-md-6">
                <h3 className="text-left">
                Layer One X rewards users based on their roles in the system and their contribution of smart devices on the network.
                </h3>
              </div>
              <div className="col-md-6">
                <p className="pb-80 text-left">
                A proprietary interconnect framework “farms” mobile devices for the Layer One X decentralised computational pool. The Validation Pool, Consensus Pool, and Constructor Pool can each scale to thousands of devices, which enables Layer One X to deliver fast, secure transaction finality for blockchain layer workloads.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
              <figure className="transaction-validation-img">
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMTransactionvalidation
                            : Transactionvalidation
                        }
                        alt="Transaction validation"
                        className="img-fluid"
                      />
                    </figure>
                </div>
                </div>
          </div>
        </section>
        <section className="section web3-usecases our-goals state-of-the-art mobile-node-validation text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-left">
                Our mobile node validation 
                <br /> mechanism, simplified.
                </h3>
              </div>
              <div className="col-md-6">
                <p className="pb-80 text-left">
                Layer One X uses Partial and Final integrity to expedite the transaction validation process through the participation of mobile-enabled devices.
                </p>                  
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMPartialIntegrity
                            : PartialIntegrity
                        }
                        alt="Partial Integrity"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>
                      Partial Integrity
                      </h4>
                      <p>
                      Partial integrity can be defined as the proof of transaction validation from the Leader node. Validators utilise partial integrity to recheck Leader response for the respective transaction validation. This feature ensures Leader node accountability for transaction validation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMFinalIntegrity
                            : FinalIntegrity
                        }
                        alt="Final Integrity"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>
                      Final Integrity
                      </h4>
                      <p>
                      Final integrity can be defined as the proof of transaction validation from the entire group. It is the combination of partial integrity with the proof of transaction validation from Validators in the group. Full nodes can easily validate the transaction based on Final Integrity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={
                          this.props.themeEnabled === "light"
                            ? LMBlockValidation
                            : BlockValidation
                        }
                        alt="Block Validation"
                        className="img-fluid"
                      />
                    </figure>
                    <div className="box">
                      <h4>
                      Block Validation
                      </h4>
                      <p>
                      Once the constructor mints a block of valid transactions, it broadcasts the block to all the Full Nodes. All Full Nodes validate the transaction based on proof, partial integrity, and final integrity. After successful validation, Full Nodes add the block to the blockchain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* <section className="section web3-usecases text-left lifecycle" id="transaction-lifecycle">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="mb-80">
                Our transaction 
                <br /> lifecycle, simplified. 
                </h2>
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                   
                    <div className="box">
                      <h3>
                      <span>1.</span> Transaction creation
                      </h3>
                      <ul>
                        <li>User submits the transaction to transfer L1X tokens to another user using the L1X portal.</li>
                        <li>This process will be updated as L1X goes live.</li>
                      </ul>                    
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="box">
                     
                    <h3>
                      <span>2.</span> Leader authentication through full node sit amet consectetur. 
                      </h3>
                      <ul>
                        <li>Leader fetches the account state history of the sender from any peer full node. </li>
                        <li>Leader gets the latest transaction hash of the sender along with the balance from the full node.</li>
                        <li>This process will be updated as L1X goes live.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    
                    <div className="box">
                      
                    <h3>
                    <span>3.</span> Partial validation through 3 further full nodes amet 
                      </h3>
                      <ul>
                        <li>User submits the transaction to transfer L1X tokens to another user using the L1X portal.</li>
                        <li>This process will be updated as L1X goes live.</li>
                      </ul> 
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    
                    <div className="box">
                      <h3>
                      <span>4.</span> Validator response is give back to the leader node to be built by the constructor. 
                      </h3>
                      <ul>
                        <li>User submits the transaction to transfer L1X tokens to another user using the L1X portal.</li>
                        <li>This process will be updated as L1X goes live.</li>
                      </ul> 
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    
                    <div className="box">
                      <h3>
                      <span>5.</span> The validated block is sent to peers and tokens are transferred.
                      </h3>
                      <ul>
                        <li>User submits the transaction to transfer L1X tokens to another user using the L1X portal.</li>
                        <li>This process will be updated as L1X goes live.</li>
                      </ul>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

    
        <section className="section worldsweb3 text-center" id="ways-to-participate">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                  <div className="title">
                <h2>
                Ways to participate
                </h2>
                <p>
                Smart phones, tablets and smart watches and are the most common devices in the consensus pool. However, opportunities to be rewarded exist through storing or staking sharded data on enterprise-level networks.
                </p>
                </div>
                <div className="buttons">
                  <a className="btn btn-style-three" href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                    <i className="fa-solid fa-file"></i> View Litepaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                  <a className="btn btn-style-three" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                    <i className="fa-solid fa-file"></i> View Whitepaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </div>

                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h3>
                        Full Nodes
                        </h3>
                        <p>
                        Full nodes store the sharded copy of the blockchain state and maintain uptime for transaction execution. Minimum Staking and Hardware requirements. Earn rewards through coin inflation allocation and transaction fees.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={FullNodes}
                          alt="Full Nodes"
                          className="img-fluid desktop"
                        />
                        <img
                          src={FullNodesmobile}
                          alt="Full Nodes"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h3>Validators</h3>
                        <p>
                        No minimum staking is needed as a validator. Layer One X innovative user ID system lets mobile smart devices store and validate transactions. Information stored, such as P2P, ENR, etc., supports a decentralised transaction lifecycle.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 order-last order-md-first order-lg-first">
                      <figure>
                        <img
                          src={Validators}
                          alt="Validators"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Validatorsmobile}
                          alt="Validators"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h3>
                        Delegated Stakers
                        </h3>
                        <p className="pb-0">
                        Allocated Full Nodes or staking pools can earn rewards as part of the validation process.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={DelegatedStakers}
                          alt="Delegated Stakers"
                          className="img-fluid desktop"
                        />
                        <img
                          src={DelegatedStakersmobile}
                          alt="Delegated Stakers"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

     

        <section className="section powering-apps power-unity text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Explore the power of unity <br />
                    with Layer One X
                  </h2>
                  <p className="pb-80">
                  Get fast confirmation and instant finality with native transactions, and additional validations for secure interoperability at the base layer of blockchain.
                  </p>
                </div>                
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde nativechain d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMNativechain
                              : Nativechain
                          }
                          alt="Native chain development "
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Native chain development </p>
                      <h3 className="pb-16">
                      Native Transaction <br/>Lifecycle
                      </h3>
                      <p className="pb-24">
                      Use Layer One X to accommodate transactions using a range <br/>of assets without the need for smart contracts.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/native-transaction-lifecycle">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-full">
                    <div className="bg wyde interoperate d-flex justify-content-center flex-column">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMInteroperate
                              : Interoperate
                          }
                          alt="Interoperate without oracles"
                          className="img-fluid"
                        />
                      </figure>
                      <p className="pb-8">Interoperate without oracles</p>
                      <h3 className="pb-16">
                      Interoperable Transaction <br/>Lifecycle
                      </h3>
                      <p className="pb-24">
                      Fast, secure transactions in the fragmented <br/>blockchain and cryptocurrency ecosystem.
                      </p>
                      <div className="buttons d-flex justify-content-center">
                        <div className="btn-style btn-style-one btn-style-white">
                          <Link className="glow-button gradient-border-mask" to="../build/interoperable-transaction-lifecycle">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                  Read more in the  <br /> whitepaper.
                  </h2>
                  <p>
                  Read the technical documentation and architectural overview of the Layer  <br/> One X blockchain network.
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <a className="glow-button gradient-border-mask" href="https://docsend.com/view/yxxumg97x5mzv77v" target={'_blank'}>
                      <span>View Whitepaper </span> <i className="fa-solid fa-arrow-up-right-from-square pl-8"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
ConsensusMechanism.propTypes = {
  theme: PropTypes.string,
};
