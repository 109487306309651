/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo.svg";
import Logohover from "../images/logohover.svg";
import Logotext from "../images/logotext.png";
import LMLogotext from "../images/logotext-lm.png";
import Menuicon from "../images/hamburger-menu.svg";
import LMMenuicon from "../images/hamburger-menu-lm.svg";
import Menuclose from "../images/close-menu.svg";
import LMMenuclose from "../images/close-menu-lm.svg";
import DMtogglemobile from "../images/dm-toggle-mobile.svg";
import LMDMtogglemobile from "../images/lm-toggle-mobile.svg";
// import Preico from "../images/icon-pre-ico.svg";
// import LMPreico from "../images/icon-pre-ico-lm.svg";
import BrandGuide from "../images/L1X_Brand_Guide.pdf";



import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Config from "../config";

function resetHeader() {
  document.getElementById("menu").style.transform = "translate3d(0, 0, 0)";
}

function setHeader() {
  document.getElementById("menu").style.transform = "translate(100%, 0)";
  document.getElementById("menu").style.transform = "translate(100%, 0)";
}

const HeaderMobileMenuNew = (props) => {
  const navigate = useNavigate();

  return (
    <header className="mobile-header mobile-header-new">
      <div className="topbar d-flex  align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 d-none d-lg-block">
              <div className="topnav d-flex justify-content-end">
                <ul>
                  <li>
                    <a
                      href="https://docsend.com/view/4kbzzm4kvsq7xuv6"
                      target={"_blank"}
                    >
                      View Litepaper
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docsend.com/view/yxxumg97x5mzv77v"
                      target={"_blank"}
                    >
                      View Whitepaper
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                  <li>
                    <Link to="about-us/join-the-team">Join The Team</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar mobile-responsive">
        <input type="checkbox" id="main-nav-check" />
        <div id="menu">
          <div className="res-top d-flex align-items-center">
            <a
              onClick={() => {
                resetHeader();
                navigate("/");
              }}
              className="navbar-brand d-flex"
            >
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img
                src={props.themeEnabled === "light" ? LMLogotext : Logotext}
                width="82"
                alt="Layer One X"
              />
            </a>
            
            <label
              htmlFor="main-nav-check"
              id="close-menu"
              className="toggle"
              title="Close"
            >
              <img
                src={props.themeEnabled === "light" ? LMMenuclose : Menuclose}
                alt="close"
                onClick={() => {
                  resetHeader();
                }}
              />
            </label>
          </div>
          <ul>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <a>Developers</a>
              <label htmlFor="aboutus" className="toggle-sub"></label>
              <input
                type="checkbox"
                name="name"
                id="aboutus"
                className="sub-nav-check"
              />
              <ul id="aboutus-sub" className="sub-nav">
                <li className="sub-heading">
                  Developers
                  <label
                    htmlFor="aboutus"
                    className="toggle"
                    title="Back"
                  ></label>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://dev-portal.l1xapp.com/"
                    target="_blank"
                  >
                    Dev Portal
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://docsend.com/view/yxxumg97x5mzv77v"
                    target="_blank"
                  >
                    Whitepaper
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://docsend.com/view/4kbzzm4kvsq7xuv6"
                    target="_blank"
                  >
                    Lightpaper
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/ecosystem"
                    target="_blank"
                  >
                    Grants program
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a>L1X App</a>
              <label htmlFor="l1xApp" className="toggle-sub"></label>
              <input
                type="checkbox"
                name="name"
                id="l1xApp"
                className="sub-nav-check"
              />
              <ul id="l1xApp-sub" className="sub-nav">
                <li className="sub-heading">
                  L1X App
                  <label
                    htmlFor="l1xApp"
                    className="toggle"
                    title="Back"
                  ></label>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com"
                    target="_blank"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/swap"
                    target="_blank"
                  >
                    Swap
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/staking"
                    target="_blank"
                  >
                    Stake
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/balancer-pool-v3"
                    target="_blank"
                  >
                    Balancer Pool
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/feed"
                    target="_blank"
                  >
                    Feed
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a>Ecosystem</a>
              <label htmlFor="Ecosystem" className="toggle-sub"></label>
              <input
                type="checkbox"
                name="name"
                id="Ecosystem"
                className="sub-nav-check"
              />
              <ul id="Ecosystem-sub" className="sub-nav">

                  


                <li className="sub-heading">
                  Ecosystem
                  <label
                    htmlFor="Ecosystem"
                    className="toggle"
                    title="Back"
                  ></label>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://projects.l1x.foundation/"
                    target="_blank"
                  >
                    Projects
                  </NavLink>
                </li>
                
                <li>
                  <a
                    className="dropdown-item"
                    href="#poweredByNetwork"
                    smooth={true}
                    duration={800}
                  >
                    Partners
                  </a>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/swap/aboutNodeNFT#faq"
                    target="_blank"
                  >
                    Become a Validator
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/explorer"
                    target="_blank"
                  >
                    L1X Explorer
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://chromewebstore.google.com/detail/xwallet/pofheakpngfbdfeidiippmmckgpdceoh"
                    target="_blank"
                  >
                    XWallet
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a>Community</a>
              <label htmlFor="Community" className="toggle-sub"></label>
              <input
                type="checkbox"
                name="name"
                id="Community"
                className="sub-nav-check"
              />
              <ul id="Community-sub" className="sub-nav">
                <li className="sub-heading">
                  Community
                  <label
                    htmlFor="Community"
                    className="toggle"
                    title="Back"
                  ></label>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://l1xapp.com/account/assistance "
                    target="_blank"
                  >
                    Support
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://blog.l1x.foundation/ "
                    target="_blank"
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={BrandGuide}
                    target="_blank"
                  >
                    Brand Guidelines
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://twitter.com/LayerOneX "
                    target="_blank"
                  >
                    Twitter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://discord.com/invite/LayerOneX "
                    target="_blank"
                  >
                    Discord
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://t.me/Layer1X "
                    target="_blank"
                  >
                    Telegram
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="https://twitter.com/L1X_XTalks "
                    target="_blank"
                  >
                    Xtalks AMA Series
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="container">
          <div id="header" className="d-flex mbl-header-nav">
            <Link to="" className="navbar-brand d-flex">
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img
                src={props.themeEnabled === "light" ? LMLogotext : Logotext}
                width="82"
                alt="Layer One X"
              />
            </Link>
            <div className="mobile-launch-app">
            <a href="https://l1xapp.com/home" target="_blank" className="common-btn-style btn-orange">Launch App</a>
            
            </div>
            <label htmlFor="main-nav-check" className="toggle" title="Menu">
              <img
                src={props.themeEnabled === "light" ? LMMenuicon : Menuicon}
                alt="Layer One X"
                width=""
                onClick={() => {
                  setHeader();
                }}
              />
            </label>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderMobileMenuNew.propTypes = {
  theme: PropTypes.string,
};

export default HeaderMobileMenuNew;
