/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";

import PropTypes from "prop-types";

import 'react-notifications/lib/notifications.css';
import logger from "../utils/logger";
import Notify from "../utils/notify";
import { serviceJoinWaitingList } from "../services/apiService";

export class JoinWaitlist extends Component {

  
  constructor(props) {
    super(props);
    this.state = {
      processingForm: false
    };
  }

  async handleSubmitForm(e) {

    e.preventDefault();


    console.log(e.target.name.value)
    if (this.state.processingForm === true) {
      return;
    }


    try {


      let _data = {
        name: e.target.name.value,
        email: e.target.email.value,
        mobileno: e.target.phone.value,
        linkedinProfileLink: e.target.linkedinLink.value,
        githubProfileLink: e.target.githubLink.value,
        dietary: e.target.dietary.value
      }

      console.log("_data", _data)
      // return
      this.setState({ processingForm: true });

      let _res = await serviceJoinWaitingList(_data);
      console.log("_res", _res);

      if (_res.status === "success") {
        Notify("Thank you for reaching out, We'll get back to very soon. ", "success");
        e.target.reset();
        this.setState({ processingForm: false });
      }
      else {
        Notify(_res.message, "error");
        this.setState({ processingForm: false });

      }

    } catch (error) {
      Notify("Error while submitting enquiry", "error");
      this.setState({ processingForm: false });
      logger("error", error)
    }

  }

  render() {
    // const theme=this.props

    // Redirect to Dev Portal 
    window.location.replace('https://portal.l1x.foundation');
    return null;

    // return (
    //   <>
    //     <div className="bg-banner">
    //       <section className="banners-section sub-banner">
    //         <nav className="breadcrumbs">
    //           <div className="container">
    //             <ol className="breadcrumb">
    //               <li className="breadcrumb-item">
    //                 <Link to="/">
    //                   <i className="fa-solid fa-home"></i>
    //                 </Link>
    //               </li>
    //               {/* <li className="breadcrumb-item">
    //                 <a>Connect</a>
    //               </li> */}
    //               <li className="breadcrumb-item active" aria-current="page">
    //                 <a href="http://portal.l1x.foundation/" target="_blank">
    //                   Join the waitlist
    //                 </a>
    //               </li>
                  
    //             </ol>
    //           </div>
    //         </nav>
    //         <div className="banner-section text-center d-flex align-self-center">
    //           <div className="container">
    //             <div className="row">
    //               <div className="col-sm-12">
    //                 <h4>Join The Waitlist</h4>
    //                 <h1>
    //                   Register to Get Access to   <br />
    //                   L1X Event in Pune.
    //                 </h1>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </section>
    //     </div>

    //     <section className="section bg-blue text-left">
    //       <div className="container">
    //         <h6 className="join-waitlist-note"><strong>PLEASE NOTE THAT ONCE YOU REGISTER AND JOIN THE WAITLIST AND IF SUCCESSFUL YOU WILL BE SENT AN EMAIL INVITE OR A TEXT MESSAGE WITH ACCESS TO DEVELOPMENT PORTAL FOR L1X THAT WILL HAVE YOUR INVITATION DETAILS. NO FEES / CHARGES TO ATTEND THE EVENT.</strong> </h6>
    //         <div className="row mt-5">
    //           <div className="col-md-5">
    //             <h3 className="mb-0">Get in touch</h3>
    //             <p>Contact our team we will be happy to assist you </p>
    //             {/* <p className="quick">
    //               <a href="mailto:support@l1x.foundation">
    //                 <i className="fa-regular fa-envelope"></i>{" "}
    //                 support@l1x.foundation
    //               </a>
    //               <a href='https://discord.gg/X67bKBZJYe' target={'_blank'}>
    //                 <i className="fa-brands fa-discord"></i> Join The Discord
    //                 Community
    //               </a>
    //             </p> */}


    //             <table className="join-waitlist-table">
    //               <tr>
    //                 <th className="time-column">Time</th>
    //                 <th className="activity-column">Activity</th>
    //                 <th className="duration-column">Duration</th>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">8 - 9 AM</td>
    //                 <td className="activity-column">Breakfast</td>
    //                 <td className="duration-column">1 Hour</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">9 AM - 11 AM</td>
    //                 <td className="activity-column">Learn about L1X Protocol</td>
    //                 <td className="duration-column">2 Hours</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">11 AM - 11.30 AM</td>
    //                 <td className="activity-column">Explore the first of its kind decentralised L1X development portal</td>
    //                 <td className="duration-column">30 Minutes</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">11.30 AM - 12.30 PM</td>
    //                 <td className="activity-column">Learn about existing projects building with us</td>
    //                 <td className="duration-column">30 Minutes</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">12.30 PM - 2.00 PM</td>
    //                 <td className="activity-column">LUNCH</td>
    //                 <td className="duration-column">1.5 Hours</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">2 PM - 3 PM</td>
    //                 <td className="activity-column">Live Demonstration</td>
    //                 <td className="duration-column">1 Hour</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">3 PM - 6 PM</td>
    //                 <td className="activity-column">Hackathon</td>
    //                 <td className="duration-column">3 Hours</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">6 PM - 7.00 PM</td>
    //                 <td className="activity-column">Keynote Speech</td>
    //                 <td className="duration-column">1 Hour</td>
    //               </tr>
    //               <tr>
    //                 <td className="time-column">7.00 PM to Late</td>
    //                 <td className="activity-column">Dinner and Socialising</td>
    //                 <td className="duration-column"></td>
    //               </tr>
    //             </table>

    //           </div>
    //           <div className="col-md-7">

    //             <form className="get-in-touch join-hackathon-waitlist-form" onSubmit={(e) => this.handleSubmitForm(e)}>
    //               <div className="form-floating">
    //                 <input
    //                   type="text"
    //                   id='name'
    //                   name="name"
    //                   className="form-control"
    //                   placeholder="John Sm"
    //                   required
    //                 />
    //                 <label>Full Name <span className="red-astric">*</span></label>
    //               </div>
    //               <div className="form-floating">
    //                 <input
    //                   type="email"
    //                   name="email"
    //                   id="email"
    //                   className="form-control"
    //                   placeholder="support@example.com"
    //                   required
    //                 />
    //                 <label>Email <span className="red-astric">*</span></label>
    //               </div>
    //               <div className="form-floating">
    //                 <input
    //                   id="phone"
    //                   type="tel"
    //                   name="phone"
    //                   className="form-control"
    //                   placeholder="Please include Country Code with Phone Number"
    //                   required
    //                 />
    //                 <label>Mobile Number <span className="red-astric">*</span></label>
    //               </div>
    //               <div className="form-floating">
    //                 <input
    //                   id="linkedinLink"
    //                   type="text"
    //                   name="linkedinLink"
    //                   className="form-control"
    //                   placeholder="LinkedIn Profile Link"
                      
    //                 />
    //                 <label>LinkedIn Profile Link (This will help us screen your application for the invite)</label>
    //               </div>
    //               <div className="form-floating">
    //                 <input
    //                   id="githubLink"
    //                   type="text"
    //                   name="githubLink"
    //                   className="form-control"
    //                   placeholder="GitHub Profile Link"
                      
    //                 />
    //                 <label>GitHub Profile Link (This will help us screen your application for the invite)</label>
    //               </div>

    //               <div className="form-floating">
    //                 <textarea
    //                   id="dietary"
    //                   name="dietary"
    //                   className="form-control"
    //                   placeholder="Dietary Requirements (If any for Breakfast, Lunch and Dinner)"
    //                 ></textarea>
    //               </div>
    //               <div className="d-grid buttons">
    //                 <div className="btn-style btn-style-one m-0">
    //                   {
    //                     this.state.processingForm ?
    //                       <button className="glow-button gradient-border-mask">
    //                         <span>Processing...</span>
    //                       </button> :
    //                       <button type="submit" className="glow-button gradient-border-mask join-waitlist-form-btn">
    //                         <span>Join The Waitlist</span>
    //                       </button>
    //                   }
    //                 </div>
    //               </div>
    //             </form>

    //           </div>
    //         </div>
    //       </div>
    //     </section>


    //   </>
    // );
  }
}
JoinWaitlist.propTypes = {
  theme: PropTypes.string,
};
