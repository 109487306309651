/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from  "prop-types"

export class Error extends Component {
  render() {
    // const theme=this.props

    return (
      <> 
        <section className="banners-section sub-banner error-banner">
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                    <h4>404 Error</h4>
                  <h1>
                  Uh oh! I think you’re lost.
                  </h1>
                  <p>
                  It looks like the page you’re looking for doesn't exist.
                  </p>
                  <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                  <Link to='/' className="glow-button gradient-border-mask">
                      <span>Go back home</span>
                    </Link>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
Error.propTypes={
  theme:PropTypes.string
}