import React from "react"
import closeIcon from "../../images/close-menu-lm.svg";
import tickCircleIcon from "../../images/tick-circle.svg";

const BankTransferThankYou = (props) => {

    const backToRegister = () => {
        window.location.href = "/public-sale";
    }

    return (
        <div className="banner__fixed__card__box thank_you_card_height" style={{justifyContent:"normal"}}>
            <div className="public_sale_early_access_header">
                <img
                    alt="Transfer $USD from bank account"
                    onClick={backToRegister}
                    className="public_sale_early_acces_close_icon" src={closeIcon} />
            </div>  
            <div className="p-30">

                <div className="head__thankyou__box">
                    <img src={tickCircleIcon} />
                    <h4 className="thankyou__head">  Thank you for your contribution.</h4>
                </div>
                <div className="direct_thank_you_txt">
                    <p> Please let us know if you have any questions, email us at; <strong>invest@l1x.foundation</strong>. </p>
                </div>
            </div>

            <div className="public_sale_check_eligibility_box p-30">
                <button
                    onClick={() => {
                        // props.handleUserSteps('publicSaleRegistrationForm')
                        window.location.href = "/public-sale"
                    }}
                    type="button"
                    className="btn btn-primary w-100 close__thankyou__btn"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default BankTransferThankYou