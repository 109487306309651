/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import 'react-notifications/lib/notifications.css';
import Config from "../../config/index";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logger from "../../utils/logger";
import Notify from "../../utils/notify";
import apiWrapper from "../../services/apiWrapper";

export class PreRegistration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      processingForm: false
    };
  }


  async handleSubmitForm(e) {


    e.preventDefault();

    if (this.state.processingForm === true) {
      return;
    }


    try {

      let data = {
        "name": e.target.name.value,
        "email": e.target.email.value,
        "mobileno": e.target.phone.value,
        "referralCode": e.target.referrer_code.value,
        "message": e.target.message.value
      }

      this.setState({ processingForm: true });

      await apiWrapper.postRawJsonData("api/public_sale/prospect_transaction/pre_registation",data)
      await fetch(Config.hubspot.formUri, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(data)
      });

      Notify("Thank you for reaching out, We'll get back to very soon. ", "success");
      e.target.reset();
      this.setState({ processingForm: false });

    } catch (error) {
      Notify('Error while submitting enquiry', 'error')
      this.setState({ processingForm: false });
      logger("error", error)
    }

  }


  render() {
    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Public sale</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Pre - Registration
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h1 className="mb-16">Buy L1X</h1>
                  <p className="pb-0 mb-0 fontsize-26">
                  Secure your spot in the growing future of Web3 by pre-registering for the L1X Public Sale waitlist!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-blue text-center join-our-community">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 m-auto">
                <h3 className="mb-0 pb-24">
                  Pre-register now and save your spot on the Layer One X public sale
                </h3>
                <p className="pb-40">
                  Join our community of pioneers in connecting our decentralised future.
                  future.
                </p>
                <form className="get-in-touch m-0" onSubmit={(e) => this.handleSubmitForm(e)}>
                  <div className="form-floating">
                    <input
                      type="text"
                      id='name'
                      name="name"
                      className="form-control"
                      placeholder="John Sm"
                      required
                    />
                    <label>Full Name</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="support@example.com"
                      required
                    />
                    <label>Email</label>
                  </div>
                  <div className="form-floating">
                    <input
                      id="phone"
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Please include Country Code with Phone Number"
                      required
                    />
                    <label>Phone (Please include Country Code)</label>
                  </div>
                  <div className="form-floating">
                    <input
                      id="referrer_code"
                      type="text"
                      name="referrer_code"
                      className="form-control"
                      placeholder="Who Referred You to L1X?"
                    />
                    <label>Referrer Code</label>
                  </div>
                  {/* <div className="form-floating select">
                    <select className="form-control" name="field" id="field" required>
                      <option value="">I am or represent a</option>
                      <option value="Developer">Developer</option>
                      <option value="Crypto Investor">Crypto Investor</option>
                      <option value="Sophisticated Investor / VC Firm">Sophisticated Investor / VC Firm</option>

                    </select>
                  </div> */}
                  <div className="form-floating">
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="d-grid buttons">
                    <div className="btn-style btn-style-one m-0">

                      {
                        this.state.processingForm ?
                          <button className="glow-button gradient-border-mask">
                            <span>Processing...</span>
                          </button> :
                          <button type="submit" className="glow-button gradient-border-mask">
                            <span>Submit</span>
                          </button>
                      }

                    </div>
                  </div>
                </form>


                {/* <div className="buttons mb-0 text-left">
                  <a
                    className="btn btn-style-three m-0"
                    href="https://docsend.com/view/rafdthevuwmzdkjn"
                    target={"_blank"}
                  >
                    <i className="fa-solid fa-file"></i> View Investor Deck
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </section>



        {/* <section
          className="section worldsweb3 text-center"
          id="ways-to-participate"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2 className="pink-color lm-dark-color">How to buy L1X</h2>
                  <p className="pb-80">
                    While blockchain networks aren’t solely a financial
                    technology, they can be used to <br />
                    build decentralised financial and investment tools.
                  </p>
                </div>

                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Step 1</h4>
                        <h3>Set up or connect a wallet</h3>
                        <p className="mb-32 pink-color">
                          The L1X Multichain wallet will be the native wallet
                          for the L1X DeX and can hold multichain digital assets
                          at one source. The integrated bridging feature will
                          allow for a token swap between blockchains internally
                          with minimal fees.
                        </p>

                        <div className="buttons d-flex justify-content-start">
                          <div className="btn-style btn-style-one m-0">
                            <a className="glow-button gradient-border-mask">
                              <span>Set up your wallet</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={ConnectAWallet}
                          alt="Connect A Wallet"
                          className="img-fluid desktop"
                        />
                        <img
                          src={ConnectAWalletmobile}
                          alt="ConnectAWallet"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Step 2</h4>
                        <h3>Connect to L1X DeX</h3>
                        <p className="pb-0 pink-color">
                          L1X DeX is a decentralised multichain exchange
                          platform on the L1X protocol that supports multichain
                          token swaps and staking.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 order-last order-md-first order-lg-first">
                      <figure>
                        <img
                          src={L1XDeX}
                          alt="L1X DeX"
                          className="img-fluid desktop"
                        />
                        <img
                          src={L1XDeXmobile}
                          alt="L1XDeX"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Step 3</h4>
                        <h3>Sed odio pellentesque orci blandit nisl</h3>
                        <p className="pb-0 pink-color">
                          Porttitor mi dolor ut purus eu maecenas blandit
                          gravida. Venenatis tortor curabitur neque posuere ut
                          in elementum.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={Step3}
                          alt="Step 3"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Step3mobile}
                          alt="Step 3"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}

PreRegistration.propTypes = {
  theme: PropTypes.string,
};
