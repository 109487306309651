/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

export class Blog extends Component {
  render() {
    return (
      <>        

        <section className="section blogs">

        <div id="dib-posts"></div>


        </section>

    

      </>
    );
  }
}
Blog.propTypes = {
  theme: PropTypes.string,
};
