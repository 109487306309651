import React, { useEffect } from "react"
import Config from "../../config";
import closeIcon from "../../images/close-menu-lm.svg";
import storageService from "../../services/storageService";
import { servicePublicSaleBankTransferInitiate } from "../../services/apiService";
import Notify from "../../utils/notify";

const PublicSaleBankTransfer = (props) => {

    async function handleRegisterEmail() {

        let _email = storageService.getData('email');
        let _amount = storageService.getData('amount');


        try {
            let _data = {
                "email": _email,
                "investment_amount": _amount
            }

            let _transferInitiate = await servicePublicSaleBankTransferInitiate(_data);

            if (_transferInitiate.status === "success") {
                props.handleUserSteps('bankTransferEmailDetails')
            }
            else {
                Notify(_transferInitiate.message, "error")
            }

        } catch (error) {
            Notify("Error while while check initiating", "error")
        }
    }

    return (
        <div className="banner__fixed__card__box banner__fixed__width">
            <div>
                <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div>

                <div className="bank__transfer__details__heading">
                    Transfer $USD from bank account.
                </div>

                <div className="bank__transfer__details__steps">
                    <span>Follow these <strong>5 simple steps</strong> to buy L1X Coin
                        using cash from your bank account.</span>

                    <ul className="bank__transfer__details__li">
                        <li >1. Click the ‘request email’ button to receive an email with your L1X $USD International transfer instructions.</li>
                        <li>2. Your international transfer may be made online, by phone, or in person. (Depending on your individual bank’s requirements) </li>
                        <li>3. Ensure your bank transfers your contribution in $USD</li>
                        <li>4. Save a copy of the transaction receipt.</li>
                        <li>5. Click on the link provided in your email and upload your transaction receipt as proof of purchase.</li>
                    </ul>
                    <button
                        onClick={() => {
                            handleRegisterEmail()
                            // props.handleUpdateCurrentStep(2)
                            // props.handleUserSteps("publicSaleValidateCustomer")
                            // window.open("https://www.moonpay.com/en-gb", '_blank')
                        }}
                        className="btn btn-primary buy-btn-loader bank__transfer__details__btn"
                    >
                        Request Email
                    </button>
                </div>
            </div>

        </div>
    )
}

export default PublicSaleBankTransfer