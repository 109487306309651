import React, { useEffect, useState } from "react"
import Config from "../../config";
import copy from "copy-to-clipboard";
import closeIcon from "../../images/close-menu-lm.svg";
import Notify from "../../utils/notify";

const PublicSaleMoonPaymentGateway = (props) => {
    const [continueToMoonPay, setContinueToMoonPay] = useState(false)

    function copyToClipboard(data) {
        copy(data);
        return Notify("Copied!", "success");
    }

    const handlecontinue = () => {
        setContinueToMoonPay(true)
    }

    return (
        <div className="banner__fixed__card__box banner__fixed__width">
            <div>
                <div className="public_sale_early_access_header">
                    <img
                        alt="Early Access available"
                        onClick={() => props.handleUserSteps('publicSaleRegistrationForm')}
                        className="public_sale_early_acces_close_icon" src={closeIcon} />
                </div>

                <div className="public_sale_early_access_heading">
                    We use Moonpay
                </div><br></br>

                <div className="public_sale_early_access_txt">
                    <span>Follow these <strong>4 simple steps</strong> to buy L1x Coin</span><br></br>
                    <span>using a Debit/Credit Card with Moonpay.</span>
                    <br></br>
                    <br></br>
                    <span>1. Sing Up/ Login to Moonpay</span><br></br>
                    <span>2. Buy USDC (ERC20)</span><br></br>
                    <span>3. Deposit to Address:

                        <div className="deposite_addres_box mb-3">

                            <text className="deposite_to_this_address_txt">  <div>{Config.l1xWalletAddress}
                                <img
                                    onClick={() => copyToClipboard(Config.l1xWalletAddress)}
                                    className="deposite_address_icon"
                                    src={require("../../images/copy.png")}
                                    alt="copy"
                                /></div></text>
                        </div>


                    </span>

                    <span>4. Send your transaction confirmation email from Moonpay to <strong>invest@l1x.foundation</strong></span><br></br>

                    {continueToMoonPay == false ? (
                        <button
                            onClick={() => {
                                handlecontinue()
                                window.open("https://www.moonpay.com/en-gb", '_blank')
                            }}
                            className="btn btn-primary buy-btn-loader btn__purple__moonpay"
                        >
                            Continue to Moonpay

                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                props.handleUpdateCurrentStep(2)
                                props.handleUserSteps("publicSaleDirectInvestment")
                                props.updatePaymentTransactionType('3')
                            }}
                            className="btn btn-primary buy-btn-loader "
                        >
                            Proceed

                        </button>)}


                    {continueToMoonPay == false ? (
                        <button
                            onClick={() => {
                                window.open("https://blog.l1x.foundation/how-to-moonpay", '_blank')
                            }}

                            className="btn btn-primary buy-btn-loader learn__more__btn__moonpay"
                        >
                            Learn more

                        </button>) : <br></br>}

                    <div>
                        {/* <span>Need some help?</span>

                        Visit our blog post which includes a video on
                        how to complete these steps in more detail. */}
                    </div>

                </div>
            </div>

        </div>
    )
}

export default PublicSaleMoonPaymentGateway