import React from "react";
import {useEffect, useState} from "react";

function BackToTopButton() {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () =>{
            if(window.scrollY > 100){
                setBackToTopButton(true)
            } else{
                setBackToTopButton(false)
            }
        })

    },    
    [])

    const scrollUp = () =>{
        window.scrollTo({
            top:0,
            behavior: "smooth",
        })
    }

    return (
      <div className="App"> 
      {backToTopButton && (
        <button className="scroller" 
        onClick={scrollUp}
        ><i className="externamIcon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
</svg>
</i></button>
      )}
      </div>
    );
  }
  
  export default BackToTopButton;