/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Banner from "../images/banner1.png";
import Bannerhover from "../images/banner-hover.png";

import Interoperability from "../images/interoperability.png";
import Interoperabilitymobile from "../images/interoperability-mobile.png";
import Scalability from "../images/scalability.png";
import Scalabilitymobile from "../images/scalability-mobile.png";
import Security from "../images/security.png";
import Securitymobile from "../images/security-mobile.png";
import RapidSpeeds from "../images/rapid-speeds.png";
import RapidSpeedsmobile from "../images/rapid-speeds-mobile.png";
import LowCost from "../images/low-cost.png";
import LowCostmobile from "../images/low-cost-mobile.png";
import FastSecure from "../images/icons/icon-fast-build.png";
import SecureTransfer from "../images/icons/icon-secure-transfer.png";
import SafeSecure from "../images/icons/icon-safe-secure.png";
import Efficient from "../images/icons/icon-efficient.png";
import Decentralised from "../images/icons/icon-decentralised.png";
import SupplyChain from "../images/icons/icon-supply-chain.png";
import Wyde from "../images/wyde.svg";
// import L1XDex from "../images/l1xdex.svg";
import HealthLink from "../images/healthlink.svg";
import LMWyde from "../images/wyde-lm.svg";
// import LML1XDex from "../images/l1xdex-lm.svg";
import LMHealthLink from "../images/healthlink-lm.svg";
import AppsLayeronexLogo2 from "../images/builton-layeronex.png";
import LMAppsLayeronexLogo2 from "../images/builton-layeronex-lm.png";
import L1xDexLogo from "../images/L1xDexLogo.png";
import L1xDexLogoWhite from "../images/L1xDexLogoWhite.png";

import DigalabsLogo from "../images/digalabs_logo.png";
import DigalabsLogoWHITE from "../images/digalabs_logo_white.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PartnerSection from "./partner-section";
import Config from "../config";
// import { TimerContainer } from "./public-sale/timerContainer";

export class Home extends Component {
  render() {
    // const theme=this.props

    return (
      <>
        <section className="banners-section">
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="title">
                    <h1>
                      The world's fastest <br />
                      interoperable <br />
                      blockchain.
                    </h1>
                    <p>
                      Layer One X is a decentralised blockchain, providing{" "}
                      <br />
                      unrivaled interoperability, scalability, speed, and
                      security.
                    </p>
                  </div>
                  <div className="buttons d-flex justify-content-center">
                    <div className="btn-style btn-style-one">
                      <Link
                        className="glow-button gradient-border-mask"
                        to="public-sale"
                      >
                        <span>{Config.publicSaleButtonLabel}</span>
                      </Link>
                    </div>

                    <div className="btn-style  btn-style-two">
                      <Link
                        className="glow-button gradient-border-mask"
                        to="/build-on-layeronex"
                      >
                        <span>Build on Layer One X</span>
                      </Link>
                    </div>
                  </div>
                  <div className="banner-img">
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? Bannerhover
                          : Banner
                      }
                      alt="Layer One X"
                      width="727"
                      className="img-fluid normal"
                    />
                    <img
                      src={
                        this.props.themeEnabled === "light"
                          ? Banner
                          : Bannerhover
                      }
                      alt="Layer One X"
                      width="727"
                      className="img-fluid hover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PartnerSection />

        </section>

        <section className="section worldsweb3 text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    How we’re connecting the <br />
                    worlds of Web3.
                  </h2>
                  <p>
                    We developed Layer One X to be a truly decentralised,
                    permissionless, and secure blockchain enabling
                    micro-validation and tokenisation. See how fast and scalable
                    interoperability delivers more equitable outcomes for all.
                  </p>
                </div>
                <div className="buttons">
                  <a
                    className="btn btn-style-three"
                    href="https://docsend.com/view/4kbzzm4kvsq7xuv6"
                    target={"_blank"}
                  >
                    <i className="fa-solid fa-file"></i> View Litepaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                  <a
                    className="btn btn-style-three"
                    href="https://docsend.com/view/yxxumg97x5mzv77v"
                    target={"_blank"}
                  >
                    <i className="fa-solid fa-file"></i> View Whitepaper
                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </div>

                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Interoperability</h4>
                        <h3>
                          Greater interoperability with fewer integrations
                        </h3>
                        <p>
                          Rapidly build dynamic smart contracts and dApps on
                          Layer One X VM or EVM. Easily move data, digital assets,
                          content and tokens across chains, wallets or digital
                          worlds fast, securely and at low cost.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={Interoperability}
                          alt="Interoperability"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Interoperabilitymobile}
                          alt="Interoperability"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Scalability</h4>
                        <h3>Superior infrastructure for dApps that scale</h3>
                        <p>
                          Get scalability with almost instant finality. The Layer One X
                          VM on Rust opens up the potential of executing more
                          than 100,000 transactions per second with lower
                          transaction fees.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 order-last order-md-first order-lg-first">
                      <figure>
                        <img
                          src={Scalability}
                          alt="Scalability"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Scalabilitymobile}
                          alt="Scalability"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="web3">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="cont align-self-center">
                        <h4>Security</h4>
                        <h3>
                          Protected by mobile Proof-of-Participation and
                          Proof-of-Consensus
                        </h3>
                        <p className="pb-32">
                          We use hashed zero knowledge proof for fast
                          transaction validation without mining or specialist
                          hardware. Any smart device can join the decentralised
                          consensus mechanism.
                        </p>
                        <div className="buttons">
                          <Link
                            className="btn btn-style-three"
                            to="build/consensus-mechanism"
                          >
                            <i className="fa-solid fa-file"></i> Consensus
                            Mechanism
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure>
                        <img
                          src={Security}
                          alt="Security"
                          className="img-fluid desktop"
                        />
                        <img
                          src={Securitymobile}
                          alt="Security"
                          className="img-fluid mobile"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section fastest-low-cost text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    The fastest operating system on the <br />
                    blockchain costs less.
                  </h2>
                  <p className="pb-80">
                    Layer One X is immensely scalable with faster finality and
                    interoperability.
                  </p>
                </div>
              </div>
            </div>

            <div className="row fact-section justify-content-center">
              <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3> &#60;$0.00001 </h3>
                  <p>
                    Enjoy the lowest fees on <br />
                    chain
                  </p>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3>100K</h3>
                  <p>
                    Transactions per second <br />
                    (theoretical)
                  </p>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3> &#60;500ms </h3>
                  <p>
                    Network block creation <br />
                    time
                  </p>
                </div>
              </div>
              {/* <div className="col-6 col-sm-3">
                <div className="fact">
                  <h3>3333x </h3>
                  <p>Faster than Ethereum</p>
                </div>
              </div> */}
            </div>

            <div className="rapid-lowcost">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Rapid Speeds</h4>
                      <h3>Every validator makes us faster, together</h3>
                      <p className="pb-32">
                        We aim to put the world’s 8.6 billion smart devices to
                        work for micro-validation, fast confirmation, and higher
                        throughput.
                      </p>
                    </div>
                    <figure>
                      <img
                        src={RapidSpeeds}
                        alt="Rapid Speeds"
                        className="img-fluid desktop"
                      />
                      <img
                        src={RapidSpeedsmobile}
                        alt="RapidSpeeds"
                        className="img-fluid mobile"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="web3">
                    <div className="cont align-self-center">
                      <h4>Low Cost</h4>
                      <h3>Super low transaction fees with Layer One X</h3>
                      <p className="pb-32">
                        The robustness, security and efficiency of our
                        validation model lies in the random group formation and
                        leader node selection.
                      </p>
                    </div>
                    <figure className="w-full">
                      <img
                        src={LowCost}
                        alt="Low Cost"
                        className="img-fluid desktop"
                      />

                      <img
                        src={LowCostmobile}
                        alt="Low Cost"
                        className="img-fluid mobile"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section web3-usecases text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    A whole new world of Web3 <br />
                    use-cases.
                  </h2>
                  <p className="pb-80">
                    We’re helping developers with the paradigm shift from <br />{" "}
                    centralised to decentralised systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="cards-section">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={FastSecure}
                        alt="Low Cost"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Build fast, secure, inexpensive DeFi technology</h4>
                    <p className="d-none d-md-block">
                      Layer One X’s security design is effectively resilient to
                      hacks and guarantees a solid, robust base model for
                      development by traditional banks to provide digital
                      services.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={SecureTransfer}
                        alt="Low Cost"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Secure transfer of patient medical records</h4>
                    <p className="d-none d-md-block">
                      Build encrypted EMR systems connected by Healthlink
                      Protocol, giving health providers, insurers and patients
                      greater control and security for sensitive personal
                      medical records.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={SafeSecure}
                        alt="Low Cost"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>
                      Safe & secure environment for gamers and developers{" "}
                    </h4>
                    <p className="d-none d-md-block">
                      A market expected to grow to $256B by 2025, Layer One X
                      interoperability will offer improved transactions and
                      security for in-game skins, characters, items and digital
                      twins.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={Efficient}
                        alt="Efficient"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>
                      Efficient, automated smart contracts for micropayments
                    </h4>
                    <p className="d-none d-md-block">
                      Microtransactions for everyday purchases are now feasible
                      with close-to-instantaneous processing from the mempool,
                      creating a new era of rights management and commerce.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={Decentralised}
                        alt="Low Cost"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>Decentralised digital identity management</h4>
                    <p className="d-none d-md-block">
                      Reduce risks of private data loss with authorisation based
                      on smart contracts. Give users or clients greater
                      portability of their digital identity with ‘self managed’
                      services.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <figure>
                      <img
                        src={SupplyChain}
                        alt="Low Cost"
                        className="img-fluid"
                      />
                    </figure>
                    <h4>
                      Build and maintain supply chains and contracts at low
                      costs
                    </h4>
                    <p className="d-none d-md-block">
                      Mitigate disruptions with contingency pipelines.
                      Traceability with immutable records of provenance and
                      possession to ensure a consistent chain of authenticity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tect-center">
                <Link
                  className="btn btn-style-four"
                  to="/use-cases"
                >
                  See All Use Cases
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section powering-apps text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Powering Apps and services <br />
                    on the blockchain.
                  </h2>
                  <p className="pb-80 build__on_txt">
                    Layer One X is being embraced by these industry leaders.
                  </p>
                </div>
                <p className="appslayeronelogo build__img">
                  <img
                    src={
                      this.props.themeEnabled === "light"
                        ? LMAppsLayeronexLogo2
                        : AppsLayeronexLogo2
                    }
                    alt="AppsLayeronexLogo "
                    className="img-fluid"
                  />
                </p>
              </div>
            </div>
            <div className="cards-section">
              <div className="row  justify-content-center">
                <div className="col-md-12">
                  <div className="card build__on_card">
                    <div className="bg  d-flex justify-content-center">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light" ? LMWyde : Wyde
                          }
                          alt="WYDE"
                          className="img-fluid"
                        />
                      </figure>
                    </div>

                    <div className="bg d-flex justify-content-center">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light" ? L1xDexLogo : L1xDexLogoWhite
                          }
                          alt="WYDE"
                          className="img-fluid"
                        />
                      </figure>
                    </div>

                    <div className="bg d-flex justify-content-center">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light" ? LMHealthLink
                              : HealthLink
                          }
                          alt="WYDE"
                          className="img-fluid"
                        />
                      </figure>
                    </div>

                    <div className="bg d-flex justify-content-center">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light" ? DigalabsLogo : DigalabsLogoWHITE
                          }
                          alt="WYDE"
                          className="img-fluid"
                        />
                      </figure>
                    </div>

                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="card">
                    <div className="bg mathwallet d-flex justify-content-center align-item-center">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LML1XDex
                              : L1XDex
                          }
                          alt="L1XDex"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="hover-labs">
                        <p className="d-flex d-none d-md-block">
                          <img
                            src={
                              this.props.themeEnabled === "light"
                                ? LMAppsLayeronexLogo2
                                : AppsLayeronexLogo2
                            }
                            alt="AppsLayeronexLogo"
                            className="img-fluid"
                          />
                        </p>
                        <h5>By L1XLABS</h5>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="card">
                    <div className="bg manadr d-flex justify-content-center">
                      <figure className="d-flex">
                        <img
                          src={
                            this.props.themeEnabled === "light"
                              ? LMHealthLink
                              : HealthLink
                          }
                          alt="HealthLink"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="hover-labs">
                        <p className="d-flex d-none d-md-block">
                          <img
                            src={
                              this.props.themeEnabled === "light"
                                ? LMAppsLayeronexLogo2
                                : AppsLayeronexLogo2
                            }
                            alt="AppsLayeronexLogo"
                            className="img-fluid"
                          />
                        </p>
                        <h5>By L1XLABS</h5>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-12">
                <div className="title">
                  <h2>
                    Built to unite a <br />
                    decentralised future.
                  </h2>
                  <p>
                    With over 7500 cryptocurrencies in the world and counting,
                    choose to build on the one that brings them all together.{" "}
                  </p>
                </div>
                <div className="buttons d-flex justify-content-center">
                  <div className="btn-style btn-style-one">
                    <Link
                      className="glow-button gradient-border-mask"
                      to="/public-sale"
                    >
                      {/* <span>Buy L1X</span> */}
                      <span>{Config.publicSaleButtonLabel}</span>
                    </Link>
                  </div>

                  <div className="btn-style  btn-style-two">
                    <Link
                      className="glow-button gradient-border-mask"
                      to="build/build-on-layeronex"
                    >
                      <span>Build on Layer One X</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
Home.propTypes = {
  theme: PropTypes.string,
};
