/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo.svg";
import Logohover from "../images/logohover.svg";
import Logotext from "../images/logotext.png";
import LMLogotext from "../images/logotext-lm.png";
// import Menuicon from "../images/hamburger-menu.svg";
// import LMMenuicon from "../images/hamburger-menu-lm.svg";
// import Menuclose from "../images/close-menu.svg";
// import LMMenuclose from "../images/close-menu-lm.svg";
// import Preico from "../images/icon-pre-ico.svg";
// import LMPreico from "../images/icon-pre-ico-lm.svg";
import LMtoggle from "../images/dm-toggle.svg";
import DMtoggle from "../images/lm-toggle.svg";

import PropTypes from "prop-types"
import Config from "../config";


export class Header extends React.Component {

  render() {
    return (
      <header className="desktop-header">
        <div className="topbar d-flex  align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                {/* <p className="d-flex align-items-center">
                Private Sale Commitments 
                  <span className="progress">
                    <span className="progress-bar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></span>
                  </span>
                  
                  USDT: $6.5M / $10M
                </p> */}
              </div>
              <div className="col-sm-5 d-none d-lg-block">
                <div className="topnav d-flex justify-content-end">
                  <ul>
                    <li>
                      <a href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                        View Litepaper
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                        View Whitepaper
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                      </a>
                    </li>
                    {/* <li>
                          <NavLink className={({isActive}) => (isActive ? "active" : '')} to='faqs'>FAQs</Link>
                    </li> */}
                    <li>
                      <NavLink className={({ isActive }) => (isActive ? "active" : '')} to='about-us/join-the-team'>Join The Team</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg" id="navbar_top">
          <div className="container">

            <Link exact="true" to="" className="navbar-brand d-flex">
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img src={this.props.themeEnabled === 'light' ? LMLogotext : Logotext}
                alt="Logo"
                width="82"
              />
            </Link>
            {/* <a className="navbar-brand d-flex">
              <div className="logo-rotate">
                <img
                  className="normal"
                  src={Logo}
                  width="47"
                  alt="Layer One X"
                />
                <img
                  className="hover"
                  src={Logohover}
                  width="47"
                  alt="Layer One X"
                />
              </div>
              <img src={this.props.themeEnabled === 'light'? LMLogotext:Logotext}
                      alt="Logo"
                      width="82"
                      />
            </a> */}

            <div className="btn-style d-md-block d-lg-none d-xl-none d-xxl-none ml-auto mr-20">
              <Link to='public-sale' className="glow-button gradient-border-mask ">
                {/* <span>Buy L1X</span> */}
                <span>{Config.publicSaleButtonLabel}</span>
              </Link>
            </div>

            <button
              className="btn btn-primary navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span className="">
                <i className="bi bi-list"></i>
              </span>
            </button>

            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <div id="offcanvasRightLabel">
                  <Link to="" className=""> </Link>
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    className="navbar-brand"
                  >
                    <img src={Logo} width="130" alt="Layer One X" />
                  </a>
                </div>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Buy
                      </a>
                      <ul className="dropdown-menu">
                        {/* <h3>Start Investing</h3> */}
                        <li>
                          <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='public-sale'>
                            {/* Buy L1X */}
                            {Config.publicSaleButtonLabel}
                          </NavLink>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                          <a className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} href='https://blog.l1x.foundation/a-step-by-step-guide-to-buying-layer-one-x-l1x-coins' target="_blank" rel="noopener noreferrer">
                            Guide to buy L1X
                          </a>
                        </li>
                        <li>
                          <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='how-it-works'>
                            How it works
                            <span>Uniting web3 systems</span>
                          </NavLink>
                        </li>
                        {/* <li>
                          <a className="dropdown-item">
                            Tokenomics
                            <span>Use and value of L1X</span>
                          </a>
                        </li> */}
                        <hr />
                        <li>
                          <a className="dropdown-item" href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                            View Lightpaper
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                            View Whitepaper
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Build
                      </a>

                      <ul className="dropdown-menu dw-menu2">
                        <ul className="dropdown-menu2 btrright">
                          <h3>Developers</h3>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='build-on-layeronex'>
                              Build on Layer One X
                              <span>Accelerate your development</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='build/grant-applications'>
                              Grant Applications
                              <span>Get funding and build</span>
                            </NavLink>
                          </li>
                          {/* <li>
                            <a className="dropdown-item">
                              Tokenomics
                              <span>Use and value of L1X</span>
                            </a>
                          </li> */}
                          <hr />
                          <li>
                            <a className="dropdown-item" href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                              View Whitepaper
                              <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>
                          </li>
                          {/* <li>
                            <a className="dropdown-item">
                              Inspect Testnet 
                              <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Developer Resources 
                              <i className="fa fa-download"></i>
                            </a>
                          </li> */}
                        </ul>
                        <ul className="dropdown-menu2">
                          <h3>Explore</h3>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='build/consensus-mechanism'>
                              Consensus Mechanism
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='build/native-transaction-lifecycle'>
                              Native Transaction Lifecycle
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='build/interoperable-transaction-lifecycle'>
                              Interoperable Transaction Lifecycle
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} target="_blank" rel="noopener noreferrer" to='https://docsend.com/view/rycxs6bj35r72dfk'>
                              Documentation
                            </NavLink>
                          </li> */}
                        </ul>
                      </ul>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link"
                        to="https://l1xapp.com/home"
                      // role="button"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      >
                        L1XApp
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="https://gaming.l1x.foundation/"
                        target="_blank"
                      >
                        Gaming
                      </a>
                    </li>

                    {/* <li className="nav-item">
                      <Link to='wallet' className="nav-link">
                        <span>Layer One X Wallet</span>
                      </Link>
                    </li> */}
                  </ul>

                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        About Us
                      </a>
                      <ul className="dropdown-menu">
                        <h3>About Us</h3>
                        <li>
                          <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='about-us/who-we-are'>
                            Who We Are
                            <span>Our leaders and partners </span>
                          </NavLink>
                        </li>
                        <li>
                          <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href='https://blog.l1x.foundation/'>
                            Blog
                            <span>Our Latest blog post </span>
                          </a>
                        </li>
                        {/* <li>
                          <a className="dropdown-item">
                            L1X Labs
                            <span>Meet the dev team</span>
                          </a>
                        </li> */}
                      </ul>
                    </li>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Ecosystem
                      </a>
                      <ul className="dropdown-menu ">
                        <ul className="dropdown-menu2 btrright">
                          <h3><Link to='use-cases'>Use Cases
                          </Link></h3>
                          <li>
                            <NavLink className={({ isActive }) => (isActive ? "dropdown-item active" : 'dropdown-item')} to='use-cases/defi'>
                              De-Fi
                            </NavLink>
                          </li>
                          {/* <li>
                            <a className="dropdown-item">
                              Interoperability
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Digital Identity
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Multi-Chain
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Micro Payments
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Healthcare
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item">
                              Gaming
                            </a>
                          </li> */}
                        </ul>
                        {/* <ul className="dropdown-menu2">
                          <h3>Apps and services </h3>
                          <li>
                            <NavLink className={({isActive}) => (isActive ? "active" : '')} to='integrations/wyde' className="dropdown-item">
                              Wyde
                          </NavLink>
                          </li>                          
                        </ul> */}
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Connect
                      </a>
                      <ul className="dropdown-menu">
                        <h3>Connect</h3>
                        <li>
                          <NavLink className='dropdown-item' to="get-in-touch">
                            Get in Touch
                          </NavLink>
                        </li>
                        {/* <li>
                          <NavLink className='dropdown-item' to="start-building">
                            Start Building
                            <span>Connect with Layer One X Labs</span>
                          </NavLink>
                        </li> */}
                        <hr />
                        <li>
                          <NavLink className='dropdown-item' to="https://l1xapp.com/xpert/about">
                            Get Referral Code
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className='dropdown-item' to="forget-referral-code">
                            Forget Referral Code ?
                          </NavLink>
                        </li>
                        <li>
                          <a className="dropdown-item" href='https://discord.gg/LayerOneX' target={'_blank'}>
                            Discord Invite
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href='https://t.me/Layer1X' target={'_blank'}>
                            Chat On Telegram
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="https://twitter.com/LayerOneX" target={'_blank'}>
                            Follow On Twitter
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="https://www.linkedin.com/company/layer-one-x" target={'_blank'}>
                            Follow On LinkedIn
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="https://medium.com/@Layeronex" target={'_blank'}>
                            Follow On Medium
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  {/* <div className="dark-lightmode-toggle"></div> */}

                  <div className='switch dark-lightmode-toggle'>
                    {/* {theme.themeEnabled}
                    {this.props.toggleTheme} */}
                    <button onClick={this.props.toggleTheme}  >
                      <img src={this.props.themeEnabled === 'light' ? LMtoggle : DMtoggle}
                        alt="Logo"
                      />

                    </button>
                  </div>

                  <div className="btn-style d-none d-lg-block">
                    <Link to='public-sale' className="glow-button gradient-border-mask head__btn__register">
                      {/* <span>Buy L1X</span> */}
                      <span className="header__buy__btn">{Config.publicSaleButtonLabel}</span>

                    </Link>
                  </div>

                  <div className="d-md-block d-lg-none d-xl-none d-xxl-none">
                    <div className="topnav">
                      <ul>
                        <li>
                          <a href="https://docsend.com/view/4kbzzm4kvsq7xuv6" target={"_blank"}>
                            View Litepaper
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://docsend.com/view/yxxumg97x5mzv77v" target={"_blank"}>
                            View Whitepaper
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </li>
                        {/* <li>
                          <NavLink className={({isActive}) => (isActive ? "active" : '')} to='faqs'>FAQs</Link>
                        </li> */}
                        <li>
                          <NavLink className={({ isActive }) => (isActive ? "active" : '')} to='about-us/join-the-team'>Join The Team
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
Header.propTypes = {
  theme: PropTypes.string
};
Header.contextTypes = {
  router: PropTypes.object
};
