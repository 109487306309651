import React, { useState, useEffect } from "react";
import { serviceAdminGetPrivateSaleInvestors } from "../../services/adminApiService";
import logger from "../../utils/logger";
import Pagination from "./pagination";
import Notify from "../../utils/notify";

const PrivateSaleInvestors = () => {
  const [privateSaleInvestors, setPrivateSaleInvestors] = useState([]);
  const headerList = [
    "Name",
    "Email",
    "Wallet Address",
    "Total Investment",
    "L1X Allocation",
    "NFT Allocation",
  ];
  const [offset, setOffset] = useState(0);
  const [itemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);

  const handlePageChange = (activePage) => setOffset(activePage * itemsPerPage);

  const getPrivateSaleInvestors = async () => {
    try {
      setLoader(true);

      let _res = await serviceAdminGetPrivateSaleInvestors(
        offset,
        itemsPerPage
      );

      console.log("_res", _res);

      if (_res.status === "success") {
        setPrivateSaleInvestors(_res.data.transactionsList.rows);
        setTotalPages(_res.data.totalPages);
        logger("prospects Data", _res.data.transactionsList.rows);
        setLoader(false);
      } else {
        setLoader(false);
        return Notify(_res.message, "");
      }
    } catch (e) {
      setLoader(false);
      Notify("Error while fetching prospects ,please try agian", "error");
      logger("error", e.message);
    }
  };

  //   useEffect(() => {
  //     async function fetchProspectData() {
  //       await getPrivateSaleInvestors();
  //     }

  //     fetchProspectData();
  //   }, [offset,saleType]);

  return (
    <>
      <div className="row premium_wallet_list_card">
        <div className="col-md-12">
          <div className="admin-container-fluid">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-12">
                <div className="dashboard__box__num">
                  <div className="number__box__head">
                    <div>
                      <div className="searchContainer">
                        Private Sale Investors
                      </div>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              {headerList.map((item, index) => (
                                <th>{item}</th>
                              ))}
                            </tr>
                          </thead>
                          {loader === false ? (
                            <tbody>
                                       {" "}
                              {privateSaleInvestors.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.tx_hash ? item.name : "-"}</td>
                                  <td>{item.network ? item.email : "-"}</td>
                                  <td>
                                    {item.wallet_address
                                      ? item.wallet_address
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.total_investment
                                      ? item.total_investment
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.l1x_allocations
                                      ? item.l1x_allocations
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.nft_allocations
                                      ? item.nft_allocations
                                      : "-"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            ""
                          )}
                        </table>
                      </div>
                      <Pagination
                        offset={offset}
                        onPageChange={handlePageChange}
                        totalPages={totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-1"></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateSaleInvestors;
