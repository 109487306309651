import React, { useState, useEffect } from "react";
import CircularProgress from "../CircularProgress/index";
import { useLocation } from 'react-router-dom';

import L1XApp1 from "../../images/L1X-App-1.png";
import L1XApp2 from "../../images/L1X-App-2.png";
import L1XApp3 from "../../images/L1X-App-3.png";
import L1XApp4 from "../../images/L1X-App-4.png";

import {
    // servicePostReferralData,
    serviceGenerateReferralCode,
    servicePostPublicSaleReferralData,
} from "../../services/apiService";
import validator from "validator";
import UserMessage from "./userMessage";
import logger from "../../utils/logger";
import Notify from "../../utils/notify";

const ReferralForm = () => {
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showUserMessage, setShowUserMessage] = useState(false);
    const location = useLocation();
    const [isInvestor, setIsInvestor] = useState(false);

    const intialValues = {
        email: "",
        firstName: "",
        lastName: "",
        //phoneNumber: "",
        twitterUserName: "",
        youtubeUserName: "",
        referralCode: "",
        combinedFollowers: "",
        referrerCode: "",
        linkedin_username: "",
        //user_website_link: "",
        //other_link: "",
    };
    const [formValues, setFormValues] = useState(intialValues);
    const [finalReferralCode, setFinalReferralCode] = useState(null);

    const submitForm = async () => {
        try {
            let _referralCode = await getReferralCode();

            let form_data = {
                email: formValues.email,
                first_name: formValues.firstName,
                last_name: formValues.lastName,
                //contact_no: formValues.phoneNumber,
                twitter_username: formValues.twitterUserName,
                youtube_username: formValues.youtubeUserName,
                referrer_code: formValues.referrerCode,
                referral_code: _referralCode,// formValues.referralCode,
                linkedin_username: formValues.linkedin_username,
                //user_website_link: formValues.user_website_link,
                //other_link: formValues.other_link,
                combined_followers: formValues.combinedFollowers
                    ? formValues.combinedFollowers
                    : 0,
            };

            await postSubmitForm(form_data);
        } catch (e) {
            Notify("Error while submitting form", "error");
            logger("exception", e.message);
        }
    };

    async function postSubmitForm(formValues) {

        setFinalReferralCode(formValues.referral_code);

        let response = await servicePostPublicSaleReferralData(formValues);
        if (response.status === "success") {
            setIsSubmitting(false);
            setShowUserMessage(true);

            setFormValues(intialValues);
            return Notify(response.message, 'success');
        } else {
            setIsSubmitting(false);
            return Notify(response.message, 'error');
        }
    }

    // async function getReferralCode() {
    //   try {
    //     let response = await serviceGenerateReferralCode();
    //     if (response.status === "success") {
    //       setFormValues({ ...formValues, referralCode: response.data.code });

    //     } else {

    //       return Notify("Something went wrong,please try again", "error")
    //     }
    //   } catch (e) {
    //     logger("exception", e.message);
    //   }
    // }

    async function getReferralCode() {
        try {
            let response = await serviceGenerateReferralCode();
            if (response.status === "success") {
                setFormValues({ ...formValues, referralCode: response.data.code });
                return response.data.code;
            } else {
                return Notify("Something went wrong,please try again", "error")
            }
        } catch (e) {
            logger("exception", e.message);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validateValues(formValues));
    };

    function validateValues(values) {
        let errors = {};
        //const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (validator.isEmpty(values.email)) {
            errors.email = "Please enter a email address";
        } else if (!validator.isEmail(values.email)) {
            errors.email = "Invalid email format";
        } else if (validator.isEmpty(values.firstName)) {
            errors.firstName = "Please enter a first name";
        } else if (validator.isEmpty(values.lastName)) {
            errors.lastName = "Please enter a last name";
        }
        // else if (validator.isEmpty(values.referralCode)) {
        //   errors.referralCode = "Please enter a referral code";
        // } 
        // else if (!validator.isLength(values.referralCode, { max: 255 })) {
        //   errors.referralCode =
        //     "Please enter a referral code less than or equal to 255 characters";
        // }

        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
        }
        return errors;
    }


    useEffect(() => {
        if (location.state) {
            setIsInvestor(true)
            setFormValues({ ...formValues, email: location.state.email, firstName: location.state.firstName, lastName: location.state.lastName });
        }
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [isSubmitting]);

    return (
        <>
            {showUserMessage === true ? (
                <UserMessage referral_code={finalReferralCode} referral_link={finalReferralCode} />
            ) : (
                <section className="banners-section sub-banner refferal__section__bx">
                    <div className="pro-ico-live text-center d-flex align-self-center p-118">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="main__header__box">
                                        <h1>Welcome to the X_Pert program, where your journey into Web3 mastery begins.</h1>
                                        <div class="l1x__revolutionize"><p>Find all the information you need to become a part of this exciting initiative and start earning rewards while contributing to the Web3 ecosystem.</p></div>
                                        <img src={L1XApp1} className="mb-5 refferal__image__form" />
                                    </div>

                                    {/* SECTION 1 */}
                                    <section className="decentralised-section text-center d-flex align-self-center" id="buy-l1x">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-sm-12 text-start l1x__app__recurring__txt">
                                                    <div className="title sub__head__elevent ">
                                                        <h3> Become an X_Pert Referrer</h3>
                                                        <h4 className="mb-5">
                                                            The X_Pert program is your opportunity to play a pivotal role in the growth of Web3.
                                                        </h4>
                                                    </div>
                                                    <h3>L1X Foundation Buy Process</h3>
                                                    <h4 className="mb-5">Share this referral code with your network, and earn rewards when they make a purchase on L1X Public Sale. It’s a one-time benefit for each successful referral.</h4>

                                                    <h3>L1X App Recurring Bonus </h3>
                                                    <h4><span>Start earning 10% for life:</span> Generate a referral Code to start earning 10% of the recurring revenue for the lifetime value of the users you onboard in L1XApp. You won’t just earn rewards, you’ll be an integral part of creating an ecosystem experience and leading the process of onboarding the next Web3 generation of users.</h4>
                                                    <h4><span>Be part of a massive market opportunity:</span> L1XApp is poised to capture a market worth more than $250 billion in the V1 system, and it’s projected to extend into trillions of dollars. </h4>
                                                    <h4 className="mb-5"><span>Simplify the complex:</span> As an X_Pert, you’ll play a crucial role in making it accessible and seamless for users. Be part of the solution, bridging the gap between technology and everyday users.</h4>
                                                    <img src={L1XApp3} className="mb-5 refferal__image__form" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    {/* SECTION 1 - END */}
                                    {/* SECTION 2 */}
                                    <section className="decentralised-section text-center d-flex align-self-center" id="buy-l1x">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-sm-12 text-start">
                                                    <div className="title sub__head__elevent">
                                                        <h3> Benefits for becoming an X_Pert</h3>

                                                    </div>

                                                    <h4>1. Actionable intelligence: Gain access to valuable insights and data about your referrals and their Web3 activities. Use this actionable intelligence to enhance your referral strategy and maximize your earnings.</h4>

                                                    <h4>2. Access to promote Launchpad projects: As an X_Pert referrer, you’ll have the opportunity to promote cutting edge projects built on L1X. Expand your reach and income potential by showcasing these exciting entures to your network.</h4>
                                                    <h4>3. Revenue from extended services: Unlock additional revenue streams by promoting extended services offered by L1XApp.com. Earn rewards as your referrals explore and utilize the full potential of L1XApp.</h4>
                                                    <h4>4. Access to our exclusive Private Telegram group. Connect with fellow X_Perts, share insights and stay up-to-date with the latest Web3 trends and opportunities.</h4>
                                                    <img src={L1XApp4} className="mb-5 mt-5 refferal__image__form" />

                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    {/* SECTION 2 - END */}
                                    {/* SECTION 3 */}
                                    <section className="decentralised-section text-center d-flex align-self-center refferal__content__bx" id="buy-l1x">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-sm-12 text-start">
                                                    <div className="title sub__head__elevent">
                                                        <h3> How the X_Perts program benefits your followers and L1XApp Users</h3>

                                                    </div>

                                                    <h4>1. Seamless Web3 experience: Enable your users and followers to effortlessly engage in over 50% of Web3 activities, including DeFi (Staking, Swapping, Lending, and Borrowing), Gaming, and NFT-related activities. Make Web3 a breeze for them.</h4>

                                                    <h4>2. Innovative dApp Store: Offer them access to the first-of-its-kind dApp Store, a revolutionary feature that allows users to install and track all permissions and actions taken on projects from a single, secure location. Promote ownership, permissions, and security.</h4>
                                                    <h4>3. Faster, Cheaper, Secure: Provide them with faster, cheaper, and more secure access to Web3 activities, all in one place. Streamline their Web3 experience while ensuring their assets and transactions are protected.</h4>
                                                    <h4>4. Exclusive rewards: Your users and followers can enjoy exclusive rewards and benefits as they explore and engage with L1XApp.com. From special promotions to enhanced user experiences, there's something for everyone.</h4>
                                                    <h4>5. Community and support: Connect them to a vibrant community of Web3 enthusiasts. At L1XApp.com, they'll find support, guidance, and opportunities to collaborate with like-minded individuals on their Web3 journey.</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    {/* SECTION 3 - END */}



                                </div>
                                <div className="col-md-4">
                                    <div className="pro-ico refferal__form__container__bx">
                                        <h3 className="pb-18 mb-0">Become A Referrer</h3>
                                        <p style={{ textAlign: 'justify' }}>
                                            <small>
                                                Join the X_Pert program today and embark on a journey of Web3 mastery, early privileges and get lifetime rewards. We’re here to support your success in the exciting world of Web3.
                                            </small>
                                            {/* <small>
                        Get your referral code and share it with your network for them to register for a
                        whitelist spot, and you get the opportunity to earn 5% bonus referral. More details
                        will be emailed to you once you submit the form.
                      </small> */}
                                        </p>
                                        <form id="referralForm" onSubmit={handleSubmit} noValidate>
                                            <div className="mb-3">
                                                {/* <label for="" className="form-label">
                                                    Email <span className="asteric">*</span>
                                                </label> */}
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={formValues.email}
                                                    onChange={handleChange}
                                                    name="email"
                                                    readOnly={isInvestor === true ? true : false}
                                                />
                                                {formErrors.email && (
                                                    <span className="error">{formErrors.email}</span>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                {/* <label for="" className="form-label">
                                                    First Name <span className="asteric">*</span>
                                                </label> */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    value={formValues.firstName}
                                                    onChange={handleChange}
                                                    name="firstName"
                                                    readOnly={isInvestor === true ? true : false}
                                                />
                                                {formErrors.firstName && (
                                                    <span className="error">{formErrors.firstName}</span>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                {/* <label for="" className="form-label">
                                                    Last Name <span className="asteric">*</span>
                                                </label> */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    value={formValues.lastName}
                                                    onChange={handleChange}
                                                    name="lastName"
                                                    readOnly={isInvestor === true ? true : false}
                                                />
                                                {formErrors.lastName && (
                                                    <span className="error">{formErrors.lastName}</span>
                                                )}
                                            </div>
                                            {/* <div className="mb-3">
                                                
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    value={formValues.phoneNumber}
                                                    onChange={handleChange}
                                                    name="phoneNumber"
                                                />
                                                {formErrors.phoneNumber && (
                                                    <span className="error">
                                                        {formErrors.phoneNumber}
                                                    </span>
                                                )}
                                            </div> */}
                                            <div className="mb-3">
                                                {/* <label for="" className="form-label">
                                                    Twitter UserName or Link
                                                </label> */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Twitter UserName or Link"
                                                    value={formValues.twitterUserName}
                                                    onChange={handleChange}
                                                    name="twitterUserName"
                                                />
                                                {formErrors.twitterUserName && (
                                                    <span className="error">
                                                        {formErrors.twitterUserName}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="LinkedIn UserName or Link"
                                                    value={formValues.linkedin_username}
                                                    onChange={handleChange}
                                                    name="linkedin_username"
                                                />
                                                {formErrors.linkedin_username && (
                                                    <span className="error">
                                                        {formErrors.linkedin_username}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                {/* <label for="" className="form-label">
                                                    Youtube UserName or Link
                                                </label> */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Youtube UserName or Link"
                                                    value={formValues.youtubeUserName}
                                                    onChange={handleChange}
                                                    name="youtubeUserName"
                                                />
                                                {formErrors.youtubeUserName && (
                                                    <span className="error">
                                                        {formErrors.youtubeUserName}
                                                    </span>
                                                )}
                                            </div>
                                            {/* <div className="mb-3">
                        <label for="" className="form-label">
                          Your Referral Code <span className="asteric">*</span>{" "}
                          (Click Auto to Generate)
                        </label>

                        <div className="d-flex position__relative">
                          <input
                            type="text"
                            value={formValues.referralCode}
                            name="referralCode"
                            onChange={handleChange}
                            className="form-control email__generate__otp__txt__box"
                          />
                          <button
                            type="button"
                            onClick={() => getReferralCode()}
                            className="generate__otp__a"
                          >
                            Auto
                          </button>
                        </div>

                        {formErrors.referralCode && (
                          <span className="error">
                            {formErrors.referralCode}
                          </span>
                        )}
                      </div> */}

                                            {/* <div className="mb-3">
                        <label for="" className="form-label">
                          Referrer Code (who referred you?)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={formValues.referrerCode}
                          onChange={handleChange}
                          name="referrerCode"
                        />
                        {formErrors.referrerCode && (
                          <span className="error">
                            {formErrors.referrerCode}
                          </span>
                        )}
                      </div> */}
                                            <div className="mb-3">
                                                {/* <label for="" className="form-label">
                                                    Combined Followers/ Subscribers{" "}
                                                </label> */}
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=" Combined Followers/ Subscribers"
                                                    value={formValues.combinedFollowers}
                                                    onChange={handleChange}
                                                    name="combinedFollowers"
                                                />
                                                {formErrors.combinedFollowers && (
                                                    <span className="error">
                                                        {formErrors.combinedFollowers}
                                                    </span>
                                                )}
                                            </div>

                                            {isSubmitting === true ? (
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    {" "}
                                                    <CircularProgress size={30} />
                                                </div>
                                            ) : (
                                                <button type="submit" className="fixed__card__btn public_sale_register_btn">
                                                    SUBMIT
                                                </button>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default ReferralForm;
