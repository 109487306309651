import React from "react";

const PauseCampaign = () => {
  return (
    <>
      {/* <section className="banners-section"> */}
      <section className="banner-transparent">
        <div className="text-center d-flex align-self-center campaign__paused__box">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="campaign__paused__head">Currently, private campaign is paused!</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PauseCampaign;
